import React, {useState} from "react";
import Sidebar from "../components/Sidebar";
import {
    Card,
    FullCard,
    Button,
    Slider,
    RadioButton,
    Spinner,
    NewText,
} from "../components/CommonComponents";
import {DragAndDrop, BrowseComputer, FileUpload} from "../components/Fileupload";
import {summarization} from "../api/serverAPI";
import {download} from "../utils";

const UlmLimitsExtraction = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const [documentType, setDocumentType] = useState("Single");
    const [summaryType, setSummaryType] = useState("paragraphs");
    const [limit, setLimit] = useState(10);
    const [limitWords, setLimitWords] = useState(150)
    const [limitBy, setLimitBy] = useState("sentences");
    const [base64Data, setBase64Data] = useState(null);
    const [loading, setLoading] = useState(false);
    const [summarize, setSummarize] = useState([]);

    // const handleFileChange = async (e) => {
    //     const files = Array.from(e.target.files);
    //     setSelectedFiles(files);

    //     const filesData = [];
    //     for await (let file of files) {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
    //             const customData = {
    //                 filename: file.name,
    //                 content: base64String,
    //             };
    //             filesData.push(customData);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    //     setBase64Data(filesData);

    //     const interval = setInterval(() => {
    //         setProgress((prevProgress) => {
    //             if (prevProgress < 100) {
    //                 return prevProgress + 10;
    //             } else {
    //                 clearInterval(interval);

    //                 return prevProgress;
    //             }
    //         });
    //     }, 1000);
    // };

    // const handleDragOver = (e) => {
    //     e.preventDefault();
    // };

    // const handleDrop = async (e) => {
    //     e.preventDefault();
    //     const files = Array.from(e.target.files);
    //     setSelectedFiles(files);

    //     const filesData = [];
    //     for await (let file of files) {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
    //             const customData = {
    //                 filename: file.name,
    //                 content: base64String,
    //             };
    //             filesData.push(customData);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    //     setBase64Data(filesData);

    //     if (files) {
    //         if (files.type === "application/pdf") {
    //             setSelectedFiles(files);
    //         } else {
    //             alert("Please upload a PDF file.");
    //         }
    //     }

    //     const interval = setInterval(() => {
    //         setProgress((prevProgress) => {
    //             if (prevProgress < 100) {
    //                 return prevProgress + 10;
    //             } else {
    //                 clearInterval(interval);

    //                 return prevProgress;
    //             }
    //         });
    //     }, 1000);
    // };

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
        handleFile(files);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = async (e) => {
        e.preventDefault();
        console.log(e);

        const files = Array.from(e.dataTransfer.files);
        handleFile(files);
    };

    const handleFile = (files) => {
        if (files) {
            for (let file of files) {
                if (file.type !== "application/pdf") {
                    alert("Please upload a PDF file.");
                    return;
                }
            }

            setSelectedFiles(files);
            const filesData = [];
            for (let file of files) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(",")[1];
                    const customData = {
                        filename: file.name,
                        content: base64String,
                    };
                    filesData.push(customData);
                };
                reader.readAsDataURL(file);

            }
            setBase64Data(filesData);
        }
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                } else {
                    clearInterval(interval);

                    return prevProgress;
                }
            });
        }, 1000);

    };

    const GetOutput = () => {
        setLoading(true);
        const limitrange = limitBy === "words" ? limitWords : limit;
        //console.log(limitrange)
        summarization({
            summary_limit_type: limitBy,
            summary_limit: limitrange,
            summary_format: summaryType,
            files: base64Data,
        }).then((response) => {
            setSummarize(response.data.summary);
            console.log(response);
            console.log(response.data.summary);
            setLoading(false);
        });
    };

    const OutputSummary = () => {
        if (summaryType === "bullet_points") {
            return (
                <>
                    {/* <NewText text={summarize} /> */}
                    <div className="bullet" dangerouslySetInnerHTML={{__html: summarize}}/>
                </>
            );
        } else {
            return <>
                {/* {summarize} */}
                <div className="" dangerouslySetInnerHTML={{__html: summarize}}/>
            </>;
        }
    };

    const DisplayOutput = () => {
        if (loading) {
            return (
                <>
                    <div className="overlay">
                        <Spinner/>
                    </div>
                </>
            );
        }
        // if (summarize.length > 0) {
        return (
            <>
                <Card>
                    <div className="subheading text-center">
                        Here is your extracted content
                    </div>
                    <div className="my-6">
                        {/*<div className="border-2 border-[#23479F] rounded-lg">*/}
                        <div className="p-4">
                            <div className="grid grid-cols-2 gap-4">
                                <p className="text-white mt-1">
                                    <div className="flex flex-nowrap sm:flex-wrap justify-end gap-3 ml-5">
                                        {/*<div>*/}
                                        {/*    <Button size="white" onClick={download}>Download</Button>*/}
                                        {/*</div>*/}
                                        {/* <div>
                                                <Button size="outline">Feedback</Button>
                                            </div> */}
                                    </div>
                                    {/*<ul className="list-none">*/}
                                    {/*    {selectedFiles.map(*/}
                                    {/*        (file, index) => (*/}
                                    {/*            <>*/}
                                    {/*                <li*/}
                                    {/*                    key={index}*/}
                                    {/*                    className="mx-1 line-clamp-1"*/}
                                    {/*                >*/}
                                    {/*                    {file.name}*/}
                                    {/*                </li>*/}
                                    {/*            </>*/}
                                    {/*        )*/}
                                    {/*    )}*/}
                                    {/*</ul>*/}
                                </p>
                                {/*<div className="flex flex-nowrap sm:flex-wrap justify-start gap-3">*/}
                                {/*    <div>*/}
                                {/*        <Button size="white" onClick={download}>Download</Button>*/}
                                {/*    </div>*/}
                                {/*    /!* <div>*/}
                                {/*                <Button size="outline">Feedback</Button>*/}
                                {/*            </div> *!/*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        {/*<div className="bg-white overflow-auto mx-auto rounded-lg">*/}
                        {/*    <div id="page-container">*/}
                        {/*        <div className="m-6">*/}
                        {/*            <div className="spacearound">*/}
                        {/*                <OutputSummary/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        {/* <div className={"flex justify-center mt-10"}>
                                <Button onClick={download}>Download</Button>
                            </div> */}
                    </div>
                </Card>
            </>
        );
        // }
    };

    return (
        <div className="flex gap-4">
            <div className="secondcardWidth">
                <FullCard>
                    <div className="subheading text-center mt-2">
                        ULM Limits Extraction
                    </div>
                    <div className="text-center">
                        {documentType === "Single" && (
                            <>
                                <div className="my-8">
                                    <div className="items-center " onDragOver={handleDragOver} onDrop={handleDrop}>
                                        <FileUpload
                                            accept={"application/pdf"}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <ul className="list-none">
                                        {selectedFiles.map(
                                            (file, index) => (
                                                <>
                                                    <li
                                                        key={index}
                                                        className="mx-1"
                                                    >
                                                        <b>File Name: </b>
                                                        {file.name}
                                                    </li>
                                                </>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </>
                        )}
                        {/*{documentType === "Multiple" && (*/}
                        {/*    <>*/}
                        {/*        <div className="my-8">*/}
                        {/*            <div className="items-center" onDragOver={handleDragOver} onDrop={handleDrop}>*/}
                        {/*                <FileUpload*/}
                        {/*                    accept={"application/pdf"}*/}
                        {/*                    onChange={handleFileChange}*/}
                        {/*                    multiple*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="mt-4">*/}
                        {/*            <ul className="list-none">*/}
                        {/*                {selectedFiles.map(*/}
                        {/*                    (file, index) => (*/}
                        {/*                        <>*/}
                        {/*                            <li*/}
                        {/*                                key={index}*/}
                        {/*                                className="mx-1"*/}
                        {/*                            >*/}
                        {/*                                <b>File Name: </b>*/}
                        {/*                                {file.name}*/}
                        {/*                            </li>*/}
                        {/*                        </>*/}
                        {/*                    )*/}
                        {/*                )}*/}
                        {/*            </ul>*/}
                        {/*        </div>*/}
                        {/*    </>*/}
                        {/*)}*/}
                        {progress > 0 && (
                            <div className="my-4">
                                <div className="bg-[#D2CFF1] h-4 rounded-full">
                                    <div
                                        className="bg-[#23479F] h-full rounded-full"
                                        style={{
                                            width: `${progress}%`,
                                        }}
                                    ></div>
                                </div>

                                <div className="text-center mt-2">{`${progress}% Complete`}</div>
                            </div>
                        )}
                    </div>
                    {/*<div className="text-lg font-medium text-indigo-800 text-left">*/}
                    {/*    Choose from the following options*/}
                    {/*</div>*/}
                    {/*<div className={"flex flex-col my-2"}>*/}
                    {/*    <div className="my-2">*/}
                    {/*        <div className="lg:w-[200px]">*/}
                    {/*            No of Documents*/}
                    {/*        </div>*/}
                    {/*        <div className="flex items-center gap-3 my-2">*/}
                    {/*            <RadioButton*/}
                    {/*                checked={documentType === "Single"}*/}
                    {/*                type="radio"*/}
                    {/*                value={"Single"}*/}
                    {/*                name={"documenttype"}*/}
                    {/*                onChange={(e) =>*/}
                    {/*                    setDocumentType(e.target.value)*/}
                    {/*                }*/}
                    {/*                id={"radio-single"}*/}
                    {/*                htmlFor="radio-single"*/}
                    {/*                label={"Single"}*/}
                    {/*                customClassName={"orangeColor"}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <div className="flex items-center gap-3 my-2">*/}
                    {/*            <RadioButton*/}
                    {/*                checked={documentType === "Multiple"}*/}
                    {/*                name={"documenttype"}*/}
                    {/*                type="radio"*/}
                    {/*                value="Multiple"*/}
                    {/*                onChange={(e) =>*/}
                    {/*                    setDocumentType(e.target.value)*/}
                    {/*                }*/}
                    {/*                id={"radio-multiple"}*/}
                    {/*                htmlFor="radio-multiple"*/}
                    {/*                label={"Multiple"}*/}
                    {/*                customClassName={"orangeColor"}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={"flex flex-col my-2"}>*/}
                    {/*    <div className="my-2">*/}
                    {/*        <div className="lg:w-[200px]">*/}
                    {/*            Summary Format*/}
                    {/*        </div>*/}
                    {/*        <div className="flex items-center gap-3 my-2">*/}
                    {/*            <RadioButton*/}
                    {/*                checked={*/}
                    {/*                    summaryType === "bullet_points"*/}
                    {/*                }*/}
                    {/*                type="radio"*/}
                    {/*                value={"bullet_points"}*/}
                    {/*                name={"summarytype"}*/}
                    {/*                onChange={(e) =>*/}
                    {/*                    setSummaryType(e.target.value)*/}
                    {/*                }*/}
                    {/*                id={"radio-bullet"}*/}
                    {/*                htmlFor="radio-bullet"*/}
                    {/*                label={"Bullet"}*/}
                    {/*                customClassName={"orangeColor"}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <div className="flex items-center gap-3 my-2">*/}
                    {/*            <RadioButton*/}
                    {/*                checked={summaryType === "paragraphs"}*/}
                    {/*                name={"summarytype"}*/}
                    {/*                type="radio"*/}
                    {/*                value="paragraphs"*/}
                    {/*                onChange={(e) =>*/}
                    {/*                    setSummaryType(e.target.value)*/}
                    {/*                }*/}
                    {/*                id={"radio-paragraph"}*/}
                    {/*                htmlFor="radio-paragraph"*/}
                    {/*                label={"Paragraph"}*/}
                    {/*                customClassName={"orangeColor"}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="flex flex-col my-2">*/}
                    {/*    <div className="my-2">*/}
                    {/*        <div className="lg:w-[200px]">*/}
                    {/*            Limit Summary by*/}
                    {/*        </div>*/}
                    {/*        <div className="flex items-center gap-3 my-2">*/}
                    {/*            <RadioButton*/}
                    {/*                checked={limitBy === "words"}*/}
                    {/*                type="radio"*/}
                    {/*                value={"words"}*/}
                    {/*                name={"limitby"}*/}
                    {/*                onChange={(e) =>*/}
                    {/*                    setLimitBy(e.target.value)*/}
                    {/*                }*/}
                    {/*                id={"radio-words"}*/}
                    {/*                htmlFor="radio-words"*/}
                    {/*                label={"Words"}*/}
                    {/*                customClassName={"orangeColor"}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <div className="flex items-center gap-3 my-2">*/}
                    {/*            <RadioButton*/}
                    {/*                checked={limitBy === "sentences"}*/}
                    {/*                name={"limitby"}*/}
                    {/*                type="radio"*/}
                    {/*                value="sentences"*/}
                    {/*                onChange={(e) =>*/}
                    {/*                    setLimitBy(e.target.value)*/}
                    {/*                }*/}
                    {/*                id={"radio-sentences"}*/}
                    {/*                htmlFor="radio-sentences"*/}
                    {/*                label={"Sentences"}*/}
                    {/*                customClassName={"orangeColor"}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="my-2">*/}
                    {/*    {limitBy === "words" && (*/}
                    {/*        <>*/}
                    {/*            <div className="my-2 items-center">*/}
                    {/*                <div className="font-bold">*/}
                    {/*                    Enter the number of words*/}
                    {/*                </div>*/}
                    {/*                <div className="">*/}
                    {/*                    <label className="block text-gray-600 flex justify-center w-full">*/}
                    {/*                        {limitWords}*/}
                    {/*                    </label>*/}
                    {/*                    <Slider*/}
                    {/*                        min={100}*/}
                    {/*                        max={700}*/}
                    {/*                        step={50}*/}
                    {/*                        value={limitWords}*/}
                    {/*                        onChange={(e) =>*/}
                    {/*                            setLimitWords(e.target.value)*/}
                    {/*                        }*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*    {limitBy === "sentences" && (*/}
                    {/*        <>*/}
                    {/*            <div className="my-2 items-center">*/}
                    {/*                <div className="font-bold">*/}
                    {/*                    Enter the number of sentences*/}
                    {/*                </div>*/}
                    {/*                <div className="">*/}
                    {/*                    <label className="block text-gray-600 flex justify-center w-full">*/}
                    {/*                        {limit}*/}
                    {/*                    </label>*/}
                    {/*                    <Slider*/}
                    {/*                        min={5}*/}
                    {/*                        max={100}*/}
                    {/*                        step={5}*/}
                    {/*                        value={limit}*/}
                    {/*                        onChange={(e) =>*/}
                    {/*                            setLimit(e.target.value)*/}
                    {/*                        }*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    {progress === 100 && (
                        <>
                            <div className={"my-8"}>
                                <Button onClick={GetOutput}>Proceed</Button>
                            </div>
                        </>
                    )}
                </FullCard>
            </div>
            <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                <DisplayOutput/>
            </div>
        </div>
    );
};

export default UlmLimitsExtraction;
