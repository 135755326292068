import React, { useEffect, useRef, useState } from "react";
import { Button, Card, FullCard, Select, Spinner } from "../components/CommonComponents";
import {
    tutorGrade,
    tutorRoles,
    tutorSubject,
    tutorTopic,
    tutorTeacherMethods,
    tutorBoard,
    translationLanguages,
    imgThemes,
} from "../components/Mapping";
import Modal from 'react-modal';
import questionIcon from "../assets/questionIcon.svg";
import cloud from "../assets/cloud.svg";
import ansIcon from "../assets/ansIcon.svg";
import DOMPurify from 'dompurify'
import { nanoid } from "nanoid";
import { pairwise } from "../utils/hacks";
import ImageSpinner from "../components/spinner";
import SimpleDropdown from "../components/SimpleDropdown";
import {
    generateImgURL,
    languageTranslationURL,
    tutorInfoURL,
    likeTutorURL,
    dislikeTutorURL,
    chatHistoryTutorURL,
    downloadPDFTutorURL
} from "../api/serverAPI";
import { ImageUpload } from "../components/Fileupload";
import { select } from "react-cookies";
import Draggable from "react-draggable";
import axios from 'axios';
import { MathKeyboard } from "../components/mathKeyboard";
import jsPDF from 'jspdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    BadgeHelp,
    ThumbsDown,
    ThumbsUp,
    Copy,
    MessageSquarePlus,
    Download,
} from 'lucide-react';

// console.log("ngoninittttttttttttt");
// window.speechSynthesis.cancel();
/**
 * BAse-64 encoding with a URL-safe charset
 * @param {string} s the string to encode
 * @returns {string} the encoded string
 */
function b64_urlsafe(s) {
    return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace('=', '')
}

/**
 * A random string of n **bytes**.
 *
 * ..note::
 *    not all characters are a single byte. intermediate steps may contain fewer characters
 *    if String.fromCharCode returns a multi-byte sequence. This is normalized by base64.
 * @param n The number of bytes, defaults to 16
 * @returns {string} The randomly generated string.
 */
function randomString(n = 16) {
    return b64_urlsafe(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(n))))
}

// Function to format timestamp
const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // This formats the date and time in a readable format
};

// export const apiMap = {
//     teacher: {
//         base: new URL('wss://edu.genai.krtrimaiq.ai'),
//         'FAQ generation': 'teacher_bot/chat_tokens_brainstorming',
//         faq: 'teacher_bot/chat_tokens_brainstorming',
//         Brainstorming: 'teacher_bot/chat_tokens_brainstorming',
//         brainstorming: 'teacher_bot/chat_tokens_brainstorming',
//     },
//     student: {
//         base: new URL('wss://edu.genai.krtrimaiq.ai'),
//         qa: 'student_bot/chat_tokens_qa',
//         Summarization: 'student_bot/chat_tokens_sum',
//         summarization: 'student_bot/chat_tokens_sum',
//     }
// };
//TMP
export const apiMap = {
    teacher: {
        base: new URL('wss://edu.genai.krtrimaiq.ai'),
        'FAQ generation': '/teacher_bot/chat_tokens_brainstorming_new',
        faq: '/teacher_bot/chat_tokens_brainstorming_new',
        Brainstorming: '/teacher_bot/chat_tokens_brainstorming_new',
        brainstorming: '/teacher_bot/chat_tokens_brainstorming_new',
    },
    student: {
        base: new URL('wss://edu.genai.krtrimaiq.ai'),
        qa: 'student_bot/chat_tokens_qa',
        Summarization: 'student_bot/chat_tokens_sum',
        summarization: 'student_bot/chat_tokens_sum',
    }
};

export function makeEndpoint(role, endpoint) {
    var url = '';
    if (endpoint === 'Summarization' || endpoint === 'summarization') {
        url.pathname = 'ws://dev.genai.krtrimaiq.ai:6421/chat_tokens_sum'
    }
    else {
        url = apiMap[role].base;
        url.pathname = apiMap[role][endpoint];
    }
    return url;
}

// eslint-disable-next-line no-control-regex
const pattern = new RegExp('data:image/[^/]+;base64,[^\0]+\0\0');
let sender = randomString();

const TutorBot = () => {


    const [selectedRole, setSelectedRole] = useState('teacher');
    const [selectedBoard, setSelectedBoard] = useState('CBSE');
    const [endpoint, setEndpoint] = useState('faq');
    const [selectedGrade, setSelectedGrade] = useState('11');
    const [selectedSubject, setSelectedSubject] = useState('Mathematics');
    const [selectedTopic, setSelectedTopic] = useState('TRIGONOMETRIC FUNCTIONS');
    const [selectedTeacherMethod, setSelectedTeacherMethod] = useState('faq');
    const [messages, setMessages] = useState([]);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [displayQuestionInput, setDisplayQuestionInput] = useState(true);
    const [voiceInput, setVoiceInput] = useState(false);

    const latestAnswerRef = useRef(null);
    const [disableTextArea, setDisableTextArea] = useState(true);
    const [imageFlag, setImageFlag] = useState(null);
    const [translationLanguage, setTranslationLanguage] = useState('hi')
    const [translatedText, setTranslatedText] = useState(null);
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const [englishAnswer, setEnglishAnswer] = useState(null);
    const [translate, setTranslate] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [base64Data, setBase64Data] = useState(null);
    const [stream, setStream] = useState(true);
    const [autoScroll, setAutoScroll] = useState(true);
    const latestTranscriptRef = useRef('');
    const [imgLoading, setImgLoading] = useState(false);
    const [imgDropdown, setImgDropdown] = useState(false);
    const [generatedImageUrl, setGeneratedImageUrl] = useState('');
    const [textToTrans, setTextToTrans] = useState('');
    const containerRef = useRef(null);
    const closeTimeoutRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const popupRef = useRef(null);
    const [popupStyle, setPopupStyle] = useState({});
    const [info, setInfo] = useState();
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);
    const [showDownload, setShowDownload] = useState(false);
    let bIndex = 0;
    // Dummy state to force re-render when updating the global sender variable
    const [, setDummy] = useState(0);

    useEffect(() => {
        window?.speechSynthesis?.cancel();
    }, [])

    // Function to refresh sender value
    const refreshSender = () => {
        clearAllVariables();
        sender = randomString(); // Update the global sender variable
        setDummy(prev => prev + 1); // Force re-render by updating a dummy state
        // Trigger a toast notification
        toast.info(`Session Refreshed: ${sender}`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            progress: undefined,
        });
    };

    const clearAllVariables = () => {
        setSelectedRole('teacher');
        setSelectedBoard('CBSE');
        setEndpoint('faq');
        setSelectedTeacherMethod('faq');
        setMessages([]);
        setSelectedQuestionId(null);
        setLoading(false);
        setCurrentQuestion("");
        setErrorMessage("");
        setDisplayQuestionInput(true);
        setVoiceInput(false);

        latestAnswerRef.current = null;
        setDisableTextArea(true);
        setImageFlag(null);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setCurrentAnswer(null);
        setEnglishAnswer(null);
        setTranslate(false);
        setSelectedFiles(null);
        setBase64Data(null);
        setStream(true);
        setAutoScroll(true);

        // Additional variables reset
        latestTranscriptRef.current = '';
        setImgLoading(false);
        setImgDropdown(false);
        setGeneratedImageUrl('');
        setTextToTrans('');
        containerRef.current = null;
        closeTimeoutRef.current = null;
        setIsHovered(false);
        popupRef.current = null;
        setPopupStyle({});
        setInfo(null);
        setShowKeyboard(false);
        setShowPopup(false);
        setChatHistory([]);
        setSelectedSession(null);
        setSelectedSessionIndex(0);
        setShowDownload(false);
    };

    const handleInfo = async () => {
        setLoading(true);
        try {
            const url = tutorInfoURL();
            const response = await axios.get(url);
            setLoading(false);
            if (response.status === 200) {
                setInfo(response.data);
            } else {
                console.error('Failed to generate info', response.data.output);
            }
        } catch (error) {
            setLoading(false);
            console.error('An error occurred while generating info', error);
        }
    };

    //Handle colour change and checks if the likebutton is enabled
    const handleLike = (id) => (event) => {
        const likeButton = event.currentTarget.firstChild;
        const dislikeButton = document.getElementById(`dislike-bot-response-${id}`).firstChild;
        const isLikeClicked = likeButton.style.color === 'blue';
        const isDislikeClicked = dislikeButton.style.color === 'red';

        // Toggle like button color
        if (isLikeClicked) {
            likeButton.style.color = 'black'; // Unclick like
        } else {
            likeButton.style.color = 'blue';  // Click like

            // If dislike is clicked, unclick it and toggle dislike in the backend
            if (isDislikeClicked) {
                dislikeButton.style.color = 'black'; // Unclick dislike
                toggleDislike(id, isDislikeClicked);  // Call function to hit API to remove dislike
            }
        }

        // Call function to toggle like in the backend
        toggleLike(id, isLikeClicked);
    };
    //Handle colour change and checks if the dislikebutton is enabled
    const handleDislike = (id) => (event) => {
        const dislikeButton = event.currentTarget.firstChild;
        const likeButton = document.getElementById(`like-bot-response-${id}`).firstChild;
        const isLikeClicked = likeButton.style.color === 'blue';
        const isDislikeClicked = dislikeButton.style.color === 'red';

        // Toggle dislike button color
        if (isDislikeClicked) {
            dislikeButton.style.color = 'black'; // Unclick dislike
        } else {
            dislikeButton.style.color = 'red';  // Click dislike

            // If like is clicked, unclick it and toggle like in the backend
            if (isLikeClicked) {
                likeButton.style.color = 'black'; // Unclick like
                toggleLike(id, isLikeClicked);  // Call function to hit API to remove like
            }
        }

        // Call function to toggle dislike in the backend
        toggleDislike(id, isDislikeClicked);
    };
    // Function to toggle like in the backend based on the current state
    const toggleLike = (id, isLikeClicked) => {
        const url = likeTutorURL();
        const payload = {
            email: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
            session_id: sender,
            index: id
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (!isLikeClicked) {
                    toast.info("We're happy you found this helpful!", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "colored",
                    });
                }
            })
            .catch(error => {
                console.error('Error in API request:', error);
            });
    };
    // Function to toggle dislike in the backend based on the current state
    const toggleDislike = (id, isDislikeClicked) => {
        const url = dislikeTutorURL();
        const payload = {
            email: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
            session_id: sender,
            index: id
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (!isDislikeClicked) {
                    toast.info("Noted! We're always learning from your feedback", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "colored",
                    });
                }
            })
            .catch(error => {
                console.error('Error in API request:', error);
            });
    };

    const handleCopyText = (elementId) => {
        const textToCopy = document.getElementById(elementId).innerText;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success(`Response Copied to Clipboard`, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const handleTopicChange = (topic) => {
        setMessages([]);
        if (!disableTextArea) {
            setStream(false);
        }
        setSelectedTopic(topic);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    }

    //Generate LLM summary pdf for entire chat
    const generatePDFForEntireChat = async () => {
        setLoading(true);
        const apiURL = downloadPDFTutorURL();
        // Determine session_id based on selectedSession
        const downloadSessionId = selectedSession ? selectedSession.session_id : sender;
        const payload = {
            email: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
            session_id: downloadSessionId
        };

        try {
            const response = await axios.post(apiURL, payload, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'blob' // This handles the binary data response (PDF in this case)
            });

            // Create a download link and trigger it to download the file
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'summary.pdf'); // Set the file name
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // Free up the memory used by the blob URL

            console.log("PDF download triggered successfully!");
            setLoading(false);
        } catch (error) {
            console.error("Error downloading PDF: ", error);
            setLoading(false);
        }
    };
    //Generate simple pdf for one response
    const generatePDF = (question, answer) => {
        const doc = new jsPDF();

        // Add question to the PDF
        doc.setFontSize(16);
        doc.text('You:', 10, 10);
        doc.setFontSize(12);
        const wrappedQuestion = doc.splitTextToSize(question, 180); // Wrap text to fit within 180 units
        doc.text(wrappedQuestion, 10, 20);

        // Clean the HTML from the answer
        const cleanedAnswer = stripHTML(answer);

        // Add answer to the PDF
        doc.setFontSize(16);
        doc.text('Bot:', 10, 40);
        doc.setFontSize(12);
        const wrappedAnswer = doc.splitTextToSize(cleanedAnswer, 180); // Wrap text to fit within 180 units
        doc.text(wrappedAnswer, 10, 50);

        // Save the PDF
        doc.save('Chat.pdf');
    };

    const stripHTML = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    useEffect(() => {
        const classname = document.getElementsByClassName('bot-response')
        if (classname[classname.length - 1] !== undefined) {
            //  console.log("--- ", classname[classname.length-1].id);
            const id = parseInt(classname[classname.length - 1].id.replace("bot-response", ''));
            // console.log(id);
            setTimeout(() => {
                if (voiceInput == true) {
                    // readAloud(id + 1, null);

                }
            }, 2000);

        }


    }, [document.getElementsByClassName('bot-response').length])

    const handleSubjectChange = (subject) => {
        setMessages([]);
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[selectedGrade][subject][0].text);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    };

    const handleBoard = (option) => {
        setSelectedBoard(option);
    }

    const handleTranslationLanguageSelect = (option) => {
        setTranslationLanguage(option.value);
    };

    const handleSymbolClick = (symbol) => {
        // Append the clicked symbol to the current textarea value
        setCurrentQuestion((prevQuestion) => prevQuestion + symbol);
    };

    const handleLanguageTranslation = (id, msg) => {


        // Replace <img> tags with empty <div> tags and store the <img> tags
        const { modifiedHTML, imgTags } = replaceImagesWithDivsAndStore(msg);

        languageTranslationURL({
            text: modifiedHTML,
            target_lang: translationLanguage,
        }).then((response) => {
            // Restore <img> tags back to their original positions
            const restoredHTML = restoreImages(response.data['translated_text'], imgTags);
            setTranslatedText(restoredHTML);
            setMessages((m) => {
                if (m[id].fromUser) {
                    return m;
                }
                m[id].text = restoredHTML;
                return m;
            });
        }).catch(error => {
            alert("Can you please try again in sometime");
        });
    }

    const stopReadAloud = () => {


        window.speechSynthesis.cancel();

    };
    const readAloud = (id, msg) => {
        var idtsr = (parseInt(id) - 1).toString();
        var elem = document.getElementById('bot-response' + idtsr);

        window.speechSynthesis.cancel();
        if (!window.speechSynthesis) {
            console.error("Speech synthesis not supported.");
            return;
        }


        const textElement = elem.innerText.trim(); // Get the trimmed inner text
        console.log(textElement);

        if (textElement) {
            const words = textElement.split(/\s+/);


            let currentIndex = -1;
            // let currentWordIndex = -1;

            const utterance = new SpeechSynthesisUtterance(textElement);
            const isHindi = /[\u0900-\u097F]/.test(textElement); // Check for Hindi characters


            const voices = window.speechSynthesis.getVoices();
            const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
            let selectedVoice;

            if (isHindi) {
                utterance.lang = "hi-IN";
                const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
                selectedVoice = hindiVoices.find(voice => voice.name.includes("Microsoft Swara Online (Natural) - Hindi (India)")) || hindiVoices[0];
            } else {

                utterance.lang = "en-US";
                const englishVoices = voices.filter(voice => voice.lang === "en-US" && voice.gender === "male");
                selectedVoice = englishVoices.find(voice => voice.name.includes("Microsoft David Desktop - English (United States)")) || englishVoices[0];
            }

            if (selectedVoice) {
                utterance.voice = selectedVoice;
            } else {
                console.warn("No appropriate voice found, using default voice.");
            }

            utterance.addEventListener("boundary", (event) => {
                const { charIndex } = event;

                console.log(words[currentIndex])

                // highlightTheWord('bot-response' + idtsr, currentIndex);
                currentIndex++;


            });

            utterance.addEventListener("end", () => {

                console.log("Reading is completed");
            });

            window.speechSynthesis.speak(utterance);
        }
    };


    function highlightTheWord(id, wordIndex) {
        const element = document.getElementById(id);
        const words = element.innerText.split(/\s+/);
        const wordToHighlight = words[wordIndex];

        // Replace the word with itself wrapped in a span with inline styles
        const highlightedText = words.map((word, index) => {
            if (index === wordIndex) {
                console.log(word);
                return `<span style="background-color: yellow; font-weight: bold;">${word}</span>`;
            } else {
                return word;
            }
        }).join(' ');

        // Update the inner HTML of the element with the highlighted text
        element.innerHTML = highlightedText;

    }

    // function removeHighlight(element) {
    //     element.innerHTML = element.innerText; // Reset innerHTML to remove highlighting
    // }


    const handleClassChange = (classItem) => {
        setMessages([]);
        // XXX: find somethig better. i`m too tired.
        if (classItem.toLowerCase().startsWith(`btech`)) classItem = `13`;
        setSelectedGrade(classItem);
        const subject = getSubjectOptions(classItem)[0].text;
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[classItem][subject][0].text);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    };

    /**
     *
     * @param {InputEvent} e
     */
    const handleRoleChange = (e) => {
        setMessages([]);
        setSelectedFiles(null);
        const role = e.target.value.toLowerCase();
        setSelectedRole(() => role);
        if (Object.keys(apiMap[role]).indexOf(endpoint) === -1) {
            setEndpoint(role === 'teacher' ? 'faq' : 'qa');
        }
        setTranslationLanguage('hi');
        setTranslatedText(null);
    }

    /**
     *
     * @param {InputEvent} e
     */
    const handleTeacherMethod = (e) => {
        setMessages([]);
        setSelectedFiles(null);
        setSelectedTeacherMethod(() => e.target.value);
        setEndpoint(() => e.target.value);
        setTranslationLanguage('hi');
        setTranslatedText(null);
    }

    // Scroll to the latest answer
    useEffect(() => {
        const container = document.getElementById("chat-box")
        if (autoScroll || loading) {
            container.scrollTo({
                behavior: "smooth",
                top: container.scrollHeight,
            });
        }
    },
        [messages, autoScroll, loading]
    )

    const handleScroll = () => {
        const container = document.getElementById("chat-box")
        if (container.scrollTop < container.scrollHeight - container.clientHeight - 50) {
            setAutoScroll(false);
        }
        else {
            setAutoScroll(true);
        }
    }

    const scrollToAnswer = (id) => {
        setSelectedQuestionId(id);
        const answerContainer = document.getElementById(`answer-${id}`);
        if (answerContainer) {
            answerContainer.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };



    const fetchChatHistory = async () => {
        console.log("Fetching Chat History");
        try {
            setLoading(true);
            const url = chatHistoryTutorURL();
            const params = new URLSearchParams({
                "email": JSON.parse(sessionStorage.getItem("userDetails"))['user_email']
            });
            const response = await axios.get(`${url}?${params.toString()}`);
            setChatHistory(response.data.chat_history);
            setDisableTextArea(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching chat history", error);
        }
    };

    // Function to go to the next session
    const handleNextClick = () => {
        if (selectedSessionIndex < chatHistory.length - 1) {
            const newIndex = selectedSessionIndex + 1;  // Calculate the new index
            setSelectedSessionIndex(newIndex);  // Update the index state
            setSelectedSession(chatHistory[newIndex]);  // Set the session using the new index
        }
    };

    // Function to go to the previous session
    const handlePreviousClick = () => {
        if (selectedSessionIndex > 0) {
            const newIndex = selectedSessionIndex - 1;  // Calculate the new index
            setSelectedSessionIndex(newIndex);  // Update the index state
            setSelectedSession(chatHistory[newIndex]);  // Set the session using the new index
        }
    };

    // Function to handle session selection from the popup
    const handleSessionClick = (session) => {
        // Find the index of the clicked session
        const sessionIndex = chatHistory.findIndex((s) => s.session_id === session.session_id);

        if (sessionIndex !== -1) {
            // First, update the selected session index
            setSelectedSessionIndex(sessionIndex);

            // Then, update the selected session based on the new index
            setSelectedSession(chatHistory[sessionIndex]);
        }
        setShowPopup(false);
        console.log("Session index:", sessionIndex);
    };

    function replaceImagesWithDivsAndStore(htmlContent) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const images = Array.from(doc.querySelectorAll('img'));

        const imgTags = [];

        images.forEach((img) => {
            const div = document.createElement('div');
            div.style.width = img.getAttribute('width') + 'px';
            div.style.height = img.getAttribute('height') + 'px';
            div.style.border = '1px solid black';
            div.className = 'imageDiv'
            imgTags.push(img.outerHTML);
            img.parentNode.replaceChild(div, img);
        });
        return { modifiedHTML: doc.documentElement.outerHTML, imgTags };
    }

    // Function to restore <img> tags back to their original positions
    function restoreImages(htmlContent, imgTags) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const divs = doc.querySelectorAll('.imageDiv');

        divs.forEach((div, index) => {
            if (div.innerHTML === '' && imgTags[index]) {
                const img = document.createElement('img');
                const parser = new DOMParser();
                const imgDoc = parser.parseFromString(imgTags[index], 'text/html');
                const imgElement = imgDoc.querySelector('img');
                img.src = imgElement.getAttribute('src');
                img.width = imgElement.getAttribute('width');
                img.height = imgElement.getAttribute('height');
                div.parentNode.insertBefore(img, div);
                div.remove();
            }
        });
        return doc.documentElement.outerHTML;
    }

    //sending data
    const handleSendMessage = (message) => {
        setVoiceInput(false);
        console.log(message, currentQuestion);
        let webSocket;
        setDisableTextArea(false);
        setTranslate(false);
        setShowDownload(true);
        const newMessages = [...messages, { text: message, fromUser: true, index: 0, id: nanoid(24) }];
        if (currentQuestion.trim() === "") {
            setErrorMessage("text cannot be empty");
            setLoading(false);
        } else {
            setCurrentQuestion('');
            setLoading(true);
            setErrorMessage("");

            setMessages(newMessages);
            let first = true;
            const sp = new URLSearchParams();
            sp.set('sender', sender); // TODO: handle collisions
            sp.set('message', message)
            sp.set('grade', `class${selectedGrade}`)
            sp.set('subject', selectedSubject.toLowerCase())
            sp.set('topic', selectedTopic)
            sp.set('board', selectedBoard)
            sp.set(`email`, JSON.parse(sessionStorage.getItem("userDetails"))['user_email'])
            if (selectedFiles) {
                sp.set('images', 'True')
            }

            const apiUrl = makeEndpoint(selectedRole, endpoint)
            apiUrl.search = sp.toString();
            webSocket = new WebSocket(apiUrl.href);

            // Event listener for when the connection is opened
            webSocket.addEventListener('open', (event) => {
                console.log('WebSocket connection opened:', event);
                if (selectedFiles) {
                    webSocket.send(JSON.stringify({ 'images': [base64Data] }));
                }
            });
            setInterval(() => {
                window.renderMathInElement(document.body, {
                    delimiters: [
                        { left: "$$", right: "$$", display: true },
                        { left: "$", right: "$", display: false },
                        { left: "\\(", right: "\\)", display: false },
                        { left: "\\[", right: "\\]", display: true }
                    ]
                });
            }, 10);
            // Event listener for when a message is received from the server
            webSocket.addEventListener('message', (event) => {
                setLoading(false);
                // Handle the received message here
                const data = JSON.parse(event.data);

                const numberOfImages = data[0]?.noofimages;
                if (data[0] && numberOfImages) {
                    setImageFlag(numberOfImages);
                }
                if (data[0] && data[0].text) {
                    setEnglishAnswer(data[0].text);
                    if (translate) {
                        setCurrentAnswer(translatedText);
                    } else {
                        setCurrentAnswer(data[0].text);
                    }

                    /** @type {string} */
                    let text = data[0].text;
                    let images = data[0].images ?? [];
                    setImageFlag(null);
                    while (text.indexOf('\0') !== -1) {
                        const start = text.indexOf('data:image/');
                        const end = text.indexOf('\0\0');
                        if (start !== -1 && end !== -1) {
                            images.push(text.substring(start, end + 1));
                            text = text.substring(0, start) + text.substring(end + 2);
                        }
                    }
                    data[0].images = images;
                    data[0].text = text;
                }
                setTimeout(() => setMessages((messages) => {
                    let m;
                    if (!(data[0] && data[0].text)) return messages;
                    if (!first) {
                        m = messages.slice(0, -1);
                    } else {
                        first = false;
                        m = messages;
                    }
                    const msg = { text: data[0].text, fromUser: false, images: data[0].images ?? [] }
                    return [...m, msg];
                }), 250);
            });

            // Event listener for when the connection is closed
            webSocket.addEventListener('close', (event) => {
                console.log('WebSocket connection closed:', event);
                setDisableTextArea(true);
                setTranslatedText(null);
                setTranslate(true);
                setTranslationLanguage('hi');
            });

            // Clean up the WebSocket connection when the component is unmounted
            return () => {
                webSocket.close();
            };

        }
    };


    const handleSendMessageSpeech = (message) => {
        console.log(message);
        let webSocket;
        setDisableTextArea(false);
        setTranslate(false);
        const newMessages = [...messages, { text: message, fromUser: true, index: 0, id: nanoid(24) }];
        if (message.trim() === "") {
            setErrorMessage("text cannot be empty");
            setLoading(false);
        } else {
            setCurrentQuestion('');
            setLoading(true);
            setErrorMessage("");

            setMessages(newMessages);
            let first = true;
            const sp = new URLSearchParams();
            sp.set('sender', sender); // TODO: handle collisions
            sp.set('message', message)
            sp.set('grade', `class${selectedGrade}`)
            sp.set('subject', selectedSubject.toLowerCase())
            sp.set('topic', selectedTopic)
            sp.set('board', selectedBoard)
            if (selectedFiles) {
                sp.set('images', 'True')
            }

            const apiUrl = makeEndpoint(selectedRole, endpoint)
            apiUrl.search = sp.toString();
            webSocket = new WebSocket(apiUrl.href);

            // Event listener for when the connection is opened
            webSocket.addEventListener('open', (event) => {
                console.log('WebSocket connection opened:', event);
                if (selectedFiles) {
                    webSocket.send(JSON.stringify({ 'images': [base64Data] }));
                }
            });
            setInterval(() => {
                window.renderMathInElement(document.body, {
                    delimiters: [
                        { left: "$$", right: "$$", display: true },
                        { left: "$", right: "$", display: false },
                        { left: "\\(", right: "\\)", display: false },
                        { left: "\\[", right: "\\]", display: true }
                    ]
                });
            }, 10);
            // Event listener for when a message is received from the server
            webSocket.addEventListener('message', (event) => {
                setLoading(false);
                // Handle the received message here
                const data = JSON.parse(event.data);
                const numberOfImages = data[0]?.noofimages;
                if (data[0] && numberOfImages) {
                    setImageFlag(numberOfImages);
                }
                if (data[0] && data[0].text) {
                    setEnglishAnswer(data[0].text);
                    if (translate) {
                        setCurrentAnswer(translatedText);
                    } else {
                        setCurrentAnswer(data[0].text);
                    }

                    /** @type {string} */
                    let text = data[0].text;
                    let images = data[0].images ?? [];
                    setImageFlag(null);
                    while (text.indexOf('\0') !== -1) {
                        const start = text.indexOf('data:image/');
                        const end = text.indexOf('\0\0');
                        if (start !== -1 && end !== -1) {
                            images.push(text.substring(start, end + 1));
                            text = text.substring(0, start) + text.substring(end + 2);
                        }
                    }
                    data[0].images = images;
                    data[0].text = text;
                }
                setTimeout(() => setMessages((messages) => {
                    let m;
                    if (!(data[0] && data[0].text)) return messages;
                    if (!first) {
                        m = messages.slice(0, -1);
                    } else {
                        first = false;
                        m = messages;
                    }
                    const msg = { text: data[0].text, fromUser: false, images: data[0].images ?? [] }
                    return [...m, msg];
                }), 250);
            });

            // Event listener for when the connection is closed
            webSocket.addEventListener('close', (event) => {
                console.log('WebSocket connection closed:', event);
                setDisableTextArea(true);
                setTranslatedText(null);
                setTranslate(true);
                setTranslationLanguage('hi');
            });

            // Clean up the WebSocket connection when the component is unmounted
            return () => {
                webSocket.close();
            };

        }
    };

    const handleAutomaticSendMessage = (message) => {

        // Check if the message is empty
        if (message.trim() === "") {
            setErrorMessage("Text cannot be empty");
            setLoading(false);
            return;
        }
        let webSocket;
        setErrorMessage("");
        setLoading(true);

        // Add message to state
        const newMessages = [
            ...messages,
            { text: message, fromUser: true, index: 0, id: nanoid(24) },
        ];
        setVoiceInput(false);
        setTranslate(false);
        setShowDownload(true);
        setDisableTextArea(false);
        setLoading(true);
        setErrorMessage("");
        setCurrentQuestion("");
        setMessages(newMessages);
        let first = true;
        const sp = new URLSearchParams();
        sp.set('sender', sender); // TODO: handle collisions
        sp.set('message', message)
        sp.set('grade', `class${selectedGrade}`)
        sp.set('subject', selectedSubject.toLowerCase())
        sp.set('topic', selectedTopic)
        sp.set('board', selectedBoard)
        sp.set(`email`, JSON.parse(sessionStorage.getItem("userDetails"))['user_email'])
        if (selectedFiles) {
            sp.set('images', 'True')
        }

        const apiUrl = makeEndpoint(selectedRole, endpoint)
        apiUrl.search = sp.toString();
        webSocket = new WebSocket(apiUrl.href);

        // Event listener for when the connection is opened
        webSocket.addEventListener('open', (event) => {
            console.log('WebSocket connection opened:', event);
            if (selectedFiles) {
                webSocket.send(JSON.stringify({ 'images': [base64Data] }));
            }
        });
        setInterval(() => {
            window.renderMathInElement(document.body, {
                delimiters: [
                    { left: "$$", right: "$$", display: true },
                    { left: "$", right: "$", display: false },
                    { left: "\\(", right: "\\)", display: false },
                    { left: "\\[", right: "\\]", display: true }
                ]
            });
        }, 10);
        // Event listener for when a message is received from the server
        webSocket.addEventListener('message', (event) => {
            setLoading(false);
            // Handle the received message here
            const data = JSON.parse(event.data);

            const numberOfImages = data[0]?.noofimages;
            if (data[0] && numberOfImages) {
                setImageFlag(numberOfImages);
            }
            if (data[0] && data[0].text) {
                setEnglishAnswer(data[0].text);
                if (translate) {
                    setCurrentAnswer(translatedText);
                } else {
                    setCurrentAnswer(data[0].text);
                }

                /** @type {string} */
                let text = data[0].text;
                let images = data[0].images ?? [];
                setImageFlag(null);
                while (text.indexOf('\0') !== -1) {
                    const start = text.indexOf('data:image/');
                    const end = text.indexOf('\0\0');
                    if (start !== -1 && end !== -1) {
                        images.push(text.substring(start, end + 1));
                        text = text.substring(0, start) + text.substring(end + 2);
                    }
                }
                data[0].images = images;
                data[0].text = text;
            }
            setTimeout(() => setMessages((messages) => {
                let m;
                if (!(data[0] && data[0].text)) return messages;
                if (!first) {
                    m = messages.slice(0, -1);
                } else {
                    first = false;
                    m = messages;
                }
                const msg = { text: data[0].text, fromUser: false, images: data[0].images ?? [] }
                return [...m, msg];
            }), 250);
        });

        // Event listener for when the connection is closed
        webSocket.addEventListener('close', (event) => {
            console.log('WebSocket connection closed:', event);
            setDisableTextArea(true);
            setTranslatedText(null);
            setTranslate(true);
            setTranslationLanguage('hi');

        });

        // Clean up the WebSocket connection when the component is unmounted
        return () => {
            webSocket.close();
        };
    };



    function getSubjectOptions(grade) {
        return (tutorSubject.filter(subject => (tutorGrade.find(item => item.code === grade).subjects).includes(subject.code)));

    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFiles(file);

        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
            setBase64Data(base64String);
        };
        reader.readAsDataURL(file);
    };

    const ImageUpload = ({ accept, onChange, multiple }) => {
        const fileInputRef = useRef(null);
        return (
            <div>
                <div className="inline-flex">
                    <button
                        type="button"
                        className="p-2 bg-white border-2 border-blue font-bold text-lg text-blue rounded-lg hover:bg-light-purple"
                        onClick={() => fileInputRef.current.click()}
                    >
                        <img
                            src={cloud}
                            alt=""
                            width={"100px"}
                            height={"100px"}
                            className="text-blue"
                        />
                    </button>
                    <input
                        type="file"
                        name="file_upload"
                        className="hidden inset-0 opacity-0 cursor-pointer"
                        ref={fileInputRef}
                        accept={accept}
                        onChange={onChange}
                        multiple={multiple}
                    />
                </div>
            </div>
        );
    };



    const [listening, setListening] = useState(false);
    const [transcription, setTranscription] = useState('');
    const handleStartListening = () => {
        setVoiceInput(true);
        const recognition = new window.webkitSpeechRecognition();
        // recognition.lang = 'en-US';
        if (selectedSubject == 'English') {
            recognition.lang = 'en-US';
        }
        else if (selectedSubject == 'Hindi') {
            recognition.lang = 'hi-IN';
        }
        recognition.start();

        recognition.onstart = () => {
            setListening(true);
        };

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            console.log(transcript);
            setModalIsOpen(true);
            latestTranscriptRef.current = transcript;

        };

        recognition.onend = () => {
            setListening(false);
            closeModal();
            setTimeout(function () {
                const transcript = latestTranscriptRef.current;
                setCurrentQuestion(transcript);
                handleSendMessageSpeech(transcript);
            }, 1000);
        };

        recognition.onerror = (event) => {
            console.error('Speech recognition error detected: ', event.error);
            setListening(false);
        };
    };

    const openDialog = () => {
        setModalIsOpen(true);
        handleStartListening();

    };
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const closeModal = () => {
        setModalIsOpen(false);
        // setDisplayQuestionInput(false);
        // console.log(currentQuestion);
        // handleSendMessage(currentQuestion, stream);
    };

    const [selectedText, setSelectedText] = useState('');
    const [buttonVisible, setButtonVisible] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        const handleSelection = () => {

            // console.log()
            if (document.getSelection().focusNode && document.getSelection().focusNode.parentElement.closest('div')?.classList.contains('bot-response')) {
                const text = document.getSelection().toString();
                setSelectedText(text);
                setTextToTrans(text);

                // Calculate position based on the selection
                const selection = window.getSelection();
                if (selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0).getBoundingClientRect();
                    console.log(range.top + window.scrollY + range.height / 2);
                    console.log(range.right + window.scrollX + 10);

                    setButtonPosition({
                        top: range.top + window.scrollY + range.height / 2, // Adjust position to be centered vertically
                        left: range.right + window.scrollX + 10, // Position the button slightly to the right of the selection
                    });
                    console.log(selection);
                    console.log(!!text);
                    setButtonVisible(!!text); // Show button only if text is selected
                }
            } else {
                setSelectedText('');
                setButtonVisible(false);
            }
        };

        document.addEventListener('mouseup', handleSelection);

        return () => {
            document.removeEventListener('mouseup', handleSelection);
        };
    }, []);

    const handleReadAloud = () => {
        if (selectedText) {
            console.log(selectedText);

            window.speechSynthesis.cancel();
            if (!window.speechSynthesis) {
                console.error("Speech synthesis not supported.");
                return;
            }
            const utterance = new SpeechSynthesisUtterance(selectedText);

            const isHindi = /[\u0900-\u097F]/.test(selectedText); // Check for Hindi characters


            const voices = window.speechSynthesis.getVoices();
            const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
            let selectedVoice;

            if (isHindi) {
                utterance.lang = "hi-IN";
                const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
                selectedVoice = hindiVoices.find(voice => voice.name.includes("Microsoft Swara Online (Natural) - Hindi (India)")) || hindiVoices[0];
            } else {

                utterance.lang = "en-US";
                const englishVoices = voices.filter(voice => voice.lang === "en-US" && voice.gender === "male");
                selectedVoice = englishVoices.find(voice => voice.name.includes("Microsoft David Desktop - English (United States)")) || englishVoices[0];
            }

            if (selectedVoice) {
                utterance.voice = selectedVoice;
            } else {
                console.warn("No appropriate voice found, using default voice.");
            }

            window.speechSynthesis.speak(utterance);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setButtonVisible(false);
            setGeneratedImageUrl('');
            setImgDropdown(false);
        }
    };

    const handleGenerateImg = async (word, theme) => {
        setImgLoading(true);
        try {
            const data = {
                "sender": sender,
                "message": word,
                "theme": theme.value
            };

            const url = generateImgURL(); // get only the URL

            const response = await axios.post(url, data); // send URL and payload

            if (response.status === 200) {
                setGeneratedImageUrl(response.data.image_url);
            } else {
                console.error('Failed to generate image', response.data.image_url);
            }
        } catch (error) {
            console.error('An error occurred while generating the image', error);
        } finally {
            setImgLoading(false);
            setTextToTrans('');
            setImgDropdown(false);
        }
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
        if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
        }
        adjustPopupPosition();
    };

    const handleMouseLeave = () => {
        closeTimeoutRef.current = setTimeout(() => {
            setIsHovered(false);
            // setDropdownVisible(false);
            setTranslationLanguage('hi');
        }, 300); // Delay closing by 300ms
    };

    const adjustPopupPosition = () => {
        if (popupRef.current) {
            const { innerWidth, innerHeight } = window;
            const { offsetWidth, offsetHeight } = popupRef.current;
            const newTop = Math.min(buttonPosition.top, innerHeight - offsetHeight - 10);
            const newLeft = Math.min(buttonPosition.left, innerWidth - offsetWidth - 10);
            setPopupStyle({
                top: newTop,
                left: newLeft,
                maxWidth: 'calc(33vw - 10px)',  // 1/3 of viewport width with 10px padding
                maxHeight: 'calc(33vh - 10px)', // 1/3 of viewport height with 10px padding
                overflow: 'auto'
            });
        }
    };

    useEffect(() => {
        const updatePopupPosition = () => {
            if (popupRef.current) {
                const { innerWidth, innerHeight } = window;
                const { offsetWidth, offsetHeight } = popupRef.current;
                const newTop = Math.min(buttonPosition.top, innerHeight - offsetHeight - 10);
                const newLeft = Math.min(buttonPosition.left, innerWidth - offsetWidth - 10);
                setPopupStyle({
                    top: newTop,
                    left: newLeft,
                    maxWidth: 'calc(33vw - 10px)',  // 1/3 of viewport width with 10px padding
                    // maxHeight: 'calc(33vh - 10px)', // 1/3 of viewport height with 10px padding
                    height: 'auto',
                    overflow: 'auto'
                });
            }
        };

        updatePopupPosition();
        window.addEventListener('resize', updatePopupPosition);
        return () => window.removeEventListener('resize', updatePopupPosition);
    }, [buttonPosition, isHovered]);

    return (
        <div className="flex gap-4">
            {info && (
                <Draggable>
                    <div
                        className={`z-50 absolute w-3/5 h-3/4 bg-white rounded-md shadow-lg overflow-auto p-4 items-center`}>
                        <button
                            className="absolute top-4 right-4 text-black"
                            onClick={(e) => { setInfo() }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 16 16">
                                <path fill="currentColor" fill-rule="evenodd" d="M13.5 8a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0M6.53 5.47a.75.75 0 0 0-1.06 1.06L6.94 8L5.47 9.47a.75.75 0 1 0 1.06 1.06L8 9.06l1.47 1.47a.75.75 0 1 0 1.06-1.06L9.06 8l1.47-1.47a.75.75 0 1 0-1.06-1.06L8 6.94z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <div dangerouslySetInnerHTML={{ __html: info }}>
                        </div>
                    </div>
                </Draggable>
            )}
            <div ref={containerRef}>
                {!(generatedImageUrl) && (buttonVisible) && (
                    <div
                        ref={popupRef}
                        style={popupStyle}
                        className={`z-50 absolute h-auto flex-col bg-white rounded-md shadow-lg border-2 ${isHovered ? 'p-4' : 'p-2'}`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <button
                            className="flex space-x-2 cursor-pointer hover:bg-gray-200 hover:w-full p-2 hover:rounded-lg"
                            onClick={() => {
                                setImgDropdown(false);
                                handleReadAloud();
                            }}
                            aria-label="Read Aloud"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" viewBox="0 0 16 16">

                                <path fill="currentColor" d="M10.044 1.498a.75.75 0 0 1 .958-.454c.214.09.001 0 .001 0h.001l.003.001l.005.003l.015.005a2 2 0 0 1 .19.08c.118.056.28.138.47.253c.379.23.876.596 1.37 1.143C14.057 3.636 15 5.44 15 8.249a.75.75 0 0 1-1.5 0c0-2.44-.807-3.885-1.556-4.715a4.7 4.7 0 0 0-1.036-.865a3 3 0 0 0-.401-.209l-.014-.005a.75.75 0 0 1-.45-.957M7.198 3.475a.75.75 0 0 0-1.395 0l-3.75 9.5a.75.75 0 0 0 1.395.55l.898-2.275h4.308l.898 2.275a.75.75 0 1 0 1.396-.55zm.864 6.275H4.938L6.5 5.793zm2.668-6.076a.75.75 0 0 0-.962 1.15l.006.006l.034.03q.049.045.139.136c.12.123.28.304.44.53c.327.463.613 1.063.613 1.724a.75.75 0 0 0 1.5 0c0-1.088-.464-1.989-.887-2.588a6 6 0 0 0-.853-.962l-.02-.017l-.006-.005l-.002-.002zm-.962 1.15l.001.002Z" />
                            </svg>
                            {isHovered && <span className="ml-2 text-gray-800">Read Aloud</span>}
                        </button>
                        <button
                            className="flex space-x-2 cursor-pointer hover:bg-gray-200 hover:w-full p-2 hover:rounded-lg"
                            onClick={() => {
                                setImgDropdown(true);
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" viewBox="0 0 256 256">
                                <path fill="currentColor" d="M144 96a16 16 0 1 1 16 16a16 16 0 0 1-16-16m92-40v144a20 20 0 0 1-20 20H40a20 20 0 0 1-20-20V56a20 20 0 0 1 20-20h176a20 20 0 0 1 20 20M44 60v79.72l33.86-33.86a20 20 0 0 1 28.28 0L147.31 147l17.18-17.17a20 20 0 0 1 28.28 0L212 149.09V60Zm0 136h118.34L92 125.66l-48 48Zm168 0v-13l-33.37-33.37L164.28 164l32 32Z" />
                            </svg>
                            {isHovered && <span>Generate Image</span>}
                        </button>
                    </div>
                )}
                {(imgDropdown) && (
                    <div
                        style={{ top: popupStyle.top - 10 + "px", left: popupStyle.left - 120 + "px", height: '200px', overflowY: 'scroll' }}
                        className="absolute left-full top-0 mt-2 ml-2 flex flex-col space-y-2 bg-white rounded-md shadow-lg border-2 z-50 p-2"
                    // onClick={(e) => e.stopPropagation()}
                    >
                        {imgThemes.map((themes) => (
                            <div className="cursor-pointer hover:bg-[#23479F] hover:text-[#ffffff] rounded p-2 border-1" key={themes.label} onClick={() => {
                                handleGenerateImg(textToTrans, themes);
                            }}>
                                {themes?.label}
                            </div>
                        ))}
                    </div>
                )}
                {(imgLoading) && (
                    <Draggable>
                        <div
                            className={`z-50 absolute flex justify-center items-center inset-0 m-auto w-1/3 h-1/3 bg-white rounded-md shadow-lg space-y-2 border-2 p-4`}>
                            <Spinner />
                        </div>
                    </Draggable>
                )}
                {!(imgLoading) && (generatedImageUrl) && (
                    <Draggable>
                        <div
                            className={`z-50 absolute flex justify-center items-center inset-0 m-auto w-1/2 h-1/2 bg-white rounded-md shadow-lg space-y-2 border-2 p-4 overflow-hidden`}>
                            <div dangerouslySetInnerHTML={{ __html: generatedImageUrl }} >{/* class="select-none" should be from backend */}
                            </div>
                        </div>
                    </Draggable>
                )}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal-dialog"
                overlayClassName="modal-overlay"
            >
                <div className="modal-content">
                    <h2 className="modal-title">Voice Search</h2>
                    {listening ? 'Listening...' : ' '}
                    {currentQuestion}
                </div>
                <button className="modal-close-btn" onClick={closeModal}>
                    Close
                </button>
            </Modal>
            <div className="secondcardWidth -ml-4">
                <FullCard>
                    <div className="">
                        <div className="flex justify-between items-center mt-2">
                            <div className="subheading text-center">
                                Editorial Assistant
                            </div>
                            <button onClick={refreshSender} className="flex items-center mx-2">
                                <MessageSquarePlus className="mr-2" size={20} />
                            </button>
                            <button type="button" className="text-blue-500" onClick={handleInfo} aria-label="Info">
                                <BadgeHelp className="mr-2" size={20} />
                            </button>
                        </div>
                        {/* ToastContainer will show notifications */}
                        <ToastContainer />
                        <div className="my-8">
                            <div className={"flex justify-center my-8"}>
                                <Button onClick={() => {
                                    fetchChatHistory();
                                    setShowPopup(true);
                                }}>Chat History</Button>
                            </div>
                        </div>
                        <div className="my-8">
                            <div className="text-lg font-medium text-indigo-800 text-left my-2">Select Roles</div>
                            <div className="w-full">
                                <Select dropdownData={tutorRoles}
                                    value={selectedRole[0].toUpperCase() + selectedRole.substring(1)}
                                    onChange={handleRoleChange} />
                            </div>
                        </div>
                        {/* {selectedRole === 'teacher' &&
                            <div className="my-8">
                                <div className="text-blue text-lg font-semibold my-2">Select method</div>
                                <Select dropdownData={tutorTeacherMethods} value={selectedTeacherMethod}
                                    onChange={handleTeacherMethod} />
                            </div>
                        } */}
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Board</div>
                            <Select dropdownData={tutorBoard} value={selectedBoard}
                                onChange={(e) => handleBoard(e.target.value)}
                            />
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Grade</div>
                            <Select dropdownData={tutorGrade}
                                value={selectedGrade === '13' ? 'Btech 1st year' : selectedGrade}
                                onChange={(e) => handleClassChange(e.target.value)} />
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Subject</div>
                            <Select dropdownData={getSubjectOptions(selectedGrade)} value={selectedSubject}
                                onChange={(e) => handleSubjectChange(e.target.value)}
                            />
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Topics</div>
                            <Select dropdownData={tutorTopic[selectedGrade][selectedSubject] || []}
                                value={selectedTopic}
                                onChange={(e) => handleTopicChange(e.target.value)} />
                        </div>
                        {/* Display previous questions lists */}
                        <div className="my-8">
                            {messages.slice().reverse().map((entry, index) => (
                                <>
                                    {(index < 1) ? (
                                        <div className="my-3 text-lg font-medium text-indigo-800 text-left my-2">
                                            Previous Questions
                                        </div>
                                    ) : null}
                                    {entry?.fromUser &&
                                        <div
                                            key={entry.id}
                                            className={`border border-[#23479F] rounded-lg text-[#23479F] px-4 p-3 mb-3 ${entry.id === selectedQuestionId ? "bg-[#385EBA] text-white" : ""
                                                }`}
                                            onClick={() => scrollToAnswer(entry.id)}
                                        >
                                            <div className="line-clamp-1">
                                                {entry.text}
                                            </div>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    </div>
                </FullCard>
            </div>
            <div className="thirdcardWidth ">
                {/* popup modal */}
                {showPopup && (
                    <Draggable>
                        <div className="z-50 absolute flex flex-col justify-between items-center inset-0 m-auto w-3/4 h-3/4 bg-white rounded-md shadow-lg border-2 p-4 overflow-hidden">
                            <div className="subheading flex justify-center text-center mb-4">
                                Chat History
                            </div>
                            <div className="flex-1 overflow-y-auto grid grid-cols-1 gap-4 w-full">
                                {chatHistory.map((session, index) => (
                                    <button
                                        key={index}
                                        className="flex flex-col p-4 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 transition-all"
                                        onClick={() => {
                                            handleSessionClick(session);
                                            setShowPopup(false);
                                            setShowDownload(true);
                                        }}>
                                        <div className="mb-2"><strong>User:</strong>
                                            <span dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(session.data[0]?.user)
                                            }} />
                                        </div>
                                        <div><strong>Timestamp:</strong> {formatDate(session.data[0]?.timestamp)}</div>
                                    </button>
                                ))}
                            </div>
                            {/* Button Row */}
                            <div className="flex gap-4 mt-4">
                                {/* Close button with icon */}
                                <Button
                                    onClick={() => { setShowPopup(false); setDisableTextArea(true); }}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Draggable>
                )}
                <div className="chat-container relative">
                    {(showDownload) && (<div>
                        <div className="absolute top-4 right-6 z-50">
                            <button
                                onClick={() => generatePDFForEntireChat()}
                            >
                                <Download />
                            </button>
                        </div>
                    </div>)}
                    <div className={`overflow-auto ${selectedRole === 'student' ? "h-[80vh]" : "h-[80vh]"}`} // ${selectedRole === 'student' ? "h-[70vh]" : "h-[78vh]"}
                        onScroll={handleScroll}
                        id="chat-box">
                        {/* Chat history */}
                        {selectedSession && (
                            <div
                                onScroll={handleScroll}
                            >
                                <div className="flex-1 grid grid-cols-1 gap-4 w-full">
                                    {selectedSession.data.map((item, index) => (
                                        <Card>
                                            <div className="chat-message relative">
                                                <div key={index} className="m-5">
                                                    <div className="flex">
                                                        <div className="w-1/12">
                                                            <img
                                                                src={questionIcon}
                                                                alt=""
                                                                width={"50px"}
                                                                height={"50px"}
                                                                className=""
                                                            />
                                                        </div>
                                                        <div className="w-11/12 ml-4 bg-white rounded-lg text-xl mb-4">
                                                            {item.user && <div className="w-full p-2"> {item.user}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="w-1/12">
                                                            <img
                                                                src={ansIcon}
                                                                alt=""
                                                                width={"50px"}
                                                                height={"50px"}
                                                                className=""
                                                            />
                                                        </div>
                                                        <div className="w-11/12 ml-4 bg-white rounded-lg text-xl mb-4">
                                                            {item.bot &&
                                                                <div
                                                                    className="w-full p-2"
                                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.bot) }}
                                                                ></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    ))}
                                </div>
                            </div>
                        )}
                        {/* Main chat box */}
                        {!(selectedSession) && (<div>
                            {pairwise(messages, (a, b, idx) => {
                                return <div
                                    key={a.id}
                                    ref={latestAnswerRef}
                                    className={`mb-4 ${a.id === selectedQuestionId ? "bg-gray-200" : ""} `}
                                    id={`answer-${a.id}`}
                                >
                                    <Card>
                                        <div className="chat-message relative">
                                            <div
                                                className="m-5">
                                                <div className="flex">
                                                    <div className="w-1/12">
                                                        <img
                                                            src={questionIcon}
                                                            alt=""
                                                            width={"50px"}
                                                            height={"50px"}
                                                            className=""
                                                        />
                                                    </div>
                                                    <div
                                                        className={` w-11/12 ml-4 bg-white rounded-lg text-xl mb-4 ${b?.id === selectedQuestionId ? "font-medium text-indigo-800" : ""}`}>

                                                        <div className="w-full p-2">
                                                            {a.text} {idx >= messages.length - 2 && (
                                                                <div className="readAloud flex flex-row gap-3">
                                                                    <div className="readAloud1 flex flex-row gap-3">
                                                                        <Button
                                                                            onClick={() => {
                                                                                readAloud(idx + 1, englishAnswer);
                                                                            }}
                                                                        >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
                                                                                <path fill="currentColor" d="M10.044 1.498a.75.75 0 0 1 .958-.454c.214.09.001 0 .001 0h.001l.003.001l.005.003l.015.005a2 2 0 0 1 .19.08c.118.056.28.138.47.253c.379.23.876.596 1.37 1.143C14.057 3.636 15 5.44 15 8.249a.75.75 0 0 1-1.5 0c0-2.44-.807-3.885-1.556-4.715a4.7 4.7 0 0 0-1.036-.865a3 3 0 0 0-.401-.209l-.014-.005a.75.75 0 0 1-.45-.957M7.198 3.475a.75.75 0 0 0-1.395 0l-3.75 9.5a.75.75 0 0 0 1.395.55l.898-2.275h4.308l.898 2.275a.75.75 0 1 0 1.396-.55zm.864 6.275H4.938L6.5 5.793zm2.668-6.076a.75.75 0 0 0-.962 1.15l.006.006l.034.03q.049.045.139.136c.12.123.28.304.44.53c.327.463.613 1.063.613 1.724a.75.75 0 0 0 1.5 0c0-1.088-.464-1.989-.887-2.588a6 6 0 0 0-.853-.962l-.02-.017l-.006-.005l-.002-.002zm-.962 1.15l.001.002Z" />
                                                                            </svg>
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => {
                                                                                stopReadAloud();
                                                                            }}
                                                                        >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                                                <path fill="currentColor" d="M7 17V7h10v10z" />
                                                                            </svg>
                                                                        </Button>
                                                                    </div>

                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>

                                                {b && <div>
                                                    <div className="flex">
                                                        <div className="w-1/12">
                                                            <img
                                                                src={ansIcon}
                                                                alt=""
                                                                width={"50px"}
                                                                height={"50px"}
                                                                className=""
                                                            />
                                                        </div>
                                                        <div className="w-11/12 ml-4 bg-white rounded-lg text-xl">
                                                            {translatedText && translate && idx >= messages.length - 2 ?
                                                                <div id={`bot-response${idx}`}
                                                                    className="w-full p-2 bot-response translatedResponse"
                                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(translatedText) }}
                                                                ></div> :
                                                                <div id={`bot-response${idx}`}
                                                                    className="w-full p-2 bot-response"
                                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(b.text) }}
                                                                ></div>}

                                                            {/* after text buttons */}
                                                            <div className="flex items-center justify-between mt-4 px-4 py-2">
                                                                <div className="flex items-center space-x-2">
                                                                    <button
                                                                        id={`like-bot-response-${bIndex}`}
                                                                        onClick={handleLike(bIndex)}
                                                                        className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                                                                    >
                                                                        <ThumbsUp className="w-5 h-5" />
                                                                    </button>
                                                                    <button
                                                                        id={`dislike-bot-response-${bIndex}`}
                                                                        onClick={handleDislike(bIndex)}
                                                                        className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                                                                    >
                                                                        <ThumbsDown className="w-5 h-5" />
                                                                    </button>
                                                                    <button
                                                                        onClick={() => handleCopyText(`bot-response${idx}`)}
                                                                        className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                                                                    >
                                                                        <Copy className="w-5 h-5" />
                                                                    </button>
                                                                    <button
                                                                        className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                                                                        onClick={() => generatePDF(a.text, b.text)}>
                                                                        <Download className="w-5 h-5" />
                                                                    </button>
                                                                </div>
                                                                {/* Increment bIndex for each b message */}
                                                                {(() => { bIndex++; })()}
                                                                {translate && idx >= messages.length - 2 && (
                                                                    <div className="flex items-center space-x-2">
                                                                        <SimpleDropdown
                                                                            defaultValue={translationLanguages[0]}
                                                                            onSelect={handleTranslationLanguageSelect}
                                                                            options={translationLanguages}
                                                                        />
                                                                        <Button
                                                                            onClick={() => {
                                                                                handleLanguageTranslation(idx + 1, englishAnswer);
                                                                            }}
                                                                        >
                                                                            Translate
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {/* Conditionally render Card only if b.images exists and has elements */}
                                                            {b.images && b.images.length > 0 && (
                                                                <Card>
                                                                    <div className="grid grid-cols-3 gap-4">
                                                                        {b.images && b.images.map((src, index) => (
                                                                            <div key={index}>
                                                                                <div id="page-container">
                                                                                    <img src={src}
                                                                                        alt={`generate_image_${index}`}
                                                                                        className="w-full h-auto" />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Card>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                            {imageFlag && idx >= messages.length - 2 &&
                                                <ImageSpinner
                                                    size='lg'
                                                />
                                            }
                                        </div>
                                    </Card>
                                </div>
                            }
                            ).map(component => component)}
                        </div>)}
                    </div>
                    <div className="fixed bottom-0 thirdCard bg-[#e5eeff]">
                        <div className="mr-10 ml-4 my-4">
                            {selectedFiles &&
                                <>
                                    <div className="relative py-[8px]">
                                        <span className="rounded-xl bg-white p-[5px]">
                                            {`Uploaded: ${selectedFiles.name}`}</span>
                                        <button onClick={() => { setSelectedFiles(null); }}
                                            className="bg-[#23479F] hover:bg-[#544AC0] px-1 py-0.5 tracking-tight leading-4 rounded-lg text-sm text-white font-bold font-heading absolute top-0">
                                            x
                                        </button>
                                    </div>
                                </>
                            }
                            {/* did not click on chat history */}
                            {!selectedSession && (
                                <div>
                                    {/* New Buttons for Roles */}
                                    <div className="mb-4">
                                        {selectedRole === "teacher" && (
                                            <div className="flex justify-center gap-2">
                                                <Button
                                                    size="white"
                                                    onClick={() => {
                                                        handleAutomaticSendMessage("Generate Summary");
                                                    }}
                                                >
                                                    Generate Summary
                                                </Button>
                                                <Button
                                                    size="white"
                                                    onClick={() => {
                                                        handleAutomaticSendMessage("Generate Questions");
                                                    }}
                                                >
                                                    Generate Questions
                                                </Button>
                                                <Button
                                                    size="white"
                                                    onClick={() => {
                                                        handleAutomaticSendMessage(
                                                            "Generate Real World Examples"
                                                        );
                                                    }}
                                                >
                                                    Generate Real World Examples
                                                </Button>
                                                <Button
                                                    size="white"
                                                    onClick={() => {
                                                        handleAutomaticSendMessage("Generate Lesson Plan");
                                                    }}
                                                >
                                                    Generate Lesson Plan
                                                </Button>
                                            </div>
                                        )}
                                        {selectedRole === "student" && (
                                            <div className="flex justify-center gap-2">
                                                <Button
                                                    size="white"
                                                    onClick={() => {
                                                        handleAutomaticSendMessage("Generate Summary");
                                                    }}
                                                >
                                                    Generate Summary
                                                </Button>
                                                <Button
                                                    size="white"
                                                    onClick={() => {
                                                        handleAutomaticSendMessage("Generate Questions");
                                                    }}
                                                >
                                                    Generate Questions
                                                </Button>
                                                <Button
                                                    size="white"
                                                    onClick={() => {
                                                        handleAutomaticSendMessage(
                                                            "Generate Real World Examples"
                                                        );
                                                    }}
                                                >
                                                    Generate Real World Examples
                                                </Button>
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex">
                                        <div className="w-full text-lg">
                                            <textarea
                                                className="w-full border border-[#23479F] rounded-lg p-2"
                                                placeholder="Ask your question here....."
                                                value={currentQuestion}
                                                onChange={(e) =>
                                                    setCurrentQuestion(e.target.value)
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                        e.preventDefault();
                                                        setDisplayQuestionInput(false);
                                                        setStream(true);
                                                        handleSendMessage(currentQuestion, stream);
                                                    }
                                                }}
                                                rows={1}
                                                disabled={!disableTextArea}
                                            />
                                            <div className="">
                                                {errorMessage && (
                                                    <div className="text-red-600">
                                                        {errorMessage}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className=" ml-4">
                                            <div className="flex gap-[10px]">
                                                {selectedRole === 'teacher' && selectedTeacherMethod === 'Brainstorming' &&
                                                    <ImageUpload
                                                        accept={"application/jpg"}
                                                        onChange={handleFileChange}
                                                    />
                                                }
                                                {(selectedSubject) === 'Mathematics' && (<div>
                                                    {/* Math Symbol Button */}
                                                    <Button
                                                        onClick={() => setShowKeyboard(!showKeyboard)}
                                                    >
                                                        π
                                                    </Button>
                                                    {/* Draggable Math Keyboard Panel */}
                                                    {showKeyboard && (
                                                        <Draggable>
                                                            <div className="absolute bottom-20 right-10 z-50">
                                                                <MathKeyboard onSymbolClick={handleSymbolClick} />
                                                            </div>
                                                        </Draggable>
                                                    )}
                                                </div>)}
                                                <Button
                                                    onClick={() => {
                                                        if (currentQuestion.trim() === '') {
                                                            setErrorMessage('Text cannot be empty');
                                                            return;
                                                        }
                                                        setShowDownload(true);
                                                        setDisplayQuestionInput(false);
                                                        handleSendMessage(currentQuestion, stream);
                                                    }}
                                                    disabled={!disableTextArea}
                                                >
                                                    Enter
                                                </Button>

                                                <Button
                                                    onClick={() => {
                                                        if (!listening) {
                                                            openDialog();
                                                        }
                                                    }}
                                                    disabled={listening}
                                                >
                                                    {listening ? 'Listening...' : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M12 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3m7 9c0 3.53-2.61 6.44-6 6.93V21h-2v-3.07c-3.39-.49-6-3.4-6-6.93h2a5 5 0 0 0 5 5a5 5 0 0 0 5-5z" />
                                                        </svg>
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            {/* clicked on chat history */}
                            {(selectedSession) && (<div>
                                {/* Buttons to navigate between chat sessions */}
                                <div className="flex justify-between mb-4">
                                    <Button
                                        onClick={handlePreviousClick}
                                        disabled={selectedSessionIndex === 0}
                                    >
                                        Previous
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            clearAllVariables();
                                            setShowDownload(false);
                                        }}
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        onClick={handleNextClick}
                                        disabled={selectedSessionIndex === chatHistory.length - 1}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="overlay">
                    <Spinner />
                </div>
            )}
        </div>
    )
}

export default TutorBot;