import React, { useEffect, useState } from "react";
import AuthForm from "../components/authForm";
import { authFields } from '../contents/authentication';
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";

const Signup = () => {
  const router = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSignup = async (formData) => {
    formData.application_id = 2;
    try {
      setLoading(true);
      const response = await axios.post("https://efhp0mxmli.execute-api.ap-south-1.amazonaws.com/dev/signup-auth", formData);
      console.log("Signup success", response.data);
      await toast.success("Signup success");
      router("/otp-verification", { state: { email: formData.email, application_id: formData.application_id, pathToNavigate: "/login" } });
    } catch (error) {
      console.log("Signup failed", error.message);
      await toast.error("Signup failed", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/*loading ? "processing" : ""*/}
      <AuthForm
        fields={authFields.signup}
        buttonText="Signup"
        onSubmit={handleSignup}
        title="Create a new account"
        loading={loading}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Signup;
