import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import enterprise from "../assets/enterprise.svg";
import knowledgeManagement from "../assets/knowledge-management.svg";
import bi from "../assets/bi.svg";
import information from "../assets/information.svg";
import roles from "../assets/roles.svg";
import content_summarize from "../assets/content_summarize.svg";
import proofReading from "../assets/proofreading.svg";
import imageGeneration from "../assets/imageGeneration.svg";
import QAGeneration from "../assets/QAGeneration.svg";
import casestudy from "../assets/casestudy.svg";
import tutorbot from "../assets/tutorbot.svg";
import evaluation from "../assets/evaluation.svg";
import adaptiveLearning from "../assets/adaptiveLearning.svg";
import readingComprehension from "../assets/readingComprehension.svg";
import storyBuilder from "../assets/storyBuilder.svg";
import guidedLearning from "../assets/guidedLearning.svg";
import llama from "../assets/llama.svg";
import videoGeneration from "../assets/videoGeneration.svg";
import debateBot from "../assets/debateBot.svg";
import essayWriter from "../assets/essayWriter.svg";
import pdfUpload from "../assets/pdfUpload.svg";


const Sidebar = ({ userDetails }) => {
    const [subMenu, setSubMenu] = useState(-1);
    const location = useLocation();
    var adminemail = ['admin1@aenig.in', 'admin2@aenig.in'];
    var testemail = ['admin1@aenig.in', 'admin2@aenig.in', 'hemanth.yernagula@krtrimaiq.ai'];
    var devemail = ['admin1@aenig.in', 'admin2@aenig.in', 'signup2@aenig.in'];
    var dev2email = ['teacher1@krtrimaiq.ai'];
    var dev3email = ['student1@krtrimaiq.ai', 'student2@krtrimaiq.ai'];
    var dev4email = ['sreedharreddy.rahul@krtrimaiq.ai'];
    var dev5email = ['teacher2@krtrimaiq.ai', 'teacher3@krtrimaiq.ai'];
    var Menus = [];
    var userEmail = userDetails.user_email;

    if (adminemail.includes(userEmail)) {
        userDetails.role = 'admin';
    } else if (testemail.includes(userEmail)) {
        userDetails.role = 'test';
    } else if (devemail.includes(userEmail)) {
        userDetails.role = 'dev';
    } else if (dev2email.includes(userEmail)) {
        userDetails.role = 'dev2';
    } else if (dev3email.includes(userEmail)) {
        userDetails.role = 'dev3';
    } else if (dev4email.includes(userEmail)) {
        userDetails.role = 'dev4';
    } else if (dev5email.includes(userEmail)) {
        userDetails.role = 'dev5';
    } else {
        userDetails.role = 'unknown';
    }


    if (userDetails) {
        Menus = [
            {
                title: "Editorial Assistant",
                path: "/tutor-bot",
                src: tutorbot,
                Submenu: []
            },
            {
                title: "Q & A Generation",
                path: "/qna-generation",
                src: QAGeneration,
                Submenu: []
            },
            {
                title: "Case Study Generation",
                path: "/case-study",
                src: casestudy,
                Submenu: []
            },
            {
                title: "Proof Reading",
                path: "/proof-reading",
                src: proofReading,
                Submenu: []
            },
            {
                title: "Visual Content Generation",
                // path: "",
                src: QAGeneration,
                Submenu: [
                    {
                        title: "Image",
                        path: "/text-to-image",
                        src: imageGeneration,
                        Submenu: []
                    },
                    {
                        title: "Video",
                        path: "/video-generation",
                        src: videoGeneration,
                        Submenu: []
                    },
                ]
            },
            {
                title: "Multimedia Search",
                // path: "/multimedia-search",
                src: evaluation,
                Submenu: [
                    { title: "Search", path: "/enterprise-search"},
                    {title: "Multi-File Upload", path: "/Enterprise-fileupload" },
                ],
            },
            // {
            //     title: "Enterprise Search",
            //     // path: "/",
            //     src: enterprise,
            //     Submenu: [
            //         { title: "Search", path: "/enterprise-search"},
            //         {title: "Multi-File Upload", path: "/Enterprise-fileupload" },
            //     ],
            // },
        ];
    }
    if (userDetails && userDetails.role == 'admin') {
        Menus = [
            {
                title: "Editorial Assistant",
                path: "/tutor-bot",
                src: tutorbot,
                Submenu: []
            },
            {
                title: "Q & A Generation",
                path: "/qna-generation",
                src: QAGeneration,
                Submenu: []
            },
            {
                title: "Case Study Generation",
                path: "/case-study",
                src: casestudy,
                Submenu: []
            },
            {
                title: "Proof Reading",
                path: "/proof-reading",
                src: proofReading,
                Submenu: []
            },
            {
                title: "Visual Content Generation",
                path: "",
                src: QAGeneration,
                Submenu: [
                    {
                        title: "Image",
                        path: "/text-to-image",
                        src: imageGeneration,
                        Submenu: []
                    },
                    {
                        title: "Video",
                        path: "/video-generation",
                        src: videoGeneration,
                        Submenu: []
                    },
                ]
            },
            {
                title: "Multimedia Search",
                // path: "/multimedia-search",
                src: evaluation,
                Submenu: [
                    { title: "Search", path: "/enterprise-search"},
                    {title: "Multi-File Upload", path: "/Enterprise-fileupload" },
                ],
            },
        ];
    }
    if (userDetails && userDetails.role == 'dev') {
        Menus = [
            {
                title: "Editorial Assistant",
                path: "/tutor-bot",
                src: tutorbot,
                Submenu: []
            },
            {
                title: "Q & A Generation",
                path: "/qna-generation",
                src: QAGeneration,
                Submenu: []
            },
            {
                title: "Case Study Generation",
                path: "/case-study",
                src: casestudy,
                Submenu: []
            },
            {
                title: "Proof Reading",
                path: "/proof-reading",
                src: proofReading,
                Submenu: []
            },
            {
                title: "Visual Content Generation",
                path: "",
                src: QAGeneration,
                Submenu: [
                    {
                        title: "Image",
                        path: "/text-to-image",
                        src: imageGeneration,
                        Submenu: []
                    },
                    {
                        title: "Video",
                        path: "/video-generation",
                        src: videoGeneration,
                        Submenu: []
                    },
                ]
            },
            {
                title: "Multimedia Search",
                // path: "/multimedia-search",
                src: evaluation,
                Submenu: [
                    { title: "Search", path: "/enterprise-search"},
                    {title: "Multi-File Upload", path: "/Enterprise-fileupload" },
                ],
            },
        ];
    }
    if (userDetails && userDetails.role == 'test') {
        Menus = [
            {
                title: "Editorial Assistant",
                path: "/tutor-bot",
                src: tutorbot,
                Submenu: []
            },
            {
                title: "Q & A Generation",
                path: "/qna-generation",
                src: QAGeneration,
                Submenu: []
            },
            {
                title: "Case Study Generation",
                path: "/case-study",
                src: casestudy,
                Submenu: []
            },
            {
                title: "Proof Reading",
                path: "/proof-reading",
                src: proofReading,
                Submenu: []
            },
            {
                title: "Visual Content Generation",
                path: "",
                src: QAGeneration,
                Submenu: [
                    {
                        title: "Image",
                        path: "/text-to-image",
                        src: imageGeneration,
                        Submenu: []
                    },
                    {
                        title: "Video",
                        path: "/video-generation",
                        src: videoGeneration,
                        Submenu: []
                    },
                ]
            },
            {
                title: "Multimedia Search",
                // path: "/multimedia-search",
                src: evaluation,
                Submenu: [
                    { title: "Search", path: "/enterprise-search"},
                    {title: "Multi-File Upload", path: "/Enterprise-fileupload" },
                ],
            },
        ];
    }
    if (userDetails && userDetails.role == 'dev2') {
        Menus = [
            {
                title: "Editorial Assistant",
                path: "/tutor-bot",
                src: tutorbot,
                Submenu: []
            },
            {
                title: "Q & A Generation",
                path: "/qna-generation",
                src: QAGeneration,
                Submenu: []
            },
            {
                title: "Case Study Generation",
                path: "/case-study",
                src: casestudy,
                Submenu: []
            },
            {
                title: "Proof Reading",
                path: "/proof-reading",
                src: proofReading,
                Submenu: []
            },
            {
                title: "Visual Content Generation",
                path: "",
                src: QAGeneration,
                Submenu: [
                    {
                        title: "Image",
                        path: "/text-to-image",
                        src: imageGeneration,
                        Submenu: []
                    },
                    {
                        title: "Video",
                        path: "/video-generation",
                        src: videoGeneration,
                        Submenu: []
                    },
                ]
            },
            {
                title: "Multimedia Search",
                // path: "/multimedia-search",
                src: evaluation,
                Submenu: [
                    { title: "Search", path: "/enterprise-search"},
                    {title: "Multi-File Upload", path: "/Enterprise-fileupload" },
                ],
            },
        ];
    }
    if (userDetails && userDetails.role == 'dev3') {
        Menus = [
            {
                title: "Editorial Assistant",
                path: "/tutor-bot",
                src: tutorbot,
                Submenu: []
            },
            {
                title: "Q & A Generation",
                path: "/qna-generation",
                src: QAGeneration,
                Submenu: []
            },
            {
                title: "Case Study Generation",
                path: "/case-study",
                src: casestudy,
                Submenu: []
            },
            {
                title: "Proof Reading",
                path: "/proof-reading",
                src: proofReading,
                Submenu: []
            },
            {
                title: "Visual Content Generation",
                path: "",
                src: QAGeneration,
                Submenu: [
                    {
                        title: "Image",
                        path: "/text-to-image",
                        src: imageGeneration,
                        Submenu: []
                    },
                    {
                        title: "Video",
                        path: "/video-generation",
                        src: videoGeneration,
                        Submenu: []
                    },
                ]
            },
            {
                title: "Multimedia Search",
                // path: "/multimedia-search",
                src: evaluation,
                Submenu: [
                    { title: "Search", path: "/enterprise-search"},
                    {title: "Multi-File Upload", path: "/Enterprise-fileupload" },
                ],
            },
        ];
    }
    if (userDetails && userDetails.role == 'dev4') {
        Menus = [
            {
                title: "Editorial Assistant",
                path: "/tutor-bot",
                src: tutorbot,
                Submenu: []
            },
            {
                title: "Q & A Generation",
                path: "/qna-generation",
                src: QAGeneration,
                Submenu: []
            },
            {
                title: "Case Study Generation",
                path: "/case-study",
                src: casestudy,
                Submenu: []
            },
            {
                title: "Proof Reading",
                path: "/proof-reading",
                src: proofReading,
                Submenu: []
            },
            {
                title: "Visual Content Generation",
                path: "",
                src: QAGeneration,
                Submenu: [
                    {
                        title: "Image",
                        path: "/text-to-image",
                        src: imageGeneration,
                        Submenu: []
                    },
                    {
                        title: "Video",
                        path: "/video-generation",
                        src: videoGeneration,
                        Submenu: []
                    },
                ]
            },
            {
                title: "Multimedia Search",
                // path: "/multimedia-search",
                src: evaluation,
                Submenu: [
                    { title: "Search", path: "/enterprise-search"},
                    {title: "Multi-File Upload", path: "/Enterprise-fileupload" },
                ],
            },
        ];
    }
    if (userDetails && userDetails.role == 'dev5') {
        Menus = [
            {
                title: "Editorial Assistant",
                path: "/tutor-bot",
                src: tutorbot,
                Submenu: []
            },
            {
                title: "Q & A Generation",
                path: "/qna-generation",
                src: QAGeneration,
                Submenu: []
            },
            {
                title: "Case Study Generation",
                path: "/case-study",
                src: casestudy,
                Submenu: []
            },
            {
                title: "Proof Reading",
                path: "/proof-reading",
                src: proofReading,
                Submenu: []
            },
            {
                title: "Visual Content Generation",
                path: "",
                src: QAGeneration,
                Submenu: [
                    {
                        title: "Image",
                        path: "/text-to-image",
                        src: imageGeneration,
                        Submenu: []
                    },
                    {
                        title: "Video",
                        path: "/video-generation",
                        src: videoGeneration,
                        Submenu: []
                    },
                ]
            },
            {
                title: "Multimedia Search",
                // path: "/multimedia-search",
                src: evaluation,
                Submenu: [
                    { title: "Search", path: "/enterprise-search"},
                    {title: "Multi-File Upload", path: "/Enterprise-fileupload" },
                ],
            },
        ];
    }



    const toggleSubMenu = (index) => {
        if (subMenu === index) {
            setSubMenu(-1); // Close the submenu if it's already open
        } else {
            setSubMenu(index); // Open the clicked submenu
        }
    };

    const isMenuActive = (menu) => {
        if (location.pathname === menu.path) {
            return true;
        }
        if (menu.Submenu) {
            return menu.Submenu.some((submenu) => location.pathname === submenu.path);
        }
        return false;
    };

    return (
        <>
            <div className="customScrollbar scrollbarStyle rounded-tr-lg duration-300 bg-[#23479F]">
                <ul className="p-4">
                    {Menus.map((menu, index) => (
                        <div key={index}>
                            <Link to={menu.path}>
                                <li className={`rounded-lg ${menu.gap ? "mt-9" : "mt-2"} ${isMenuActive(menu) && "colorbg"}`}>
                                    <div onClick={() => toggleSubMenu(index)} className="flex items-center gap-x-3 p-3 text-base font-normal rounded-lg cursor-pointer hover:bg-[#1D3C86] hover:text-black">
                                        <span>
                                            <img src={menu.src} alt="" className="p-0.5 max-w-fit" />
                                        </span>
                                        <button className="text-left origin-left duration-300 hover:block text-white">
                                            {menu.title}
                                        </button>
                                        {menu.Submenu.length > 0 && (
                                            <span className={`ml-auto transform transition-transform duration-300 ${subMenu === index ? "rotate-180" : "rotate-0"}`}>
                                                <span className="text-white">&#9660;</span>
                                            </span>
                                        )}
                                    </div>
                                </li>
                            </Link>
                            {subMenu === index && menu.Submenu.length > 0 && (
                                <ul>
                                    {menu.Submenu.map((submenu, subIndex) => (
                                        <div key={subIndex}>
                                            <Link to={submenu.path}>
                                                <li
                                                    className={`flex items-center text-left gap-x-3 p-3 mx-10 text-base font-normal rounded-lg cursor-pointer hover:bg-[#1D3C86] hover:text-black ${submenu.gap ? "mt-9" : "mt-2"} ${location.pathname === submenu.path && "colorbg"}`}
                                                >
                                                    {/* Display the image for the submenu item */}
                                                    <span>
                                                        <img src={submenu.src} alt="" className="p-0.5 max-w-fit" />
                                                    </span>
                                                    <span className="origin-left duration-300 hover:block text-white">
                                                        {submenu.title}
                                                    </span>
                                                </li>
                                            </Link>
                                        </div>
                                    ))}
                                </ul>
                            )}

                        </div>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default Sidebar;
