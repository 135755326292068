import React, { useEffect, useRef, useState } from "react";
import { Button, Card, FullCard, Select, Slider, Spinner } from "../components/CommonComponents";
import {
    scienceMiddle,
    sciencedifficultyLevel,
    tutorBoard,
    tutorGrade,
    tutorSubject,
    tutorTopic,
    context,
} from "../components/Mapping";
import { qnaAPIURL } from "../api/serverAPI";
import axios from "axios";
import Math from "./Math";
import MultiSelectDropdown from "../components/MultiSelectDropdown";
import { FileUpload } from "../components/Fileupload";
import renderMathInElement from "katex/contrib/auto-render";
import English from "./English";
import Science from "./Science";

function b64_urlsafe(s) {
    return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace('=', '')
}

function randomString(n = 16) {
    return b64_urlsafe(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(n))))
}
let sender = randomString();

const QnAGeneration = () => {

    const [loading, setLoading] = useState(false);
    const [selectedBoard, setSelectedBoard] = useState('CBSE');
    const [selectedSubject, setSelectedSubject] = useState('Mathematics');
    const [selectedGrade, setSelectedGrade] = useState('11');
    const [selectedTopic, setSelectedTopic] = useState('TRIGONOMETRIC FUNCTIONS');
    const [noOfQuestion, setNoOfQuestion] = useState(3);
    const [responseData, setResponseData] = useState([]);
    const [subTopic, setSubTopic] = useState("");
    const [difficultyLevel, setDifficultyLevel] = useState([]);
    const [middleOptions, setMiddleOptions] = useState([]);
    const [progress, setProgress] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [base64Data, setBase64Data] = useState(null);
    const [selectedContext, setSelectedContext] = useState('Select Topic')


    const handleSubjectChange = (subject) => {
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[selectedGrade][subject][0].text);
    };

    const handleBoard = (option) => {
        setSelectedBoard(option);
    }

    const handleTopicChange = (topic) => {
        setSelectedTopic(topic);
    }

    const handleClassChange = (classItem) => {
        if (classItem.toLowerCase().startsWith(`btech`)) classItem = `13`;
        setSelectedGrade(classItem);
        const subject = getSubjectOptions(classItem)[0].text;
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[classItem][subject][0].text);
    };

    function getSubjectOptions(grade) {
        return (tutorSubject.filter(subject => (tutorGrade.find(item => item.code === grade).subjects).includes(subject.code)));

    }

    const GetOutput = () => {
        setLoading(true);

        const payload = {
            subject: selectedSubject.toLowerCase(),
            grade: `class${selectedGrade}`,
            board: "",
            questionCount: noOfQuestion,
            questionType: middleOptions,
            difficultyLevel: difficultyLevel,
            subtopic: subTopic,
            ...(selectedFiles ? { fileName: selectedFiles.name, file: base64Data } : { topic: selectedTopic })
        };

        const url = qnaAPIURL(); // get only the URL
        axios.post(url, payload) // send URL and payload
            .then((response) => {
                console.log("response", response);
                setResponseData(response.data.response);
                console.log(response.data.response);
                setLoading(false);
            })
            .catch((error) => {
                alert("Can you please try again in sometime");
                setLoading(false); // Ensure loading state is reset on error
            });
    };

    const handleDifficultyLevel = (updatedOptions) => {
        setDifficultyLevel(updatedOptions);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        handleFile(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
    };

    const handleFile = (file) => {
        setSelectedFiles(file);
        if (file && file.type === "application/pdf") {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                setBase64Data(base64String);
            };
            reader.readAsDataURL(file);
        }
        else {
            alert("Please upload a PDF file.");
        }
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                } else {
                    clearInterval(interval);

                    return prevProgress;
                }
            });
        }, 1000);
    };

    const handleMiddleChange = (updatedOptions) => {
        setMiddleOptions(updatedOptions);
    };

    const DisplayOutput = () => {
        useEffect(() => {
            renderMathInElement(document.body, {
                delimiters: [
                    { left: "$$", right: "$$", display: true },
                    { left: "$", right: "$", display: false }
                ]
            });
        }, []);

        return (
            <div className="flex flex-col gap-4">
                {Object.entries(responseData).map(([key, value]) => (
                    <Card key={key}>
                        <strong className="block text-center text-lg font-semibold m-2">
                            {key.replace(/_/g, " ")}
                        </strong>
                        <div dangerouslySetInnerHTML={{ __html: value }} />
                    </Card>
                ))}
            </div>
        );
    };

    const isGenerateDisabled =
        (selectedContext === "Upload File" && (progress !== 100 || difficultyLevel.length === 0 || middleOptions.length === 0)) ||
        (selectedContext !== "Upload File" && (difficultyLevel.length === 0 || middleOptions.length === 0));


    return (
        <div className="flex gap-4">
            <div className="secondcardWidth">
                <FullCard>
                    <div className="">
                        <div className="flex justify-between items-center mt-2">
                            <div className="subheading text-center">
                                Q & A Generation
                            </div>
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Board</div>
                            <Select dropdownData={tutorBoard} value={selectedBoard}
                                onChange={(e) => handleBoard(e.target.value)}
                            />
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Grade</div>
                            <Select dropdownData={tutorGrade}
                                value={selectedGrade === '13' ? 'Btech 1st year' : selectedGrade}
                                onChange={(e) => handleClassChange(e.target.value)} />
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Subject</div>
                            <Select dropdownData={getSubjectOptions(selectedGrade)} value={selectedSubject}
                                onChange={(e) => handleSubjectChange(e.target.value)}
                            />
                        </div>
                        {selectedSubject === "Science" && (
                            <div className="my-8">
                                <div className="text-blue text-lg font-semibold my-2">Get Context From</div>
                                <Select dropdownData={context} value={selectedContext}
                                    onChange={(e) => setSelectedContext(e.target.value)}
                                />
                            </div>
                        )}
                        {selectedSubject === "Science" && selectedContext === "Upload File" && (
                            <div className="my-8">
                                <div className="items-center" onDragOver={handleDragOver}
                                    onDrop={handleDrop}>
                                    <FileUpload
                                        accept={"application/pdf"}
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <div className="mt-4 text-center">
                                    {selectedFiles && (
                                        <div className="mx-10">
                                            <b>File Name: </b>
                                            {selectedFiles.name}
                                        </div>
                                    )}
                                    {progress > 0 && (
                                        <div className="my-4">
                                            <div className="bg-[#D2CFF1] h-4 rounded-full">
                                                <div
                                                    className="bg-[#23479F] h-full rounded-full"
                                                    style={{
                                                        width: `${progress}%`,
                                                    }}
                                                ></div>
                                            </div>
                                            <div className="text-center mt-2">{`${progress}% Complete`}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {selectedSubject === "Science" && selectedContext === "Select Topic" && (<div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Topics</div>
                            <Select dropdownData={tutorTopic[selectedGrade][selectedSubject] || []}
                                value={selectedTopic}
                                onChange={(e) => handleTopicChange(e.target.value)} />
                        </div>)}
                        {selectedSubject !== "Science" && (<div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Topics</div>
                            <Select dropdownData={tutorTopic[selectedGrade][selectedSubject] || []}
                                value={selectedTopic}
                                onChange={(e) => handleTopicChange(e.target.value)} />
                        </div>)}
                        <div className="my-8">
                            <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                Question Type
                            </div>
                            <div className="w-full">
                                <MultiSelectDropdown
                                    options={scienceMiddle}
                                    selectedOptions={middleOptions}
                                    onChange={handleMiddleChange}
                                    placeholder="Select one or more"
                                />
                            </div>
                            <div className="my-8">
                                <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                    Difficulty Level
                                </div>
                                <div className="w-full">
                                    <MultiSelectDropdown
                                        options={sciencedifficultyLevel}
                                        selectedOptions={difficultyLevel}
                                        onChange={handleDifficultyLevel}
                                        placeholder="Select one or more"
                                    />
                                </div>
                            </div>
                            <div className="my-2">
                                <div className="my-2 items-center">
                                    <div className="font-bold">
                                        Number of Questions
                                    </div>
                                    <div className="">
                                        <label className="block text-gray-600 flex justify-center w-full">
                                            {noOfQuestion}
                                        </label>
                                        <Slider
                                            min={1}
                                            max={20}
                                            step={1}
                                            value={noOfQuestion}
                                            onChange={(e) =>
                                                setNoOfQuestion(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Sub Topic</div>
                            <div className="w-full text-sm">
                                <textarea
                                    className="w-full border border-[#23479F] rounded-lg p-2"
                                    placeholder="Mention Sub topic if any."
                                    value={subTopic}
                                    onChange={(e) =>
                                        setSubTopic(e.target.value)
                                    }
                                    rows={1}
                                />
                            </div>
                        </div>
                        <div className="my-8">
                            <div className="flex justify-center my-8">
                                <Button
                                    onClick={GetOutput}
                                    disabled={isGenerateDisabled}
                                >
                                    Generate
                                </Button>
                            </div>
                        </div>
                    </div>
                </FullCard>
            </div>
            <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                {responseData && Object.keys(responseData).length > 0 && (
                    <DisplayOutput />
                )}
            </div>
            {loading && (
                <div className="overlay">
                    <Spinner />
                </div>
            )}
        </div>
    )
}

export default QnAGeneration;