import { useEffect, useRef, useState } from "react";
import { Check } from "lucide-react";
import { Button } from "./CommonComponents";
import { signupManagementURL } from "../api/serverAPI";
import {
    fetchOrganizations,
    fetchInstitutions,
    fetchRoles,
    fetchFeatures,
    fetchSections,
    fetchUsers
} from "../components/fetchManagement";

const MultiSelect = ({ label, options, selected, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const toggleOption = (event, value) => {
        event.stopPropagation();
        const newSelected = selected.includes(value)
            ? selected.filter(item => item !== value)
            : [...selected, value];
        onChange(newSelected);
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <label className="text-sm font-medium text-gray-700 mb-1 block">
                {label}
            </label>
            <div
                className="w-full p-3 border border-gray-300 rounded-lg bg-white cursor-pointer flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-150"
                onClick={() => setIsOpen(!isOpen)}
                tabIndex={0}
            >
                <span className={selected.length === 0 ? 'text-gray-500' : 'text-gray-900'}>
                    {selected.length === 0 ? `Select ${label}` : `${selected.length} selected`}
                </span>
                <svg
                    className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''
                        }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </div>

            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-48 overflow-auto">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="flex items-center px-3 py-2.5 hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                            onClick={(e) => toggleOption(e, option.value)}
                        >
                            <div className={`
                                w-4 h-4 border rounded mr-2 flex items-center justify-center
                                ${selected.includes(option.value)
                                    ? 'border-blue-500 bg-blue-500'
                                    : 'border-gray-300'
                                }
                            `}>
                                {selected.includes(option.value) && (
                                    <Check className="w-3 h-3 text-white" />
                                )}
                            </div>
                            <span className="text-gray-700">{option.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const AddUserForm = ({ onSubmit, onCancel, role }) => {
    const [formData, setFormData] = useState({
        org: [],
        institute: [],
        name: '',
        email: '',
        password: '',
        roleId: '',
        features_list: [],
        classes_list: [],
        // classId: '',    // Uncomment when needed
        // sectionId: '',  // Uncomment when needed
    });
    // Options state for each dropdown
    const [orgOptions, setOrgOptions] = useState([]);
    const [instituteOptions, setInstituteOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    //    const [userOptions, setUserOptions] = useState([]);
    const [featureOptions, setFeatureOptions] = useState([]);
    const [classOptions, setClassOptions] = useState([]);
    const [sectionOptions, setSectionOptions] = useState({});
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [loading, setLoading] = useState(false);
    const token = sessionStorage.getItem('accessToken');


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleMultiSelectChange = (name, selectedValues) => {
        setFormData(prev => ({
            ...prev,
            [name]: selectedValues
        }));
    };

    const handleRoleChange = (e) => {
        // setSelectedRole(e.target.value);
        // If you're using form state:
        setFormData(prev => ({ ...prev, roleId: e.target.value }));
    };

    // Handlers
    const handleClassChange = (e) => {
        const classId = e.target.value;
        setSelectedClass(classId);
        setSelectedSection(''); // Reset section when class changes

        // Uncomment these when you need to include in form data
        // setFormData(prev => ({
        //     ...prev,
        //     classId: classId,
        //     sectionId: '' // Reset section in form data too
        // }));
    };

    const handleSectionChange = (e) => {
        const sectionId = e.target.value;
        setSelectedSection(sectionId);

        // Uncomment when you need to include in form data
        // setFormData(prev => ({
        //     ...prev,
        //     sectionId: sectionId
        // }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form data being submitted:', formData);
        const token = sessionStorage.getItem('accessToken');
        // Map the form data to API input
        const payload = {
            name: formData.name,
            email: formData.email,
            password: formData.password,
            institution: formData.institute[0], // Example: Use the first item in the array
            role: formData.roleId,
            features: formData.features_list,
        };

        try {
            const response = await fetch(signupManagementURL(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Signup successful:', data);
            } else {
                console.error('Signup failed:', response.status);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    // const orgOptions = [
    //     { value: 'org1', label: 'Organization 1' },
    //     { value: 'org2', label: 'Organization 2' },
    //     { value: 'org3', label: 'Organization 3' }
    // ];

    // const instituteOptions = [
    //     { value: '671b698122343ce2b4945dca', label: 'Institute 1' },
    //     { value: 'inst2', label: 'Institute 2' },
    //     { value: 'inst3', label: 'Institute 3' }
    // ];

    // const featureOptions = [
    //     { value: '671b698222343ce2b4945dcf', label: 'Feature 1' },
    //     { value: 'feature2', label: 'Feature 2' },
    //     { value: 'feature3', label: 'Feature 3' }
    // ];

    // const classOptions = [
    //     { value: 'class1', label: 'Class 1' },
    //     { value: 'class2', label: 'Class 2' },
    //     { value: 'class3', label: 'Class 3' }
    // ];

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                setLoading(true);
                const [organizations, institutions, roles, features, sections] = await Promise.all([
                    fetchOrganizations(token),
                    fetchInstitutions(token),
                    fetchRoles(token),
                    fetchFeatures(token),
                    fetchSections(token),
                    // fetchUsers(token),
                ]);

                setOrgOptions(
                    organizations.map(org => ({
                        value: org.id,
                        label: org.organization
                    }))
                );

                setInstituteOptions(
                    institutions.map(instData => ({
                        value: instData.id,
                        label: instData.institution
                    }))
                );

                setRoleOptions(
                    roles.map(roleData => ({
                        value: roleData.id,
                        label: roleData.role.charAt(0).toUpperCase() + roleData.role.slice(1) // Capitalize first letter
                    }))
                );

                setFeatureOptions(
                    features.map(featureData => ({
                        value: featureData.id,
                        label: featureData.feature
                    }))
                );

                // Transform classes and sections
                const classOpts = [];
                const sectionOpts = {};

                Object.entries(sections).forEach(([className, classData]) => {
                    // Add class to options
                    classOpts.push({
                        value: classData.id,
                        label: className    // or format it like "Class 1" if needed
                    });

                    // Store sections for this class
                    sectionOpts[classData.id] = classData.sections.map(section => ({
                        value: section.id,
                        label: section.name
                    }));
                });

                setClassOptions(classOpts);
                setSectionOptions(sectionOpts);

                // setSectionOptions(
                //     sections.map(sectionData => ({
                //         value: sectionData.id,
                //         label: sectionData.className
                //     }))
                // );

                // setUserOptions(
                //     users.map(user => ({
                //         value: user.id,
                //         label: user.name // adjust based on your user object structure
                //     }))
                // );



            } catch (error) {
                console.error('Error fetching data:', error);
                // setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchAllData();
    }, [token]); // Added token as dependency since it's used in the effect

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Modal Overlay */}
            <div className="absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm" onClick={onCancel} />

            {/* Modal Content */}
            <div className="relative w-full max-w-2xl mx-4 bg-white rounded-lg shadow-xl">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between border-b border-gray-200 pb-4">
                        <h2 className="text-2xl font-semibold text-gray-800">Add User</h2>
                        <button
                            onClick={onCancel}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="grid grid-cols-2 gap-6">

                            {/* Organization and Institute Group - visible if role is false */}
                            {!role && (
                                <div className="col-span-2 grid grid-cols-2 gap-4 p-4 bg-gray-50 rounded-lg">
                                    <MultiSelect
                                        label="Organization"
                                        options={orgOptions}
                                        selected={formData.org}
                                        onChange={(selected) => handleMultiSelectChange('org', selected)}
                                    />
                                    <MultiSelect
                                        label="Institute"
                                        options={instituteOptions}
                                        selected={formData.institute}
                                        onChange={(selected) => handleMultiSelectChange('institute', selected)}
                                    />
                                </div>

                            )}
                            {/* Personal Information Group */}
                            <div className="col-span-2 grid grid-cols-3 gap-4 p-4 bg-gray-50 rounded-lg">
                                <div>
                                    <label className="block text-sm font-medium mb-1">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter Name"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-1">Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter Email"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-1">Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter Password"
                                        required
                                    />
                                </div>
                            </div>

                            {/* Role, Features, and Classes Group */}
                            <div className="col-span-2 grid grid-cols-3 gap-4 p-4 bg-gray-50 rounded-lg">
                                <div>
                                    <label className="block text-sm font-medium mb-1">Role</label>
                                    <select
                                        value={formData.roleId}
                                        onChange={handleRoleChange}
                                        name="roleId"
                                        required
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    >
                                        <option value="">Select Role</option>
                                        {roleOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <MultiSelect
                                    label="Features"
                                    options={featureOptions}
                                    selected={formData.features_list}
                                    onChange={(selected) => handleMultiSelectChange('features_list', selected)}
                                />

                                <div>
                                    <label className="block text-sm font-medium mb-1">Class</label>
                                    <select
                                        value={selectedClass}
                                        onChange={handleClassChange}
                                        name="classId"
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    >
                                        <option value="">Select Class</option>
                                        {classOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Section Select - only shown if class is selected */}
                                {selectedClass && (
                                    <div>
                                        <label className="block text-sm font-medium mb-1">Section</label>
                                        <select className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" name="sectionId">
                                            <option value="">Select Section</option>
                                            {sectionOptions[selectedClass]?.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                {/* <MultiSelect
                            label="Classes"
                            options={sectionOptions}
                            selected={formData.classes}
                            onChange={(selected) => handleMultiSelectChange('classes', selected)}
                        />
                        {role && (
                            <MultiSelect
                                label="Sections"
                                options={sectionOptions} // Update with actual section options
                                selected={formData.classes} // Adjust formData structure as needed
                                onChange={(selected) => handleMultiSelectChange('sections', selected)}
                            />
                        )} */}
                            </div>
                        </div>

                        <div className="flex justify-center space-x-4 pt-4 border-t border-gray-200">
                            <Button
                                type="submit"
                            >
                                Add User
                            </Button>
                            <Button
                                type="button"
                                onClick={onCancel}
                                variant="outline"
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddUserForm;