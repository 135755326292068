import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import { Card, FullCard, Spinner, Button } from '../components/CommonComponents';
import { FileUpload, BrowseComputer } from '../components/Fileupload';
import { DragAndDrop } from '../components/Fileupload';
import { informationExtractionURL } from '../api/serverAPI';
import { download } from "../utils";
import axios from 'axios';

export const InformationExtraction = () => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [base64Data, setBase64Data] = useState(null);
    const [error, setError] = useState(null);
    const [generateqaData, setGenerateqaData] = useState({});

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFiles(file);

        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
            setBase64Data(base64String);
        };
        reader.readAsDataURL(file);

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                } else {
                    clearInterval(interval);

                    return prevProgress;
                }
            });
        }, 1000);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        setSelectedFiles(file);

        if (file) {
            if (file.type === "application/pdf") {
                setSelectedFiles(file);
            } else {
                alert("Please upload a PDF file.");
            }
        }
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
            setBase64Data(base64String);
        };
        reader.readAsDataURL(file);

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                } else {
                    clearInterval(interval);

                    return prevProgress;
                }
            });
        }, 1000);
    };

    const GetOutput = () => {
        setLoading(true);
        const payload = {
            filename: selectedFiles.name,
            file: base64Data,
        };
        const url = informationExtractionURL(); 
        axios.post(url, payload)
            .then((response) => {
                const data = JSON.parse(response.data.response);
                setGenerateqaData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Failed to fetch user details:", error);
                setError(error); // Update the error state
                setLoading(false); // Ensure loading state is reset on error
            });
    };


    const DisplayOutput = () => {
        if (loading) {
            return (
                <>
                    <div className="overlay">
                        <Spinner />
                    </div>
                </>
            );
        }
        if (Object.keys(generateqaData).length !== 0) {
            const renderTableRow = (label, value) => (
                <tr>
                    <td className="border px-4 py-2 font-semibold text-left">{label}</td>
                    <td className="border px-4 py-2 text-left">{value}</td>
                </tr>
            );

            const renderArrayAsString = (array) => (Array.isArray(array) ? array.join(', ') : array);
            return (
                <div className="">
                    <Card>
                        <div className="subheading text-center">
                            Here Is Your Extracted document
                        </div>
                        <div className="my-6">
                            <div className="border-2 border-[#23479F] rounded-lg">
                                <div className="bg-[#23479F] p-4">
                                    <div class="grid grid-cols-2 gap-4">
                                        <p className="text-white mt-1">
                                            {selectedFiles && (
                                                <div className='mx-1 line-clamp-1'>
                                                    {selectedFiles.name}
                                                </div>
                                            )}
                                        </p>
                                        <div className="flex flex-nowrap sm:flex-wrap justify-end gap-3">
                                            <div>
                                                <Button size="white" onClick={download}>Download</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white mx-auto rounded-lg">
                                    <div className="" id="page-container">
                                        <table className="w-full table-auto">
                                            <tbody>
                                                {Object.entries(generateqaData).map(([key, value]) => (
                                                    <React.Fragment key={key}>
                                                        {Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' ? (
                                                            <tr>
                                                                <td className="border px-4 py-2 font-semibold text-left">
                                                                    Professional Experience
                                                                </td>
                                                                <td className="border px-4 py-2 text-left">
                                                                    Organizations worked for are listed below.
                                                                </td>
                                                            </tr>
                                                        ) : null}

                                                        {Array.isArray(value) && value.length > 0 && typeof value[0] === 'object'
                                                            ? value.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    <tr>
                                                                        <td className="border px-4 py-2 font-semibold text-left"></td>
                                                                        <td className="border px-4 py-2 text-left">
                                                                            <div>{value[index].roles}</div>
                                                                            <div>{value[index].start} - {value[index].end}</div>
                                                                            <div>{value[index].company_name}</div>
                                                                            <div>{value[index].programming_skills}</div>
                                                                            <div>{value[index].platforms}</div>

                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ))
                                                            : renderTableRow(key, renderArrayAsString(value))}
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        }
    };

    // const renderTableRow = (label, value) => (
    //     <tr>
    //         <td className="border px-4 py-2 font-semibold text-left">{label}</td>
    //         <td className="border px-4 py-2 text-left">{value}</td>
    //     </tr>
    // );

    // const renderArrayAsString = (array) => (Array.isArray(array) ? array.join(', ') : array);

    return (
        <>
            <div className="flex gap-4">
                <div className="secondcardWidth">
                    <FullCard>
                        <div className="subheading text-center mt-2">
                            Information Extraction
                        </div>
                        <div className="text-center">
                            <div className="my-8">
                                <div className="items-center">
                                    <FileUpload
                                        accept={"application/pdf"}
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>
                            <div className="mt-4">
                                {selectedFiles && (
                                    <div>
                                        <b>File Name: </b>
                                        {selectedFiles.name}
                                    </div>
                                )}
                                {progress > 0 && (
                                    <div className="my-4">
                                        <div className="bg-[#D2CFF1] h-4 rounded-full">
                                            <div
                                                className="bg-[#23479F] h-full rounded-full"
                                                style={{
                                                    width: `${progress}%`,
                                                }}
                                            ></div>
                                        </div>
                                        <div className="text-center mt-2">{`${progress}% Complete`}</div>
                                    </div>
                                )}
                            </div>
                            {progress === 100 && (
                                <>
                                    <div className={"flex justify-center my-8"}>
                                        <Button onClick={GetOutput}>Proceed</Button>
                                    </div>
                                    <div>
                                        {error && (
                                            <div className="text-red-500">
                                                {'An error occurred. Please try again.'}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        {/* <div className='border-b-2 border-[#CACACA]'>
                            <div className="text-lg font-medium text-indigo-800 text-left">
                                System Extracted Tags
                            </div>
                            <div className='py-4 text-gray-300'>
                                Need to display tag here
                            </div>
                        </div>
                        <div className="text-left">
                            <div className="text-lg font-medium text-indigo-800 my-2">
                                Add Your Tags
                            </div>
                            <div>
                                <input
                                    name="myInput"
                                    className="font-normal text-black-600 w-full border border-[#23479F] rounded-lg p-2 my-2"
                                    placeholder="Add tags here"
                                    rows={1}
                                />

                            </div>
                        </div>
                        <div className={"flex justify-center my-8"}>
                            <Button disabled>Extract</Button>
                        </div> */}
                    </FullCard>
                </div>
                <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                    <DisplayOutput />
                </div>
            </div>
        </>
    );
};

export default InformationExtraction;
