import React, { memo, useRef, useState, useEffect } from "react";
import { RxDot, RxDragHandleDots2 } from "react-icons/rx";
import { diffWords } from "diff"; // Import the diffWords function
import { nanoid } from "nanoid";
import katex from "katex";
import * as store from "../common/store";
import { parseTemplateDataPlain } from "../utils/utils";
import ClickAway from "./ClickAway";
import Draggable from "react-draggable";

function TextBoxMemo(props) {
  const inputRef = useRef(null);
  const parentRef = useRef(null);
  const toolbarRef = useRef(null);
  const tooltipRef = useRef(null);
  const key = props.id ?? nanoid(5);

  const { readOnly, content } = props;
  const {
    position: initialPos,
    insert: insertNew,
    focused: initialFocused,
    style = {},
  } = content;

  const [focused, setFocused] = useState(
    !readOnly && (initialFocused ?? false)
  );

  // console.log("focused", props.pageIndex);

  const [isHovered, setIsHovered] = useState(false);

  const [hoveredButton, setHoveredButton] = useState(null);

  const [accumulatedText, setAccumulatedText] = useState("");

  const [acceptClickCount, setAcceptClickCount] = useState(0);

  const [dismissClickCount, setDismissClickCount] = useState(0);

  // Function to save the current cursor position
  function saveSelection() {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return null;
    const range = selection.getRangeAt(0);
    return {
      range,
      startContainer: range.startContainer,
      startOffset: range.startOffset,
    };
  }

  // Function to restore the cursor position
  function restoreSelection(savedSelection) {
    if (!savedSelection) return;
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(savedSelection.range);
    savedSelection.startContainer.splitText(savedSelection.startOffset);
  }

  const contentModified = (contentId, accumulatedText) => {
    const savedSelection = saveSelection();
    if (!props.data || !props.data.pages) {
      // Handle cases where dataCopy or dataCopy.pages is not defined
      return;
    }

    const pages = props.data.pages;
    const page = pages[props.pageIndex];
    page.content = page.content.map((c, idx) => {
      if (c.id !== contentId) {
        return c;
      }
      const data = [...c.data];
      const text = htmlToPlainText(accumulatedText);
      data[0] = { ...data[0], text };
      return { ...c, data };
    });
    pages[props.pageIndex] = page;
    return pages;
    // props.data.pages.forEach((page, pageIndex) => {
    //   if (!page.content) {
    //     // Handle cases where page.content is not defined
    //     return;
    //   }
    //   console.log(store.get());
    //   store.update("pages", []);
    //   console.log(store.get());
    //   // page.content.forEach((content, contentIndex) => {
    //   //   if (content.id === contentId) {
    //   //     // You can access other properties of the content here
    //   //     const modifiedText = htmlToPlainText(accumulatedText);
    //   //     if (content.data[0].text !== modifiedText) {
    //   //       // Update the text property of the content with modifiedText
    //   //       // content.data[0].text = modifiedText;
    //   //       const newContent = [...content];
    //   //       newContent.data[0].text = modifiedText;
    //   //       // store.update()

    //   //       // Now, set the updated data back into dataCopy using setDataCopy
    //   //       // const updatedDataCopy = { ...dataCopy }; // Create a copy to avoid mutating the original data
    //   //       // updatedDataCopy.pages[pageIndex].content[contentIndex] = content;
    //   //       // setDataCopy(updatedDataCopy);
    //   //       // restoreSelection(savedSelection);
    //   //     }
    //   //   }
    //   // });
    // });
  };

  const handleButtonClick = (button) => {
    if (button === "Accept" && acceptClickCount === 0) {
      const newText = wordDiffs
        .map((wordDiff) =>
          wordDiff.removed ? wordDiff.correctedText : wordDiff.value
        )
      // .join(" ");



      const updatedAccumulatedText = accumulatedText + newText;

      //const pages = contentModified(key, updatedAccumulatedText);
      const pages = contentModified();
      store.update("pages", pages);
      inputRef.current.innerHTML = tooltipRef?.current?.innerHTML;

      // const newData = store.get();
      // newData.pages = pages;

      // setData

      setAcceptClickCount(1); // Mark "Accept" as clicked for this paragraph
    } else if (button === "Dismiss") {
      setAcceptClickCount(1);
    }
    setFocused(false);
  };

  // Calculate word differences between "text" and "correctedText"
  const wordDiffs = diffWords(
    props.content.data.map((data) => data.text).join(" "),
    props.content.data.map((data) => data.correctedText ? data.correctedText : data.text).join(" "),
    { ignoreWhitespace: false } // Keep whitespace for word boundary detection
  );

  // Function to split text into words
  function splitIntoWords(text) {
    return text.split(/\s+/);
  }

  // Extract words from the word differences
  const removedWords = wordDiffs
    .filter((wordDiff) => wordDiff.removed)
    .map((wordDiff) => splitIntoWords(wordDiff.value));

  //console.log("removedWords", removedWords)

  const correctedWords = wordDiffs
    .filter((wordDiff) => wordDiff.added)
    .map((wordDiff) => splitIntoWords(wordDiff.value));

  //console.log("correctedWords", correctedWords)

  // Modify parseTemplateData to add spans for removed words
  function parseTemplateData(data, key = "text") {
    let content = "";
    const words = splitIntoWords(
      data.map((c) => (c[key] ? c[key] : "")).join(" ")
    );

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const isRemoved = removedWords.some(
        (removedWord) => removedWord.join(" ") === word
      );

      // const rgbColor = `rgb(${props?.content?.style?.color?.map?.((c) => c * 255).join(",")})`
      // if (isRemoved) {
      //   content += `<span class="highlighter" style="color: red;" data-color="${rgbColor}">${word}</span>`;
      // } else {
      // }
      content += `<span class="highlighter" ${isRemoved ? 'style="color: red;"' : ""
        } >${word}</span>`;

      if (i < words.length - 1) {
        content += " ";
      }
    }

    return content;
  }

  const getTextBoxPosition = (x = 0, y = 0) => {
    let pos = {
      x: props.content.position.x - 12,
      y: props.content.position.y - 68,
    };
    try {
      pos = window.pdfDoc
        .getElementById(`tbox-id--${key}`)
        .getBoundingClientRect(); // position object
      // console.log(pos, window.scrollX, window.scrollY, props.sheetId);
      const sheet = window.pdfDoc
        .getElementById(props.sheetId)
        .getBoundingClientRect();
      return `translate(${pos.left - sheet.x + x}px, ${pos.top - sheet.y + y - 68
        }px)`;
    } catch (err) {
      return `translate(${pos.x + x}px, ${pos.y + y}px)`;
    }
  };

  function syncActive(value) {
    setFocused(value);

    if (value) {
      // Only focus when value is true (i.e., when setting it to true)
      if (inputRef.current) {
        //inputRef.current.focus();
      }
    }
  }

  function htmlToPlainText(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  function handleInputChange(e) {
    const pages = contentModified(key, e.target.innerHTML);
    store.update("pages", pages);
  }

  if (props.isClicked) {
    return (
      <ClickAway onClickAway={syncActive}>
        <div id={`textbox--main-cont--${key}`}>
          <div
            ref={parentRef}
            refid={key}
            id={`tooltip-id--${key}`}
            className={`pdft-tbox-tooltip--cont ${focused && acceptClickCount === 0 ? "focused" : ""
              }`}
            style={{
              // position: "sticky",
              display: focused && acceptClickCount === 0 ? "flex" : "none",
              flexDirection:
                focused && acceptClickCount === 0 ? "column" : "none",
              justifyContent:
                focused && acceptClickCount === 0 ? "flex-start" : "none",
              transform: getTextBoxPosition(),

            }}
          >
            {/* Tooltip Contents Start here */}
            <div
              className={`tbody-cont pdf-text ${focused ? "focused" : ""}`}
              data-focused={focused.toString()}
              style={{
                color: `rgb(${style?.color?.map?.((c) => c * 255).join(",")})`,
                //fontSize: style?.size + "px",
              }}
            >
              <div
                id={`container--${key}`}
                className={`data-container`}
                ref={tooltipRef}
                //ref={inputRef}
                spellCheck={false}
                contentEditable={focused}
                onInput={handleInputChange}
                data-placeholder="Start typing here ..."
                textnode={1}
                data-color={`rgb(${props?.content?.style?.color
                  ?.map?.((c) => c * 255)
                  .join(",")})`}
                dangerouslySetInnerHTML={{
                  __html: parseTemplateData(props?.content?.data, "text"),
                }}
                style={{
                  fontSize: props?.content?.fontSize
                    ? props?.content?.fontSize
                    : "",
                  outline: "none",
                }}
                onContextMenu={(e) => {
                  if (focused) {
                    // console.log(e);
                  }
                }}
              />
            </div>
          </div>

          <Draggable
            handle=".tbox"
            cancel={focused ? `.cancel` : `.tbox`}
            bounds=".pdf-sheet-wrap"
            defaultPosition={initialPos}
          >
            <div
              key={key}
              // ref={parentRef}
              refid={key}
              id={`tbox-id--${key}`}
              className={`tbox pdf-text ${focused ? "focused" : ""}`}
              dataheight={style.height ? style.height + 5.2 : "null"}
              style={{
                width: `calc(${`${style.width}px`} + 30px + 2.6px + 5.6px + 6.26px)`,
                fontFamily: "'Noto Sans', sans-serif",
                transform: `translateX(${content.position.x}px) translateY(${content.position.y}px)`,
                //transform: "translateX(100px) translateY(150px)",
                overflow: "auto",
              }}
            // onInput={(e) => contentModified(key, e.target.innerHTML)}
            >
              {/* PDF Contents Start here */}
              <div
                //ref={tooltipRef}
                ref={inputRef}
                className={`tooltip-content ${focused ? "focused" : ""}`}
                contentEditable={true}
              //style={{ fontSize: style.size }}
              >
                {wordDiffs.map((wordDiff, i) => {
                  return (
                    <span
                      key={i}
                      ref={inputRef}
                      // contentEditable={focused}
                      className={`cursor-pointer`}
                      style={{
                        fontWeight: wordDiff.added ? "bold" : "normal",
                        color: wordDiff.removed
                          ? "red"
                          : wordDiff.added
                            ? "green"
                            : "inherit",
                        //fontSize: style.size

                      }}
                    >
                      {wordDiff.removed ? wordDiff.correctedText : wordDiff.value}
                    </span>
                  )
                }
                )}
              </div>
              {/* button disable condition */}
              <div style={{ display: focused && acceptClickCount === 0 ? "block" : "none", }} >
                <div
                  className="button-container btn-body"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: "4px",
                    paddingLeft: "2px",
                  }}
                >
                  <button
                    className={`dismiss-button ${hoveredButton === "Dismiss" ? "hovered" : ""
                      }`}
                    onClick={(e) => {
                      handleButtonClick("Dismiss");
                    }}
                    disabled={acceptClickCount > 0}
                    style={{ display: acceptClickCount > 0 ? "none" : "block" }}
                  >
                    Accept
                  </button>
                  <button
                    className={`accept-button ${hoveredButton === "Accept" ? "hovered" : ""
                      }`}
                    onClick={(e) => handleButtonClick("Accept")}
                    disabled={acceptClickCount > 0}
                    style={{ display: acceptClickCount > 0 ? "none" : "block" }}
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            </div>
          </Draggable>
        </div>
      </ClickAway>
    );
  } else {
    return (
      <div
        key={key}
        ref={parentRef}
        refid={key}
        id={`tbox-id--${key}`}
        className={`tbox pdf-text ${focused ? "focused" : ""}`}
        dataheight={style.height ? style.height + 5.2 : "null"}
        style={{
          width: `calc(${`${style.width}px`} + 12px + 2.6px + 5.6px + 6.26px)`,
          fontFamily: "'Noto Sans', sans-serif",
          transform: `translateX(${content.position.x}px) translateY(${content.position.y}px)`,
        }}
      >
        <div className={`tmover pdf-text ${focused ? "focused" : ""}`}>
          <RxDragHandleDots2 />
        </div>
        <div
          className={`tbody-cont pdf-text ${focused ? "focused" : ""}`}
          data-focused={focused.toString()}
          style={{
            color: `rgb(${style?.color?.map?.((c) => c * 255).join(",")})`,
            fontSize: style?.size + "px",
          }}
        >
          <div
            contentEditable={focused}
            data-placeholder="Start typing here ..."
            textnode={1}
            dangerouslySetInnerHTML={{
              __html: parseTemplateDataPlain(props?.content?.data, "text"),
            }}
            style={{
              fontSize: props?.content?.fontSize
                ? props?.content?.fontSize + 1
                : "",
            }}
            onContextMenu={(e) => {
              if (focused) {
                // console.log(e);
              }
            }}
          />
          <div
            className={`resizer pdf-text cancel  ${focused ? "focused" : ""}`}
          >
            <RxDot />
          </div>
        </div>
      </div>
    );
  }
}

const TextBox = memo(TextBoxMemo);

export default TextBox;
