import React, { useEffect, useState } from 'react';
import { Search, ChevronRight, ChevronDown } from 'lucide-react';

// Utility functions remain the same
const getUniqueTopicCodes = (grade, subject, topics) =>
    topics.map(topic => `${grade}-${subject}-${topic.code}`);

const getAllCodesInGrade = (grade, subjects) =>
    Object.entries(subjects).flatMap(([subject, topics]) => getUniqueTopicCodes(grade, subject, topics));

// Custom Badge Component
const Badge = ({ children, variant = 'primary' }) => {
    const baseClasses = "px-2 py-0.5 rounded-full text-xs font-medium";
    const variants = {
        primary: "bg-blue-100 text-blue-800",
        secondary: "bg-gray-100 text-gray-800",
        outline: "border border-gray-200 text-gray-600"
    };

    return (
        <span className={`${baseClasses} ${variants[variant]}`}>
            {children}
        </span>
    );
};

// Topic Node Component
const TopicNode = ({ topic, isSelected, onToggle, searchTerm }) => {
    const highlightText = (text, term) => {
        if (!term) return text;
        const parts = text.split(new RegExp(`(${term})`, 'gi'));
        return parts.map((part, i) =>
            part.toLowerCase() === term.toLowerCase() ?
                <span key={i} className="bg-yellow-200">{part}</span> : part
        );
    };

    return (
        <div className="ml-8 py-1 group">
            <label className="flex items-center space-x-2 cursor-pointer hover:bg-slate-50 p-2 rounded-md transition-colors">
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={(e) => onToggle(topic.code, !e.target.checked)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-gray-700 group-hover:text-gray-900">
                    {highlightText(topic.text, searchTerm)}
                </span>
            </label>
        </div>
    );
};

// Subject Node Component
const SubjectNode = ({ grade, subjectName, topics, selectedTopics, onToggle, searchTerm }) => {
    const [isOpen, setIsOpen] = useState(false);
    const uniqueTopicCodes = getUniqueTopicCodes(grade, subjectName, topics);
    const isAllSelected = uniqueTopicCodes.every(code => selectedTopics.includes(code));

    const handleSubjectToggle = () => {
        onToggle(uniqueTopicCodes, isAllSelected);
    };

    // Auto-expand only if search term matches any topic in this subject
    useEffect(() => {
        if (searchTerm) {
            const matchesSearch = topics.some(topic =>
                topic.text.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setIsOpen(matchesSearch);
        }
    }, [searchTerm, topics]);

    return (
        <div className="ml-6 my-2">
            <div className="flex items-center space-x-2 hover:bg-slate-50 p-2 rounded-md transition-colors">
                <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleSubjectToggle}
                    className="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                />
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center space-x-2 font-medium text-purple-700 hover:text-purple-900"
                >
                    {isOpen ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                    <span>{subjectName}</span>
                </button>
                <Badge variant="outline">{topics.length} topics</Badge>
            </div>
            <div className={`overflow-hidden transition-all duration-200 ${isOpen ? 'max-h-[1000px]' : 'max-h-0'}`}>
                {topics.map(topic => (
                    <TopicNode
                        key={`${grade}-${subjectName}-${topic.code}`}
                        topic={topic}
                        isSelected={selectedTopics.includes(`${grade}-${subjectName}-${topic.code}`)}
                        onToggle={(code, deselect) => onToggle([`${grade}-${subjectName}-${code}`], deselect)}
                        searchTerm={searchTerm}
                    />
                ))}
            </div>
        </div>
    );
};

// Grade Node Component
const GradeNode = ({ grade, subjects, selectedTopics, onToggle, searchTerm }) => {
    const [isOpen, setIsOpen] = useState(false);
    const allGradeCodes = getAllCodesInGrade(grade, subjects);
    const isAllSelected = allGradeCodes.every(code => selectedTopics.includes(code));
    const totalTopics = Object.values(subjects).reduce((sum, topics) => sum + topics.length, 0);

    const handleGradeToggle = () => {
        onToggle(allGradeCodes, isAllSelected);
    };

    // Auto-expand only if search term matches any topic in this grade
    useEffect(() => {
        if (searchTerm) {
            const matchesSearch = Object.values(subjects).some(topics =>
                topics.some(topic => topic.text.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setIsOpen(matchesSearch);
        }
    }, [searchTerm, subjects]);

    return (
        <div className="mb-4">
            <div className="flex items-center space-x-2 bg-slate-50 p-3 rounded-lg hover:bg-slate-100 transition-colors">
                <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleGradeToggle}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center space-x-2 font-semibold text-blue-800"
                >
                    {isOpen ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                    <span>Grade {grade}</span>
                </button>
                <Badge>{Object.keys(subjects).length} subjects</Badge>
                <Badge variant="outline">{totalTopics} topics</Badge>
            </div>
            <div className={`overflow-hidden transition-all duration-200 ${isOpen ? 'max-h-[2000px]' : 'max-h-0'}`}>
                {Object.keys(subjects).map(subject => (
                    <SubjectNode
                        key={`${grade}-${subject}`}
                        grade={grade}
                        subjectName={subject}
                        topics={subjects[subject]}
                        selectedTopics={selectedTopics}
                        onToggle={onToggle}
                        searchTerm={searchTerm}
                    />
                ))}
            </div>
        </div>
    );
};

// Selection Summary Component
const SelectionSummary = ({ selectedTopics, tutorTopic }) => {
    const getTopicText = (topicCode) => {
        const [grade, subject, code] = topicCode.split('-');
        
        // Access the subject object from tutorTopic
        const subjectTopics = tutorTopic[grade]?.[subject];
        
        if (!subjectTopics) {
            console.log(`No topics found for ${grade} ${subject}`);
            return topicCode;
        }

        // Find the specific topic
        const topic = subjectTopics.find(t => String(t.code) === String(code));
        
        if (!topic) {
            console.log(`Topic not found for code: ${code}`);
            return topicCode;
        }

        return topic.text;
    };

    return (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
            <h3 className="font-semibold text-gray-700 mb-2">Selected Topics ({selectedTopics.length})</h3>
            <div className="flex flex-wrap gap-2">
                {selectedTopics.map((topicCode, index) => {
                    if (index < 20) {
                        const topicText = getTopicText(topicCode);
                        return (
                            <Badge key={topicCode} variant="secondary">
                                {topicText}
                            </Badge>
                        );
                    }
                    return null;
                })}
                {selectedTopics.length > 20 && (
                    <Badge variant="secondary">
                        +{selectedTopics.length - 5} more
                    </Badge>
                )}
            </div>
        </div>
    );
};
// Main TutorTopicTree component
const TutorTopicTree = ({ tutorTopic }) => {
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const handleToggle = (codes, deselect = false) => {
        setSelectedTopics(prevSelected => {
            if (deselect) {
                return prevSelected.filter(code => !codes.includes(code));
            }
            return [...new Set([...prevSelected, ...codes])];
        });
    };

    return (
        <div className="w-full max-w-3xl mx-auto max-h-screen overflow-y-auto bg-white rounded-lg shadow-sm border border-gray-200">
            <div className="p-6">
                <div className="mb-6 relative">
                    <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                    <input
                        type="text"
                        placeholder="Search topics..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <div className="space-y-4">
                    {Object.keys(tutorTopic).map((grade) => (
                        <GradeNode
                            key={grade}
                            grade={grade}
                            subjects={tutorTopic[grade]}
                            selectedTopics={selectedTopics}
                            onToggle={handleToggle}
                            searchTerm={searchTerm}
                        />
                    ))}
                </div>

                {selectedTopics.length > 0 && (
                    <SelectionSummary
                        selectedTopics={selectedTopics}
                        tutorTopic={tutorTopic}
                    />
                )}
            </div>
        </div>
    );
};

export default TutorTopicTree;