import React from "react";
import { useState } from "react";
import  UpIcon  from "../assets/up.svg";
import DownIcon from "../assets/down.svg";
import PlusIcon from "../assets/plus.svg";
import ZoomInIcon from "../assets/zoomIn.svg";
import MinusIcon from "../assets/minus.svg";
import FullScreenIcon from "../assets/fullscreen.svg"

export function Pagination() {
  const [active, setActive] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1);
 
  const zoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };
 
  const zoomOut = () => {
    setZoomLevel(Math.max(zoomLevel - 0.1, 0.1));
  };
  const next = () => {
    if (active === 100) return;
    setActive(active + 1);
  };
  const prev = () => {
    if (active === 1) return;
    setActive(active - 1);
  };
  return (
<div className="flex flex items-center justify-center ">
    <div className="bg-white border border-black rounded-lg p-6 shadow-xl card w-1/6 absolute bottom-10">
        <div className="flex flex-wrap justify-between">

<button
        onClick={prev}
        disabled={active === 1}
        
>
<img src={DownIcon} alt="downicon"/>
</button>
<div className="gap-2">
<strong className="text-gray-900">{active}</strong> /
<strong className="text-gray-900">100</strong>
</div>
<button
        
        onClick={next}
        disabled={active === 100}
>
<img src={UpIcon} alt="upicon"/>
</button>
<button
        
        onClick={zoomIn}
        disabled={zoomLevel>=2}
      > <img src={ZoomInIcon} alt="zoomin"/></button>
<div >
<button
        
        onClick={zoomIn}
        disabled={zoomLevel>=2}
      > <img src={PlusIcon} alt="plus"/></button>
  </div>
  <div style={{ background: "#f8f8f8", padding: "2px 8px" }}>100</div>
  <button
        onClick={zoomOut}
        disabled={zoomLevel<=0.1}
      > <img src={MinusIcon} alt="minus"/></button>
      <button
        onClick={zoomOut}
        disabled={zoomLevel<=0.1}
      > <img src={FullScreenIcon} alt="fullscreen"/></button>
</div>
</div>
</div>

  );
}



export default Pagination;

