import { styled } from "styled-components";
import { theme } from "../configs/themeConfig";

export const Icon = styled.button.attrs((props) => ({
  type: props.type || "button",
  // style,
}))`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  border-radius: 2.6px;
  outline: none;
  border: none;
  background: none;
  /* background: ${({ active }) => (active ? "#eaeaea" : "none")}; */
  background: ${({ active }) => (active ? theme.primary.light : "none")};
  &:hover {
    /* background: #eaeaea; */
    background: ${theme.primary.light};
  }
`;

// function Icon(props){
//     return <divn >

//     </div>
// }
