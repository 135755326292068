import React, { useState, useEffect } from "react";
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import 'pdfjs-dist/legacy/build/pdf.worker.entry';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {
    Card,
    Button,
    Spinner,
    FullCard,
} from "../components/CommonComponents";
import { FileUpload } from "../components/Fileupload";
import { scienceURL } from "../api/serverAPI";
import axios from 'axios';

const PdfUpload = () => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [collName, setCollName] = useState('');
    const [message, setMessage] = useState('');
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredCollections, setFilteredCollections] = useState([]);
    const [pageImages, setPageImages] = useState([]);
    const [deletedPages, setDeletedPages] = useState([]);
    const [undoStack, setUndoStack] = useState([]);
    const [originalFile, setOriginalFile] = useState(null);
    const [createColl, setCreateColl] = useState(false);


    useEffect(() => {
        fetchCollections();
    }, []);

    useEffect(() => {
        if (selectedFiles) {
            generatePageImages();
        }
    }, [selectedFiles]);

    useEffect(() => {
        filterCollections();
    }, [searchQuery, collections]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        handleFile(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
    };

    const handleFile = async (file) => {
        setSelectedFiles(URL.createObjectURL(file));
        setOriginalFile(file);
        setDeletedPages([]);
        setUndoStack([]);

        // Send file name to the /getname API
        try {
            const response = await axios.post('http://localhost:5000/getname', {
                file_name: file.name
            });
            setSearchQuery(response.data.message);
        } catch (error) {
            console.error('Error fetching collection name:', error);
        }

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                } else {
                    clearInterval(interval);

                    return prevProgress;
                }
            });
        }, 1000);
    };

    const fetchCollections = async () => {
        try {
            const response = await axios.post('http://localhost:5000/showcoll');
            if (response.data.message !== "No collections found") {
                setCollections(response.data.message);
            } else {
                setCollections([]);  // Clear collections if none found
                setMessage(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const handleCreateCollection = async () => {
        if (!collName) {
            alert('Please enter a collection name');
            return;
        }

        try {
            const response = await axios.post('http://localhost:5000/createcoll', { collname: collName });
            setMessage(response.data.message);
            fetchCollections();  // Refresh the collections list after creation
        } catch (error) {
            setMessage('An error occurred');
            console.error('Error creating collection:', error);
        }
    };

    const handleDeleteCollection = async (collName) => {
        const confirmDelete = window.confirm(`Do you really want to delete the collection: ${collName}?`);
        if (confirmDelete) {
            try {
                const response = await axios.post('http://localhost:5000/deletecoll', { collname: collName });
                setMessage(response.data.message);
                fetchCollections();  // Refresh the collections list after deletion
            } catch (error) {
                setMessage('An error occurred');
                console.error('Error deleting collection:', error);
            }
        }
    };

    const handlePageDelete = (pageIndex) => {
        setUndoStack([...undoStack, [...pageImages]]);
        setDeletedPages([...deletedPages, pageIndex]);
    };

    const handleUndo = () => {
        if (undoStack.length > 0) {
            const previousState = undoStack.pop();
            setPageImages(previousState);
            setDeletedPages(deletedPages.slice(0, -1));
        }
    };

    const handleCollectionClick = (collection) => {
        setSelectedCollection(collection);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filterCollections = () => {
        if (!searchQuery) {
            setFilteredCollections(collections);
        } else {
            const query = searchQuery.toLowerCase();
            setFilteredCollections(
                collections.filter(coll => coll.toLowerCase().includes(query))
            );
        }
    };

    const generatePageImages = async () => {
        if (!selectedFiles) return;

        const pdf = await pdfjsLib.getDocument({ url: selectedFiles }).promise;
        const numPages = pdf.numPages;
        const newPageImages = [];

        for (let i = 1; i <= numPages; i++) {
            const page = await pdf.getPage(i);
            const viewport = page.getViewport({ scale: 1 });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            await page.render({
                canvasContext: context,
                viewport: viewport
            }).promise;

            newPageImages.push(canvas.toDataURL());
        }

        setPageImages(newPageImages);
    };

    const handleDownload = async (event) => {
        event.preventDefault();

        if (!selectedFiles) {
            alert('Please upload a PDF file');
            return;
        }

        const pageRanges = [];
        let start = null;

        for (let i = 0; i < pageImages.length; i++) {
            const pageIndex = i + 1;
            if (!deletedPages.includes(i)) {
                if (start === null) start = pageIndex;
                if (deletedPages.includes(i + 1) || i + 1 === pageImages.length) {
                    pageRanges.push([start, pageIndex]);
                    start = null;
                }
            }
        }

        const formData = new FormData();
        formData.append('file', originalFile);
        formData.append('ranges', JSON.stringify(pageRanges));

        console.log('File:', originalFile);
        console.log('Page Ranges:', pageRanges);

        setLoading(true);
        setMessage('');

        try {
            const response = await axios.post('http://localhost:5000/downloadpdf', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob'  // Important to receive the response as a Blob
            });

            // Create a download link
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            // Set the file name from the Content-Disposition header, if available
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'downloaded_file.pdf';
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename="(.+)"/);
                if (matches.length > 1) {
                    fileName = matches[1];
                }
            }
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Cleanup
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            setMessage('An error occurred');
            console.error('Error downloading file:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedFiles) {
            alert('Please upload a PDF file');
            return;
        }

        if (!selectedCollection) {
            alert('Please select a collection');
            return;
        }

        const pageRanges = [];
        let start = null;

        for (let i = 0; i < pageImages.length; i++) {
            const pageIndex = i + 1;
            if (!deletedPages.includes(i)) {
                if (start === null) start = pageIndex;
                if (deletedPages.includes(i + 1) || i + 1 === pageImages.length) {
                    pageRanges.push([start, pageIndex]);
                    start = null;
                }
            }
        }

        const formData = new FormData();
        formData.append('file', originalFile);
        formData.append('ranges', JSON.stringify(pageRanges));
        formData.append('collection', selectedCollection);

        console.log('File:', originalFile);
        console.log('Page Ranges:', pageRanges);
        console.log('Selected Collection:', selectedCollection);

        setLoading(true);
        setMessage('');

        try {
            const response = await axios.post('http://localhost:5000/processpdf', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setMessage(response.data.message);
        } catch (error) {
            setMessage('An error occurred');
            console.error('Error uploading file:', error);
        } finally {
            alert(message);
            setLoading(false);
        }
    };

    return (
        <div className="flex gap-4">
            {loading && (
                <div className="overlay">
                    <Spinner />
                </div>
            )}
            <div className="secondcardWidth">
                <FullCard>
                    <div className="subheading text-center mt-2">
                        PDF Uploader
                    </div>
                    <div className="my-8 text-center">
                        <div className="items-center" onDragOver={handleDragOver}
                            onDrop={handleDrop}>
                            <FileUpload
                                accept={"application/pdf"}
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                    <div className="mt-4 text-center">
                        {originalFile && (
                            <div className="mx-10">
                                <b>File Name: </b>
                                {originalFile.name}
                            </div>
                        )}
                        {progress > 0 && (
                            <div className="my-4">
                                <div className="bg-[#D2CFF1] h-4 rounded-full">
                                    <div
                                        className="bg-[#23479F] h-full rounded-full"
                                        style={{
                                            width: `${progress}%`,
                                        }}
                                    ></div>
                                </div>
                                <div className="text-center mt-2">{`${progress}% Complete`}</div>
                            </div>
                        )}
                    </div>
                    <div className="my-8">
                        <Button onClick={() => setCreateColl(true)} >Create Collection</Button>
                    </div>
                </FullCard>
            </div>
            <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                <div className="h-full w-full">
                    {originalFile && progress === 100 && !createColl && (
                        <>
                            <div className="p-2">
                                <div className="flex items-center justify-between">
                                    <label className="text-2xl">Preview & Delete Pages:</label>
                                    {undoStack != 0 && (<Button onClick={handleDownload}>
                                        Download
                                    </Button>)}
                                </div>
                                <div className="grid grid-cols-4 gap-4">
                                    {pageImages.map((image, index) => (
                                        !deletedPages.includes(index) && (
                                            <div key={index} className="relative border p-2 bg-white text-black">
                                                <img src={image} alt={`Page ${index + 1}`} className="w-full h-auto" />
                                                <button
                                                    onClick={() => handlePageDelete(index)}
                                                    className="absolute top-2 right-2 bg-red-600 text-white p-1 rounded-full">
                                                    X
                                                </button>
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                            {undoStack != 0 && (<div className="p-2">
                                <Button
                                    onClick={handleUndo}
                                >
                                    Undo
                                </Button>
                            </div>)}
                            <div className="p-2">
                                <label className="text-2xl">Search Collections:</label>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeholder="Search collections..."
                                    className="bg-gray-800 text-white p-2 rounded w-full"
                                />
                            </div>

                            {searchQuery && (<div className="p-2">
                                <div className="grid grid-cols-3 text-white gap-4">
                                    {filteredCollections.map((coll, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleCollectionClick(coll)}
                                            className={`p-4 border rounded cursor-pointer ${selectedCollection === coll ? 'bg-[#23479F]' : 'bg-gray-800'}`}
                                        >
                                            {coll}
                                        </div>
                                    ))}
                                </div>
                            </div>)}

                            {selectedCollection && (<div className="p-2">
                                <Button
                                    onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </div>)}
                        </>
                    )}

                    {createColl && (
                        <><div className="p-2">
                            <label className="text-2xl">Create Collections:</label>
                            <input
                                type="text"
                                placeholder="Enter collection name"
                                value={collName}
                                onChange={(e) => setCollName(e.target.value)}
                                className="bg-gray-800 text-white p-2 rounded mb-4 w-full"
                            />
                        </div>

                            <div className="p-2 flex gap-4">
                                <Button onClick={handleCreateCollection}>
                                    Create
                                </Button>
                                <Button onClick={() => setCreateColl(false)}>
                                    Cancel
                                </Button>
                            </div>


                            {message && <div className="mt-4">{message}</div>}

                            <div className="p-2">
                                <label className="text-2xl">Existing Collections</label>
                                {collections.length > 0 ? (
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                        {collections.map((coll, index) => (
                                            <div key={index} className="bg-gray-800 p-4 rounded shadow-md">
                                                <h3 className="text-lg font-semibold mb-2">{coll}</h3>
                                                <button
                                                    onClick={() => handleDeleteCollection(coll)}
                                                    className="bg-red-600 hover:bg-red-700 text-white p-2 rounded"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    !message && <div>No collections found</div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PdfUpload;