import React from "react";
import Sidebar from "../components/Sidebar";

const AskQuestion = () => {
  return (
    <div className="flex">
      <div>
        <Sidebar />
      </div>
      <div className="flex items-center justify-center h-screen w-full">
        <div className="text-2xl font-bold">Coming Soon...</div>
      </div>
    </div>
  );
};

export default AskQuestion;
