import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import { Card, FullCard, Spinner, Button } from '../components/CommonComponents';
import { FileUploadMms } from '../components/Fileupload';
// import { pdfjs } from 'react-pdf';
import { LuX } from "react-icons/lu"; 
import { mmsFileUpload } from "../api/serverAPI"; 

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const EnterpriseFileUpload = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [url, setUrl] = useState("");
    const [progress, setProgress] = useState(0);
    const [base64Data, setBase64Data] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadMethod, setUploadMethod] = useState("method1");
    const [messageMethod1, setMessageMethod1] = useState("");  
    const [messageMethod2, setMessageMethod2] = useState(""); 
    const [uploadedFiles, setUploadedFiles] = useState([]); 
    const [loading, setLoading] = useState(false);
    const [urlError, setUrlError] = useState("");

    const handleFileChange = (e) => {
        if (!e.target || !e.target.files) {
            return; 
        }

       
        setProgress(0);
        const files = Array.from(e.target.files);
        setSelectedFiles(files);

        if (files.length > 0) {
            const readers = files.map(file => {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(",")[1];
                    setBase64Data(prev => [...(prev || []), base64String]);
                };
                reader.readAsDataURL(file);
                return reader;
            });

            const interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress < 100) {
                        return prevProgress + 10; 
                    } else {
                        clearInterval(interval);
                        return prevProgress;
                    }
                });
            }, 1000);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);
        setUrlError(""); 
        try {
            let formData = new FormData();

            if (uploadMethod === "method1" && selectedFiles.length > 0) {
                selectedFiles.forEach(file => {
                    formData.append('files', file); 
                });
            } else if (uploadMethod === "method2" && url) {
                formData.append('urls', url); 
            } else if (uploadMethod === "method2" && !url) {
                setUrlError("URL cannot be empty"); 
                setLoading(false); 
                return;
            } else {
                throw new Error("No file selected or URL entered.");
            }

            const response = await mmsFileUpload(formData); 

            const data = response.data; 
            
            if (uploadMethod === "method1") {
                setMessageMethod1(data.cleanup_result); 
                setUploadedFiles(prev => [...prev, ...data.processing_results]); 
                setMessageMethod2(""); 
                setUrl("");
            } else {
                setMessageMethod2(data.cleanup_result); 
                setMessageMethod1("");
                setUrl(""); 
            }

            setIsModalOpen(true);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFile = (fileToRemove) => {
        const updatedFiles = selectedFiles.filter(file => file !== fileToRemove);
        setSelectedFiles(updatedFiles);

        if (updatedFiles.length === 0) {
            setMessageMethod1("");
            setMessageMethod2("");
            setUploadedFiles([]);
            setSelectedFiles([]);
            setProgress(0); 
        }
    };
    
    const overlayStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000 
    };

    return (
        <>
        {loading && (
            <div style={overlayStyle}>
                <Spinner />
            </div>
        )}
        <div className="flex gap-4">
            <div className="secondcardWidth">
                <FullCard>
                    <div>
                        <div className="subheading text-center mt-2">
                            Multi-File Upload
                        </div>
                        <div className="my-8">
                            <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                Choose Upload Method
                            </div>
                            <div className="my-4">
                                <div className="flex items-center">
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="uploadMethod"
                                            value="method1"
                                            className="h-5 w-5"
                                            checked={uploadMethod === "method1"}
                                            onChange={() => {
                                                setUploadMethod("method1");
                                                setUrlError("");
                                                setMessageMethod2(""); 
                                                setUploadedFiles([]);

                                            }}
                                        />
                                        <span className="ml-2">Upload Files</span>
                                    </label>
                                </div>

                                <div className="flex items-center mt-4">
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="uploadMethod"
                                            value="method2"
                                            className="h-5 w-5"
                                            checked={uploadMethod === "method2"}
                                            onChange={() => {
                                                setUploadMethod("method2");
                                                setSelectedFiles([]); 
                                                setMessageMethod1(""); 
                                                setUploadedFiles([]);
                                                setUrlError("");
                                            }}
                                        />
                                        <span className="ml-2">Enter URL's</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </FullCard>
            </div>

            <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                <div className="chat-container">
                    {uploadMethod === "method1" && (
                        <div className="text-center mx-auto">
                            <div className="my-6">
                                <div className="flex items-center justify-center">
                                    <FileUploadMms onChange={handleFileChange} multiple />
                                </div>
                            </div>

                            {selectedFiles.length > 0 && (
                                <div className="flex flex-col items-center justify-center">
                                    <div className="mb-2">
                                        <b>Files Selected:</b>
                                        <ul>
                                            {selectedFiles.map((file, index) => (
                                                <li key={index} className="flex justify-between items-center">
                                                    {file.name}
                                                    <button onClick={() => handleRemoveFile(file)} className="ml-2 text-black">
                                                        <LuX />
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    {progress > 0 && progress < 100 && (
                                        <div className="my-4 w-full flex flex-col items-center">
                                            <div className="bg-[#D2CFF1] h-4 rounded-full w-[30vw]">
                                                <div
                                                    className="bg-[#23479F] h-full rounded-full"
                                                    style={{ width: `${progress}%` }}
                                                ></div>
                                            </div>
                                            <div className="text-center mt-2 w-[40vw]">{`${progress}% Complete`}</div>
                                        </div>
                                    )}

                                    {progress === 100 && (
                                        <>
                                            <div className="mt-2">
                                                <Button
                                                    size="medium"
                                                    color="light-purple"
                                                    type="button"
                                                    onClick={handleSubmit}
                                                    disabled={loading}
                                                    className="mt-4"
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                            {error && (
                                                <div className="text-red-500 mt-2">
                                                    An error occurred. Please try again.
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}

                            {uploadedFiles.length > 0 && (
                                <div className="flex flex-wrap justify-center mt-4">
                                    {uploadedFiles.map((file, index) => (
                                        <div key={index} className="border p-2 m-2 rounded">
                                            {file}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {messageMethod1 && (  
                                <div className="bg-[#e8fee6] text-black p-2 rounded-md mt-4 text-center w-[30vw] mx-auto">
                                    {messageMethod1}
                                </div>
                            )}
                        </div>
                    )}

                    {uploadMethod === "method2" && (
                        <div className="flex flex-col items-center space-y-4">
                              <div className="flex items-center space-x-4 mt-8 mx-auto">
                                <textarea
                                    className="flex-grow border-2 border-blue-600 rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:outline-none text-lg resize-y w-[50vw] min-h-[50px]"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    placeholder="Enter the URL"
                                />
                                 
                            </div>
                            {urlError && <p className="text-red-500">{urlError}</p>}
                            <Button size="medium" color="light-purple" type="button" onClick={handleSubmit}>
                                Submit
                            </Button>
                            {messageMethod2 && (  
                                <div className="bg-[#e8fee6] text-black p-2 rounded-md mt-4 text-center w-[30vw] mx-auto">
                                    {messageMethod2}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
        </>
    );
};

export default EnterpriseFileUpload;
