"use client"
import { css } from "./print";
import { saveAs } from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";

export async function downloadWord() {
    try {
        // Get the HTML content
        //const htmlContent = document.getElementById("page-container").innerHTML;
        const htmlString = `
        <html>
            <head><style>${css}</style></head>
            <body>${document.getElementById("page-container").innerHTML}</body>
        </html>
        `;
        // const htmlString = `
        // <html>
        //     <head></head>
        //     <body>
        //         <div style="font-size: 18px;">
        //             <span style="font-weight: 800; color: red">1.</span> 
        //             <span style="font-style: italic; margins: {top: 720}">
        //              Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
        //              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
        //              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        //             </span>
        //         </div>
        //     </body>
        // </html>
        // `;
        console.log(htmlString)
        // Create a Blob containing the Word document
        const docx = htmlDocx.asBlob(htmlString);

        // Use file-saver to trigger the download
        saveAs(docx, 'file_doc.docx');
    } catch (error) {
        console.error('Error generating Word document:', error);
    }
};

export async function download() {
    // console.log(css);
    const htmlString = `
    <html>
        <head><style>${css}</style></head>
        <body>${document.getElementById("page-container").innerHTML}</body>
    </html>
    `;
    //console.log(htmlString);
    const res = await fetch(
        //"https://mlmachine.krtrimaiq.ai:7012/pdf/download",
        "https://mlmachine.krtrimaiq.ai:6012/topdf",
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ html: htmlString }),
        }
    );
    const blob = await res.blob();
    saveAs(blob, "file.pdf");
};