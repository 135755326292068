import React, { useEffect, useRef, useState } from "react";
import { Button, Card, FullCard, Select, Spinner } from "../components/CommonComponents";
import {
    tutorGrade,
    tutorRoles,
    tutorSubject,
    tutorTopic,
    tutorTeacherMethods,
    tutorBoard,
    translationLanguages,
    essayCategory,
} from "../components/Mapping";
import Modal from 'react-modal';
import questionIcon from "../assets/questionIcon.svg";
import cloud from "../assets/cloud.svg";
import ansIcon from "../assets/ansIcon.svg";
import DOMPurify from 'dompurify'
import { nanoid } from "nanoid";
import { pairwise } from "../utils/hacks";
import ImageSpinner from "../components/spinner";
import SimpleDropdown from "../components/SimpleDropdown";
import {
    essayListURL,
    languageTranslationURL,
    essayInfoURL,
    chatHistoryEssayURL,
    attemptEssayURL,
    missingSectionFullEssayURL,
    questionAnswerEssayURL,
    missingSectionPartsEssayURL,
    generateQuestionsEssayURL,
    teacherGenerateEssayURL,
    likeEssayURL,
    dislikeEssayURL
} from "../api/serverAPI";
import { MessageSquarePlus } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    BadgeHelp,
    ThumbsDown,
    ThumbsUp,
    Copy
} from 'lucide-react';
import Draggable from "react-draggable";
import axios from 'axios';
import { ImageUpload } from "../components/Fileupload";
import { select } from "react-cookies";


// window.speechSynthesis.cancel();
/**
 * BAse-64 encoding with a URL-safe charset
 * @param {string} s the string to encode
 * @returns {string} the encoded string
 */
function b64_urlsafe(s) {
    return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace('=', '')
}

/**
 * A random string of n **bytes**.
 *
 * ..note::
 *    not all characters are a single byte. intermediate steps may contain fewer characters
 *    if String.fromCharCode returns a multi-byte sequence. This is normalized by base64.
 * @param n The number of bytes, defaults to 16
 * @returns {string} The randomly generated string.
 */
function randomString(n = 16) {
    return b64_urlsafe(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(n))))
}

// Function to format timestamp
const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // This formats the date and time in a readable format
};


// eslint-disable-next-line no-control-regex
const pattern = new RegExp('data:image/[^/]+;base64,[^\0]+\0\0');
let sender = randomString();

const EssayWriter = () => {


    const [selectedRole, setSelectedRole] = useState('teacher');
    const [selectedBoard, setSelectedBoard] = useState('CBSE');
    const [selectedCategory, setSelectedCategory] = useState(essayCategory[0].text);
    const [selectedEssay, setSelectedEssay] = useState('Select a Essay');
    const [endpoint, setEndpoint] = useState('brainstorming');
    const [selectedGrade, setSelectedGrade] = useState('8');
    const [selectedSubject, setSelectedSubject] = useState('English');
    const [selectedTopic, setSelectedTopic] = useState('The Best Christmas Present in the World & The Ant and the Cricket');
    const [selectedTeacherMethod, setSelectedTeacherMethod] = useState('brainstorming');
    const [messages, setMessages] = useState([]);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [displayQuestionInput, setDisplayQuestionInput] = useState(true);
    const [voiceInput, setVoiceInput] = useState(false);

    const [disableTextArea, setDisableTextArea] = useState(true);
    const [imageFlag, setImageFlag] = useState(null);
    const [translationLanguage, setTranslationLanguage] = useState('hi')
    const [translatedText, setTranslatedText] = useState(null);
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const [englishAnswer, setEnglishAnswer] = useState(null);
    const [translate, setTranslate] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [base64Data, setBase64Data] = useState(null);
    const [stream, setStream] = useState(true);
    const [autoScroll, setAutoScroll] = useState(true);
    // const [content, setContent] = useState("");
    const [essayTopic, setEssayTopic] = useState("");
    const [essayOutcome, setEssayOutcome] = useState("");
    const [wholeAns, setWholeAns] = useState("");
    const [essays, setEssays] = useState([]);
    const [essayResult, setEssayResult] = useState("");
    const [missingEssayResult, setMissingEssayResult] = useState([]);
    const [questionEssayResult, setQuestionEssayResult] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isQuestionFormSubmitted, setIsQuestionFormSubmitted] = useState(false);
    const [ansIntro, setAnsIntro] = useState("");
    const [ansBody, setAnsBody] = useState("");
    const [ansConclusion, setAnsConclusion] = useState("");
    const [essayParts, setEssayParts] = useState({ introduction: "", body: "", conclusion: "" });
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const ws = useRef(null);
    const [info, setInfo] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);
    let bIndex = 0;
    // Dummy state to force re-render when updating the global sender variable
    const [, setDummy] = useState(0);

    // Function to refresh sender value
    const refreshSender = () => {
        clearAllVariables();
        sender = randomString(); // Update the global sender variable
        setDummy(prev => prev + 1); // Force re-render by updating a dummy state
        // Trigger a toast notification
        toast.info(`Session Refreshed: ${sender}`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            progress: undefined,
        });
    };

    useEffect(() => {
        window?.speechSynthesis?.cancel();
    }, [])

    const clearAllVariables = () => {
        setSelectedEssay('Select a Essay');
        setWholeAns("");
        setEssays([]);
        setEssayResult("");
        setMissingEssayResult([]);
        setQuestionEssayResult([]);
        setIsFormSubmitted(false);
        setIsQuestionFormSubmitted(false);
        setAnsIntro("");
        setAnsBody("");
        setAnsConclusion("");
        setEssayParts({ introduction: "", body: "", conclusion: "" });
        setQuestions([]);
        setAnswers({});
        setShowPopup(false);
        setChatHistory([]);
        setSelectedSession(null);
        setSelectedSessionIndex(0);
    };

    // reseting when changing essay
    const changeEssay = () => {
        setWholeAns("");
        setEssayResult("");
        setMissingEssayResult([]);
        setQuestionEssayResult([]);
        setIsFormSubmitted(false);
        setIsQuestionFormSubmitted(false);
        setAnsIntro("");
        setAnsBody("");
        setAnsConclusion("");
        setEssayParts({ introduction: "", body: "", conclusion: "" });
        setQuestions([]);
        setAnswers({});
    }


    const handleInfo = async () => {
        setLoading(true);
        try {
            const url = essayInfoURL();
            const response = await axios.get(url);
            setLoading(false);
            if (response.status === 200) {
                setInfo(response.data.output);
            } else {
                console.error('Failed to generate info', response.data.output);
            }
        } catch (error) {
            setLoading(false);
            console.error('An error occurred while generating info', error);
        }
    };

    //Handle colour change and checks if the likebutton is enabled
    const handleLike = (id) => (event) => {
        const likeButton = event.currentTarget.firstChild;
        const dislikeButton = document.getElementById(`dislike-bot-response-${id}`).firstChild;
        const isLikeClicked = likeButton.style.color === 'blue';
        const isDislikeClicked = dislikeButton.style.color === 'red';

        // Toggle like button color
        if (isLikeClicked) {
            likeButton.style.color = 'black'; // Unclick like
        } else {
            likeButton.style.color = 'blue';  // Click like

            // If dislike is clicked, unclick it and toggle dislike in the backend
            if (isDislikeClicked) {
                dislikeButton.style.color = 'black'; // Unclick dislike
                toggleDislike(id, isDislikeClicked);  // Call function to hit API to remove dislike
            }
        }

        // Call function to toggle like in the backend
        toggleLike(id, isLikeClicked);
    };
    //Handle colour change and checks if the dislikebutton is enabled
    const handleDislike = (id) => (event) => {
        const dislikeButton = event.currentTarget.firstChild;
        const likeButton = document.getElementById(`like-bot-response-${id}`).firstChild;
        const isLikeClicked = likeButton.style.color === 'blue';
        const isDislikeClicked = dislikeButton.style.color === 'red';

        // Toggle dislike button color
        if (isDislikeClicked) {
            dislikeButton.style.color = 'black'; // Unclick dislike
        } else {
            dislikeButton.style.color = 'red';  // Click dislike

            // If like is clicked, unclick it and toggle like in the backend
            if (isLikeClicked) {
                likeButton.style.color = 'black'; // Unclick like
                toggleLike(id, isLikeClicked);  // Call function to hit API to remove like
            }
        }

        // Call function to toggle dislike in the backend
        toggleDislike(id, isDislikeClicked);
    };
    // Function to toggle like in the backend based on the current state
    const toggleLike = (id, isLikeClicked) => {
        const url = likeEssayURL();
        const payload = {
            email: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
            session_id: sender,
            index: id
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (!isLikeClicked) {
                    toast.info("We're happy you found this helpful!", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "colored",
                    });
                }
            })
            .catch(error => {
                console.error('Error in API request:', error);
            });
    };
    // Function to toggle dislike in the backend based on the current state
    const toggleDislike = (id, isDislikeClicked) => {
        const url = dislikeEssayURL();
        const payload = {
            email: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
            session_id: sender,
            index: id
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (!isDislikeClicked) {
                    toast.info("Noted! We're always learning from your feedback", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "colored",
                    });
                }
            })
            .catch(error => {
                console.error('Error in API request:', error);
            });
    };

    const handleCopyText = (elementId) => {
        const textToCopy = document.getElementById(elementId).innerText;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success(`Response Copied to Clipboard`, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const handleBackClick = () => {
        setSelectedSession(null);
        setSelectedSessionIndex(0);
    };

    const fetchChatHistory = async () => {
        console.log("Fetching Chat History");
        try {
            const url = chatHistoryEssayURL();
            const params = new URLSearchParams({
                "email": JSON.parse(sessionStorage.getItem("userDetails"))['user_email']
            });
            const response = await axios.get(`${url}?${params.toString()}`);
            setChatHistory(response.data.chat_history);
            setDisableTextArea(false);
        } catch (error) {
            console.error("Error fetching chat history", error);
        }
    };

    // Function to go to the next session
    const handleNextClick = () => {
        if (selectedSessionIndex < chatHistory.length - 1) {
            console.log("Inside if statement");
            const newIndex = selectedSessionIndex + 1;  // Calculate the new index
            setSelectedSessionIndex(newIndex);  // Update the index state
            setSelectedSession(chatHistory[newIndex]);  // Set the session using the new index
        }
    };

    // Function to go to the previous session
    const handlePreviousClick = () => {
        if (selectedSessionIndex > 0) {
            console.log("Inside if statement");
            const newIndex = selectedSessionIndex - 1;  // Calculate the new index
            setSelectedSessionIndex(newIndex);  // Update the index state
            setSelectedSession(chatHistory[newIndex]);  // Set the session using the new index
        }
    };

    // Function to handle session selection from the popup
    const handleSessionClick = (session) => {
        // Find the index of the clicked session
        const sessionIndex = chatHistory.findIndex((s) => s.session_id === session.session_id);

        if (sessionIndex !== -1) {
            // First, update the selected session index
            setSelectedSessionIndex(sessionIndex);

            // Then, update the selected session based on the new index
            setSelectedSession(chatHistory[sessionIndex]);
        }
        setShowPopup(false);
        console.log("Session index:", sessionIndex);
    };

    useEffect(() => {
        const classname = document.getElementsByClassName('bot-response')
        if (classname[classname.length - 1] !== undefined) {
            console.log("--- ", classname[classname.length - 1].id);
            const id = parseInt(classname[classname.length - 1].id.replace("bot-response", ''));
            console.log(id);
            setTimeout(() => {
                if (voiceInput == true) {
                    // readAloud(id + 1, null);

                }
            }, 2000);

        }

    }, [document.getElementsByClassName('bot-response').length])

    /**
     *
     * @param {InputEvent} e
     */
    const handleRoleChange = async (e) => {
        setMessages([]);
        setSelectedFiles(null);
        const role = e.target.value.toLowerCase();
        setSelectedRole(() => role);

        setTranslationLanguage('hi');
        setTranslatedText(null);
        if (role === "student") {
            try {
                setLoading(true);
                const url = essayListURL();
                const params = new URLSearchParams({
                    "sender": sender
                });
                const response = await axios.get(`${url}?${params.toString()}`);
                if (response.status === 200) {
                    setEssays(response.data.essay_names);
                } else {
                    console.error('Failed to get Essay List', response.data.essay_names);
                }
            } catch (error) {
                console.error('An error occurred while getting the Essay list', error);
            } finally {
                setLoading(false);
            }
        };
    }

    const handleScroll = () => {
        const container = document.getElementById("chat-box")
        if (container.scrollTop < container.scrollHeight - container.clientHeight - 50) {
            setAutoScroll(false);
        }
        else {
            setAutoScroll(true);
        }
    }


    // Scroll to the latest answer
    useEffect(() => {
        const container = document.getElementById("chat-box")
        if (autoScroll || loading) {
            container?.scrollTo({
                behavior: "smooth",
                top: container.scrollHeight,
            });
        }
    },
        [messages, autoScroll, loading]
    )


    // Function to restore <img> tags back to their original positions

    //sending data

    const [listening, setListening] = useState(false);
    const [transcription, setTranscription] = useState('');

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const closeModal = () => {
        setModalIsOpen(false);
        // setDisplayQuestionInput(false);
        // console.log(currentQuestion);
        // handleSendMessage(currentQuestion, stream);
    };

    const [selectedText, setSelectedText] = useState('');
    const [buttonVisible, setButtonVisible] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        const handleSelection = () => {

            // console.log()
            if (document.getSelection().focusNode && document.getSelection().focusNode.parentElement.closest('div')?.classList.contains('bot-response')) {
                const text = document.getSelection().toString();
                setSelectedText(text);

                // Calculate position based on the selection
                const selection = window.getSelection();
                if (selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0).getBoundingClientRect();
                    console.log(range.top + window.scrollY + range.height / 2);
                    console.log(range.right + window.scrollX + 10);

                    setButtonPosition({
                        top: range.top + window.scrollY + range.height / 2, // Adjust position to be centered vertically
                        left: range.right + window.scrollX + 10, // Position the button slightly to the right of the selection
                    });
                    console.log(selection);
                    console.log(!!text);
                    setButtonVisible(!!text); // Show button only if text is selected
                }
            } else {
                setSelectedText('');
                setButtonVisible(false);
            }
        };

        document.addEventListener('mouseup', handleSelection);

        return () => {
            document.removeEventListener('mouseup', handleSelection);
        };
    }, []);

    const handleReadAloud = () => {
        if (selectedText) {
            // console.log(selectedText);

            window.speechSynthesis.cancel();
            if (!window.speechSynthesis) {
                console.error("Speech synthesis not supported.");
                return;
            }
            const utterance = new SpeechSynthesisUtterance(selectedText);

            const isHindi = /[\u0900-\u097F]/.test(selectedText); // Check for Hindi characters


            const voices = window.speechSynthesis.getVoices();
            let selectedVoice;

            if (isHindi) {
                utterance.lang = "hi-IN";
                const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
                selectedVoice = hindiVoices.find(voice => voice.name.includes("Microsoft Swara Online (Natural) - Hindi (India)")) || hindiVoices[0];
            } else {

                utterance.lang = "en-US";
                const englishVoices = voices.filter(voice => voice.lang === "en-US" && voice.gender === "male");
                selectedVoice = englishVoices.find(voice => voice.name.includes("Microsoft David Desktop - English (United States)")) || englishVoices[0];
            }

            if (selectedVoice) {
                utterance.voice = selectedVoice;
            } else {
                console.warn("No appropriate voice found, using default voice.");
            }

            window.speechSynthesis.speak(utterance);
        }
    };

    //email list
    useEffect(() => {
        if (essays.length > 0) {
            setSelectedEssay(essays[0]);  // Set the default value to essays[0] once the data is loaded
            setEssayResult("");
            setMissingEssayResult([]);
            setQuestionEssayResult([]);
            setIsQuestionFormSubmitted(false);
            setIsFormSubmitted(false);
        }
    }, [essays]);

    const handlefinish = () => {
        console.log("Essay Topic: ", essayTopic, "Essay Outcomes: ", essayOutcome);
        let webSocket;
        setLoading(true);
        const sp = new URLSearchParams();
        sp.set('sender', sender);
        sp.set('essay_name', essayTopic);
        sp.set('grade', 'class9')
        sp.set('outcomes', essayOutcome);

        const apiUrl = new URL(teacherGenerateEssayURL());
        apiUrl.search = sp.toString();
        webSocket = new WebSocket(apiUrl.href);

        // Event listener for when the connection is opened
        webSocket.addEventListener('open', (event) => {
            console.log('WebSocket connection opened:', event);
        });

        webSocket.addEventListener('message', (event) => {
            const data = JSON.parse(event.data);
            if (data.status === "success") {
                alert(data.message);
            }
        });

        // Event listener for when the connection is closed
        webSocket.addEventListener('close', (event) => {
            console.log('WebSocket connection closed:', event);
            setLoading(false);
            toast.success(`Essay Saved`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        });

        // Clean up the WebSocket connection when the component is unmounted
        return () => {
            setLoading(false);
            webSocket.close();
        };
    };

    //handling the question and the textarea 
    const handleInputChange = (index, value) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [index]: value,
        }));
    };

    //hitting the api for Question based
    useEffect(() => {
        console.log("inside useeEffect()")
        if (selectedCategory === "Question Based") {
            if (selectedEssay === "Select a Essay") {
                toast.info(`Please select an essay topic.`, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    progress: undefined,
                });
                return;
            }
            const fetchQuestions = async () => {
                try {
                    setLoading(true);
                    const url = generateQuestionsEssayURL();
                    const params = new URLSearchParams({
                        "sender": sender,
                        "essay_name": selectedEssay
                    });
                    const response = await axios.post(`${url}?${params.toString()}`);
                    const data = response.data;
                    setQuestions([])
                    setQuestions(data.questions);
                    setLoading(false);
                    setSelectedSession(null);
                } catch (error) {
                    setLoading(false);
                    setSelectedSession(null);
                    console.error("Error fetching questions:", error);
                }
            };

            fetchQuestions();
        }
    }, [selectedCategory, sender, selectedEssay]);
    //for hitting the api for missing section
    useEffect(() => {
        if (selectedCategory === "Missing Section") {
            if (selectedEssay === "Select a Essay") {
                toast.info(`Please select an essay topic.`, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    progress: undefined,
                });
                return;
            }
            // Initialize WebSocket for "Missing Section"
            setLoading(true);
            const sp = new URLSearchParams();
            sp.set('sender', sender);
            sp.set('essay_name', selectedEssay);

            const apiUrl = new URL(missingSectionPartsEssayURL());
            apiUrl.search = sp.toString();

            ws.current = new WebSocket(apiUrl.href);

            ws.current.addEventListener('open', (event) => {
                console.log('WebSocket connection opened:', event);
            });

            ws.current.onmessage = (event) => {
                try {
                    const response = JSON.parse(event.data);

                    if (response) {
                        // Update the state for essay parts
                        setEssayParts({
                            introduction: response.introduction || "",
                            body: response.body || "",
                            conclusion: response.conclusion || ""
                        });

                        // Log the incoming data for testing
                        console.log("Received Data:", response);
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };

            ws.current.onerror = (error) => console.error('WebSocket error:', error);

            ws.current.onclose = () => {
                console.log('WebSocket connection closed');
            };
            setLoading(false);
            setSelectedSession(null);

            return () => {
                if (ws.current) {
                    ws.current.close();
                }
            };
        }
    }, [selectedCategory, sender, selectedEssay]);
    //checking for Attempt
    useEffect(() => {
        console.log("inside useeEffect()")
        if (selectedCategory === "Attempt") {
            if (selectedEssay === "Select a Essay") {
                toast.info(`Please select an essay topic.`, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    progress: undefined,
                });
                return;
            }
            setSelectedSession(null);
        }
    }
    );


    const handleSubmit = () => {
        if (selectedCategory === "Question Based") {
            const answerList = Object.values(answers);
            const answerString = `${JSON.stringify(answerList)}`;
            console.log("answerlist:", answerList)
            const initialBotMessage = { text: "Validating result...", sender: 'bot', timestamp: new Date() };
            setQuestionEssayResult((prevMessages) => [...prevMessages, initialBotMessage]);

            const sp = new URLSearchParams();
            sp.set(`email`, JSON.parse(sessionStorage.getItem("userDetails"))['user_email'])
            sp.set('sender', sender);
            sp.set('essay_name', selectedEssay);
            sp.set('answers', answerString);
            if (selectedEssay === "Select a Essay") {
                toast.info(`Please select an essay topic.`, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    progress: undefined,
                });
                return;
            }

            const apiUrl = new URL(questionAnswerEssayURL());
            apiUrl.search = sp.toString();

            // Initialize the WebSocket and assign it to ws.current
            ws.current = new WebSocket(apiUrl.href);

            ws.current.addEventListener('open', (event) => {
                console.log('WebSocket connection opened:', event);
            });

            ws.current.onmessage = (event) => {
                console.log("inside WebSocket event")
                try {
                    const response = JSON.parse(event.data);
                    if (response && response.text.length > 0) {
                        const currentText = response.text;  // Current chunk
                        // Update the chat with the streaming bot's response
                        setQuestionEssayResult((prevMessages) => {
                            const lastMessage = prevMessages[prevMessages.length - 1];

                            if (lastMessage.sender === 'bot') {
                                // If the last message was from the bot, update it with the new chunk
                                lastMessage.text = currentText;
                                return [...prevMessages.slice(0, -1), lastMessage];
                            } else {
                                // If no previous bot message, add a new one
                                const botMessage = {
                                    text: currentText,
                                    sender: 'bot',
                                    timestamp: new Date(),
                                };
                                return [...prevMessages, botMessage];
                            }
                        });
                        setIsQuestionFormSubmitted(true);
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }

            }


        }
        else if (selectedCategory === "Missing Section") {
            {
                if (selectedEssay === "Select a Essay") {
                    toast.info(`Please select an essay topic.`, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                        progress: undefined,
                    });
                    return;
                }
                const introduction = ansIntro || essayParts.introduction;
                const body = ansBody || essayParts.body;
                const conclusion = ansConclusion || essayParts.conclusion;

                // query params
                const sp = new URLSearchParams();
                sp.set('sender', sender);
                sp.set('essay_name', selectedEssay);

                if (introduction) sp.set('introduction', introduction);
                if (body) sp.set('body', body);
                if (conclusion) sp.set('conclusion', conclusion);

                sp.set('grade', selectedGrade);
                sp.set('outcomes', essayOutcome);
                sp.set(`email`, JSON.parse(sessionStorage.getItem("userDetails"))['user_email'])

                const initialBotMessage = { text: "Validating result...", sender: 'bot', timestamp: new Date() };
                setMissingEssayResult((prevMessages) => [...prevMessages, initialBotMessage]);

                const apiUrl = new URL(missingSectionFullEssayURL());
                apiUrl.search = sp.toString();

                ws.current = new WebSocket(apiUrl.href);

                ws.current.addEventListener('open', (event) => {
                    console.log('WebSocket connection opened:', event);
                });

                ws.current.onmessage = (event) => {
                    try {
                        const response = JSON.parse(event.data);
                        if (response && response.text) {
                            const currentText = response.text; // Current chunk of response

                            // Update the bot's response progressively
                            setMissingEssayResult((prevMessages) => {
                                const lastMessage = prevMessages[prevMessages.length - 1];

                                if (lastMessage.sender === 'bot') {
                                    // If the last message was from the bot, update its text
                                    lastMessage.text = currentText;
                                    return [...prevMessages.slice(0, -1), lastMessage];
                                } else {
                                    // Otherwise, add a new message from the bot
                                    const botMessage = {
                                        text: currentText,
                                        sender: 'bot',
                                        timestamp: new Date(),
                                    };
                                    return [...prevMessages, botMessage];
                                }
                            });

                            // Mark the form as submitted once a response is received
                            setIsFormSubmitted(true);
                        }
                    } catch (error) {
                        console.error('Error parsing WebSocket message:', error);
                    }
                };

                ws.current.onerror = (error) => console.error('WebSocket error:', error);

                ws.current.onclose = () => {
                    console.log('WebSocket connection closed');
                };

                return () => {
                    if (ws.current) {
                        ws.current.close();
                    }
                };
            };
        }
        else if (selectedCategory === "Attempt") {
            if (selectedEssay === "Select a Essay") {
                toast.info(`Please select an essay topic.`, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    progress: undefined,
                });
                return;
            }
            const initialBotMessage = { text: "Validating result...", sender: 'bot', timestamp: new Date() };
            setEssayResult((prevMessages) => [...prevMessages, initialBotMessage]);
            const sp = new URLSearchParams();
            sp.set('sender', sender);
            sp.set('essay_name', selectedEssay);
            sp.set('user_input', wholeAns);
            sp.set(`email`, JSON.parse(sessionStorage.getItem("userDetails"))['user_email'])

            const apiUrl = new URL(attemptEssayURL());
            apiUrl.search = sp.toString();

            // Initialize the WebSocket and assign it to ws.current
            ws.current = new WebSocket(apiUrl.href);

            ws.current.addEventListener('open', (event) => {
                console.log('WebSocket connection opened:', event);
            });

            ws.current.onmessage = (event) => {
                try {
                    const response = JSON.parse(event.data);
                    if (response && response.text.length > 0) {
                        const currentText = response.text;  // Current chunk
                        // Update the chat with the streaming bot's response
                        setEssayResult((prevMessages) => {
                            const lastMessage = prevMessages[prevMessages.length - 1];

                            if (lastMessage.sender === 'bot') {
                                // If the last message was from the bot, update it with the new chunk
                                lastMessage.text = currentText;
                                return [...prevMessages.slice(0, -1), lastMessage];
                            } else {
                                // If no previous bot message, add a new one
                                const botMessage = {
                                    text: currentText,
                                    sender: 'bot',
                                    timestamp: new Date(),
                                };
                                return [...prevMessages, botMessage];
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };

            ws.current.onerror = (error) => console.error('WebSocket error:', error);

            ws.current.onclose = () => {
                console.log('WebSocket connection closed');
            };

            return () => {
                if (ws.current) {
                    ws.current.close();
                }
            };
        };
    };

    return (
        <div className="flex gap-4">
            {info && (
                <Draggable>
                    <div
                        className={`z-50 absolute w-3/5 h-3/4 bg-white rounded-md shadow-lg overflow-auto p-4 items-center`}>
                        <button
                            className="absolute top-4 right-4 text-black"
                            onClick={() => { setInfo() }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 16 16">
                                <path fill="currentColor" fill-rule="evenodd" d="M13.5 8a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0M6.53 5.47a.75.75 0 0 0-1.06 1.06L6.94 8L5.47 9.47a.75.75 0 1 0 1.06 1.06L8 9.06l1.47 1.47a.75.75 0 1 0 1.06-1.06L9.06 8l1.47-1.47a.75.75 0 1 0-1.06-1.06L8 6.94z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <div dangerouslySetInnerHTML={{ __html: info }}>
                        </div>
                    </div>
                </Draggable>)}
            {(buttonVisible) && (
                <div style={{ zIndex: 2, position: "fixed", top: buttonPosition.top, left: buttonPosition.left }} className="readSelected">
                    <Button
                        onClick={handleReadAloud}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
                            <path fill="currentColor" d="M10.044 1.498a.75.75 0 0 1 .958-.454c.214.09.001 0 .001 0h.001l.003.001l.005.003l.015.005a2 2 0 0 1 .19.08c.118.056.28.138.47.253c.379.23.876.596 1.37 1.143C14.057 3.636 15 5.44 15 8.249a.75.75 0 0 1-1.5 0c0-2.44-.807-3.885-1.556-4.715a4.7 4.7 0 0 0-1.036-.865a3 3 0 0 0-.401-.209l-.014-.005a.75.75 0 0 1-.45-.957M7.198 3.475a.75.75 0 0 0-1.395 0l-3.75 9.5a.75.75 0 0 0 1.395.55l.898-2.275h4.308l.898 2.275a.75.75 0 1 0 1.396-.55zm.864 6.275H4.938L6.5 5.793zm2.668-6.076a.75.75 0 0 0-.962 1.15l.006.006l.034.03q.049.045.139.136c.12.123.28.304.44.53c.327.463.613 1.063.613 1.724a.75.75 0 0 0 1.5 0c0-1.088-.464-1.989-.887-2.588a6 6 0 0 0-.853-.962l-.02-.017l-.006-.005l-.002-.002zm-.962 1.15l.001.002Z" />
                        </svg>
                    </Button></div>
            )}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal-dialog"
                overlayClassName="modal-overlay"
            >
                <div className="modal-content">
                    <h2 className="modal-title">Voice Search</h2>
                    {/* <p>Speak to search...</p> */}
                    {listening ? 'Listening...' : ' '}
                    {currentQuestion}
                </div>
                <button className="modal-close-btn" onClick={closeModal}>
                    Close
                </button>
            </Modal>
            <div className="secondcardWidth">
                <FullCard>
                    <div className="">
                        <div className="flex justify-between items-center mt-2">
                            <div className="subheading text-center">
                                Essay Bot
                            </div>
                            <button onClick={refreshSender} className="flex items-center mx-2">
                                <MessageSquarePlus className="mr-2" size={20} />
                            </button>
                            <button type="button" className="text-blue-500" onClick={handleInfo} aria-label="Info">
                                <BadgeHelp className="mr-2" size={20} />
                            </button>
                        </div>
                        {/* ToastContainer will show notifications */}
                        <ToastContainer />
                        <div className="my-8">
                            <div className={"flex justify-center my-8"}>
                                <Button onClick={() => {
                                    fetchChatHistory();
                                    setShowPopup(true);
                                }}>Chat History</Button>
                            </div>
                        </div>
                        <div className="my-8">
                            <div className="text-lg font-medium text-indigo-800 text-left my-2">Select Roles</div>
                            <div className="w-full">
                                <Select dropdownData={tutorRoles}
                                    value={selectedRole[0].toUpperCase() + selectedRole.substring(1)}
                                    onChange={handleRoleChange} />
                            </div>
                        </div>
                        {(selectedRole === "teacher") && (
                            <div>
                                <div className="my-8">
                                    <div className="text-blue text-lg font-semibold my-2">Essay Topic</div>
                                    <div className="w-full text-sm">

                                        <textarea
                                            className="w-full border border-[#23479F] rounded-lg p-2"
                                            placeholder="Give Title of Essay."
                                            value={essayTopic}
                                            onChange={(e) =>
                                                setEssayTopic(e.target.value)
                                            }
                                            rows={1}
                                        />
                                    </div>
                                </div>
                                <div className="my-8">
                                    <div className="text-blue text-lg font-semibold my-2">Outcomes</div>
                                    <div className="w-full text-sm">

                                        <textarea
                                            className="w-full border border-[#23479F] rounded-lg p-2"
                                            placeholder="Give outcomes of Essay."
                                            value={essayOutcome}
                                            onChange={(e) =>
                                                setEssayOutcome(e.target.value)
                                            }
                                            rows={5}
                                        />
                                    </div>
                                </div>
                                <div className="my-8">
                                    <div className={"flex justify-center my-8"}>
                                        <Button onClick={() => {
                                            handlefinish();
                                        }}>Save</Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(selectedRole === "student") && (
                            <div>
                                <div className="my-8">
                                    <div className="text-blue text-lg font-semibold my-2">Select Category</div>
                                    <Select dropdownData={essayCategory} value={selectedCategory}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                    />
                                </div>
                                <div className="my-8">
                                    <div className="text-blue text-lg font-semibold my-2">Select Essay</div>
                                    <select
                                        onChange={(e) => {
                                            setSelectedEssay(e.target.value);
                                            changeEssay();
                                        }}
                                        className="border p-2 rounded w-full"
                                    >
                                        {essays.map((essay, index) => (
                                            <option key={index} value={essay}>
                                                {essay}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )}
                    </div>
                </FullCard>
            </div>
            <div className="thirdcardWidth ">
                {/* popup modal */}
                {showPopup && (
                    <Draggable>
                        <div className="z-50 absolute flex flex-col justify-between items-center inset-0 m-auto w-3/4 h-3/4 bg-white rounded-md shadow-lg border-2 p-4 overflow-hidden">
                            <div className="subheading flex justify-center text-center mb-4">
                                Chat History
                            </div>
                            <div className="flex-1 overflow-y-auto grid grid-cols-1 gap-4 w-full">
                                {chatHistory.map((session, index) => (
                                    <button
                                        key={index}
                                        className="flex flex-col p-4 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 transition-all"
                                        onClick={() => {
                                            handleSessionClick(session);
                                            setShowPopup(false);
                                        }}>
                                        <div className="mb-2"><strong>Session ID:</strong> {session.session_id}</div>
                                        <div><strong>Timestamp:</strong> {formatDate(session.data[0]?.timestamp)}</div>
                                    </button>
                                ))}
                            </div>
                            {/* Button Row */}
                            <div className="flex gap-4 mt-4">
                                {/* Close button with icon */}
                                <Button
                                    onClick={() => { setShowPopup(false); }}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Draggable>
                )}
                {/* Chat history */}
                {selectedSession && (
                    <div className={`overflow-auto ${selectedRole === 'student' ? "h-[80vh]" : "h-[80vh]"}`} // ${selectedRole === 'student' ? "h-[70vh]" : "h-[78vh]"}
                        onScroll={handleScroll}
                        id="chat-box">
                        <div
                            onScroll={handleScroll}
                        >
                            <div className="flex-1 grid grid-cols-1 gap-4 w-full">
                                {selectedSession.data.map((item, index) => (
                                    <Card>
                                        <div className="chat-message relative">
                                            <div key={index} className="m-5">
                                                <div className="flex">
                                                    <div className="w-1/12">
                                                        <img
                                                            src={questionIcon}
                                                            alt=""
                                                            width={"50px"}
                                                            height={"50px"}
                                                            className=""
                                                        />
                                                    </div>
                                                    <div className="w-11/12 ml-4 bg-white rounded-lg text-xl mb-4">
                                                        {item.user &&
                                                            <div
                                                                className="w-full p-2"
                                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.user) }}
                                                            >
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="w-1/12">
                                                        <img
                                                            src={ansIcon}
                                                            alt=""
                                                            width={"50px"}
                                                            height={"50px"}
                                                            className=""
                                                        />
                                                    </div>
                                                    <div className="w-11/12 ml-4 bg-white rounded-lg text-xl mb-4">
                                                        {item.bot &&
                                                            <div
                                                                className="w-full p-2"
                                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.bot) }}
                                                            ></div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {/* clicked on chat history */}
                {(selectedSession) && (<div>
                    {/* Buttons to navigate between chat sessions */}
                    <div className="fixed bottom-0 thirdCard bg-[#e5eeff]">
                        <div className="mr-10 ml-4 my-4">
                            <div className="flex justify-between mb-4">
                                <Button
                                    onClick={handlePreviousClick}
                                    disabled={selectedSessionIndex === 0}
                                >
                                    Previous
                                </Button>

                                <Button
                                    onClick={handleBackClick}
                                >
                                    Back
                                </Button>

                                <Button
                                    onClick={handleNextClick}
                                    disabled={selectedSessionIndex === chatHistory.length - 1}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>)}
                {/* Main Page */}
                {!selectedSession && (<div>
                    {!(selectedRole === "teacher") && (selectedCategory === "Question Based") && (
                        <div className="h-[90vh] bg-white rounded-lg">
                            <div class="flex justify-between items-center bg-blue text-white p-2 rounded-t-lg">
                                {isQuestionFormSubmitted && (
                                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                        <button id={`like-bot-response-${bIndex}`}
                                            onClick={handleLike(bIndex)}
                                        >
                                            <ThumbsUp />
                                        </button>
                                        <button id={`dislike-bot-response-${bIndex}`}
                                            onClick={handleDislike(bIndex)}
                                        >
                                            <ThumbsDown />
                                        </button>
                                        <button
                                            onClick={() => handleCopyText(`bot-response-QA`)}
                                        >
                                            <Copy />
                                        </button>
                                    </div>
                                )}
                                <p className="text-xl font-semibold leading-tight flex-grow text-center">
                                    {selectedEssay}
                                </p>
                                {!isQuestionFormSubmitted && (
                                    <Button onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                )}

                            </div>

                            <div className="h-[79.75vh] overflow-auto border-2 border-[#23479F] rounded-b-lg p-2">
                                {isQuestionFormSubmitted ? (<div id={`bot-response-QA`}
                                    className="w-full p-2 bot-response-QA"
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(questionEssayResult[0].text) }}
                                ></div>) : (
                                    <div>
                                        {(Array.isArray(questions) && questions.length > 0) ? (
                                            questions.map((question, index) => (
                                                <div key={index}>
                                                    <label>{question}</label>
                                                    <textarea
                                                        value={answers[index] || ""}
                                                        className="w-full border border-gray-300 rounded-md p-2 mt-2"
                                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                                        rows="4"
                                                        cols="50"
                                                        placeholder="Type your answer here"
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            <p>No questions available</p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {!(selectedRole === "teacher") && (selectedCategory === "Missing Section") && (
                        <div className="h-[90vh] bg-white rounded-lg">
                            <div className="flex justify-between items-center bg-blue text-white p-2 rounded-t-lg">
                                {isFormSubmitted && (
                                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                        <button id={`like-bot-response-${bIndex}`}
                                            onClick={handleLike(bIndex)}
                                        >
                                            <ThumbsUp />
                                        </button>
                                        <button id={`dislike-bot-response-${bIndex}`}
                                            onClick={handleDislike(bIndex)}
                                        >
                                            <ThumbsDown />
                                        </button>
                                        <button
                                            onClick={() => handleCopyText(`bot-response-MS`)}
                                        >
                                            <Copy />
                                        </button>
                                    </div>
                                )}
                                <p className="text-xl font-semibold leading-tight flex-grow text-center">
                                    {selectedEssay}
                                </p>
                                {!isFormSubmitted && (
                                    <Button onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                )}

                            </div>

                            <div className="h-[79.75vh] overflow-auto border-2 border-[#23479F] rounded-b-lg p-2">
                                {isFormSubmitted ? (
                                    <div id={`bot-response-MS`}
                                        className="w-full p-2 bot-response-MS"
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(missingEssayResult[0].text) }}
                                    ></div>) : (

                                    <div class="space-y-4">
                                        <div>
                                            <p class="font-bold">Introduction:</p>
                                            {essayParts.introduction ? (
                                                <p dangerouslySetInnerHTML={{ __html: essayParts.introduction }} />
                                            ) : (
                                                <textarea
                                                    class="w-full border border-gray-300 rounded-md p-2 mt-2"
                                                    placeholder="Write the Introduction here..."
                                                    value={ansIntro}
                                                    onChange={(e) => setAnsIntro(e.target.value)}
                                                    rows="4"
                                                />
                                            )}
                                        </div>

                                        <div>
                                            <p class="font-bold">Body:</p>
                                            {essayParts.body ? (
                                                <p dangerouslySetInnerHTML={{ __html: essayParts.body }} />
                                            ) : (
                                                <textarea
                                                    class="w-full border border-gray-300 rounded-md p-2 mt-2"
                                                    placeholder="Write about the Body here..."
                                                    value={ansBody}
                                                    onChange={(e) => setAnsBody(e.target.value)}
                                                    rows="6"
                                                />
                                            )}
                                        </div>

                                        <div>
                                            <p class="font-bold">Conclusion:</p>
                                            {essayParts.conclusion ? (
                                                <p dangerouslySetInnerHTML={{ __html: essayParts.conclusion }} />
                                            ) : (
                                                <textarea
                                                    class="w-full border border-gray-300 rounded-md p-2 mt-2"
                                                    placeholder="Write the Conclusion here..."
                                                    value={ansConclusion}
                                                    onChange={(e) => setAnsConclusion(e.target.value)}
                                                    rows="4"
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {!(selectedRole === "teacher") && (selectedCategory === "Attempt") && (
                        <div className="h-[90vh] bg-white rounded-lg">
                            <div class="flex justify-between items-center bg-blue text-white p-2 rounded-t-lg">
                                {essayResult && (
                                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                        <button id={`like-bot-response-${bIndex}`}
                                            onClick={handleLike(bIndex)}
                                        >
                                            <ThumbsUp />
                                        </button>
                                        <button id={`dislike-bot-response-${bIndex}`}
                                            onClick={handleDislike(bIndex)}
                                        >
                                            <ThumbsDown />
                                        </button>
                                        <button
                                            onClick={() => handleCopyText(`bot-response-AT`)}
                                        >
                                            <Copy />
                                        </button>
                                    </div>
                                )}
                                <p className="text-xl font-semibold leading-tight flex-grow text-center">
                                    {essayResult ? "Result" : selectedEssay}
                                </p>
                                {!(essayResult) && (<Button
                                    onClick={handleSubmit}>
                                    Submit
                                </Button>)}
                            </div>

                            <div className="h-[79.75vh] overflow-auto border-2 border-[#23479F] rounded-b-lg p-2">
                                {/* {essayResult} */}
                                {essayResult ? (<div id={`bot-response-AT`}
                                    className="w-full p-2 bot-response-AT"
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(essayResult[0].text) }}
                                ></div>) : (<textarea
                                    className="h-full w-full border-none outline-none"
                                    placeholder="Start typing your essay..."
                                    onChange={(e) =>
                                        setWholeAns(e.target.value)
                                    }
                                />)}
                            </div>
                        </div>
                    )}
                    {(selectedRole === "teacher") && (<div></div>)}
                </div>)}
            </div>
            {loading && (
                <div className="overlay">
                    <Spinner />
                </div>
            )};
        </div>
    )
}

export default EssayWriter;
