/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { nanoid } from "nanoid";
import React, { useEffect, useRef } from "react";

function ClickAway(props) {
  const containerRef = useRef(null);

  const handle = props.handle || containerRef;
  // console.log(handle);
  function handleClick(e) {
    e.preventDefault();
    if (!handle.current) {
      props.onClickAway(false);
    } else {
      const sync = props.sync ?? true;
      if (!sync) {
        props.onClickAway(false);
      } else {
        props.onClickAway(handle.current.contains(e.target));
      }
    }
  }

  const handleScroll = (e) => {
    props.onClickAway(false);
  };

  function handleBounds(target) {
    if (!props?.boundClass) {
      return;
    }
    const boundsClass = [].concat(props.boundClass);

    const boundNodes = [];
    boundsClass.forEach((c) => {
      const nodeList = window.pdfDoc.querySelectorAll("." + c);
      for (const node of nodeList.values()) {
        boundNodes.push(node);
      }
    });

    return boundNodes.some((n) => n.contains(target));
  }

  function handleContextMenu(e) {
    if (!props?.boundClass) {
      return;
    }

    props.onClickAway(handleBounds(e.target));
    // props.onClickAway(bounds.some((c) => e.target.classList.contains(c)));
  }

  useEffect(() => {
    window?.pdfDoc?.body?.addEventListener?.("click", handleClick);
    window?.pdfDoc?.body?.addEventListener?.("contextmenu", handleContextMenu);

    const pdfSheetCont = window?.pdfDoc?.getElementById?.("pdf-sheet--cont");
    pdfSheetCont?.addEventListener?.("scroll", handleScroll);

    return () => {
      window?.pdfDoc?.body?.removeEventListener?.("click", handleClick);
      window?.pdfDoc?.body?.removeEventListener?.(
        "contextmenu",
        handleContextMenu
      );
      pdfSheetCont?.removeEventListener?.("scroll", handleScroll);
    };
  }, [props.sync]);

  return (
    <div
      id={props.id || `clkaway-id--${nanoid(3)}`}
      key={props._key || 0}
      ref={containerRef}
    >
      {props.children}
    </div>
  );
}

export default ClickAway;
