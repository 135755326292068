// SimpleDropdown.js
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const SimpleDropdown = ({ options, onSelect, defaultValue }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue || null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State to handle search input
  const [filteredOptions, setFilteredOptions] = useState(options); // State for filtered options
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
    setSearchTerm(''); // Reset search when an option is selected
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearchTerm(''); // Reset search when clicked outside
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value); // Update search term
  };

  useEffect(() => {
    const filtered = options.filter(option =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()) // Filter options by search term
    );
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="bg-white border border-light-purple text-black text-sm rounded-lg focus:ring-purple focus:border-purple block w-full p-2.5 flex items-center justify-between"
        onClick={handleToggle}
      >
        <span>{selectedOption ? selectedOption.label : 'Select an option'}</span>
        <svg
          className={`w-5 h-5 text-gray-400 transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-light-purple rounded-lg shadow-lg">
          {/* Conditionally render search bar if options > 5 */}
          {options.length > 5 && (
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full p-2 border-b border-light-purple"
            />
          )}
          <ul className="max-h-60 overflow-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((option, index) => (
                <li
                  key={index}
                  className="px-4 py-2 hover:bg-[#D2CFF1] cursor-pointer"
                  onClick={() => handleOptionSelect(option)}
                >
                  {option.label}
                </li>
              ))
            ) : (
              <li className="px-4 py-2 text-gray-500">No options found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

SimpleDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
};

export default SimpleDropdown;