import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import { Button, Card, Slider, Spinner, FullCard } from "../components/CommonComponents";
import { comprehensionURL } from "../api/serverAPI";
import { download, downloadWord } from "../utils";
import { comprehensionClassTypes, comprehensionStyles } from "../components/Mapping";
import SimpleDropdown from "../components/SimpleDropdown";
import axios from 'axios';

const lookUp = {
    englit_mcq: "MCQ",
    englit_fillup: "Fill-Up",
    englit_truefalse: "True or False",
    englit_desc_simple: "Descriptive (Simple)",
    englit_desc_medium: "Descriptive (Medium)",
    englit_desc_hard: "Descriptive (Hard)",
}

const Comprehension = () => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    const [noOfQuestion, setNoOfQuestion] = useState(2);
    const [generateqaData, setGenerateqaData] = useState(null);
    const [base64Data, setBase64Data] = useState(null);
    const [primaryOptions, setPrimaryOptions] = useState([]);
    const [middleOptions, setMiddleOptions] = useState([]);

    const [classType, setClassType] = useState("primary");
    const [styleType, setStyleType] = useState("narrative");
    const [textareaContent, setTextareaContent] = useState("");
    const [loading, setLoading] = useState(false);
    const isTextareaEmpty = textareaContent.trim() === "";

    const handleSelect = (option) => {
        setClassType(option.value);

        // Determine which set of options to use based on the selected value
        if (option.value === "primary") {
            setPrimaryOptions([]);
        } else if (option.value === "middle") {
            setMiddleOptions([]);
        }

        // Reset the selected options in MultiSelectDropdown
        setPrimaryOptions([]);
        setMiddleOptions([]);
    };

    const handlePrimaryChange = (updatedOptions) => {
        setPrimaryOptions(updatedOptions);
    };

    const handleMiddleChange = (updatedOptions) => {
        setMiddleOptions(updatedOptions);
    };

    const GetOutput = () => {
        setLoading(true);

        const payload = {
            level: classType,
            style: styleType,
            theme: textareaContent,
            questionCount: noOfQuestion,
        };

        const url = comprehensionURL(); // get only the URL

        axios.post(url, payload) // send URL and payload
            .then((response) => {
                console.log("response", response);
                setGenerateqaData(response.data?.response || null);
                setLoading(false);
            })
            .catch((error) => {
                alert("Can you please try again in sometime");
                setLoading(false); // Ensure loading state is reset on error
            });
    };


    const DisplayOutput = () => {
        if (loading) {
            return (
                <>
                    <div className="overlay">
                        <Spinner />
                    </div>
                </>
            );
        }
        if (generateqaData) {
            return (
                <Card>
                    <div className="subheading text-center">
                        Here is your Questions
                    </div>
                    <div className="mt-6">
                        <div className="border-2 border-[#23479F] rounded-lg">
                            <div className="bg-[#23479F] p-4">
                                <div class="grid grid-cols-2 gap-4">
                                    <p className="text-white mt-1">
                                        {selectedFiles && (
                                            <div className='mx-1 line-clamp-1'>
                                                {selectedFiles.name}
                                            </div>
                                        )}
                                    </p>
                                    <div className="flex flex-nowrap sm:flex-wrap justify-end gap-3">
                                        <div>
                                            <Button size="outline" onClick={handleEditClick}>Edit</Button>
                                        </div>
                                        <div>
                                            <Button size="outline" >Save</Button>
                                        </div>
                                        <div>
                                            <Button size="white" onClick={downloadWord}>Download</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white overflow-auto mx-auto rounded-lg">
                                <div id="page-container" className="">
                                    <div className="m-2">
                                        {outputContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            );
        }
    };

    const outputContent = () => {
        return (
            <>
                <div
                    id="editableContent"  // Add an id to identify the contentEditable div
                    className="px-6 flex flex-col align-center gap-[25px] outline-none focus-within:bg-[#ebeafd]"
                    contentEditable={false}  // Set to "true" when the Edit button is clicked
                >
                    {Object.entries(generateqaData).map(([key, item], index) => (
                        <div key={`${key}-${index}`}>
                            <div className="underline text-xl flex mb-5">
                                <strong>{lookUp[key]}</strong>
                            </div>
                            <div className="" dangerouslySetInnerHTML={{ __html: item }} />
                        </div>
                    ))}
                </div>
            </>
        );
    };

    // Inside your EvsContent component
    const handleEditClick = () => {
        const editableContent = document.getElementById("editableContent");
        if (editableContent) {
            editableContent.contentEditable = "true";
            editableContent.focus();
        }
    };

    return (
        <>
            <div className="flex gap-4">
                <div className="secondcardWidth">
                    <FullCard>
                        <div className="subheading text-center mt-2">
                            Comprehension
                        </div>
                        <div className="text-lg font-medium text-indigo-800 text-left mt-6">
                            Choose from the following options
                        </div>
                        <div className="font-medium text-base my-6">
                            Choose your class
                            <div className="my-2 text-base">
                                <SimpleDropdown
                                    options={comprehensionClassTypes}
                                    onSelect={handleSelect}
                                    defaultValue={comprehensionClassTypes[0]}
                                />
                            </div>
                        </div>
                        <div className="font-medium text-base my-6">
                            Writing Styles
                            <div className="my-2 text-base">
                                <SimpleDropdown
                                    options={comprehensionStyles}
                                    onSelect={handleSelect}
                                    defaultValue={comprehensionStyles[0]}
                                />
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="my-8">
                                <div className="items-center">
                                    <textarea
                                        className="w-full border border-light-purple p-2"
                                        placeholder="Specify a theme here to generate passage (max 1000 characters)"
                                        value={textareaContent}
                                        onChange={(e) => setTextareaContent(e.target.value)}
                                        rows={6}
                                        maxLength={1000}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="my-2 items-center">
                            <div className="font-bold">
                                Number of Questions
                            </div>
                            <div className="">
                                <label className="block text-gray-600 flex justify-center w-full">
                                    {noOfQuestion}
                                </label>
                                <Slider
                                    min={1}
                                    max={20}
                                    step={1}
                                    value={noOfQuestion}
                                    onChange={(e) =>
                                        setNoOfQuestion(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className={"flex justify-center my-8"}>
                            <button
                                onClick={GetOutput}
                                disabled={isTextareaEmpty}
                                // Change the color based on the textarea content
                                className={`${isTextareaEmpty
                                    ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                                    : "bg-[#23479F] hover:bg-[#544AC0] text-white"
                                    } px-6 py-3 tracking-wider leading-5 rounded-lg text-base font-bold font-heading`}
                            >
                                Proceed
                            </button>
                        </div>
                    </FullCard>
                </div>
                <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                    <DisplayOutput />
                </div>
            </div>
        </>
    )
}

export default Comprehension