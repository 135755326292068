/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
// import "./Toolbar.css";
import { Icon } from "./styles";
import {
  RxDownload,
  RxGear,
  RxHamburgerMenu,
  RxMinus,
  RxPlus,
  RxText,
  RxWidth,
  RxUpload,
} from "react-icons/rx";
import { VscScreenFull, VscScreenNormal } from "react-icons/vsc";
import { BsPrinter } from "react-icons/bs";
// import { MdDriveFolderUpload } from "react-icons/md";
import { TbSearch } from "react-icons/tb";
import { getHTML } from "../utils/htmlParser";
import { saveAs } from "file-saver";
import { trackPromise } from "react-promise-tracker";
import { inputValidation } from "../utils/utils";
import * as store from "../common/store";


function pageNumberValidation(pageNo, limit, defaultValue) {
  // console.log(inputValidation(pageNo, "int"), +pageNo > +limit, +pageNo < 1);
  if (!inputValidation(pageNo, "int") || +pageNo > +limit || +pageNo < 1) {
    return defaultValue;
  }
  return parseInt(pageNo);
}

function PageInputBox(props) {
  const inputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(props.currentPage + 1);
  

  useEffect(() => setCurrentPage(props.currentPage + 1), [props.currentPage]);

  function handleChange(e) {
    e.preventDefault();
    console.log(e.target.value);
    setCurrentPage(e.target.value);
  }

  function handlePageNav(e) {
    if (e.keyCode !== 13) {
      return;
    }

    const currentPage_ = pageNumberValidation(
      +currentPage,
      props.totalPage,
      props.currentPage + 1
    );
    setCurrentPage(currentPage_);

    const currentPageNode = window?.pdfDoc?.querySelector?.(
      `.pdf-sheet[dataindex="${currentPage_ - 1}"]`
    );

    currentPageNode.scrollIntoView();
    inputRef.current.blur();
  }

  // console.log(currentPage);

  function scrollHandler(e) {
    inputRef.current.blur();
  }
  useEffect(() => {
    const scrollCont = window?.pdfDoc?.getElementById("pdf-sheet--cont");
    // console.log(scrollCont);
    // scrollCont.addEventListener("scroll", scrollHandler);

    // return () => scrollCont.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <div className="flex-row">
      <input
        ref={inputRef}
        type="text"
        className="page-input-box--cont"
        value={currentPage}
        onChange={handleChange}
        onKeyDown={handlePageNav}
      />
      <div> of {props.totalPage}</div>
    </div>
  );
}

function Toolbar(props) {
  const document = window?.pdfDoc || window.document;

  const [fitWith, setFitWith] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  async function handleDownload() {
    const html = getHTML();
    // console.log(html);
    const data_id = store.get("doc-editor")?.data_id || null;
    const fileId = store.get("doc-editor")?.fileId || null;

    // let _url = "https://mlmachine.krtrimaiq.ai:7012/pdf/download";
    let _url = 'https://mlmachine.krtrimaiq.ai:6012/topdf'

    const res = await fetch(_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        html,
        data_id,
        fileId,
        metaData: { ...props.metaData, id: fileId || props.metaData.id },
      }),
    });
    const result = await res.blob();
    // console.log(result);

    saveAs(result, `${props?.metaData?.name || "file"}.pdf`);
  }

  function handleToggleFullScreen() {
    if (!document?.fullscreenElement) {
      document?.documentElement?.requestFullscreen?.();
      setIsFullScreen(true);
    } else if (document?.exitFullscreen) {
      document?.exitFullscreen?.();
      setIsFullScreen(false);
    }
  }

  const handleClick = () => {
    props.setIsClicked(true);
  };

  function syncFullScreenStateUpdate(e) {
    setIsFullScreen(!!window?.pdfDoc?.fullscreenElement);
  }

  // useEffect(() => {
  //   document.addEventListener("fullscreenchange", syncFullScreenStateUpdate);
  //   return () =>
  //     document.removeEventListener(
  //       "fullscreeenchange",
  //       syncFullScreenStateUpdate
  //     );
  // }, []);

  return (
    <div className="pdf-toolbar--cont">
      <div className="flex-row" style={{ gap: 26 }}>
        {/* <Icon className="pdf--icon">
          <RxHamburgerMenu />
        </Icon> */}
        {/* <Icon
          className="pdf--icon"
          onClick={props?.onClick}
          // style={{ cursor: "pointer" }}
          title="Add Text"
        >
          <RxText style={{ border: "1px solid #000", borderRadius: 2.6 }} />
        </Icon> */}
        {/* <Icon
          title="File"
          style={{ cursor: "default", userSelect: "none", padding: "6px 16px" }}
          onClick={(e) => console.log(e)}
        >
          <RxUpload style={{ fontSize: "24px" }} />
        </Icon> */}
      </div>

      <div
        className="flex-row"
        style={{
          position: "absolute",
          left: "calc(50% - 6px)",
          transform: "translateX(-50%)",
          // border: "1px solid ",
        }}
      >
        {/* <Icon className="pdf--icon">
          <RxMinus />
        </Icon>

        <Icon className="pdf--icon">
          <RxPlus />
        </Icon> */}

        {/* {fitWith ? (
          <Icon className="pdf--icon" onClick={() => setFitWith(false)}>
            <RxWidth
              style={{
                border: "1px solid #000",
                borderRadius: 2.6,
              }}
            />
          </Icon>
        ) : (
          <Icon className="pdf--icon" onClick={() => setFitWith(true)}>
            <VscScreenFull />
          </Icon>
        )} */}

        <PageInputBox
          totalPage={props.totalPage}
          currentPage={props.currentPage}
        />
      </div>

      <div className="flex-row" style={{ gap: 16 }}>
        {/* <Icon>
          <TbSearch />
        </Icon> */}

        {/* <Icon className="pdf--icon">
          <BsPrinter />
        </Icon> */}

        {/* <button
          className={`my-button ${props.isClicked ? "clicked" : ""}`}
          onClick={handleClick}
          disabled={props.isClicked}
        >
          Proof Read
        </button> */}

        <Icon
          title="Download"
          className="pdf--icon"
          onClick={() => trackPromise(handleDownload(), "download-pdf")}
        >
          <RxDownload style={{ fontSize: "24px" }} />
        </Icon>

        {/* <Icon className="pdf--icon" onClick={handleToggleFullScreen}>
          {!isFullScreen ? <MdOpenInFull /> : <MdCloseFullscreen />}
        </Icon> */}

        {/* <Icon className="pdf--icon">
          <RxGear />
        </Icon> */}
      </div>
    </div>
  );
}

export default Toolbar;
