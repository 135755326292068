import React, { useEffect, useRef, useState } from "react";
import { Button, Card, FullCard, Select, Spinner } from "../components/CommonComponents";
import {
    tutorGrade,
    tutorRoles,
    tutorSubject,
    tutorTopic,
    tutorTeacherMethods,
    tutorBoard,
    translationLanguages,
    storyTheme
} from "../components/Mapping";
import Modal from 'react-modal';
import questionIcon from "../assets/questionIcon.svg";
import cloud from "../assets/cloud.svg";
import ansIcon from "../assets/ansIcon.svg";
import DOMPurify from 'dompurify'
import { nanoid } from "nanoid";
import { pairwise } from "../utils/hacks";
import ImageSpinner from "../components/spinner";
import SimpleDropdown from "../components/SimpleDropdown";
import {
    languageTranslationURL,
    storyInfoURL,
    charactersStoryURL,
    getListStoryURL,
    processStoryURL,
    dislikeStoryURL,
    likeStoryURL,
    chatHistoryStoryURL,
    downloadPDFStoryURL
} from "../api/serverAPI";
import Draggable from "react-draggable";
import axios from 'axios';
import { ImageUpload } from "../components/Fileupload";
import { select } from "react-cookies";
import jsPDF from 'jspdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    BadgeHelp,
    ThumbsDown,
    ThumbsUp,
    Copy,
    MessageSquarePlus,
    Download,
} from 'lucide-react';

// console.log("ngoninittttttttttttt");
// window.speechSynthesis.cancel();
/**
 * BAse-64 encoding with a URL-safe charset
 * @param {string} s the string to encode
 * @returns {string} the encoded string
 */
function b64_urlsafe(s) {
    return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace('=', '')
}


function randomString(n = 16) {
    return b64_urlsafe(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(n))))
}

// eslint-disable-next-line no-control-regex
let sender = randomString();

const MultimediaSearch = () => {


    const [selectedRole, setSelectedRole] = useState('teacher');
    const [selectedBoard, setSelectedBoard] = useState('CBSE');
    const [endpoint, setEndpoint] = useState('faq');
    const [selectedGrade, setSelectedGrade] = useState('8');
    const [selectedSubject, setSelectedSubject] = useState('English');
    const [selectedTopic, setSelectedTopic] = useState('The Best Christmas Present in the World & The Ant and the Cricket');
    const [selectedTeacherMethod, setSelectedTeacherMethod] = useState('faq');
    const [messages, setMessages] = useState([]);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [displayQuestionInput, setDisplayQuestionInput] = useState(true);
    const [voiceInput, setVoiceInput] = useState(false);

    const latestAnswerRef = useRef(null);
    const [disableTextArea, setDisableTextArea] = useState(true);
    const [imageFlag, setImageFlag] = useState(null);
    const [translationLanguage, setTranslationLanguage] = useState('hi')
    const [translatedText, setTranslatedText] = useState(null);
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const [englishAnswer, setEnglishAnswer] = useState(null);
    const [translate, setTranslate] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [base64Data, setBase64Data] = useState(null);
    const [stream, setStream] = useState(true);
    const [autoScroll, setAutoScroll] = useState(true);
    const [selectedTheme, setSelectedTheme] = useState(storyTheme[0].text);
    const [customTheme, setCustomTheme] = useState("");
    const [charactersList, setCharactersList] = useState([]);
    const [info, setInfo] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [chatHistoryPopup, setChatHistoryPopup] = useState(false);
    const [selectedCharacters, setSelectedCharacters] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);
    const [chatHistory, setChatHistory] = useState([]);
    const [showDownload, setShowDownload] = useState(false);
    const latestTranscriptRef = useRef('');
    // Dummy state to force re-render when updating the global sender variable
    const [, setDummy] = useState(0);

    // Function to refresh sender value
    const refreshSender = () => {
        // clearAllVariables();
        sender = randomString(); // Update the global sender variable
        setDummy(prev => prev + 1); // Force re-render by updating a dummy state
        // Trigger a toast notification
        toast.info(`Session Refreshed: ${sender}`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            progress: undefined,
        });
    };

    return (
        <div className="flex gap-4">
            <div className="secondcardWidth">
                <FullCard>
                    <div className="">
                        <div className="flex justify-between items-center mt-2">
                            <div className="subheading text-center">
                                Multi Media Search
                            </div>
                            <button onClick={refreshSender} className="flex items-center mx-2">
                                <MessageSquarePlus className="mr-2" size={20} />
                            </button>
                            <button type="button" className="text-blue-500" aria-label="Info">
                                <BadgeHelp className="mr-2" size={20} />
                            </button>
                        </div>
                        {/* ToastContainer will show notifications */}
                        <ToastContainer />
                    </div>
                </FullCard>
            </div>

        </div>
    )
}

export default MultimediaSearch;
