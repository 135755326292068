import React, { useState } from "react";
import { FullCard } from "../components/CommonComponents";
import { FileUpload } from "../components/Fileupload";
import Sidebar from "../components/Sidebar";
import PdfEditorApp from "../PdfEditor";
import axios from "axios";
import { Spinner, Button } from "../components/CommonComponents";
import { proofReadingURL } from "../api/serverAPI";

const ProofReading = () => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    const [showEditor, setShowEditor] = useState(false);
    const [base64Data, setBase64Data] = useState(null);
    const [pdfData, setPdfData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [btnClicked, setBtnClicked] = useState(false);

    const sendToApi = async () => {
        setLoading(true);
        try {
            // Check if base64Data and selectedFiles are available
            if (base64Data && selectedFiles) {
                // Prepare the data object to send
                const payload = {
                    filename: selectedFiles.name,
                    content: base64Data,
                };
                const url = proofReadingURL();
                const response = await axios.post(url, payload);
                // Handle the response as needed
                console.log("API response:", response.data.response);

                setPdfData(response.data.response);
                setLoading(false);
            } else {
                console.error("Missing base64Data or selectedFiles.");
            }
        } catch (error) {
            console.error("Error sending data to the API:", error);
        }
    };

    const handleFileChange = (e) => {
        const files = e.target.files[0];
        setSelectedFiles(files);

        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
            setBase64Data(base64String);
        };
        reader.readAsDataURL(files);

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                } else {
                    clearInterval(interval);

                    return prevProgress;
                }
            });
        }, 1000);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files[0];
        setSelectedFiles(files);

        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
            setBase64Data(base64String);
        };
        reader.readAsDataURL(files);

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                } else {
                    clearInterval(interval);

                    return prevProgress;
                }
            });
        }, 1000);
    };

    return (
        <>
            <div className="flex gap-4">
                <div className="secondcardWidth">
                    <FullCard>
                        <div className="subheading text-center mt-2">
                            Proof Reading
                        </div>
                        <div className="my-8 text-center">
                            <div className="items-center">
                                <FileUpload
                                    accept={"application/pdf"}
                                    onChange={handleFileChange}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                />
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            {selectedFiles && (
                                <div className="">
                                    <b>File Name: </b>
                                    {selectedFiles.name}
                                </div>
                            )}
                            {progress > 0 && (
                                <div className="my-4">
                                    <div className="bg-[#D2CFF1] h-4 rounded-full">
                                        <div
                                            className="bg-[#23479F] h-full rounded-full"
                                            style={{
                                                width: `${progress}%`,
                                            }}
                                        ></div>
                                    </div>
                                    <div className="text-center mt-2">{`${progress}% Complete`}</div>
                                </div>
                            )}

                            <>
                                <div className={"my-8"}>
                                    <Button
                                        disabled={!(progress === 100)}
                                        onClick={() => {
                                            setShowEditor(true);
                                            setBtnClicked(true);
                                            sendToApi();
                                        }}>
                                        Proceed
                                    </Button>
                                </div>
                            </>

                        </div>
                    </FullCard>
                </div>
                <div className="thirdcardWidth">
                    {showEditor && (
                        <div
                            className="p-64 bg-white rounded-lg p-6 shadow-xl card mb-6 z-10"
                            style={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            {loading ? (
                                <div className="overlay">
                                    <Spinner />
                                </div>
                            ) : (
                                <PdfEditorApp
                                    data={pdfData}
                                    btnClicked={btnClicked}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ProofReading