import React, { useEffect, useState, useContext } from "react";
import Navbar from "./Navbar";

import Sidebar from "./Sidebar";

const Layout = ({ isAuthenticated, userDetails,children }) => {
  



    if (isAuthenticated) {
        return (
            <>
                <Navbar userDetails={userDetails} />
                <div className="mt-3">
                    <div className="flex gap-4 w-full">
                        <div className="secondCard">
                            {userDetails && (
                                <Sidebar userDetails={userDetails} />
                            )
                            }
                        </div>
                        <div className="MainContent">
                            {children}
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="flex flex-auto">
                    {/* <Sidebar */}

                    <div className="grow">
                        <div>{children}</div>
                    </div>
                </div>
            </>
        );
    }
};

export default Layout;
