import React, { useState, useRef, useEffect } from 'react';
import { HiChevronUp, HiChevronDown } from "react-icons/hi";
import downloadicon from "../assets/downloadicon.svg";
import { ChevronDown } from 'lucide-react';

export const Card = ({ children }) => {
    return (
        <>
            <div className="bg-white rounded-lg p-6 card">
                {children}
            </div>
        </>
    );
};

export const FullCard = ({ children }) => {
    return (
        <>
            <div className="overflow-y-auto h-[90vh] duration-300 bg-white rounded-t-lg p-6">
                {children}
            </div>
        </>
    );
};

export const WhiteCard = ({ children }) => {
    return (
        <>
            <div className="customScrollbar duration-300 bg-white rounded-t-lg">
                {children}
            </div>
        </>
    );
};

export const Spinner = () => {
    return (
        <div className="object-none object-center justify-center my-10">
            <div className="animate-spin rounded-full h-40 w-40 border-t-2 border-b-2 border-purple"></div>
        </div>
    );
};

// Dropdown element
export const Select = ({ label, value, onChange, dropdownData, disabled = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const filteredData = dropdownData.filter(item =>
        item.text.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSelectClick = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
            setSearchTerm('');
        }
    };

    const handleOptionClick = (optionValue) => {
        if (!disabled) {
            onChange({ target: { value: optionValue } });
            setIsOpen(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <div
                className={`bg-white border border-light-purple text-black text-sm rounded-lg focus:ring-purple focus:border-purple block w-full p-2.5 flex items-center justify-between
                    ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                    }`}
                onClick={handleSelectClick}
            >
                <span>{value || 'Select an option'}</span>
                <ChevronDown
                    className={`w-5 h-5 text-gray-400 transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''
                        }`}
                />
            </div>
            {isOpen && !disabled && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-light-purple rounded-lg shadow-lg">
                    {dropdownData.length > 5 && (
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-full p-2 border-b border-light-purple"
                        />
                    )}
                    <ul className="max-h-60 overflow-auto">
                        {filteredData.map((item, index) => (
                            <li
                                key={index}
                                className="px-4 py-2 hover:bg-[#D2CFF1] cursor-pointer"
                                onClick={() => handleOptionClick(item.text)}
                            >
                                {item.text}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export const IconButton = ({ onClick, children }) => {
    return (
        <button
            className={
                "bg-white hover:bg-light-purple p-2 tracking-wider leading-5 rounded-lg text-lg text-white font-bold font-heading"
            }
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export const Button = ({ 
    size = "medium",
    onClick,
    children,
    radius = "lg",
    disabled,
}) => {
    const baseStyles = "tracking-wider leading-5 rounded text-white font-bold font-heading";
    const disabledStyles = "opacity-50 cursor-not-allowed"; 

    if (size === "large")
        return (
            <button
                className={`bg-[#766AFF] hover:bg-[#544AC0] px-8 py-4 ${baseStyles} text-lg rounded-${radius} ${
                    disabled ? disabledStyles : ""
                }`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
    else if (size === "small")
        return (
            <button
                className={`bg-[#23479F] hover:bg-[#544AC0] px-4 py-2 ${baseStyles} text-sm rounded-${radius} ${
                    disabled ? disabledStyles : ""
                }`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
    else if (size === "outline")
        return (
            <button
                className={`border-2 border-white px-4 py-2 ${baseStyles} text-sm rounded-${radius} ${
                    disabled ? disabledStyles : ""
                }`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
    else if (size === "download")
        return (
            <button
                className={`border-2 bg-white text-[#23479F] hover:bg-slate-100 px-4 py-2 tracking-wider leading-4 rounded-${radius} text-sm font-bold font-heading${
                    disabled ? disabledStyles : ""
                }`}
                onClick={onClick}
                disabled={disabled}
            >
                <div className="flex">
                    {children}
                    <img
                        src={downloadicon}
                        alt=""
                        width={"15px"}
                        height={"15px"}
                        className="ml-2"
                    />
                </div>
            </button>
        );
    else if (size === "white")
        return (
            <button
                className={`border-2 bg-white text-[#23479F] hover:bg-slate-100 px-4 py-2 tracking-wider leading-4 rounded-${radius} text-sm font-bold font-heading${
                    disabled ? disabledStyles : ""
                }`}
                onClick={onClick}
                disabled={disabled}
            >
                <div className="flex">
                    {children}
                </div>
            </button>
        );
    else
        return (
            <button
                className={`bg-[#23479F] hover:bg-[#544AC0] px-6 py-3 ${baseStyles} text-base rounded-${radius} ${
                    disabled ? disabledStyles : ""
                }`}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
};


export const Slider = ({ min, max, step, value, onChange }) => {
    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={onChange}
                className="orangeColor mt-2 w-full bg-[#EBEAFD] h-1 rounded-full focus:outline-none"
            />
            <div className="flex justify-between w-full">
                <span>{min}</span>
                <span>{max}</span>
            </div>
        </>
    );
};

export const RadioButton = ({
    checked,
    type,
    value,
    name,
    onChange,
    id,
    label,
    htmlFor,
    customClassName,
}) => {
    return (
        <>
            <input
                checked={checked}
                type={type}
                value={value}
                name={name}
                onChange={onChange}
                id={id}
                className={`w-4 h-4 text-orange-500 bg-medium-gray border-medium-gray mx-2 ${customClassName}`}
            />
            <label className="text-gray-700" htmlFor={htmlFor}>{label}</label>
        </>
    );
};

export const NewText = ({ text }) => {
    const startIndex = 1;
    const modifiedString = text.substring(startIndex);
    const newText = modifiedString.split("- ").map((str, i) => (
        <li className="list-disc" key={i}>
            {str}
        </li>
    ));
    return newText;
};

export const Accordion = ({ children, index }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <Card>
                <div onClick={toggleAccordion}>
                    {isOpen ? (
                        <div className="flex justify-end items-center">
                            <HiChevronDown
                                style={{ fontSize: "40px" }}
                                className="cursor-pointer"
                            />
                        </div>
                    ) : (
                        <span>
                            <div className="flex justify-between items-center">
                                <div className="subtitle my-5 text-left px-[2%]">
                                    {/* Display the question number dynamically */}
                                    <span className="border-2 border-black py-2 px-4 rounded-full mr-4">
                                        {index + 1}
                                    </span>{" "}
                                    Asked Question
                                </div>
                                <HiChevronUp
                                    style={{ fontSize: "40px" }}
                                    className="cursor-pointer"
                                />
                            </div>
                        </span>
                    )}
                </div>
                {isOpen && <div>{children}</div>}
            </Card>
        </div>
    );
};

export const AnswerWithReadMore = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 100; // Maximum length of text before truncation

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div>
            <p>
                {isExpanded || text.length <= maxLength
                    ? text
                    : `${text.substring(0, maxLength)}...`}
            </p>
            {text.length > maxLength && (
                <button
                    onClick={toggleExpand}
                    className="text-blue-500 underline"
                >
                    {isExpanded ? 'Read less' : 'Read more...'}
                </button>
            )}
        </div>
    );
};