/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Sheet.css";
import { nanoid } from "nanoid";
import TextBox from "./TextBox";
// import { deleteById, pushX } from "../utils/utils";

function Sheet(props) {
  const readOnly = props?.readOnly ?? true;
  const key = props.id ?? nanoid(5);
  const [data, setData] = useState(props.data);

  useEffect(() => setData(props.data), [props.data]);

  // useEffect(() => {
  //   const payload = props.insertText;
  //   // console.log(payload);
  //   if (!payload) {
  //     return;
  //   }
  //   if (`sheet-id--${key}` === payload?.id) {
  //     console.log("TRIGGER ", key, payload);
  //     const data_ = { ...data };
  //     data_.content = pushX(payload.content, data.content);
  //     setData(data_);
  //   } else {
  //     return;
  //   }
  // }, [props.insertText]);
  return (
    <div className="pdf-sheet-wrap">
      <div style={{ width: 12, height: "100%", background: "none" }} />
      <div
        key={key}
        dataindex={props.index}
        datakey={key}
        id={`sheet-id--${key}`}
        className="pdf-sheet"
        style={{ ...props.style }}
        // onContextMenu={(e) => {
        //   e.preventDefault();
        //   props.onContextMenu(e, key);
        // }}
      >
        {props.bg && (
          <img
            src={`data:image/png;base64,${props.bg}`}
            className="sheet-bg"
            style={{
              width: "100%",
              height: "100%",
              userSelect: "none",
            }}
            alt=""
          />
        )}

        {data.content.map((d, idx) => (
           <TextBox
            key={d.id}
            index={idx}
            pageIndex={props.index}
            readOnly={readOnly}
            id={d.id}
            content={d}
            sheetId={`sheet-id--${key}`}
            isClicked={props.isClicked}
            data = {props.dataCopy}
            setData = {props.setDataCopy}
            // onDelete={handleDeleteTextBox}
            // onContextMenu={(e) => props.onContextMenu(e, key)}
          /> 
        ))}
      </div>
    </div>
  );
}

export default Sheet;
