import React, { useState, useEffect } from 'react';
import { FullCard, Button, Spinner, Card, Select } from "../components/CommonComponents";
import { FileUpload } from '../components/Fileupload';
import { schemaDownloadURL, evaluateResultURL, downloadResultURL } from '../api/serverAPI';
import { FaDownload } from 'react-icons/fa';
import { fileTypes } from "../components/Mapping";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Evaluation = () => {

    const [schemaFile, setSchemaFile] = useState(null);
    const [schemaFileName, setSchemaFileName] = useState("");
    const [evaluationResult, setEvaluationResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isEvaluating, setIsEvaluating] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedFileType, setSelectedFileType] = useState('');

    const handleSchemaDownload = async () => {
        setLoading(true);
        try {
            // Get the schema download URL
            const url = schemaDownloadURL();

            // Make the axios call to download the schema as a blob
            const response = await axios.get(url, {
                responseType: 'blob'
            });

            // Create a blob from the response data
            const blob = response.data;
            const downloadURL = window.URL.createObjectURL(blob);

            // Create an anchor element to trigger the download
            const link = document.createElement('a');
            link.href = downloadURL;
            link.setAttribute('download', 'Schema.zip');
            document.body.appendChild(link);

            // Trigger the download
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading schema:', error);
        } finally {
            setLoading(false);
        }
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split('base64,')[1]);
            reader.onerror = reject;
        });
    };

    const getAcceptedFileTypes = (fileType) => {
        switch (fileType.toLowerCase()) {
            case 'pdf':
                return ['application/pdf'];
            case 'excel':
                return [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel'
                ];
            default:
                return [];
        }
    };

    const handleSchemaChange = async (event) => {
        const acceptedExcelMimeTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel'
        ];
        const selectedFile = event.target.files[0];
        if (!selectedFile || !acceptedExcelMimeTypes.includes(selectedFile.type)) {
            // ERROR notification
            toast.error(`Please upload only Excel files.`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        try {
            const base64 = await readFileAsBase64(selectedFile);
            setSchemaFile(base64);
            setSchemaFileName(selectedFile.name);
        } catch (error) {
            console.error('Error: ', error);
        }
    };

    const handleFilesChange = async (event) => {
        setLoading(true);
        const selectedFiles = Array.from(event.target.files);
        const acceptedFileTypes = getAcceptedFileTypes(selectedFileType);

        // Filter out any files that don't match the selected file type
        const filteredFiles = selectedFiles.filter(file => acceptedFileTypes.includes(file.type));

        if (selectedFileType === undefined || selectedFileType.trim() === '') {
            toast.error(`Please choose a file type`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            setLoading(false);
            return;
        }
        // Check if there are any files that are not accepted
        else if (filteredFiles.length !== selectedFiles.length) {
            // ERROR notification
            toast.error(`Please upload only ${selectedFileType.toUpperCase()} files.`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            setLoading(false);
            return;
        }

        try {
            const filesData = await Promise.all(
                filteredFiles.map(async (file) => {
                    const fileType = file.type === 'application/pdf'
                        ? 'pdf'
                        : ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)
                            ? 'excel'
                            : 'unknown';

                    const base64 = await readFileAsBase64(file);
                    return ({
                        base64,
                        file_name: file.name,
                        file_type: fileType
                    });
                })
            );
            setUploadedFiles([...uploadedFiles, ...filesData]);
        } catch (error) {
            console.error('Error: ', error);
        } finally {
            setLoading(false);
        }
    };


    const handleFileTypeChange = (e) => {
        console.log('Before clearing: uploadedFiles =', uploadedFiles);
        console.log('Before clearing: selectedFileType =', selectedFileType);

        // Clear uploadedFiles
        setUploadedFiles([]);
        console.log('After clearing uploadedFiles');

        // Clear selectedFileType
        setSelectedFileType('');
        console.log('After clearing selectedFileType');

        // Set the new file type after a short delay

        console.log('Before setting new selectedFileType');
        setSelectedFileType(e.target.value);
        console.log('After setting new selectedFileType to:', e.target.value);

        console.log('End of handleFileTypeChange function');
    };

    const handleEvaluate = async () => {
        if (schemaFile && uploadedFiles.length) {
            setLoading(true);
            try {
                const payload = {
                    schema_sheet: schemaFile,
                    answer_sheets: uploadedFiles,
                };
                const url = evaluateResultURL();
                const response = await axios.post(url, payload);
                setEvaluationResult(response.data.output);
            } catch (error) {
                console.error('Error evaluating:', error);
            } finally {
                setLoading(false);
                setIsEvaluating(true);
            }
        }
    };

    const handleDownloadResult = async () => {
        setLoading(true);
        try {
            // Get the download URL
            const url = downloadResultURL();

            // Make the axios POST request with the data (e.g., evaluationResult)
            const response = await axios.post(url, { data: evaluationResult }, {
                responseType: 'blob'
            });

            // Create a blob from the response data
            const blob = response.data;
            const downloadURL = window.URL.createObjectURL(blob);

            // Create an anchor element to trigger the download
            const link = document.createElement('a');
            link.href = downloadURL;
            link.setAttribute('download', 'Result.xlsx');
            document.body.appendChild(link);

            // Trigger the download
            link.click();
            link.remove();

        } catch (error) {
            console.error('Error downloading result:', error);
        } finally {
            setLoading(false);
        }
    };

    const removeFile = (index) => {
        const newFiles = [...uploadedFiles];
        newFiles.splice(index, 1);
        setUploadedFiles(newFiles);
    };

    const StudentAccordion = ({ students = [] }) => {
        const [openIndex, setOpenIndex] = useState(null);

        useEffect(() => {
            if (students.length === 1) {
                setOpenIndex(0);
            } else {
                setOpenIndex(null);
            }
        }, [students]);

        const toggleAccordion = (index) => {
            setOpenIndex(openIndex === index ? null : index);
        };

        const fieldMapping = {
            'Question No': 'Question No',
            'Question': 'Question',
            'Maximum Marks': 'Total Marks',
            'Marks obtained': 'marks',
            'Reason': 'reason'
        };

        const uiFields = ['Question No', 'Question', 'Maximum Marks', 'Marks obtained', 'Reason'];

        return (
            <div>
                {students.length > 0 ? (
                    students.map((student, index) => (
                        <div key={index} className="border mb-2 rounded">
                            <div
                                onClick={() => toggleAccordion(index)}
                                className="cursor-pointer p-4 bg-slate-300 flex justify-between items-center rounded-lg"
                            >
                                <span><b>{student.answer_sheet}</b></span>
                                <span>{openIndex === index ? <button>&#x25b4;</button> : <button>&#x25be;</button>}</span>
                            </div>
                            {openIndex === index && (
                                <div className="p-4 overflow-y-auto h-[65vh]">
                                    <table className="w-full border-collapse">
                                        <thead>
                                            <tr className="border-b">
                                                {uiFields.map((key) => (
                                                    <th key={key} className="p-2 border-b text-left">{key}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {student.results.map((result, idx) => (
                                                <tr key={idx} className="border-b">
                                                    {uiFields.map((uiKey, subIdx) => (
                                                        <td key={subIdx} className="p-2">{result[fieldMapping[uiKey]]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p>No students available.</p>
                )}
            </div>
        );
    };

    return (
        <div className="relative">
            {loading && (
                <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
                    <Spinner />
                    <div className="absolute bottom-4 bg-slate-450 text-white p-2 rounded-lg" style={{ borderRadius: '12px' }}>
                        This may take a while
                    </div>
                </div>
            )}
            <div className="flex gap-4 mr-4">
                <div className="secondcardWidth">
                    <FullCard>
                        <div className="subheading text-center mt-2">
                            Evaluation
                        </div>
                        <div className="my-8 text-center">
                            <div className="items-center pb-6">
                                <Button size="white" onClick={handleSchemaDownload} disabled={loading}>
                                    <FaDownload className="pl-2 mr-2" style={{ height: '40px', width: '40px', textAlign: 'center' }} />
                                    Download Evaluation Template
                                </Button>
                            </div>
                            <div className="items-center pb-6">
                                <div className="items-left color-blue">Upload Evaluation Template</div>
                                <FileUpload
                                    accept={"application/excel"}
                                    fileFormat="EXCEL"
                                    onChange={handleSchemaChange}
                                />
                                {/* ToastContainer will show notifications */}
                                <ToastContainer />
                            </div>
                            <div className="my-8">
                                <div className="text-blue text-lg font-semibold my-2">Select File Type</div>
                                <Select
                                    dropdownData={fileTypes}
                                    value={selectedFileType}
                                    onChange={handleFileTypeChange}
                                />
                            </div>
                            <div className="items-center pb-6">
                                <div className="items-left color-blue">Upload Student Answer Sheets</div>
                                <FileUpload
                                    accept={getAcceptedFileTypes(selectedFileType)}
                                    fileFormat={selectedFileType ? selectedFileType.toUpperCase() : "PDF or Excel"}
                                    multiple="multiple"
                                    onChange={handleFilesChange}
                                />
                            </div>
                            <div className="items-center pb-6">
                                <button
                                    className={`${uploadedFiles.length && schemaFile
                                        ? "bg-[#23479F] hover:bg-[#544AC0] text-white"
                                        : "bg-gray-400 text-gray-700 cursor-not-allowed"
                                        } px-6 py-3 tracking-wider leading-5 rounded-lg text-base font-bold font-heading`}
                                    onClick={handleEvaluate}
                                    disabled={!uploadedFiles.length || loading}
                                >
                                    Evaluate
                                </button>
                            </div>
                        </div>
                    </FullCard>
                </div>
                <div
                    className={`thirdcardWidth flex flex-col w-1/2 p-6 rounded-lg overflow-y-auto h-[90vh] ${(schemaFile || (Array.isArray(uploadedFiles) && uploadedFiles.length > 0) || (isEvaluating && Array.isArray(evaluationResult) && evaluationResult.length > 0))
                        ? 'bg-white'
                        : ''
                        }`}
                >
                    {(schemaFile || (Array.isArray(uploadedFiles) && uploadedFiles.length > 0)) && !isEvaluating && (
                        <Card>
                            <div className="subheading text-center">Here is your uploaded Files</div>
                            <div className="my-6">
                                <div className="border-2 border-[#23479F] rounded-lg">
                                    <div className="bg-white mx-auto rounded-lg">
                                        {schemaFile && (
                                            <div className="p-4 bg-white border rounded mb-4">
                                                <h3 className="text-lg font-bold">Evaluation Template</h3>
                                                <p>{schemaFileName}</p>
                                            </div>
                                        )}
                                        {Array.isArray(uploadedFiles) && uploadedFiles.length > 0 && (
                                            <>
                                                <p className="font-medium mb-2 pl-4 pt-4">You have selected {uploadedFiles.length} answer sheets</p>
                                                <div className="overflow-y-auto h-[50vh] p-4 bg-white border rounded">
                                                    <h3 className="text-lg font-bold">Answer Sheets</h3>
                                                    <ul>
                                                        {uploadedFiles.map((file, index) => (
                                                            <li key={index} className="flex justify-between items-center py-2">
                                                                {file.file_name}
                                                                <button
                                                                    className="bg-red-500 text-white px-2 py-1 rounded"
                                                                    onClick={() => removeFile(index)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    )}
                    {isEvaluating && Array.isArray(evaluationResult) && evaluationResult.length > 0 && (
                        <>
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl font-semibold flex-grow text-center">Results</h2>
                                <Button
                                    className="ml-auto bg-blue-500 text-white px-4 py-2 rounded"
                                    onClick={handleDownloadResult}
                                    disabled={loading}
                                >
                                    Download
                                </Button>
                            </div>
                            <StudentAccordion students={evaluationResult} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Evaluation;
