import React, { useState, useContext } from 'react';
import AuthForm from './authForm';
import { authFields } from '../contents/authentication';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { CookiesProvider, useCookies } from 'react-cookie';


const Login = () => {
  const router = useNavigate();
  const [loading, setLoading] = useState(false);
  const [,setCookie, removeCookie] = useCookies(['jwtToken']);



  const handleLogin = async (formData) => {
    formData.application_id = 2;
    try {
      setLoading(true);
      const response = await axios.post("https://efhp0mxmli.execute-api.ap-south-1.amazonaws.com/dev/login", formData);
      //console.log(formData);
      // console.log("Login success", response.data);
      await toast.success("Login success");
      // setToken(response.data.token);
      const jwtToken=response.data.token;
      setCookie('jwtToken', jwtToken);
      router("/");
    } catch (error) {
      console.log("Login failed", error.message);
      await toast.error("Login failed", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <AuthForm fields={authFields.login} buttonText="Login" onSubmit={handleLogin} title="Login" loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Login;
