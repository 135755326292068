import axios from 'axios';
import { 
    getOrganizationsManagementURL, 
    getInstitutionsManagementURL, 
    getRolesManagementURL, 
    getFeaturesManagementURL, 
    getSectionsManagementURL, 
    getUsersManagementURL 
} from "../api/serverAPI";


export const fetchOrganizations = async (token) => {
    try {
        const response = await axios.get(getOrganizationsManagementURL(), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching organizations:', error);
        throw error; // You may want to handle this differently based on your application needs
    }
};

export const fetchInstitutions = async (token) => {
    try {
        const response = await axios.get(getInstitutionsManagementURL(), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching institutions:', error);
        throw error;
    }
};

export const fetchRoles = async (token) => {
    try {
        const response = await axios.get(getRolesManagementURL(), {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching roles:', error);
        throw error;
    }
};

export const fetchFeatures = async (token) => {
    try {
        const response = await axios.get(getFeaturesManagementURL(), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching features:', error);
        throw error;
    }
};

export const fetchSections = async (token) => {
    try {
        const response = await axios.get(getSectionsManagementURL(), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching sections:', error);
        throw error;
    }
};

export const fetchUsers = async (token) => {
    try {
        const response = await axios.get(getUsersManagementURL(), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};
