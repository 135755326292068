import {Link} from 'react-router-dom'

export const EseAnswer = ({type, answer, streaming}) => {
    console.log(answer.docs)
    const arr = Array.isArray(answer.docs[0]) ? answer.docs[0] : answer.docs;
    return (
        <div>
            {
                <div>
                    {/*<div>
                            {index + 1}. <span className="" dangerouslySetInnerHTML={{ __html: data.matched }} />
                        </div>*/}
                    <div>
                        <div className="" dangerouslySetInnerHTML={{__html: answer.text}}/>
                    </div>
                    {answer.displayDocs && arr && arr.map((doc, index) => {
                        return (
                            <div>
                                Document {index+1}: <Link className="text-indigo-600 underline" target="_blank"
                                                        to={doc.url}> {doc.Document}</Link>
                            </div>)
                    })}
                    <br/>
                </div>
            }
        </div>
    )
}
