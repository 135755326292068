import React, { useEffect, useRef, useState } from "react";
import { Button, Card, FullCard, Select, Spinner } from "../components/CommonComponents";
import {
    tutorGrade,
    tutorRoles,
    tutorSubject,
    tutorTopic,
    tutorTeacherMethods,
    tutorBoard,
    translationLanguages,
    noAgents
} from "../components/Mapping";
import Modal from 'react-modal';
import questionIcon from "../assets/questionIcon.svg";
import cloud from "../assets/cloud.svg";
import ansIcon from "../assets/ansIcon.svg";
import DOMPurify from 'dompurify'
import { nanoid } from "nanoid";
import { pairwise } from "../utils/hacks";
import ImageSpinner from "../components/spinner";
import SimpleDropdown from "../components/SimpleDropdown";
import {
    languageTranslationURL,
    debateInfo,
    judgeDebateURL,
    updatePersonaDebateURL,
    generateDebateURL,
} from "../api/serverAPI";
import { ImageUpload } from "../components/Fileupload";
import { select } from "react-cookies";
import { MessageSquarePlus } from 'lucide-react';
import {
    ToastContainer,
    toast
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BadgeHelp } from 'lucide-react';
import Draggable from "react-draggable";
import axios from 'axios';

// console.log("ngoninittttttttttttt");
window.speechSynthesis.cancel();
/**
 * BAse-64 encoding with a URL-safe charset
 * @param {string} s the string to encode
 * @returns {string} the encoded string
 */
function b64_urlsafe(s) {
    return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace('=', '')
}

/**
 * A random string of n **bytes**.
 *
 * ..note::
 *    not all characters are a single byte. intermediate steps may contain fewer characters
 *    if String.fromCharCode returns a multi-byte sequence. This is normalized by base64.
 * @param n The number of bytes, defaults to 16
 * @returns {string} The randomly generated string.
 */
function randomString(n = 16) {
    return b64_urlsafe(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(n))))
}
// Function to format timestamp
const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // This formats the date and time in a readable format
};

export const apiMap = {
    teacher: {
        base: new URL('wss://edu.genai.krtrimaiq.ai'),
        'FAQ generation': '/debate/', //'/chat_tokens_faq',
        faq: '/debate/',
        Brainstorming: '/chat_tokens_brainstorming',
        brainstorming: '/chat_tokens_brainstorming',
    },
    student: {
        base: new URL('wss://edu.genai.krtrimaiq.ai'),
        qa: '/debate/',
        Summarization: '/chat_tokens_sum',
        summarization: '/chat_tokens_sum',
    }
};

export function makeEndpoint(role, endpoint) {
    var url = '';
    if (endpoint === 'Summarization' || endpoint === 'summarization') {
        url.pathname = 'wss://edu.genai.krtrimaiq.ai/debate/'
    }
    else {
        url = apiMap[role].base;
        url.pathname = apiMap[role][endpoint];
    }
    return url;
}

// eslint-disable-next-line no-control-regex
const pattern = new RegExp('data:image/[^/]+;base64,[^\0]+\0\0');
let sender = randomString();

const DebateBot = () => {


    const [selectedRole, setSelectedRole] = useState('teacher');
    const [selectedBoard, setSelectedBoard] = useState('CBSE');
    const [endpoint, setEndpoint] = useState('faq');
    const [selectedGrade, setSelectedGrade] = useState('8');
    const [selectedSubject, setSelectedSubject] = useState('English');
    const [selectedTopic, setSelectedTopic] = useState('The Best Christmas Present in the World & The Ant and the Cricket');
    const [selectedTeacherMethod, setSelectedTeacherMethod] = useState('faq');
    const [messages, setMessages] = useState([]);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [displayQuestionInput, setDisplayQuestionInput] = useState(true);
    const [voiceInput, setVoiceInput] = useState(false);

    const latestAnswerRef = useRef(null);
    const [disableTextArea, setDisableTextArea] = useState(true);
    const [imageFlag, setImageFlag] = useState(null);
    const [translationLanguage, setTranslationLanguage] = useState('hi')
    const [translatedText, setTranslatedText] = useState(null);
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const [englishAnswer, setEnglishAnswer] = useState(null);
    const [translate, setTranslate] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [base64Data, setBase64Data] = useState(null);
    const [stream, setStream] = useState(true);
    const [autoScroll, setAutoScroll] = useState(true);
    const [concept, setConcept] = useState('');
    const [info, setInfo] = useState();
    const [editing, setEditing] = useState(false);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [selectedPersonas, setSelectedPersonas] = useState([]);
    const [judge, setJudge] = useState('');
    const [showJudge, setShowJudge] = useState(false);
    const [showStart, setShowStart] = useState('1');
    const [selectedNoAgents, setSelectedNoAgents] = useState('3');
    const [tempAgentChanges, setTempAgentChanges] = useState({})
    // const [chatHistoryPopup, setChatHistoryPopup] = useState(false);
    // const [chatHistory, setChatHistory] = useState([]);
    // const [selectedSession, setSelectedSession] = useState(null);
    // const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);
    // const [showDownload, setShowDownload] = useState(false);
    // Dummy state to force re-render when updating the global sender variable
    const [, setDummy] = useState(0);

    // Function to refresh sender value
    const refreshSender = () => {
        clearAllVariables();
        sender = randomString(); // Update the global sender variable
        setDummy(prev => prev + 1); // Force re-render by updating a dummy state
        // Trigger a toast notification
        toast.info(`Session Refreshed: ${sender}`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            progress: undefined,
        });
    };

    const clearAllVariables = () => {
        setSelectedRole('teacher');
        setSelectedBoard('CBSE');
        setEndpoint('faq');
        setSelectedGrade('8');
        setSelectedSubject('English');
        setSelectedTopic('The Best Christmas Present in the World & The Ant and the Cricket');
        setSelectedTeacherMethod('faq');
        setMessages([]);
        setSelectedQuestionId(null);
        setLoading(false);
        setCurrentQuestion("");
        setErrorMessage("");
        setDisplayQuestionInput(true);
        setVoiceInput(false);
        setDisableTextArea(true);
        setImageFlag(null);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setCurrentAnswer(null);
        setEnglishAnswer(null);
        setTranslate(false);
        setSelectedFiles(null);
        setBase64Data(null);
        setStream(true);
        setAutoScroll(true);
        setConcept('');
        setInfo(undefined);
        setEditing(false);
        setSelectedAgents([]);
        setSelectedPersonas([]);
        setJudge('');
        setShowJudge('');
        setShowStart('1');
        setSelectedNoAgents('3');
        setTempAgentChanges({});
        // setChatHistoryPopup(false);
        // setChatHistory([]);
        // setSelectedSession(null);
        // setSelectedSessionIndex(0);
        // setShowDownload(false);
    };


    const tableHeaderStyle = {
        border: '1px solid black',
        padding: '8px',
        backgroundColor: '#f2f2f2',
        textAlign: 'left',
    };

    const tableCellStyle = {
        border: '1px solid black',
        padding: '8px',
    };

    // const fetchChatHistory = async () => {
    //     console.log("Fetching chat history");
    //     try {
    //         setLoading(true);
    //         const url = chatHistoryDebateURL();
    //         const params = new URLSearchParams({
    //             "email": JSON.parse(sessionStorage.getItem("userDetails"))['user_email']
    //         });
    //         const response = await axios.get(`${url}?${params.toString()}`);
    //         setChatHistoryPopup(true)
    //         setChatHistory(response.data.chat_history);
    //         setDisableTextArea(false);
    //         setLoading(false);
    //     }
    //     catch (error) {
    //         setLoading(false);
    //         console.error('Error fetching the chat history:', error);
    //     }
    // }

    // // Function to go to the next session
    // const handleNextClick = () => {
    //     if (selectedSessionIndex < chatHistory.length - 1) {
    //         const newIndex = selectedSessionIndex + 1;  // Calculate the new index
    //         setSelectedSessionIndex(newIndex);  // Update the index state
    //         setSelectedSession(chatHistory[newIndex]);  // Set the session using the new index
    //     }
    // };

    // // Function to go to the previous session
    // const handlePreviousClick = () => {
    //     if (selectedSessionIndex > 0) {
    //         const newIndex = selectedSessionIndex - 1;  // Calculate the new index
    //         setSelectedSessionIndex(newIndex);  // Update the index state
    //         setSelectedSession(chatHistory[newIndex]);  // Set the session using the new index
    //     }
    // };

    // // Function to handle session selection from the popup
    // const handleSessionClick = (session) => {
    //     // Find the index of the clicked session
    //     const sessionIndex = chatHistory.findIndex((s) => s.session_id === session.session_id);

    //     if (sessionIndex !== -1) {
    //         // First, update the selected session index
    //         setSelectedSessionIndex(sessionIndex);

    //         // Then, update the selected session based on the new index
    //         setSelectedSession(chatHistory[sessionIndex]);
    //     }
    //     console.log("Session index:", sessionIndex);
    // };

    // //Generate LLM summary pdf for entire chat
    // const generatePDFForEntireChat = async () => {
    //     setLoading(true);
    //     const apiURL = downloadPDFStoryURL();
    //     // Determine session_id based on selectedSession
    //     const downloadSessionId = selectedSession ? selectedSession.session_id : sender;
    //     const payload = {
    //         email: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
    //         session_id: downloadSessionId
    //     };

    //     try {
    //         const response = await axios.post(apiURL, payload, {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             responseType: 'blob' // This handles the binary data response (PDF in this case)
    //         });

    //         // Create a download link and trigger it to download the file
    //         const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', 'summary.pdf'); // Set the file name
    //         document.body.appendChild(link);
    //         link.click();

    //         // Clean up
    //         document.body.removeChild(link);
    //         window.URL.revokeObjectURL(url); // Free up the memory used by the blob URL

    //         console.log("PDF download triggered successfully!");
    //         setLoading(false);
    //     } catch (error) {
    //         console.error("Error downloading PDF: ", error);
    //         setLoading(false);
    //     }
    // };

    const latestTranscriptRef = useRef('');
    const handleTopicChange = (topic) => {
        setMessages([]);
        if (!disableTextArea) {
            setStream(false);
        }
        setSelectedTopic(topic);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    }

    const handleInfo = async () => {
        setLoading(true);
        try {
            const url = debateInfo();
            const response = await axios.get(url);
            setLoading(false);
            if (response.status === 200) {
                setInfo(response.data.output);
            } else {
                console.error('Failed to generate info', response.data.output);
            }
        } catch (error) {
            setLoading(false);
            console.error('An error occurred while generating info', error);
        }
    };

    useEffect(() => {
        const classname = document.getElementsByClassName('bot-response')
        if (classname[classname.length - 1] !== undefined) {
            const id = parseInt(classname[classname.length - 1].id.replace("bot-response", ''));
            setTimeout(() => {
                if (voiceInput == true) {
                    // readAloud(id + 1, null);

                }
            }, 2000);

        }


    }, [document.getElementsByClassName('bot-response').length])

    const handleSubjectChange = (subject) => {
        setMessages([]);
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[selectedGrade][subject][0].text);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    };

    const handleBoard = (option) => {
        setSelectedBoard(option);
    }

    const handleTranslationLanguageSelect = (option) => {
        setTranslationLanguage(option.value);
    };

    const handleLanguageTranslation = (id, msg) => {


        // Replace <img> tags with empty <div> tags and store the <img> tags
        const { modifiedHTML, imgTags } = replaceImagesWithDivsAndStore(msg);

        languageTranslationURL({
            text: modifiedHTML,
            target_lang: translationLanguage,
        }).then((response) => {
            // Restore <img> tags back to their original positions
            const restoredHTML = restoreImages(response.data['translated_text'], imgTags);
            setTranslatedText(restoredHTML);
            setMessages((m) => {
                if (m[id].fromUser) {
                    return m;
                }
                m[id].text = restoredHTML;
                return m;
            });
        }).catch(error => {
            alert("Can you please try again in sometime");
        });
    }

    const stopReadAloud = () => {


        window.speechSynthesis.cancel();

    };
    const readAloud = (id, msg) => {
        var idtsr = (parseInt(id) - 1).toString();
        var elem = document.getElementById('bot-response' + idtsr);

        window.speechSynthesis.cancel();
        if (!window.speechSynthesis) {
            console.error("Speech synthesis not supported.");
            return;
        }

        const textElement = elem.innerText.trim(); // Get the trimmed inner text

        if (textElement) {
            const words = textElement.split(/\s+/);


            let currentIndex = -1;
            // let currentWordIndex = -1;

            const utterance = new SpeechSynthesisUtterance(textElement);
            const isHindi = /[\u0900-\u097F]/.test(textElement); // Check for Hindi characters


            const voices = window.speechSynthesis.getVoices();
            const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
            let selectedVoice;

            if (isHindi) {
                utterance.lang = "hi-IN";
                const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
                selectedVoice = hindiVoices.find(voice => voice.name.includes("Microsoft Swara Online (Natural) - Hindi (India)")) || hindiVoices[0];
            } else {

                utterance.lang = "en-US";
                const englishVoices = voices.filter(voice => voice.lang === "en-US" && voice.gender === "male");
                selectedVoice = englishVoices.find(voice => voice.name.includes("Microsoft David Desktop - English (United States)")) || englishVoices[0];
            }

            if (selectedVoice) {
                utterance.voice = selectedVoice;
            } else {
                console.warn("No appropriate voice found, using default voice.");
            }

            utterance.addEventListener("boundary", (event) => {
                const { charIndex } = event;


                // highlightTheWord('bot-response' + idtsr, currentIndex);
                currentIndex++;


            });

            utterance.addEventListener("end", () => {

                console.log("Reading is completed");
            });

            window.speechSynthesis.speak(utterance);
        }
    };


    function highlightTheWord(id, wordIndex) {
        const element = document.getElementById(id);
        const words = element.innerText.split(/\s+/);
        const wordToHighlight = words[wordIndex];

        // Replace the word with itself wrapped in a span with inline styles
        const highlightedText = words.map((word, index) => {
            if (index === wordIndex) {
                console.log(word);
                return `<span style="background-color: yellow; font-weight: bold;">${word}</span>`;
            } else {
                return word;
            }
        }).join(' ');

        // Update the inner HTML of the element with the highlighted text
        element.innerHTML = highlightedText;

    }

    // function removeHighlight(element) {
    //     element.innerHTML = element.innerText; // Reset innerHTML to remove highlighting
    // }


    const handleClassChange = (classItem) => {
        setMessages([]);
        // XXX: find somethig better. i`m too tired.
        if (classItem.toLowerCase().startsWith(`btech`)) classItem = `13`;
        setSelectedGrade(classItem);
        const subject = getSubjectOptions(classItem)[0].text;
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[classItem][subject][0].text);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    };

    const handleNoAgentsChange = (e) => {
        setSelectedNoAgents(e);
    }
    /**
     *
     * @param {InputEvent} e
     */
    const handleRoleChange = (e) => {
        setMessages([]);
        setSelectedFiles(null);
        const role = e.target.value.toLowerCase();
        setSelectedRole(() => role);
        if (Object.keys(apiMap[role]).indexOf(endpoint) === -1) {
            setEndpoint(role === 'teacher' ? 'faq' : 'qa');
        }
        setTranslationLanguage('hi');
        setTranslatedText(null);
    }

    /**
     *
     * @param {InputEvent} e
     */
    const handleTeacherMethod = (e) => {
        setMessages([]);
        setSelectedFiles(null);
        setSelectedTeacherMethod(() => e.target.value);
        setEndpoint(() => e.target.value);
        setTranslationLanguage('hi');
        setTranslatedText(null);
    }

    // Scroll to the latest answer
    useEffect(() => {
        const container = document.getElementById("chat-box")
        if (autoScroll || loading) {
            container.scrollTo({
                behavior: "smooth",
                top: container.scrollHeight,
            });
        }
    },
        [messages, autoScroll, loading]
    )

    const handleScroll = () => {
        const container = document.getElementById("chat-box")
        if (container.scrollTop < container.scrollHeight - container.clientHeight - 50) {
            setAutoScroll(false);
        }
        else {
            setAutoScroll(true);
        }
    }

    const scrollToAnswer = (id) => {
        setSelectedQuestionId(id);
        const answerContainer = document.getElementById(`answer-${id}`);
        if (answerContainer) {
            answerContainer.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    function replaceImagesWithDivsAndStore(htmlContent) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const images = Array.from(doc.querySelectorAll('img'));

        const imgTags = [];

        images.forEach((img) => {
            const div = document.createElement('div');
            div.style.width = img.getAttribute('width') + 'px';
            div.style.height = img.getAttribute('height') + 'px';
            div.style.border = '1px solid black';
            div.className = 'imageDiv'
            imgTags.push(img.outerHTML);
            img.parentNode.replaceChild(div, img);
        });
        return { modifiedHTML: doc.documentElement.outerHTML, imgTags };
    }

    // Function to restore <img> tags back to their original positions
    function restoreImages(htmlContent, imgTags) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const divs = doc.querySelectorAll('.imageDiv');

        divs.forEach((div, index) => {
            if (div.innerHTML === '' && imgTags[index]) {
                const img = document.createElement('img');
                const parser = new DOMParser();
                const imgDoc = parser.parseFromString(imgTags[index], 'text/html');
                const imgElement = imgDoc.querySelector('img');
                img.src = imgElement.getAttribute('src');
                img.width = imgElement.getAttribute('width');
                img.height = imgElement.getAttribute('height');
                div.parentNode.insertBefore(img, div);
                div.remove();
            }
        });
        return doc.documentElement.outerHTML;
    }

    //sending data
    const handleStart = async () => {
        if (!concept.trim()) {
            toast.error('Concept cannot be empty', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        try {
            setLoading(true);
            const payload = {
                sender: sender,
                num_agents: selectedNoAgents, //maximum number of agents
                concept: concept,
                grade: `class${selectedGrade}`
            };
            const url = judgeDebateURL();
            const response = await axios.post(url, payload);
            setSelectedAgents(response.data.agents);
            setJudge(response.data.introduction);
            setSelectedPersonas(response.data.all_personas);
            console.log('Data sent successfully:', response.data);
            setShowJudge(true);
            setShowStart('2');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error sending data:', error);
        }
    };

    // Handle agent update when a new persona is selected
    const handlePersonaChange = (agentId, newPersona) => {
        setTempAgentChanges(prev => ({
            ...prev,
            [agentId]: { persona: newPersona.persona, tone: newPersona.tone, response_style: newPersona.response_style, purpose: newPersona.purpose }
        }))
    }

    const handleUpdateAgents = async () => {
        const updatedAgents = selectedAgents.map(agent => ({
            ...agent,
            ...(tempAgentChanges[agent.id] || {})
        }))
        setSelectedAgents(updatedAgents)
        setTempAgentChanges({})
        console.log("updatedagents:", updatedAgents)


        console.log("Inside handle updated agents slectedagents:", selectedAgents);
        const updates = selectedAgents.map(agent => ({
            agent_id: agent.id,
            profile_name: agent.profile_name,
            persona: agent.persona,
            tone: agent.tone,
            response_style: agent.response_style,
            purpose: agent.purpose,
        }));

        try {
            const params = new URLSearchParams({
                "sender": sender
            });
            const url = updatePersonaDebateURL();
            await axios.put(`${url}?${params.toString()}`, { updates });
            toast.success(`Agents updated successfully`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            setEditing(false)
        } catch (error) {
            toast.error(`Agents update failed`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            console.error("Error updating agents: ", error);
        }
    };

    const [newMessage, setNewMessage] = useState('');
    let socket = useRef(null);

    function handleIncomingMessage(event) {
        try {
            const data = JSON.parse(event.data);

            if (Array.isArray(data) && data.length > 0) {
                const { recipient_id, text, index, buttons, role } = data[0];
                // Handle system messages first
                if (role === "System" || role === "Judge") {
                    setMessages(prevMessages => {
                        if (prevMessages.length > 0 && prevMessages[prevMessages.length - 1].user === role) {
                            // Update the last message's text
                            const updatedMessages = [...prevMessages];
                            updatedMessages[updatedMessages.length - 1].text = text;
                            return updatedMessages;
                        } else {
                            // Add a new message from the role
                            return [...prevMessages, { user: role, text }];
                        }
                    });
                } else {
                    // Only check for selected agents if it's not a system message
                    const isSelectedAgent = selectedAgents.some(agent =>
                        agent.profile_name === role
                    );

                    if (isSelectedAgent) {
                        setMessages(prevMessages => {
                            if (prevMessages.length > 0 && prevMessages[prevMessages.length - 1].user === role) {
                                // Update the last message's text
                                const updatedMessages = [...prevMessages];
                                updatedMessages[updatedMessages.length - 1].text = text;
                                return updatedMessages;
                            } else {
                                // Add a new message from the role
                                return [...prevMessages, { user: role, text }];
                            }
                        });
                    } else {
                        console.log("Role not found in selected agents profile names:", role);
                    }
                }
            } else {
                console.warn("Unexpected message format received:", data);
            }
        } catch (error) {
            console.error("Error processing incoming message:", error);
        }
    }

    async function handleSendMessage() {

        if (newMessage.trim()) {
            setLoading(true);
            setMessages(prevMessages => [...prevMessages, { user: 'You', text: newMessage }]);

            if (socket && socket.readyState === WebSocket.OPEN) {
                // If the socket is open, send the message
                socket.send(newMessage);
            } else {
                try {
                    console.log("inside handleSendMessage3")
                    // If the socket is closed or closing, create a new WebSocket connection
                    const sp = new URLSearchParams();
                    sp.set('grade', `class${selectedGrade}`);  // Ensure `grade` is defined in state or props
                    sp.set('sender', sender);
                    sp.set('user_message', newMessage);
                    sp.set('concept', concept);

                    const apiUrl = new URL(generateDebateURL());
                    apiUrl.search = sp.toString();
                    socket = new WebSocket(apiUrl.href);

                    socket.onopen = () => {
                        socket.send(newMessage);
                    };

                    socket.onmessage = handleIncomingMessage;

                    socket.onerror = (error) => {
                        console.error("WebSocket error:", error);
                    };

                    socket.onclose = (event) => {
                        console.log("WebSocket closed:", event);
                    };
                } catch (error) {
                    console.error("Error creating or using WebSocket:", error);
                }
            }
            setLoading(false);
            setNewMessage('');
            setCurrentQuestion('');
        }
    }

    // To handle the first introduction message
    async function handleSendMessageFirst() {
        const newMessage = "";
        setCurrentQuestion("");
        setShowStart('3');
        // setShowDownload(true);
        setLoading(true);
        if (socket && socket.readyState === WebSocket.OPEN) {
            // If the socket is open, send the message
            socket.send(newMessage);
        } else {
            try {
                console.log("inside handleSendMessage3")
                // If the socket is closed or closing, create a new WebSocket connection
                const sp = new URLSearchParams();
                sp.set('grade', `class${selectedGrade}`);  // Ensure `grade` is defined in state or props
                sp.set('sender', sender);
                sp.set('user_message', newMessage);
                sp.set('concept', concept);

                const apiUrl = new URL(generateDebateURL());
                apiUrl.search = sp.toString();
                socket = new WebSocket(apiUrl.href);

                socket.onopen = () => {
                    socket.send(newMessage);
                };

                socket.onmessage = handleIncomingMessage;

                socket.onerror = (error) => {
                    console.error("WebSocket error:", error);
                };

                socket.onclose = (event) => {
                    console.log("WebSocket closed:", event);
                };
            } catch (error) {
                console.error("Error creating or using WebSocket:", error);
            }
        }
        setLoading(false);
        setNewMessage('');
        setCurrentQuestion('');
    }

    // To handle the final message
    async function handleSendMessageFinish(newMessage = "Conclude") {
        setCurrentQuestion("Conclude");
        // setShowDownload(true);

        if (newMessage.trim()) {
            setLoading(true);
            setMessages(prevMessages => [...prevMessages, { user: 'You', text: newMessage }]);

            if (socket && socket.readyState === WebSocket.OPEN) {
                // If the socket is open, send the message
                socket.send(newMessage);
            } else {
                try {
                    console.log("inside handleSendMessage3")
                    // If the socket is closed or closing, create a new WebSocket connection
                    const sp = new URLSearchParams();
                    sp.set('grade', `class${selectedGrade}`);  // Ensure `grade` is defined in state or props
                    sp.set('sender', sender);
                    sp.set('user_message', newMessage);
                    sp.set('concept', concept);

                    const apiUrl = new URL(generateDebateURL());
                    apiUrl.search = sp.toString();
                    socket = new WebSocket(apiUrl.href);

                    socket.onopen = () => {
                        socket.send(newMessage);
                    };

                    socket.onmessage = handleIncomingMessage;

                    socket.onerror = (error) => {
                        console.error("WebSocket error:", error);
                    };

                    socket.onclose = (event) => {
                        console.log("WebSocket closed:", event);
                    };
                } catch (error) {
                    console.error("Error creating or using WebSocket:", error);
                }
            }
            setLoading(false);
            setNewMessage('');
            setCurrentQuestion('');
            setShowStart('4');
        }
    }

    const handleSendMessageSpeech = (message) => {
        // setContent(prevContent => prevContent + message);
        let webSocket;
        setDisableTextArea(false);
        setTranslate(false);
        const newMessages = [...messages, { text: message, fromUser: true, index: 0, id: nanoid(24) }];
        if (message.trim() === "") {
            toast.error(`Text cannot be empty.`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            setLoading(false);
        } else {
            setCurrentQuestion('');
            setLoading(true);
            setErrorMessage("");

            setMessages(newMessages);
            let first = true;
            const sp = new URLSearchParams();
            sp.set('sender', sender); // TODO: handle collisions
            sp.set('user_input', message);
            sp.set('continue_story', true);
            if (selectedFiles) {
                sp.set('images', 'True')
            }

            const url = generateDebateURL();
            const fullUrl = `${url}?${sp.toString()}`;
            webSocket = new WebSocket(fullUrl);

            // Event listener for when the connection is opened
            webSocket.addEventListener('open', (event) => {
                console.log('WebSocket connection opened:', event);
                if (selectedFiles) {
                    webSocket.send(JSON.stringify({ 'images': [base64Data] }));
                }
            });
            setInterval(() => {
                window.renderMathInElement(document.body, {
                    delimiters: [
                        { left: "$$", right: "$$", display: true },
                        { left: "$", right: "$", display: false },
                        { left: "\\(", right: "\\)", display: false },
                        { left: "\\[", right: "\\]", display: true }
                    ]
                });
            }, 10);
            // Event listener for when a message is received from the server
            webSocket.addEventListener('message', (event) => {
                setLoading(false);
                // Handle the received message here
                const data = JSON.parse(event.data);
                const numberOfImages = data[0]?.noofimages;
                if (data[0] && numberOfImages) {
                    setImageFlag(numberOfImages);
                }
                if (data[0] && data[0].text) {
                    setEnglishAnswer(data[0].text);
                    // setContent(prevContent => prevContent + data[0].text);
                    if (translate) {
                        setCurrentAnswer(translatedText);
                    } else {
                        setCurrentAnswer(data[0].text);
                    }

                    /** @type {string} */
                    let text = data[0].text;
                    let images = data[0].images ?? [];
                    setImageFlag(null);
                    while (text.indexOf('\0') !== -1) {
                        const start = text.indexOf('data:image/');
                        const end = text.indexOf('\0\0');
                        if (start !== -1 && end !== -1) {
                            images.push(text.substring(start, end + 1));
                            text = text.substring(0, start) + text.substring(end + 2);
                        }
                    }
                    data[0].images = images;
                    data[0].text = text;
                }
                setTimeout(() => setMessages((messages) => {
                    let m;
                    if (!(data[0] && data[0].text)) return messages;
                    if (!first) {
                        m = messages.slice(0, -1);
                    } else {
                        first = false;
                        m = messages;
                    }
                    const msg = { text: data[0].text, fromUser: false, images: data[0].images ?? [] }
                    return [...m, msg];
                }), 250);
            });

            // Event listener for when the connection is closed
            webSocket.addEventListener('close', (event) => {
                console.log('WebSocket connection closed:', event);
                setDisableTextArea(true);
                setTranslatedText(null);
                setTranslate(true);
                setTranslationLanguage('hi');
            });

            // Clean up the WebSocket connection when the component is unmounted
            return () => {
                webSocket.close();
            };

        }
    };


    function getSubjectOptions(grade) {
        return (tutorSubject.filter(subject => (tutorGrade.find(item => item.code === grade).subjects).includes(subject.code)));

    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFiles(file);

        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
            setBase64Data(base64String);
        };
        reader.readAsDataURL(file);
    };

    const ImageUpload = ({ accept, onChange, multiple }) => {
        const fileInputRef = useRef(null);
        return (
            <div>
                <div className="inline-flex">
                    <button
                        type="button"
                        className="p-2 bg-white border-2 border-blue font-bold text-lg text-blue rounded-lg hover:bg-light-purple"
                        onClick={() => fileInputRef.current.click()}
                    >
                        <img
                            src={cloud}
                            alt=""
                            width={"100px"}
                            height={"100px"}
                            className="text-blue"
                        />
                    </button>
                    <input
                        type="file"
                        name="file_upload"
                        className="hidden inset-0 opacity-0 cursor-pointer"
                        ref={fileInputRef}
                        accept={accept}
                        onChange={onChange}
                        multiple={multiple}
                    />
                </div>
            </div>
        );
    };



    const [listening, setListening] = useState(false);
    const [transcription, setTranscription] = useState('');
    const handleStartListening = () => {
        setVoiceInput(true);
        const recognition = new window.webkitSpeechRecognition();
        // recognition.lang = 'en-US';
        if (selectedSubject == 'English') {
            recognition.lang = 'en-US';
        }
        else if (selectedSubject == 'Hindi') {
            recognition.lang = 'hi-IN';
        }
        recognition.start();

        recognition.onstart = () => {
            setListening(true);
        };

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            console.log(transcript);
            setModalIsOpen(true);
            latestTranscriptRef.current = transcript;

        };

        recognition.onend = () => {
            setListening(false);
            closeModal();
            setTimeout(function () {
                const transcript = latestTranscriptRef.current;
                setCurrentQuestion(transcript);
                handleSendMessageSpeech(transcript);
            }, 1000);
        };

        recognition.onerror = (event) => {
            console.error('Speech recognition error detected: ', event.error);
            setListening(false);
        };
    };

    const openDialog = () => {
        setModalIsOpen(true);
        handleStartListening();

    };
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const closeModal = () => {
        setModalIsOpen(false);
        // setDisplayQuestionInput(false);
        // console.log(currentQuestion);
        // handleSendMessage(currentQuestion, stream);
    };

    const [selectedText, setSelectedText] = useState('');
    const [buttonVisible, setButtonVisible] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        const handleSelection = () => {

            // console.log()
            if (document.getSelection().focusNode && document.getSelection().focusNode.parentElement.closest('div')?.classList.contains('bot-response')) {
                const text = document.getSelection().toString();
                setSelectedText(text);

                // Calculate position based on the selection
                const selection = window.getSelection();
                if (selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0).getBoundingClientRect();
                    console.log(range.top + window.scrollY + range.height / 2);
                    console.log(range.right + window.scrollX + 10);

                    setButtonPosition({
                        top: range.top + window.scrollY + range.height / 2, // Adjust position to be centered vertically
                        left: range.right + window.scrollX + 10, // Position the button slightly to the right of the selection
                    });
                    console.log(selection);
                    console.log(!!text);
                    setButtonVisible(!!text); // Show button only if text is selected
                }
            } else {
                setSelectedText('');
                setButtonVisible(false);
            }
        };

        document.addEventListener('mouseup', handleSelection);

        return () => {
            document.removeEventListener('mouseup', handleSelection);
        };
    }, []);

    const handleReadAloud = () => {
        if (selectedText) {

            window.speechSynthesis.cancel();
            if (!window.speechSynthesis) {
                console.error("Speech synthesis not supported.");
                return;
            }
            const utterance = new SpeechSynthesisUtterance(selectedText);






            const isHindi = /[\u0900-\u097F]/.test(selectedText); // Check for Hindi characters


            const voices = window.speechSynthesis.getVoices();
            const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
            let selectedVoice;

            if (isHindi) {
                utterance.lang = "hi-IN";
                const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
                selectedVoice = hindiVoices.find(voice => voice.name.includes("Microsoft Swara Online (Natural) - Hindi (India)")) || hindiVoices[0];
            } else {

                utterance.lang = "en-US";
                const englishVoices = voices.filter(voice => voice.lang === "en-US" && voice.gender === "male");
                selectedVoice = englishVoices.find(voice => voice.name.includes("Microsoft David Desktop - English (United States)")) || englishVoices[0];
            }

            if (selectedVoice) {
                utterance.voice = selectedVoice;
            } else {
                console.warn("No appropriate voice found, using default voice.");
            }








            window.speechSynthesis.speak(utterance);
        }
    };



    return (
        <div className="flex gap-4">
            {(buttonVisible) && (
                <div style={{ zIndex: 2, position: "fixed", top: buttonPosition.top, left: buttonPosition.left }} className="readSelected">
                    <Button
                        onClick={handleReadAloud}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
                            <path fill="currentColor" d="M10.044 1.498a.75.75 0 0 1 .958-.454c.214.09.001 0 .001 0h.001l.003.001l.005.003l.015.005a2 2 0 0 1 .19.08c.118.056.28.138.47.253c.379.23.876.596 1.37 1.143C14.057 3.636 15 5.44 15 8.249a.75.75 0 0 1-1.5 0c0-2.44-.807-3.885-1.556-4.715a4.7 4.7 0 0 0-1.036-.865a3 3 0 0 0-.401-.209l-.014-.005a.75.75 0 0 1-.45-.957M7.198 3.475a.75.75 0 0 0-1.395 0l-3.75 9.5a.75.75 0 0 0 1.395.55l.898-2.275h4.308l.898 2.275a.75.75 0 1 0 1.396-.55zm.864 6.275H4.938L6.5 5.793zm2.668-6.076a.75.75 0 0 0-.962 1.15l.006.006l.034.03q.049.045.139.136c.12.123.28.304.44.53c.327.463.613 1.063.613 1.724a.75.75 0 0 0 1.5 0c0-1.088-.464-1.989-.887-2.588a6 6 0 0 0-.853-.962l-.02-.017l-.006-.005l-.002-.002zm-.962 1.15l.001.002Z" />
                        </svg>
                    </Button></div>
            )}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal-dialog"
                overlayClassName="modal-overlay"
            >
                <div className="modal-content">
                    <h2 className="modal-title">Voice Search</h2>
                    {/* <p>Speak to search...</p> */}
                    {listening ? 'Listening...' : ' '}
                    {currentQuestion}
                </div>
                <button className="modal-close-btn" onClick={closeModal}>
                    Close
                </button>
            </Modal>
            <div className="secondcardWidth">
                <FullCard>
                    <div className="">
                        <div className="flex justify-between items-center mt-2">
                            <div className="subheading text-center">
                                Debate Bot
                            </div>
                            <button onClick={refreshSender} className="flex items-center mx-2">
                                <MessageSquarePlus className="mr-2" size={20} />
                            </button>
                            <button type="button" className="text-blue-500" onClick={handleInfo} aria-label="Info">
                                <BadgeHelp className="mr-2" size={20} />
                            </button>
                        </div>
                        {/* ToastContainer will show notifications */}
                        <ToastContainer />

                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Debate Topic</div>
                            <div className="w-full text-sm">
                                <textarea
                                    className={`w-full border border-[#23479F] rounded-lg p-2 ${showStart !== '1'
                                            ? 'bg-gray-100 cursor-not-allowed opacity-50'
                                            : 'bg-white'
                                        }`}
                                    placeholder="Give topic for debate."
                                    value={concept}
                                    onChange={(e) => setConcept(e.target.value)}
                                    rows={1}
                                    disabled={showStart !== '1'}
                                />
                            </div>
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Grade</div>
                            <Select dropdownData={tutorGrade}
                                value={selectedGrade === '13' ? 'Btech 1st year' : selectedGrade}
                                onChange={(e) => handleClassChange(e.target.value)}
                                disabled={showStart !== '1'}
                            />
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Number of Agents</div>
                            <Select dropdownData={noAgents}
                                value={selectedNoAgents}
                                onChange={(e) => handleNoAgentsChange(e.target.value)}
                                disabled={showStart !== '1'}
                            />
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Selected Agents:</div>
                            <ul>
                                {selectedAgents.length > 0 ? (
                                    selectedAgents.map((agent) => (
                                        <p key={agent.id}>{agent.profile_name} - {agent.persona}</p>
                                    ))
                                ) : (
                                    <p>No Agents selected.</p>
                                )}
                            </ul>
                        </div>
                        {/* shown at the start */}
                        {showStart == '1' && (<>
                            {concept && (<>
                                <div className="my-8">
                                    <div className="flex justify-center my-8">
                                        <Button
                                            onClick={() => {
                                                handleStart();
                                            }}>Initialize Debate</Button>
                                    </div>
                                </div>
                            </>)}
                        </>)}
                        {/* Only shown once the start button is clicked and not shown after the debate has started*/}
                        {showStart == '2' && (<>
                            <div className="my-8">
                                {selectedAgents.length > 0 && (
                                    <div className={"flex justify-center my-8"}>
                                        <Button
                                            className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                            onClick={() => {
                                                setEditing(true);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="my-8">
                                <div className="flex justify-center my-8">
                                    <Button onClick={() => {
                                        handleSendMessageFirst();
                                    }}>Start Debate</Button>
                                </div>
                            </div>
                        </>)}
                        {/* Once the use wants to conclude the debate as well as get the conclusion */}
                        {showStart == '3' && (<>
                            <div className="my-8">
                                <div className="flex justify-center my-8">
                                    <Button onClick={() => {
                                        handleSendMessageFinish();
                                    }}>Conclude</Button>
                                </div>
                            </div>
                        </>)}
                        {/* {showStart == '4' && (<>
                            <div className="my-8">
                                <div className="flex justify-center my-8">
                                    <Button onClick={() => {
                                        clearAllVariables();
                                    }}>Refresh</Button>
                                </div>
                            </div>
                        </>)} */}
                    </div>
                </FullCard>
            </div>
            <div className="thirdcardWidth">
                <div className="chat-container">

                    <div className={`overflow-auto ${selectedRole === 'student' ? "h-[78vh]" : "h-[78vh]"}`}
                        onScroll={handleScroll}
                        id="chat-box">
                        {/* Judge Section */}
                        {showJudge && (
                            <div className="mb-4">
                                <div className="chat-message bg-white rounded-lg p-4 relative">
                                    <div className="m-5">
                                        <div className="flex">
                                            <div className="w-1/12">
                                                <b>Judge:</b>
                                            </div>
                                            <div className="w-11/12 ml-4 bg-white rounded-lg text-xl">
                                                <div className="w-full p-2">
                                                    {judge}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {editing && (
                            <div>
                                <Draggable>
                                    <div className="z-50 absolute flex flex-col justify-between items-center inset-0 m-auto w-1/2 h-fit bg-white rounded-md shadow-lg border-2 p-4 overflow-hidden">
                                        {/* Table for agents with personas */}
                                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th style={tableHeaderStyle}>Agent ID</th>
                                                    <th style={tableHeaderStyle}>Profile Name</th>
                                                    <th style={tableHeaderStyle}>Persona</th>
                                                    <th style={tableHeaderStyle}>Replace Persona</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedAgents.map((agent) => (
                                                    <tr key={agent.id}>
                                                        <td style={tableCellStyle}>{agent.id.charAt(0).toUpperCase() + agent.id.slice(1)}</td>
                                                        <td style={tableCellStyle}>{agent.profile_name}</td>
                                                        <td style={tableCellStyle}>{agent.persona}</td>
                                                        <td style={tableCellStyle}>
                                                            {/* Dropdown to select a new persona */}
                                                            <select
                                                                value={tempAgentChanges[agent.id]?.persona || ''}
                                                                onChange={(e) => {
                                                                    const newPersona = selectedPersonas.find(
                                                                        (persona) => persona.persona === e.target.value
                                                                    )
                                                                    handlePersonaChange(agent.id, newPersona || { persona: e.target.value })
                                                                }}
                                                            >
                                                                <option value="">Select a Persona</option>
                                                                {selectedPersonas.map((persona) => (
                                                                    <option key={persona.persona} value={persona.persona}>
                                                                        {persona.persona}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="flex gap-4 mt-4">
                                            <Button
                                                onClick={() => {
                                                    handleUpdateAgents();
                                                }}
                                            >
                                                Update Agents
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setEditing(false);
                                                    setTempAgentChanges({});
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </Draggable>
                            </div>
                        )}

                        {messages.map((message, idx) => {
                            // Check if the message belongs to user 'a' or 'b'
                            // const isUserA = message.userGroup === 'a';
                            // const isUserB = message.userGroup === 'b';

                            return (
                                <div key={message.id} ref={latestAnswerRef} id={`answer-${message.id}`} className={`mb-4 ${message.id === selectedQuestionId ? "bg-gray-200" : ""} `}>
                                    <div className="chat-message bg-white rounded-lg p-4 relative">
                                        <div className="m-5">
                                            <div className="flex">
                                                <div className="w-1/12 mt-2 mr-2 bg-white">
                                                    <b>{message.user ? message.user : "Agent"}:</b>
                                                </div>
                                                <div
                                                    className={`w-11/12 ml-4 bg-white rounded-lg text-xl ${message.id === selectedQuestionId ? "font-medium text-indigo-800" : ""}`}>
                                                    <div className="w-full p-2">
                                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.text) }}
                                                        ></div>
                                                        {idx >= messages.length - 2}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Render messages for group 'b' */}
                                            {/* {isUserA && messages
                                                .filter(m => m.userGroup === 'b' && m.pairId === message.pairId)
                                                .map(b => (
                                                    <div key={b.id}>
                                                        <div className="flex">
                                                            <div className="w-1/12">
                                                                <b>{b.user}:</b>
                                                            </div>
                                                            <div className="w-11/12 ml-4 bg-white rounded-lg text-xl">
                                                                {translatedText && translate && idx >= messages.length - 2 ?
                                                                    <div id={`bot-response${idx}`}
                                                                        className="w-full p-2 bot-response translatedResponse"
                                                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(translatedText) }}
                                                                    ></div> :
                                                                    <div id={`bot-response${idx}`}
                                                                        className="w-full p-2 bot-response"
                                                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(b.text) }}
                                                                    ></div>}

                                                                <Card>
                                                                    <div className="grid grid-cols-3 gap-4">
                                                                        {b.images && b.images.map((src, index) => (
                                                                            <div key={index}>
                                                                                <div id="page-container">
                                                                                    <img src={src}
                                                                                        alt={`generate_image_${index}`}
                                                                                        className="w-full h-auto" />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            } */}
                                        </div>
                                    </div>
                                    {imageFlag && idx >= messages.length - 2 &&
                                        <ImageSpinner size='lg' />
                                    }
                                </div>
                            );
                        })}

                    </div>
                    <div className="fixed bottom-0 thirdCard bg-[#e5eeff]">
                        <div className="mr-10 ml-4 my-4">
                            {selectedFiles &&
                                <>
                                    <div className="relative py-[8px]">
                                        <span className="rounded-xl bg-white p-[5px]">
                                            {`Uploaded: ${selectedFiles.name}`}</span>
                                        <button onClick={() => { setSelectedFiles(null); }}
                                            className="bg-[#23479F] hover:bg-[#544AC0] px-1 py-0.5 tracking-tight leading-4 rounded-lg text-sm text-white font-bold font-heading absolute top-0">
                                            x
                                        </button>
                                    </div>
                                </>
                            }
                            <div className="flex">
                                {showStart == '3' && (<>
                                    <div className="w-full text-lg">
                                        <textarea
                                            className="w-full border border-[#23479F] rounded-lg p-2"
                                            placeholder="Make your case..."
                                            value={currentQuestion}
                                            onChange={(e) => {
                                                setCurrentQuestion(e.target.value);
                                                setNewMessage(e.target.value);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault();
                                                    handleSendMessage();
                                                }
                                            }}
                                            rows={1}
                                            disabled={!disableTextArea}
                                        />
                                        <div className="">
                                            {errorMessage && (
                                                <div className="text-red-600">
                                                    {errorMessage}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className=" ml-4">
                                        <div className="flex gap-[10px]">
                                            {selectedRole === 'teacher' && selectedTeacherMethod === 'Brainstorming' &&
                                                <ImageUpload
                                                    accept={"application/jpg"}
                                                    onChange={handleFileChange}
                                                />
                                            }
                                            <Button
                                                onClick={() => {
                                                    if (currentQuestion.trim() === '') {
                                                        toast.error(`Text cannot be empty.`, {
                                                            position: "bottom-right",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            theme: "colored",
                                                            progress: undefined,
                                                        });
                                                        return;
                                                    }
                                                    setDisplayQuestionInput(false);
                                                    handleSendMessage();
                                                }}
                                                disabled={!disableTextArea}
                                            >
                                                Ask
                                            </Button>
                                            {/* 
                                            <Button
                                                onClick={() => {
                                                    if (!listening) {
                                                        openDialog();
                                                    }
                                                }}
                                                disabled={listening}
                                            >
                                                {listening ? 'Listening...' : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M12 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3m7 9c0 3.53-2.61 6.44-6 6.93V21h-2v-3.07c-3.39-.49-6-3.4-6-6.93h2a5 5 0 0 0 5 5a5 5 0 0 0 5-5z" />
                                                    </svg>
                                                )}
                                            </Button> */}
                                        </div>
                                    </div>
                                </>)}
                            </div>



                        </div>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="overlay">
                    <Spinner />
                </div>
            )}
        </div>
    )
}

export default DebateBot;
