import React, { useState } from 'react';
import { Button } from './CommonComponents';
import { Link } from 'react-router-dom';
import Spinner from './spinner';
import Logo from "../assets/logo.svg";
import signin from '../Thumbnails/Signin.png'

const AuthForm = ({ fields, buttonText, onSubmit, title, loading }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="flex h-screen relative">
      {/* Logo - positioned top right */}
      <div className="absolute top-5 left-5 z-10">
        <img
          src={Logo}
          alt="Logo of krtrimaIQ Cognitive Solutions"
          width={200}
        />
      </div>

      {/* Left column - Form */}
      <div className="w-1/2 flex flex-col items-center justify-center px-16 bg-white">
        <div className="w-full max-w-md">
          {/* Card with border and shadow */}
          <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-200">
            {/* Login Title with Divider */}
            <h2 className="text-2xl font-semibold mb-4 text-start text-purple pb-4 border-b border-gray-200">
              {title}
            </h2>

            <form onSubmit={handleSubmit} className="mt-6">
              {fields.map((field) => (
                <input
                  key={field.name}
                  type={field.type}
                  name={field.name}
                  placeholder={field.title}
                  value={formData[field.name] || ''}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring focus:ring-indigo-200"
                />
              ))}

              <div className="flex justify-center">
                <Button size="medium" color="light-purple" type="submit">
                  {loading ? <Spinner size='sm' colour='white' /> : buttonText}
                </Button>
              </div>

              <div
                className={`${buttonText === "Login" ? "flex" : "hidden"} text-center pt-4`}
              >
                <div className="flex justify-between mx-auto">
                  <Link to="/forgot-password">
                    <span
                      className="text-purple hover:text-almost-purple cursor-pointer px-2"
                    >
                      Forgot Password?
                    </span>
                  </Link>
                  <Link to="/signup">
                    <span
                      className="text-purple hover:text-almost-purple cursor-pointer px-2"
                    >
                      Sign Up
                    </span>
                  </Link>
                </div>
              </div>

              <div
                className={`${buttonText === "Signup" ? "flex justify-center" : "hidden"} text-center pt-4`}
              >
                <Link to="/login">
                  <span
                    className="text-purple hover:text-almost-purple cursor-pointer px-1"
                  >
                    Already have an account?
                  </span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Right column - Background Image */}
      <div className="w-1/2 bg-cover bg-center">
        <img
          src={signin}
          alt="Sign In Illustration"
          className="w-full h-full object-cover"
          style={{ objectPosition: 'center' }}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default AuthForm;