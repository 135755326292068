// MultiSelectDropdown.js
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const MultiSelectDropdown = ({ options, selectedOptions, onChange, placeholder, labelAboveOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionToggle = (option) => {
    const updatedOptions = selectedOptions.includes(option.value)
      ? selectedOptions.filter((selectedOption) => selectedOption !== option.value)
      : [...selectedOptions, option.value];

    onChange(updatedOptions);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative text-left" ref={dropdownRef}>
      <div>
        <span className="rounded-md shadow-sm">
          <button
            type="button"
            onClick={handleToggle}
            className="inline-flex justify-between w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded={isOpen}
          >
            <span>
              {selectedOptions.length > 0
                ? `${selectedOptions.length} selected`
                : placeholder}
            </span>
            <svg
              className={`h-5 w-5 ${isOpen ? 'transform rotate-180' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
        </span>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-full overflow-y-auto h-[10rem] rounded-md shadow-lg z-50">
          <div className="rounded-md bg-white shadow-xs">
            <div className="py-1">
              {options.map((option) => (
                <div key={option.value} className="flex items-center justify-between px-4 py-2">
                  <div>
                    {option.additionalLabel && (
                      <div className="text-sm font-bold text-black mb-3">
                        {option.additionalLabel}
                      </div>
                    )}
                    <label className="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={selectedOptions.includes(option.value)}
                        onChange={() => handleOptionToggle(option)}
                      />
                      <span className="ml-2 text-sm leading-5">{option.label}</span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MultiSelectDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired, // Added placeholder prop
};

export default MultiSelectDropdown;