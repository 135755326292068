import React, { useRef } from "react";
import cloud from "../assets/cloud.svg";
import grayCloud from "../assets/gray_cloud.svg";
import Image from "../assets/image.svg";
import questionIcon from "../assets/questionIcon.svg";
import { IoMdInformationCircle } from "react-icons/io";
import { BiImageAdd } from "react-icons/bi";

export const FileUpload = ({ accept, onChange, multiple, fileFormat = "PDF" }) => {
    return (
        <div>
            <label className="flex justify-center w-full h-50 px-4 transition bg-slate-100 border-2 border-slate-400 border-dashed rounded-md appearance-none cursor-pointer hover:border-indigo-400 focus:outline-none">
                <div className="items-center my-4">
                    <div className="flex justify-center space-x-2">
                        <img src={grayCloud} alt="cloud" />
                    </div>
                    <div className="font-medium text-purple flex justify-center mt-1">
                        <div className="text-base text-gray-800">
                            Drag & drop your files here or
                            <span className="text-orange-600 underline ml-1">Choose file</span>
                        </div>
                    </div>
                    <div className="text-sm text-gray-600 flex justify-center">
                        Supported formats : {fileFormat} - 500MB max file size
                    </div>
                </div>
                <input
                    type="file"
                    name="file_upload"
                    className="hidden"
                    accept={accept}
                    onChange={onChange}
                    multiple={multiple}
                />
            </label>
        </div>
    );
};

export const DragAndDrop = () => {
    return (
        <div>
            <label className="flex justify-center w-full h-50 px-4 transition bg-light-purple border-2 border-[#766AFF] border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                <div className="items-center my-4">
                    <div className="flex justify-center space-x-2">
                        <img src={cloud} alt="cloud" />
                    </div>
                    <div className="font-medium text-purple flex justify-center">
                        Drag N Drop Your Files
                    </div>
                </div>
            </label>
        </div>
    );
};

export const TexttoImageConversion = () => {
    return (
        <div>
            <div className="bg-[#23479F] text-white rounded-lg p-6">
                <div className="text-xl mb-4">Refer To This Example:-</div>
                <p className="text-base pr-10">
                    Produce a book cover suitable for Indian children. The central scene should depict a diverse group of school children walking in park under the guidance of their female teacher. The teacher should be enthusiastically explaining the landscape surrounding them. The student's engaged and curious faces should be realistically presented, capturing the lively and enriching moment. The background should be dominated by lush maple trees. The entire scene should radiate a sense of learning, exploration, and the beauty of nature.
                </p>
            </div>
            <label className="flex justify-center ">
                <div className="items-center my-10">
                    <div className="flex justify-center">
                        <img src={Image} alt="image" />
                    </div>
                    <div className="text-xl text-purple flex justify-center">
                        Your generated image will show here
                    </div>
                </div>
            </label>
        </div>
    );
};

export const BrowseComputer = ({ accept, onChange, multiple }) => {
    const fileInputRef = useRef(null);
    return (
        <div>
            <div className="inline-flex">
                <button
                    type="button"
                    className="p-4 bg-white border-4 border-purple font-bold text-xl text-purple rounded-lg hover:bg-light-purple"
                    onClick={() => fileInputRef.current.click()}
                >
                    Browse Your Computer
                </button>
                <input
                    type="file"
                    name="file_upload"
                    className="hidden inset-0 opacity-0 cursor-pointer"
                    ref={fileInputRef}
                    accept={accept}
                    onChange={onChange}
                    multiple={multiple}
                />
            </div>
        </div>
    );
};

export const FileUploadMms = ({ accept, onChange, multiple }) => {
    return (
        <div className="relative">
            
            <div className="absolute top-2 left-2 group">
    <IoMdInformationCircle size={24} className="text-gray-600 cursor-pointer" />
    <span className="absolute left-8 top-0 w-max opacity-0 group-hover:opacity-100 bg-black text-white text-xs rounded-md p-2 transition-opacity z-10">
        Supported formats: JPEG, PNG, PDF, Word, Excel, PPT, TXT, MP4, MP3, WAV, HTML, ZIP
    </span>
</div>


            
            <label className="flex justify-center w-full h-55 px-8 transition bg-slate-100 border-2 border-slate-400 border-dashed rounded-md appearance-none cursor-pointer hover:border-indigo-400 focus:outline-none">
                <div className="items-center my-12">
                    <div className="flex justify-center space-x-2">
                        <img src={grayCloud} alt="cloud" />
                    </div>
                    <div className="font-medium text-purple flex justify-center mt-4">
                        <div className="text-base text-gray-800">
                            Drag & drop your files here or
                            <span className="text-orange-600 underline ml-1"> Choose file</span>
                        </div>
                    </div>
                    <div className="text-sm text-gray-600 flex justify-center">
                        500MB max file size
                    </div>
                </div>
                <input
                    type="file"
                    name="file_upload"
                    className="hidden"
                    accept={accept}
                    onChange={onChange}
                    multiple={multiple}
                />
            </label>
        </div>
    );
};

export const FileUploadSearch = ({ accept, onChange, multiple }) => {
    const handleClick = () => {
        document.getElementById("file-upload-input").click();
    };

    return (
        <div className="relative">
            <BiImageAdd
                className="absolute top-4 left-3 text-blue-600 text-xl cursor-pointer" 
                onClick={handleClick} 
            />
            <input
                type="file"
                id="file-upload-input"
                name="file_upload"
                className="hidden"
                accept={accept}
                onChange={onChange}
                multiple={multiple}
            />
        </div>
    );
};
