import React, { useEffect, useRef, useState } from "react";
import { Button, Card, FullCard, Select, Spinner } from "../components/CommonComponents";
import {
    tutorGrade,
    tutorRoles,
    tutorSubject,
    tutorTopic,
    tutorTeacherMethods,
    tutorBoard,
    translationLanguages
} from "../components/Mapping";
import Modal from 'react-modal';
import questionIcon from "../assets/questionIcon.svg";
import cloud from "../assets/cloud.svg";
import ansIcon from "../assets/ansIcon.svg";
import DOMPurify from 'dompurify'
import { nanoid } from "nanoid";
import { pairwise } from "../utils/hacks";
import ImageSpinner from "../components/spinner";
import SimpleDropdown from "../components/SimpleDropdown";
import { languageTranslationURL, llamaTestURL } from "../api/serverAPI";
import { ImageUpload } from "../components/Fileupload";
import { select } from "react-cookies";
import axios from 'axios';
import { MathKeyboard } from "../components/mathKeyboard";
import Draggable from "react-draggable";

// console.log("ngoninittttttttttttt");
// window.speechSynthesis.cancel();
/**
 * BAse-64 encoding with a URL-safe charset
 * @param {string} s the string to encode
 * @returns {string} the encoded string
 */
function b64_urlsafe(s) {
    return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace('=', '')
}

/**
 * A random string of n **bytes**.
 *
 * ..note::
 *    not all characters are a single byte. intermediate steps may contain fewer characters
 *    if String.fromCharCode returns a multi-byte sequence. This is normalized by base64.
 * @param n The number of bytes, defaults to 16
 * @returns {string} The randomly generated string.
 */
function randomString(n = 16) {
    return b64_urlsafe(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(n))))
}

// eslint-disable-next-line no-control-regex
const pattern = new RegExp('data:image/[^/]+;base64,[^\0]+\0\0');
const sender = randomString();

const Llama = () => {


    const [selectedRole, setSelectedRole] = useState('teacher');
    const [selectedBoard, setSelectedBoard] = useState('CBSE');
    const [endpoint, setEndpoint] = useState('faq');
    const [selectedGrade, setSelectedGrade] = useState('8');
    const [selectedSubject, setSelectedSubject] = useState('English');
    const [selectedTopic, setSelectedTopic] = useState('The Best Christmas Present in the World & The Ant and the Cricket');
    const [selectedTeacherMethod, setSelectedTeacherMethod] = useState('faq');
    const [messages, setMessages] = useState([]);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [displayQuestionInput, setDisplayQuestionInput] = useState(true);
    const [voiceInput, setVoiceInput] = useState(false);

    const latestAnswerRef = useRef(null);
    const [disableTextArea, setDisableTextArea] = useState(true);
    const [imageFlag, setImageFlag] = useState(null);
    const [translationLanguage, setTranslationLanguage] = useState('hi')
    const [translatedText, setTranslatedText] = useState(null);
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const [englishAnswer, setEnglishAnswer] = useState(null);
    const [translate, setTranslate] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [base64Data, setBase64Data] = useState(null);
    const [stream, setStream] = useState(true);
    const [autoScroll, setAutoScroll] = useState(true);
    const latestTranscriptRef = useRef('');
    const [showKeyboard, setShowKeyboard] = useState(false);
    const handleTopicChange = (topic) => {
        setMessages([]);
        if (!disableTextArea) {
            setStream(false);
        }
        setSelectedTopic(topic);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    }

    useEffect(() => {
        window?.speechSynthesis?.cancel();
    }, [])

    useEffect(() => {
        const classname = document.getElementsByClassName('bot-response')
        if (classname[classname.length - 1] !== undefined) {
            //  console.log("--- ", classname[classname.length-1].id);
            const id = parseInt(classname[classname.length - 1].id.replace("bot-response", ''));
            // console.log(id);
            setTimeout(() => {
                if (voiceInput == true) {
                    // readAloud(id + 1, null);

                }
            }, 2000);

        }


    }, [document.getElementsByClassName('bot-response').length])

    const handleSubjectChange = (subject) => {
        setMessages([]);
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[selectedGrade][subject][0].text);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    };

    const handleTranslationLanguageSelect = (option) => {
        setTranslationLanguage(option.value);
    };

    const handleSymbolClick = (symbol) => {
        // Append the clicked symbol to the current textarea value
        setCurrentQuestion((prevQuestion) => prevQuestion + symbol);
    };


    // Function to restore <img> tags back to their original positions
    function restoreImages(htmlContent, imgTags) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const divs = doc.querySelectorAll('.imageDiv');

        divs.forEach((div, index) => {
            if (div.innerHTML === '' && imgTags[index]) {
                const img = document.createElement('img');
                const parser = new DOMParser();
                const imgDoc = parser.parseFromString(imgTags[index], 'text/html');
                const imgElement = imgDoc.querySelector('img');
                img.src = imgElement.getAttribute('src');
                img.width = imgElement.getAttribute('width');
                img.height = imgElement.getAttribute('height');
                div.parentNode.insertBefore(img, div);
                div.remove();
            }
        });
        return doc.documentElement.outerHTML;
    }

    const handleLanguageTranslation = (id, msg) => {


        // Replace <img> tags with empty <div> tags and store the <img> tags
        const { modifiedHTML, imgTags } = replaceImagesWithDivsAndStore(msg);

        languageTranslationURL({
            text: modifiedHTML,
            target_lang: translationLanguage,
        }).then((response) => {
            // Restore <img> tags back to their original positions
            const restoredHTML = restoreImages(response.data['translated_text'], imgTags);
            setTranslatedText(restoredHTML);
            setMessages((m) => {
                if (m[id].fromUser) {
                    return m;
                }
                m[id].text = restoredHTML;
                return m;
            });
        }).catch(error => {
            alert("Can you please try again in sometime");
        });
    }

    const stopReadAloud = () => {


        window.speechSynthesis.cancel();

    };
    const readAloud = (id, msg) => {
        var idtsr = (parseInt(id) - 1).toString();
        var elem = document.getElementById('bot-response' + idtsr);

        window.speechSynthesis.cancel();
        if (!window.speechSynthesis) {
            console.error("Speech synthesis not supported.");
            return;
        }


        const textElement = elem.innerText.trim(); // Get the trimmed inner text
        console.log(textElement);

        if (textElement) {
            const words = textElement.split(/\s+/);


            let currentIndex = -1;
            // let currentWordIndex = -1;

            const utterance = new SpeechSynthesisUtterance(textElement);
            const isHindi = /[\u0900-\u097F]/.test(textElement); // Check for Hindi characters


            const voices = window.speechSynthesis.getVoices();
            const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
            let selectedVoice;

            if (isHindi) {
                utterance.lang = "hi-IN";
                const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
                selectedVoice = hindiVoices.find(voice => voice.name.includes("Microsoft Swara Online (Natural) - Hindi (India)")) || hindiVoices[0];
            } else {

                utterance.lang = "en-US";
                const englishVoices = voices.filter(voice => voice.lang === "en-US" && voice.gender === "male");
                selectedVoice = englishVoices.find(voice => voice.name.includes("Microsoft David Desktop - English (United States)")) || englishVoices[0];
            }

            if (selectedVoice) {
                utterance.voice = selectedVoice;
            } else {
                console.warn("No appropriate voice found, using default voice.");
            }

            utterance.addEventListener("boundary", (event) => {
                const { charIndex } = event;

                console.log(words[currentIndex])

                // highlightTheWord('bot-response' + idtsr, currentIndex);
                currentIndex++;


            });

            utterance.addEventListener("end", () => {

                console.log("Reading is completed");
            });

            window.speechSynthesis.speak(utterance);
        }
    };

    const handleClassChange = (classItem) => {
        setMessages([]);
        // XXX: find somethig better. i`m too tired.
        if (classItem.toLowerCase().startsWith(`btech`)) classItem = `13`;
        setSelectedGrade(classItem);
        const subject = getSubjectOptions(classItem)[0].text;
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[classItem][subject][0].text);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    };

    useEffect(() => {
        const container = document.getElementById("chat-box")
        if (autoScroll || loading) {
            container.scrollTo({
                behavior: "smooth",
                top: container.scrollHeight,
            });
        }
    },
        [messages, autoScroll, loading]
    )

    const handleScroll = () => {
        const container = document.getElementById("chat-box")
        if (container.scrollTop < container.scrollHeight - container.clientHeight - 50) {
            setAutoScroll(false);
        }
        else {
            setAutoScroll(true);
        }
    }

    const scrollToAnswer = (id) => {
        setSelectedQuestionId(id);
        const answerContainer = document.getElementById(`answer-${id}`);
        if (answerContainer) {
            answerContainer.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    function replaceImagesWithDivsAndStore(htmlContent) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const images = Array.from(doc.querySelectorAll('img'));

        const imgTags = [];

        images.forEach((img) => {
            const div = document.createElement('div');
            div.style.width = img.getAttribute('width') + 'px';
            div.style.height = img.getAttribute('height') + 'px';
            div.style.border = '1px solid black';
            div.className = 'imageDiv'
            imgTags.push(img.outerHTML);
            img.parentNode.replaceChild(div, img);
        });
        return { modifiedHTML: doc.documentElement.outerHTML, imgTags };
    }

    //sending data
    const handleSendMessage = async (message) => {
        setVoiceInput(false);
        console.log(message, currentQuestion);
        setDisableTextArea(false);
        setTranslate(false);
        const newMessages = [...messages, { text: message, fromUser: true, index: 0, id: nanoid(24) }];
        if (currentQuestion.trim() === "") {
            setErrorMessage("text cannot be empty");
            setLoading(false);
        } else {
            setCurrentQuestion('');
            setLoading(true);
            setErrorMessage("");
            setMessages(newMessages);
            let first = true;
            try {
                const payload = {
                    sender: sender,
                    grade: `class${selectedGrade}`,
                    subject: selectedSubject.toLowerCase(),
                    topic: selectedTopic,
                    message: message
                };
                const url = llamaTestURL();
                const response = await axios.post(url, payload);
                if (response.status === 200) {
                    setLoading(false);
                    console.log(response);
                    const data = response.data.response;
                    setEnglishAnswer(data);
                    if (translate) {
                        setCurrentAnswer(translatedText);
                    } else {
                        setCurrentAnswer(data);
                    }
                    while (data.indexOf('\0') !== -1) {
                        const start = data.indexOf('data:image/');
                        const end = data.indexOf('\0\0');
                        if (start !== -1 && end !== -1) {
                            data = data.substring(0, start) + data.substring(end + 2);
                        }
                    }
                    console.log(messages);
                    setTimeout(() => setMessages((messages) => {
                        let m;
                        if (!data) return messages;
                        if (!first) {
                            m = messages.slice(0, -1);
                        } else {
                            first = false;
                            m = messages;
                        }
                        const msg = { text: data, fromUser: false, images: data[0].images ?? [] }
                        return [...m, msg];
                    }), 250);
                }
            }
            catch (error) {
                console.error('An error occurred', error);
            }
            setDisableTextArea(true);
            setTranslatedText(null);
            setTranslate(true);
            setTranslationLanguage('hi');
        }
    };


    const handleSendMessageSpeech = async (message) => {
        console.log(message);
        setDisableTextArea(false);
        setTranslate(false);
        const newMessages = [...messages, { text: message, fromUser: true, index: 0, id: nanoid(24) }];
        if (message.trim() === "") {
            setErrorMessage("text cannot be empty");
            setLoading(false);
        } else {
            setCurrentQuestion('');
            setLoading(true);
            setErrorMessage("");
            setMessages(newMessages);
            let first = true;
            try {
                const payload = {
                    sender: sender,
                    grade: `class${selectedGrade}`,
                    subject: selectedSubject.toLowerCase(),
                    topic: selectedTopic,
                    message: message
                };
                const url = llamaTestURL();
                const response = await axios.post(url, payload);

                if (response.status === 200) {
                    setLoading(false);
                    console.log(response);
                    const data = response.data.response;
                    setEnglishAnswer(data);
                    if (translate) {
                        setCurrentAnswer(translatedText);
                    } else {
                        setCurrentAnswer(data);
                    }
                    while (data.indexOf('\0') !== -1) {
                        const start = data.indexOf('data:image/');
                        const end = data.indexOf('\0\0');
                        if (start !== -1 && end !== -1) {
                            data = data.substring(0, start) + data.substring(end + 2);
                        }
                    }
                    console.log(messages);
                    setTimeout(() => setMessages((messages) => {
                        let m;
                        if (!data) return messages;
                        if (!first) {
                            m = messages.slice(0, -1);
                        } else {
                            first = false;
                            m = messages;
                        }
                        const msg = { text: data, fromUser: false, images: data[0].images ?? [] }
                        return [...m, msg];
                    }), 250);
                }
            }
            catch (error) {
                console.error('An error occurred', error);
            }
            setDisableTextArea(true);
            setTranslatedText(null);
            setTranslate(true);
            setTranslationLanguage('hi');
        }
    };

    function getSubjectOptions(grade) {
        return (tutorSubject.filter(subject => (tutorGrade.find(item => item.code === grade).subjects).includes(subject.code)));

    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFiles(file);

        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
            setBase64Data(base64String);
        };
        reader.readAsDataURL(file);
    };

    const ImageUpload = ({ accept, onChange, multiple }) => {
        const fileInputRef = useRef(null);
        return (
            <div>
                <div className="inline-flex">
                    <button
                        type="button"
                        className="p-2 bg-white border-2 border-blue font-bold text-lg text-blue rounded-lg hover:bg-light-purple"
                        onClick={() => fileInputRef.current.click()}
                    >
                        <img
                            src={cloud}
                            alt=""
                            width={"100px"}
                            height={"100px"}
                            className="text-blue"
                        />
                    </button>
                    <input
                        type="file"
                        name="file_upload"
                        className="hidden inset-0 opacity-0 cursor-pointer"
                        ref={fileInputRef}
                        accept={accept}
                        onChange={onChange}
                        multiple={multiple}
                    />
                </div>
            </div>
        );
    };



    const [listening, setListening] = useState(false);
    const [transcription, setTranscription] = useState('');
    const handleStartListening = () => {
        setVoiceInput(true);
        const recognition = new window.webkitSpeechRecognition();
        // recognition.lang = 'en-US';
        if (selectedSubject == 'English') {
            recognition.lang = 'en-US';
        }
        else if (selectedSubject == 'Hindi') {
            recognition.lang = 'hi-IN';
        }
        recognition.start();

        recognition.onstart = () => {
            setListening(true);
        };

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            console.log(transcript);
            setModalIsOpen(true);
            latestTranscriptRef.current = transcript;

        };

        recognition.onend = () => {
            setListening(false);
            closeModal();
            setTimeout(function () {
                const transcript = latestTranscriptRef.current;
                setCurrentQuestion(transcript);
                handleSendMessageSpeech(transcript);
            }, 1000);
        };

        recognition.onerror = (event) => {
            console.error('Speech recognition error detected: ', event.error);
            setListening(false);
        };
    };

    const openDialog = () => {
        setModalIsOpen(true);
        handleStartListening();

    };
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const closeModal = () => {
        setModalIsOpen(false);
        // setDisplayQuestionInput(false);
        // console.log(currentQuestion);
        // handleSendMessage(currentQuestion, stream);
    };

    const [selectedText, setSelectedText] = useState('');
    const [buttonVisible, setButtonVisible] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        const handleSelection = () => {

            // console.log()
            if (document.getSelection().focusNode && document.getSelection().focusNode.parentElement.closest('div')?.classList.contains('bot-response')) {
                const text = document.getSelection().toString();
                setSelectedText(text);

                // Calculate position based on the selection
                const selection = window.getSelection();
                if (selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0).getBoundingClientRect();
                    console.log(range.top + window.scrollY + range.height / 2);
                    console.log(range.right + window.scrollX + 10);

                    setButtonPosition({
                        top: range.top + window.scrollY + range.height / 2, // Adjust position to be centered vertically
                        left: range.right + window.scrollX + 10, // Position the button slightly to the right of the selection
                    });
                    console.log(selection);
                    console.log(!!text);
                    setButtonVisible(!!text); // Show button only if text is selected
                }
            } else {
                setSelectedText('');
                setButtonVisible(false);
            }
        };

        document.addEventListener('mouseup', handleSelection);

        return () => {
            document.removeEventListener('mouseup', handleSelection);
        };
    }, []);

    const handleReadAloud = () => {
        if (selectedText) {
            console.log(selectedText);

            window.speechSynthesis.cancel();
            if (!window.speechSynthesis) {
                console.error("Speech synthesis not supported.");
                return;
            }
            const utterance = new SpeechSynthesisUtterance(selectedText);

            const isHindi = /[\u0900-\u097F]/.test(selectedText); // Check for Hindi characters


            const voices = window.speechSynthesis.getVoices();
            const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
            let selectedVoice;

            if (isHindi) {
                utterance.lang = "hi-IN";
                const hindiVoices = voices.filter(voice => voice.lang === "hi-IN");
                selectedVoice = hindiVoices.find(voice => voice.name.includes("Microsoft Swara Online (Natural) - Hindi (India)")) || hindiVoices[0];
            } else {

                utterance.lang = "en-US";
                const englishVoices = voices.filter(voice => voice.lang === "en-US" && voice.gender === "male");
                selectedVoice = englishVoices.find(voice => voice.name.includes("Microsoft David Desktop - English (United States)")) || englishVoices[0];
            }

            if (selectedVoice) {
                utterance.voice = selectedVoice;
            } else {
                console.warn("No appropriate voice found, using default voice.");
            }
            window.speechSynthesis.speak(utterance);
        }
    };
    return (
        <div className="flex gap-4">
            {(buttonVisible) && (
                <div style={{ zIndex: 2, position: "fixed", top: buttonPosition.top, left: buttonPosition.left }} className="readSelected">
                    <Button


                        onClick={handleReadAloud}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
                            <path fill="currentColor" d="M10.044 1.498a.75.75 0 0 1 .958-.454c.214.09.001 0 .001 0h.001l.003.001l.005.003l.015.005a2 2 0 0 1 .19.08c.118.056.28.138.47.253c.379.23.876.596 1.37 1.143C14.057 3.636 15 5.44 15 8.249a.75.75 0 0 1-1.5 0c0-2.44-.807-3.885-1.556-4.715a4.7 4.7 0 0 0-1.036-.865a3 3 0 0 0-.401-.209l-.014-.005a.75.75 0 0 1-.45-.957M7.198 3.475a.75.75 0 0 0-1.395 0l-3.75 9.5a.75.75 0 0 0 1.395.55l.898-2.275h4.308l.898 2.275a.75.75 0 1 0 1.396-.55zm.864 6.275H4.938L6.5 5.793zm2.668-6.076a.75.75 0 0 0-.962 1.15l.006.006l.034.03q.049.045.139.136c.12.123.28.304.44.53c.327.463.613 1.063.613 1.724a.75.75 0 0 0 1.5 0c0-1.088-.464-1.989-.887-2.588a6 6 0 0 0-.853-.962l-.02-.017l-.006-.005l-.002-.002zm-.962 1.15l.001.002Z" />
                        </svg>
                    </Button></div>
            )}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal-dialog"
                overlayClassName="modal-overlay"
            >
                <div className="modal-content">
                    <h2 className="modal-title">Voice Search</h2>
                    {/* <p>Speak to search...</p> */}
                    {listening ? 'Listening...' : ' '}
                    {currentQuestion}
                </div>
                <button className="modal-close-btn" onClick={closeModal}>
                    Close
                </button>
            </Modal>
            <div className="secondcardWidth">
                <FullCard>
                    <div className="">
                        <div className="subheading text-center mt-2">
                            Llama 3.O
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Grade</div>
                            <Select dropdownData={tutorGrade}
                                value={selectedGrade === '13' ? 'Btech 1st year' : selectedGrade}
                                onChange={(e) => handleClassChange(e.target.value)} />
                        </div>

                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Subject</div>
                            <Select dropdownData={getSubjectOptions(selectedGrade)} value={selectedSubject}
                                onChange={(e) => handleSubjectChange(e.target.value)}
                            />
                        </div>

                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Topics</div>
                            <Select dropdownData={tutorTopic[selectedGrade][selectedSubject] || []}
                                value={selectedTopic}
                                onChange={(e) => handleTopicChange(e.target.value)} />
                        </div>
                        {/* Display previous questions lists */}
                        <div className="my-8">
                            {messages.slice().reverse().map((entry, index) => (
                                <>
                                    {(index < 1) ? (
                                        <div className="my-3 text-lg font-medium text-indigo-800 text-left my-2">
                                            Previous Questions
                                        </div>
                                    ) : null}
                                    {entry?.fromUser &&
                                        <div
                                            key={entry.id}
                                            className={`border border-[#23479F] rounded-lg text-[#23479F] px-4 p-3 mb-3 ${entry.id === selectedQuestionId ? "bg-[#385EBA] text-white" : ""
                                                }`}
                                            onClick={() => scrollToAnswer(entry.id)}
                                        >
                                            <div className="line-clamp-1">
                                                {entry.text}
                                            </div>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    </div>
                </FullCard>
            </div>
            <div className="thirdcardWidth ">
                <div className="chat-container">
                    <div className={`overflow-auto ${selectedRole === 'student' ? "h-[80vh]" : "h-[80vh]"}`} // ${selectedRole === 'student' ? "h-[70vh]" : "h-[78vh]"}
                        onScroll={handleScroll}
                        id="chat-box">
                        {pairwise(messages, (a, b, idx) => {
                            return <div
                                key={a.id}
                                ref={latestAnswerRef}
                                className={`mb-4 ${a.id === selectedQuestionId ? "bg-gray-200" : ""} `}
                                id={`answer-${a.id}`}
                            >
                                <Card>
                                    <div className="chat-message relative">
                                        <div
                                            className="m-5">
                                            <div className="flex">
                                                <div className="w-1/12">
                                                    <img
                                                        src={questionIcon}
                                                        alt=""
                                                        width={"50px"}
                                                        height={"50px"}
                                                        className=""
                                                    />
                                                </div>
                                                <div
                                                    className={` w-11/12 ml-4 bg-white rounded-lg text-xl mb-4 ${b?.id === selectedQuestionId ? "font-medium text-indigo-800" : ""}`}>

                                                    <div className="w-full p-2">
                                                        {a.text} {idx >= messages.length - 2 && (
                                                            <div className="readAloud flex flex-row gap-3">
                                                                <div className="readAloud1 flex flex-row gap-3">
                                                                    <Button
                                                                        onClick={() => {
                                                                            readAloud(idx + 1, englishAnswer);
                                                                        }}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
                                                                            <path fill="currentColor" d="M10.044 1.498a.75.75 0 0 1 .958-.454c.214.09.001 0 .001 0h.001l.003.001l.005.003l.015.005a2 2 0 0 1 .19.08c.118.056.28.138.47.253c.379.23.876.596 1.37 1.143C14.057 3.636 15 5.44 15 8.249a.75.75 0 0 1-1.5 0c0-2.44-.807-3.885-1.556-4.715a4.7 4.7 0 0 0-1.036-.865a3 3 0 0 0-.401-.209l-.014-.005a.75.75 0 0 1-.45-.957M7.198 3.475a.75.75 0 0 0-1.395 0l-3.75 9.5a.75.75 0 0 0 1.395.55l.898-2.275h4.308l.898 2.275a.75.75 0 1 0 1.396-.55zm.864 6.275H4.938L6.5 5.793zm2.668-6.076a.75.75 0 0 0-.962 1.15l.006.006l.034.03q.049.045.139.136c.12.123.28.304.44.53c.327.463.613 1.063.613 1.724a.75.75 0 0 0 1.5 0c0-1.088-.464-1.989-.887-2.588a6 6 0 0 0-.853-.962l-.02-.017l-.006-.005l-.002-.002zm-.962 1.15l.001.002Z" />
                                                                        </svg>
                                                                    </Button>
                                                                    <Button
                                                                        onClick={() => {
                                                                            stopReadAloud();
                                                                        }}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                                            <path fill="currentColor" d="M7 17V7h10v10z" />
                                                                        </svg>
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>

                                            {b && <div>
                                                <div className="flex">
                                                    <div className="w-1/12">
                                                        <img
                                                            src={ansIcon}
                                                            alt=""
                                                            width={"50px"}
                                                            height={"50px"}
                                                            className=""
                                                        />
                                                    </div>
                                                    <div className="w-11/12 ml-4 bg-white rounded-lg text-xl">
                                                        {translatedText && translate && idx >= messages.length - 2 ?
                                                            <div id={`bot-response${idx}`}
                                                                className="w-full p-2 bot-response translatedResponse"
                                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(translatedText) }}
                                                            ></div> :
                                                            <div id={`bot-response${idx}`}
                                                                className="w-full p-2 bot-response"
                                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(b.text) }}
                                                            ></div>}

                                                        {translate && idx >= messages.length - 2 &&
                                                            <div className="grid grid-cols-4 gap-4">
                                                                <SimpleDropdown
                                                                    defaultValue={translationLanguages[0]}
                                                                    onSelect={handleTranslationLanguageSelect}
                                                                    options={translationLanguages}
                                                                />
                                                                <Button
                                                                    onClick={() => {
                                                                        handleLanguageTranslation(idx + 1, englishAnswer);
                                                                    }}
                                                                >Translate</Button>
                                                            </div>
                                                        }

                                                        <Card>
                                                            <div className="grid grid-cols-3 gap-4">
                                                                {b.images && b.images.map((src, index) => (
                                                                    <div key={index}>
                                                                        <div id="page-container">
                                                                            <img src={src}
                                                                                alt={`generate_image_${index}`}
                                                                                className="w-full h-auto" />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        {imageFlag && idx >= messages.length - 2 &&
                                            <ImageSpinner
                                                size='lg'
                                            />
                                        }
                                    </div>
                                </Card>
                            </div>
                        }
                        ).map(component => component)}
                    </div>
                    <div className="fixed bottom-0 thirdCard bg-[#e5eeff]">
                        <div className="mr-10 ml-4 my-4">
                            {selectedFiles &&
                                <>
                                    <div className="relative py-[8px]">
                                        <span className="rounded-xl bg-white p-[5px]">
                                            {`Uploaded: ${selectedFiles.name}`}</span>
                                        <button onClick={() => { setSelectedFiles(null); }}
                                            className="bg-[#23479F] hover:bg-[#544AC0] px-1 py-0.5 tracking-tight leading-4 rounded-lg text-sm text-white font-bold font-heading absolute top-0">
                                            x
                                        </button>
                                    </div>
                                </>
                            }
                            <div className="flex">
                                <div className="w-full text-lg">

                                    <textarea
                                        className="w-full border border-[#23479F] rounded-lg p-2"
                                        placeholder="Ask your question here....."
                                        value={currentQuestion}
                                        onChange={(e) =>
                                            setCurrentQuestion(e.target.value)
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault();
                                                setDisplayQuestionInput(false);
                                                setStream(true);
                                                handleSendMessage(currentQuestion, stream);
                                            }
                                        }}
                                        rows={1}
                                        disabled={!disableTextArea}
                                    />
                                    <div className="">
                                        {errorMessage && (
                                            <div className="text-red-600">
                                                {errorMessage}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className=" ml-4">
                                    {loading ? (
                                        <div className="overlay">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <div className="flex gap-[10px]">
                                            {selectedRole === 'teacher' && selectedTeacherMethod === 'Brainstorming' &&
                                                <ImageUpload
                                                    accept={"application/jpg"}
                                                    onChange={handleFileChange}
                                                />
                                            }
                                            {(selectedSubject) === 'Mathematics' && (<div>
                                                {/* Math Symbol Button */}
                                                <Button
                                                    onClick={() => setShowKeyboard(!showKeyboard)}
                                                >
                                                    π
                                                </Button>
                                                {/* Draggable Math Keyboard Panel */}
                                                {showKeyboard && (
                                                    <Draggable>
                                                        <div className="absolute bottom-20 right-10 z-50">
                                                            <MathKeyboard onSymbolClick={handleSymbolClick} />
                                                        </div>
                                                    </Draggable>
                                                )}
                                            </div>)}
                                            <Button
                                                onClick={() => {
                                                    if (currentQuestion.trim() === '') {
                                                        setErrorMessage('Text cannot be empty');
                                                        return;
                                                    }
                                                    setDisplayQuestionInput(false);
                                                    handleSendMessage(currentQuestion, stream);
                                                }}
                                                disabled={!disableTextArea}
                                            >
                                                Ask
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    if (!listening) {
                                                        openDialog();
                                                    }
                                                }}
                                                disabled={listening}
                                            >
                                                {listening ? 'Listening...' : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M12 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3m7 9c0 3.53-2.61 6.44-6 6.93V21h-2v-3.07c-3.39-.49-6-3.4-6-6.93h2a5 5 0 0 0 5 5a5 5 0 0 0 5-5z" />
                                                    </svg>
                                                )}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Llama;
