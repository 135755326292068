const store = {};

export function init(data={}){
    for(let k in data){
        if(k in store) return 
        store[k] = data[k]
    } 
}

export function get(){
    return store;
}

export function update(key, value){
    store[key] = value
    // console.log('STORE ', store)
}