/* eslint-disable import/no-webpack-loader-syntax */
import React, { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { StyleSheetManager } from "styled-components";
import IFrameContext from "../context/IFrameContext";
import style from "!!raw-loader!../style-min.css";
import { nanoid } from "nanoid";
// import style from "raw-loader!../"

function Iframe(props) {
  const [ref, setRef] = useState();
  const container = ref?.contentWindow?.document?.body;
  const context = useContext(IFrameContext);
  const [IFrameCtx, setIFrameCtx] = useState(context);
  const [ready, setReady] = useState(null);


  useEffect(() => {
    if (container) {
      const head = ref.contentWindow.document.head;
      head.innerHTML = `<style>${style}</style>`;
      window.pdfDoc = ref.contentWindow.document;
      window.pdfWin = ref.contentWindow;
      window.pdfHead = head;

      setIFrameCtx({
        window: ref.contentWindow,
        document: ref.contentWindow.document,
      });

      setReady(true);
    }
  }, [container]);

  return (
    <iframe
      ref={setRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: "none",
      }}
      id={props.id || `iframe--${nanoid(3)}`}
      loading="lazy"
      allowFullScreen
      frameBorder={0}
    >
      {ready &&
        createPortal(
          <IFrameContext.Provider value={IFrameCtx}>
            <StyleSheetManager target={window?.pdfHead}>
              {props.children}
            </StyleSheetManager>
          </IFrameContext.Provider>,
          container
        )}
    </iframe>
  );
}

export default Iframe;
