import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import SimpleDropdown from "../components/SimpleDropdown";
import {
  organisation as organisationMapping,
  llm as llmMapping,
} from "../components/Mapping";
import { FileUploadSearch } from "../components/Fileupload";
import {
  Card,
  FullCard,
  Select,
  Accordion,
  Spinner,
  Button,
} from "../components/CommonComponents";
import { FaFileExcel, FaHtml5, FaRegFilePdf } from "react-icons/fa";
import { BsFiletypeDocx, BsFiletypeMp3, BsFiletypeTxt } from "react-icons/bs";
import { FaPhotoVideo } from "react-icons/fa";
import {
  mmsSearch,
  mmsSearchSpeech,
  mmsSearchFeedback,
  mmsExternal,
} from "../api/serverAPI";
import { TbFileTypePpt } from "react-icons/tb";
import { GrDocumentZip } from "react-icons/gr";
import { IoMdMic } from "react-icons/io";
import { BsRecordFill } from "react-icons/bs";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { LuCopy } from "react-icons/lu";
import { AiFillLike } from "react-icons/ai";
import { AiFillDislike } from "react-icons/ai";
import { LuX } from "react-icons/lu";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import questionIcon from "../assets/questionIcon.svg";
import ansIcon from "../assets/ansIcon.svg";
import { enterpriseSearchEngine } from "../api/serverAPI";
import { useCookies } from "react-cookie";
import { EseAnswer } from "../components/EseAnswer";

const EnterpriseSearch = () => {
  const initialOrg = organisationMapping[0].text;
  const [repoType, setRepoType] = useState("krtrimaIQ");
  const [organisation, setOrganisation] = useState(initialOrg);
  const [llm, setLlm] = useState(llmMapping[initialOrg][0].text);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const textareaRef = useRef(null);
  const [query, setQuery] = useState("");
  const [databases, setDatabases] = useState({
    docs: false,
    image: false,
    audio: false,
    video: false,
  });
  const [base64Data, setBase64Data] = useState([]);
  const [responseAnswer, setResponseAnswer] = useState("");
  const [links, setLinks] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [image, setImage] = useState(false);
  const [imageQuestion, setImageQuestion] = useState([]);
  const [textQuery, setTextQuery] = useState("");
  const [feedbackTxt, setFeedbackTxt] = useState("");
  const [feedbackInput, setFeedbackInput] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [activeIcon, setActiveIcon] = useState("");
  const [rating, setRating] = useState("none");
  const [base, setBase] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [popoverStates, setPopoverStates] = useState({});
  const [modalStates, setModalStates] = useState({});
  const [responses, setResponses] = useState([]);
  const [firstQuery, setFirstQuery] = useState("");
  const [followQuery, setFollowQuery] = useState("none");
  const [followUpQuestion, setFollowUpQuestion] = useState("");
  const [index, setIndex] = useState(0);
  const [selectedRadioButton, setSelectedRadioButton] = useState("question");
  const [selectedDatabase, setSelectedDatabase] = useState("krtrimaIQ");
  const [docsCount, setDocsCount] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [data, setData] = useState([]);
  const chatContainerRef = useRef(null);
  const [cookies, setCookie] = useCookies(["jwtToken"]);
  const jwtToken = cookies["jwtToken"];
  const [error, setError] = useState(null);
  const [streaming, setStreaming] = useState(true);
  const [external, setExternal] = useState(false);
  const previousRepoType = useRef(null);

  useEffect(() => {
    if (previousRepoType.current === "oup" && repoType === "external") {
      setFirstQuery("");
    }
    previousRepoType.current = repoType;
  }, [repoType]);

  function getCodeByTextLLM(list, text) {
    let result = null;
    Object.values(list).map((category) => {
      const match = category.find((item) => item.text === text);
      if (match) {
        result = match.code;
      }
    });
    return result;
  }
  function getCodeByTextOrg(arr, text) {
    const match = arr.find((item) => item.text === text);
    return match ? match.code : null;
  }
  const callEnterpriseSearchAPI = async () => {
    if (currentQuestion.trim() === "") {
      setErrorMessage("Question cannot be empty");
      setLoading(false);
    } else {
      setStreaming(true);
      setLoading(true);
      setErrorMessage("");
      const sp = new URLSearchParams();
      sp.set(`query`, currentQuestion);
      sp.set("sender", jwtToken);
      sp.set(`vectorStore`, selectedDatabase);
      sp.set(`model`, getCodeByTextOrg(organisationMapping, organisation)); //organisation
      sp.set("modelName", getCodeByTextLLM(llmMapping, llm)); //model name

      const url = new URL("ws://164.52.215.146:6003/ask_docs");
      url.search = sp.toString();
      const ws = new WebSocket(url);

      let first = true;
      ws.onmessage = (ev) => {
        const data = JSON.parse(ev.data);
        if (data.last_msg) {
          setStreaming(false);
          return;
        }
        const answer = data[0]?.text;

        // FIXME: DRY
        setData((prevData) => {
          const last = first ? {} : prevData[prevData.length - 1] || {};
          if (!first) prevData = prevData.slice(0, -1);
          first = false;
          if (data[0]?.text) last.text = data[0].text;
          if (Array.isArray(data)) {
            last.displayDocs = Boolean(
              Array.isArray(data[0]) ? data[0][0]?.Document : data[0]?.Document
            );
            last.docs = data;
          }
          return [...prevData, { question: currentQuestion, ...last }];
        });

        // Update the current answer
        setCurrentAnswer(answer);

        // Clear the current question
        setCurrentQuestion("");

        setLoading(false);

        // Scroll to the newly added content
        // chatContainerRef.current.scrollIntoView({
        //     behavior: "smooth",
        //     block: "end", // Scroll to the end (bottom)
        // });

        setLoading(false);
      };
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      const scrollHeight = textareaRef.current.scrollHeight;
      if (scrollHeight <= parseInt(window.innerHeight * 0.2)) {
        textareaRef.current.style.height = `${scrollHeight}px`;
        textareaRef.current.style.overflowY = "hidden";
      } else {
        textareaRef.current.style.height = "20vh";
        textareaRef.current.style.overflowY = "auto";
      }
    }
  }, [query]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setDatabases((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);

    if (files.length > 0) {
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];
          setBase64Data((prev) => [...(prev || []), base64String]);
        };
        reader.readAsDataURL(file);
      });
    }
    event.target.value = null;
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    setImagePreviews(updatedPreviews);
    setBase64Data((prev) => prev.filter((_, i) => i !== index));
  };

  const getIconByExtension = (file_name, file_url) => {
    const cleanExtension = file_name
      .split(".")
      .pop()
      .toLowerCase()
      .replace(/[^a-z0-9]/g, "");
    switch (cleanExtension) {
      case "pdf":
        return <FaRegFilePdf color="#23479F" size="2em" />;
      case "docx":
        return <BsFiletypeDocx color="#23479F" size="2em" />;
      case "txt":
        return <BsFiletypeTxt color="#23479F" size="2em" />;
      case "xlsx":
        return <FaFileExcel color="#23479F" size="2em" />;
      case "ppt":
        return <TbFileTypePpt color="#23479F" size="2em" />;
      case "mp3":
      case "wav":
        return <BsFiletypeMp3 color="#23479F" size="2em" />;
      case "html":
        return <FaHtml5 color="#23479F" size="2em" />;
      case "zip":
        return <GrDocumentZip color="#23479F" size="2em" />;
      case "png":
      case "jpg":
      case "jpeg":
        return (
          <img
            src={file_url}
            alt={file_name}
            style={{
              width: "40px",
              height: "40px",
              objectFit: "contain",
            }}
          />
        );
      case "mp4":
      case "vid":
      case "avi":
        return <FaPhotoVideo color="#23479F" size="2em" />;
    }
  };

  const overlayStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 1000,
  };
  const getIconColor = (iconName) =>
    activeIcon === iconName ? "text-blue" : "text-gray-600";

  const handleVoice = async () => {
    try {
      const response = await fetch(
        "https://languageapi.krtrimaiq.ai:5502/speech",
        {
          method: "POST",
          body: JSON.stringify({
            cmd: "textOutput",
            payload: {
              text: responseAnswer,
              language: "en",
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);

      const audioElement = new Audio(audioUrl);

      await audioElement.play();

      audioElement.addEventListener("ended", () => {
        URL.revokeObjectURL(audioUrl);
      });
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  };

  const handleCopy = () => {
    const feedbackText = responseAnswer;
    navigator.clipboard
      .writeText(feedbackText)
      .then(() => {})
      .catch((error) => {});
  };
  const handleLike = async () => {
    setActiveIcon("like");
    setErrorMessage("Thanks for Providing Feedback!");

    const selectedDatabases = Object.keys(databases).filter(
      (db) => databases[db]
    );
    let payload = {
      query: textQuery,
      databases:
        selectedDatabases.length > 0
          ? selectedDatabases
          : ["docs", "image", "audio", "video"],
      response: responseAnswer,
      external_sources: external,
      feedback: 5,
      model: getCodeByTextOrg(organisationMapping, organisation),
      modelName: getCodeByTextLLM(llmMapping, llm),
    };

    if (imageQuestion.length > 0 && base.length > 0) {
      payload = {
        query: base[0],
        query_1: textQuery,
        databases: ["image_image"],
        response: responseAnswer,
        feedback: 5,
        model: getCodeByTextOrg(organisationMapping, organisation),
        modelName: getCodeByTextLLM(llmMapping, llm),
      };
    }

    try {
      const response = await mmsSearchFeedback(payload);
    } catch (error) {
      console.error("Error in handleDislike:", error);
    } finally {
      setActiveIcon("");
      setErrorMessage("");
    }
  };

  const handleSubmit = async () => {
    if (!query.trim()) {
      setErrorMessage("Question cannot be empty");
      return;
    }

    if (repoType === "external") {
      setExternal(true);
    } else if (repoType === "oup") {
      setExternal(false);
    }

    if (repoType === "") {
      setErrorMessage("Select Repository Type");
      return;
    }

    setImageQuestion([]);
    let currentFirstQuery = firstQuery || query;
    let currentFollowQuery = firstQuery ? query : "";

    if (!firstQuery) setFirstQuery(query);
    if (firstQuery) setFollowQuery(query);

    setBase(base64Data);
    setImage(false);
    setActiveIcon("");
    setErrorMessage("");
    setTextQuery(query);

    let payload = {};
    if (repoType === "oup") {
      const selectedDatabases = Object.keys(databases).filter(
        (db) => databases[db]
      );
      payload = {
        query: query,
        databases:
          selectedDatabases.length > 0
            ? selectedDatabases
            : ["docs", "image", "audio", "video"],
        feedback: "none",
        model: getCodeByTextOrg(organisationMapping, organisation),
        modelName: getCodeByTextLLM(llmMapping, llm),
      };

      if (imagePreviews.length > 0 && base64Data.length > 0) {
        payload = {
          query: base64Data[0],
          query_1: query,
          databases: ["image_image"],
          feedback: "none",
          model: getCodeByTextOrg(organisationMapping, organisation),
          modelName: getCodeByTextLLM(llmMapping, llm),
        };
        setImageQuestion(imagePreviews);
      }
    } else if (repoType === "external") {
      payload = {
        query: currentFirstQuery,
        follow_up_query: currentFollowQuery,
        model: getCodeByTextOrg(organisationMapping, organisation),
        modelName: getCodeByTextLLM(llmMapping, llm),
      };
    }

    setLoading(true);

    try {
      let response;
      let newResponseEntry = {};

      if (repoType === "oup") {
        response = await mmsSearch(payload);
        const {
          answer = ["Answer Not Found"],
          follow_up_questions: followQuestion = [],
        } = response.data;
        setResponseAnswer(answer[0]);
        const sourcesLinks = Array.isArray(answer[1])
          ? answer[1]
              .map((source) =>
                source?.file_name && source?.url
                  ? {
                      link: source.url.trim(),
                      displayText: source.file_name.trim(),
                    }
                  : null
              )
              .filter(Boolean)
          : [];

        newResponseEntry = {
          index,
          question: query,
          answer: answer[0],
          followUpQuestion: followQuestion[0],
          links: sourcesLinks,
          imageQuestion: imagePreviews.length > 0 ? imagePreviews : [],
        };

        if (imagePreviews.length > 0) {
          setImagePreviews(imagePreviews);
          setImage(true);
        }
      } else if (repoType === "external") {
        response = await mmsExternal(payload);
        const { answer, follow_up_questions: followQuestion = [] } =
          response.data;

        setResponseAnswer(
          answer || followQuestion.join(", ") || "No data available"
        );

        newResponseEntry = {
          index,
          question: query,
          answer: answer || "No data available",
          followUpQuestion: followQuestion,
          links: [],
        };
      }

      setResponses((prevResponses) => [...prevResponses, newResponseEntry]);
      setIndex((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.error("Error in handleSubmit:", error.message || error);
    } finally {
      setLoading(false);
      setQuery("");
      setSelectedFiles([]);
      setBase64Data([]);
      setImagePreviews([]);
      setImage(false);
      setFollowQuery("");
    }
  };

  const handleDislike = async (feedback) => {
    setActiveIcon("dislike");
    setLoading(true);

    const selectedDatabases = Object.keys(databases).filter(
      (db) => databases[db]
    );
    let payload = {
      query: textQuery,
      databases:
        selectedDatabases.length > 0
          ? selectedDatabases
          : ["docs", "image", "audio", "video"],
      response: responseAnswer,
      feedbackResponse: feedback,
      feedback: 0,
      external_sources: external,
      model: getCodeByTextOrg(organisationMapping, organisation),
      modelName: getCodeByTextLLM(llmMapping, llm),
    };

    if (imageQuestion.length > 0 && base.length > 0) {
      payload = {
        query: base[0],
        query_1: textQuery,
        databases: ["image_image"],
        response: responseAnswer,
        feedbackResponse: feedback,
        feedback: 0,
        model: getCodeByTextOrg(organisationMapping, organisation),
        modelName: getCodeByTextLLM(llmMapping, llm),
      };
    }

    try {
      const response = await mmsSearchFeedback(payload);
      const {
        answer = ["Answer Not Found"],
        follow_up_questions: followQuestion = [],
      } = response.data;

      const sourcesLinks = Array.isArray(answer[1])
        ? answer[1]
            .map((source) =>
              source?.file_name && source?.url
                ? {
                    link: source.url.trim(),
                    displayText: source.file_name.trim(),
                  }
                : null
            )
            .filter(Boolean)
        : [];
      let newResponseEntry;
      if (repoType === "oup") {
        setResponseAnswer(answer[0]);
        newResponseEntry = {
          index,
          question: textQuery,
          answer: answer[0],
          followUpQuestion: followQuestion,
          links: sourcesLinks,
          imageQuestion: imageQuestion,
        };
      } else if (repoType === "external") {
        setResponseAnswer(answer);
        newResponseEntry = {
          index,
          question: textQuery,
          answer: answer,
          followUpQuestion: followQuestion,
          links: sourcesLinks,
        };
      }

      setResponses((prevResponses) => [...prevResponses, newResponseEntry]);
      setIndex((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.error("Error in handleDislike:", error.message || error);
    } finally {
      setActiveIcon("");
      setLoading(false);
    }
  };

  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  let recognition;

  if (SpeechRecognition) {
    recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = "en-US";
  } else {
    alert("Speech Recognition API is not supported in this browser.");
  }

  const handleMicClick = () => {
    if (recognition) {
      recognition.start();

      recognition.onstart = () => {
        console.log("Voice recognition started. Please speak.");
      };

      recognition.onspeechend = () => {
        console.log("Voice recognition ended.");
        recognition.stop();
      };

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setQuery((prevQuery) => `${prevQuery} ${transcript}`);
        console.log("Transcript: ", transcript);
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error detected: ", event.error);
        recognition.stop();
      };
    }
  };

  const togglePopover = (index) => {
    setPopoverStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleModal = (index) => {
    setModalStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const closeModal = (index) => {
    setModalStates((prev) => ({
      ...prev,
      [index]: false,
    }));
  };

  return (
    <>
      {loading && (
        <div style={overlayStyle}>
          <Spinner />
        </div>
      )}
      <div className="flex gap-4">
        <div className="secondcardWidth">
          <FullCard>
            <div className="subheading text-center mt-2 mb-8">Search</div>
            <div className="mt-8">
              <div className="text-lg font-medium text-indigo-800 text-left my-2">
                Repository
              </div>
              <select
                className="inline-flex w-[17vw] rounded-md border px-4 py-2 text-sm"
                value={repoType}
                onChange={(e) => setRepoType(e.target.value)}
              >
                <option value="krtrimaIQ">KrtrimaIQ</option>
                <option value="oup">OUP</option>
                <option value="external">External</option>
              </select>
            </div>

            <div className="my-8">
              <div className="text-lg font-medium text-indigo-800 text-left my-2">
                LLM Organisation
              </div>
              <Select
                dropdownData={organisationMapping}
                value={organisation}
                onChange={(e) => {
                  setOrganisation(e.target.value);
                  setLlm(llmMapping[e.target.value][0].text);
                }}
              />
            </div>
            <div className="my-8">
              <div className="text-lg font-medium text-indigo-800 text-left my-2">
                LLM Model
              </div>
              <Select
                dropdownData={llmMapping[organisation]}
                value={llm}
                onChange={(e) => setLlm(e.target.value)}
              />
            </div>

            {repoType !== "krtrimaIQ" && (
              <>
                <div className="text-lg font-medium text-indigo-800 text-left my-4">
                  Choose Document Type
                </div>
                <div className="px-5">
                  <div
                    className={
                      imagePreviews.length > 0 || repoType === "external"
                        ? "opacity-50"
                        : ""
                    }
                  >
                    {["docs", "image", "audio", "video"].map((type) => (
                      <label key={type} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          className="transform scale-150"
                          name={type}
                          onChange={handleCheckboxChange}
                          disabled={
                            imagePreviews.length > 0 || repoType === "other"
                          }
                        />
                        <span>
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </>
            )}
          </FullCard>
        </div>
        {repoType === "krtrimaIQ" && (
          <div className="thirdcardWidth overflow-y-auto h-[90vh]">
            <div className="chat-container">
              {data.map((entry, index) => (
                <div className="mb-4" key={index}>
                  <Accordion index={index}>
                    <div key={index} className="chat-message">
                      <div className="subheading text-left px-[2%]">
                        {index === 0
                          ? "Asked Question"
                          : "Asked follow up Question"}
                      </div>
                      <div className="m-5">
                        <div className="flex">
                          <div className="w-1/12">
                            <img
                              src={questionIcon}
                              alt=""
                              width={"50px"}
                              height={"50px"}
                              className=""
                            />
                          </div>
                          <div className="w-11/12 ml-4 bg-white rounded-lg text-xl">
                            <div className="w-full border border-light-purple p-2">
                              {entry.question}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div key={index} className="chat-message">
                      <div className="m-5">
                        <div className="flex">
                          <div className="w-1/12">
                            <img
                              src={ansIcon}
                              alt=""
                              width={"50px"}
                              height={"50px"}
                              className=""
                            />
                          </div>
                          <div className="w-11/12 ml-4 bg-white rounded-lg text-xl">
                            <div className="w-full border border-light-purple p-2">
                              <EseAnswer
                                type={selectedRadioButton}
                                answer={entry}
                                streaming={streaming}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </div>
              ))}
              <div className="fixed bottom-0 thirdCard bg-[#e5eeff]">
                {/* <div className="subheading text-left px-[2%]">
                        {
                            data.length + 1 === 1
                                ? "Enter a Question"
                                : "Enter a different or follow-up Question"
                        }
                    </div> */}
                <div className="mr-10 ml-4 my-6">
                  <div className="flex">
                    <div className="w-full text-lg">
                      <textarea
                        className="w-full border border-[#23479F] rounded-lg p-2"
                        placeholder="Ask your query here....."
                        value={currentQuestion}
                        onChange={(e) => setCurrentQuestion(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            callEnterpriseSearchAPI(false);
                          }
                        }}
                        rows={1}
                      />
                      <div className="">
                        {errorMessage && (
                          <div className="text-red-600">{errorMessage}</div>
                        )}
                      </div>
                    </div>
                    <div className="ml-4">
                      {loading ? (
                        <div className="overlay">
                          <Spinner />
                        </div>
                      ) : (
                        <Button
                          onClick={() => {
                            callEnterpriseSearchAPI(true);
                          }}
                        >
                          Ask
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {repoType !== "krtrimaIQ" && (
          <div className="thirdcardWidth overflow-y-auto h-[90vh]">
            <div
              className="chat-container"
              style={{
                marginBottom: "8rem",
              }}
            >
              <div className="space-y-2">
                {responses.map((responseItem, index) => (
                  <Accordion
                    key={responseItem.index}
                    index={responseItem.index}
                  >
                    <div className="chat-message">
                      <div className="subheading text-left px-[2%]">
                        {responseItem.index === 0
                          ? "Asked Question"
                          : "Asked Follow-up Question"}
                      </div>
                      <div className="m-5">
                        <div className="flex">
                          <div className="w-1/12">
                            <img
                              src={questionIcon}
                              alt=""
                              width={"50px"}
                              height={"50px"}
                            />
                          </div>
                          <div className="w-11/12 ml-2 bg-white rounded-lg text-xl">
                            <div className="w-full border border-light-purple p-2">
                              <p className="pb-2">{responseItem.question}</p>
                              {responseItem.imageQuestion && (
      <img 
        src={responseItem.imageQuestion} 
        style={{ width: '90px', height: 'auto' }} 
      />
    )}
                              {/* {imageQuestion && (
                                <img
                                  src={imageQuestion}
                                  style={{ width: "90px", height: "auto" }}
                                />
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="chat-message">
                      <div className="m-5">
                        <div className="flex">
                          <div className="w-1/12">
                            <img
                              src={ansIcon}
                              alt=""
                              width={"50px"}
                              height={"50px"}
                            />
                          </div>
                          <div className="w-11/12 ml-2 bg-white rounded-lg text-xl">
                            <div className="w-full border border-light-purple p-2">
                              {responseItem.answer &&
                                responseItem.followUpQuestion && (
                                  <div>
                                    <div className="w-full">
                                      <div className="p-0">
                                        {responseItem.answer}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {(responseItem.answer &&
                                !responseItem.followUpQuestion) ||
                              (!responseItem.answer &&
                                responseItem.followUpQuestion) ? (
                                <>
                                  {responseItem.answer && (
                                    <p className="w-[45vw] text-lg">
                                      {responseItem.answer}
                                    </p>
                                  )}
                                  {responseItem.followUpQuestion && (
                                    <div className="mt-4 text-lg">
                                      {responseItem.followUpQuestion}
                                    </div>
                                  )}
                                </>
                              ) : null}

                              {responseItem.links?.length > 0 && (
                                <>
                                  {responseItem.links.map((source, idx) => (
                                    <div
                                      key={idx}
                                      className="flex items-center mt-2"
                                    >
                                      {getIconByExtension(
                                        source.displayText,
                                        source.link
                                      )}
                                      <a
                                        href={source.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="ml-2 text-blue-500 hover:underline"
                                      >
                                        {source.displayText}
                                      </a>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {typeof responseItem.answer === "string" &&
                        responseItem.answer.trim() &&
                        typeof responseItem.followUpQuestion === "string" &&
                        responseItem.followUpQuestion.trim() && (
<div className="mt-4 pl-4">
  <div className="text-lg font-medium text-indigo-800 text-left my-2">
    Suggested Question:
  </div>
  <button
    onClick={() => setQuery(responseItem.followUpQuestion)}
    className="w-full text-left"
  >
    <div className="w-full bg-white rounded-lg">
      <div className="w-full border border-light-purple p-2 text-left">
        {responseItem.followUpQuestion}
      </div>
    </div>
  </button>
</div>

                        )}
                    </div>
                    <div>
                      <div className="flex justify-start mt-2 mb-2 pl-10">
                        <div className="flex space-x-4">
                          <HiMiniSpeakerWave
                            className="text-gray-600 w-6 h-6 cursor-pointer opacity-50"
                            onClick={handleVoice}
                          />
                          <LuCopy
                            className="text-gray-600 w-6 h-6 cursor-pointer opacity-50"
                            onClick={handleCopy}
                          />
                          <AiFillLike
                            className={`${getIconColor(
                              "like"
                            )} w-6 h-6 cursor-pointer opacity-50`}
                            onClick={handleLike}
                          />
                          <AiFillDislike
                            className={`${getIconColor(
                              "dislike"
                            )} w-6 h-6 cursor-pointer opacity-50`}
                            onClick={() => togglePopover(index)}
                          />
                        </div>
                      </div>
                    </div>
                    {popoverStates[index] && (
                      <div className="mt-2 p-4 bg-white rounded-md border border-gray-200 w-[80vh]">
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-gray-700">Tell us more:</p>
                          <LuX
                            className="text-gray-500 hover:text-gray-800 cursor-pointer"
                            onClick={() => togglePopover(index)}
                          />
                        </div>
                        <div className="flex flex-wrap gap-2">
                          {[
                            "Need More Details",
                            "Incorrect/misleading Information",
                            "Too Complex/Need Simplification",
                            "Too Brief/Need elaboration",
                            "More...",
                          ].map((option) => (
                            <button
                              key={option}
                              className="bg-gray-100 hover:bg-gray-200 text-gray-800 py-1 px-2 rounded cursor-pointer"
                              style={{
                                flex: "1 1 calc(33.33% - 8px)",
                                minWidth: "35%",
                              }}
                              onClick={() => {
                                if (option === "More...") {
                                  toggleModal(index);
                                } else {
                                  setFeedbackTxt(option);
                                  togglePopover(index);
                                  handleDislike(option);
                                }
                              }}
                            >
                              {option}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}

                    {modalStates[index] && (
                      <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-[50vh]">
                          <div className="flex justify-between items-center mb-4">
                            <p className="text-gray-700">
                              Please provide us more feedback:
                            </p>
                            <LuX
                              className="text-gray-500 hover:text-gray-800 cursor-pointer"
                              onClick={() => closeModal(index)}
                            />
                          </div>
                          <textarea
                            rows="2"
                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                            placeholder="Your feedback here..."
                            value={feedbackInput}
                            onChange={(e) => setFeedbackInput(e.target.value)}
                          ></textarea>
                          <div className="mt-4 flex justify-end">
                            <Button
                            
                              onClick={() => {
                                setFeedbackTxt(feedbackInput);
                                handleDislike(feedbackInput);
                                setFeedbackTxt("");
                                closeModal(index);
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Accordion>
                ))}
              </div>

              <div className="fixed bottom-0 thirdCard bg-[#e5eeff]">
                <div className="mr-10 ml-4 mb-6">
                  <div className="flex">
                    <div className="w-full text-lg">
                      <div className="flex items-center mt-8 w-[55vw] space-x-4">
                        <div className="relative flex-grow bg-[#ffffff] rounded-lg z-90">
                          <FileUploadSearch
                            onChange={handleFileChange}
                            accept="image/*"
                          />
                          <div className="relative w-full border-blue-600 rounded-lg ml-12 py-3 flex flex-col space-y-2">
                            {imagePreviews.length > 0 && (
                              <div className="flex space-x-2">
                                {imagePreviews.map((src, index) => (
                                  <div key={index} className="relative">
                                    <img
                                      src={src}
                                      alt={`Preview ${index}`}
                                      className="h-16 w-16 object-cover rounded-lg border-2 border-blue-600"
                                    />
                                    <button
                                      onClick={() => handleRemoveFile(index)}
                                      className="absolute top-0 right-0 bg-red-600 text-white rounded-full p-1 h-5 w-5 flex items-center justify-center text-xs hover:bg-red-700"
                                    >
                                      X
                                    </button>
                                  </div>
                                ))}
                              </div>
                            )}
                            <div className="relative flex pr-2">
                              <textarea
                                ref={textareaRef}
                                className="border-none focus:ring-0 focus:outline-none text-lg resize-none w-[45vw]"
                                placeholder="Enter your query here..."
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSubmit();
                                  }
                                }}
                                rows={1}
                                style={{ maxHeight: "20vh" }}
                              />

                              <div onClick={handleMicClick}>
                                <IoMdMic size={25} className="text-blue-600" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ml-4">
                          <Button onClick={handleSubmit}>Ask</Button>
                        </div>
                      </div>
                      {errorMessage && (
                        <div className="text-red-600 mt-2">{errorMessage}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EnterpriseSearch;
