import { Link } from 'react-router-dom'

export const KnowledgeManagementAnswer = ({ type, answer }) => {

    if (type === 'question') {
        console.log(answer);
        return (
            <div>
                <p>
                    <div className="" dangerouslySetInnerHTML={{__html: answer}}/>
                </p>
            </div>
        )
    }
    else {
        return (
            <div>
                {
                    answer.map((data, index) => (
                            <div>
                                <div>
                                    {index + 1}. <span className="" dangerouslySetInnerHTML={{ __html: data.Matched }} />
                                </div>
                                <div>
                                    <b>Document:</b> <Link className="text-indigo-600 underline" target="_blank" to={data.url}> {data.Document}</Link>
                                </div>
                                <br />
                            </div>
                        )
                    )
                }
            </div>
        )
    }

}
