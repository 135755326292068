import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import {
    Accordion,
    Card,
    WhiteCard,
    Select,
    Spinner,
    Button,
} from "../components/CommonComponents";
import { roleSelect } from "../components/Mapping";
import { RoleplayURL } from "../api/serverAPI";
import { FullCard } from "../components/CommonComponents";
import questionIcon from "../assets/questionIcon.svg";
import ansIcon from "../assets/ansIcon.svg";
import { nanoid } from "nanoid";
import { EseAnswer } from "../components/EseAnswer";
import axios from 'axios';

const RolePlay = () => {
    const [loading, setLoading] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [data, setData] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState("");
    const [displayQuestionInput, setDisplayQuestionInput] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [role, setRole] = useState("Cardiologist");
    const chatContainerRef = useRef(null);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const latestAnswerRef = useRef(null);

    // Scroll to the latest answer
    useEffect(() => {
        const container = document.getElementById("chat-box")
        //console.log(container.scrollHeight)
        container.scrollTo({
            behavior: "smooth",
            top: container.scrollHeight,
        });
    },
        [role]
    )

    const callOneEngineManyRoleAPI = async () => {
        if (currentQuestion.trim() === "") {
            setErrorMessage("Question cannot be empty");
            setLoading(false);
        } else {
            setLoading(true);
            setErrorMessage("");
            // Prepare the request data with the current question
            const requestData = { role: role, query: currentQuestion };
            const url = RoleplayURL();

            try {
                const response = await axios.post(url, requestData);
                console.log(response);

                const answer = response.data.response;

                // Update the data array with the current question and answer
                setData((prevData) => [
                    ...prevData,
                    { question: currentQuestion, answer, id: nanoid(24) },
                ]);


                // Update the current answer
                setCurrentAnswer(answer);

                // Clear the current question
                setCurrentQuestion("");

                setLoading(false);

                // // Scroll to the newly added content
                // chatContainerRef.current.scrollIntoView({
                //     behavior: "smooth",
                //     block: "end", // Scroll to the end (bottom)
                // });
            } catch (error) {
                console.error("Error while receiving answer:", error);
                setLoading(false);
            }
        }
    };

    const scrollToAnswer = (id) => {
        setSelectedQuestionId(id);

        const answerContainer = document.getElementById(`answer-${id}`);
        if (answerContainer) {
            answerContainer.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
    };

    return (
        <div className="flex gap-4 mr-4">
            <div className="secondcardWidth">
                <WhiteCard>
                    <div className="">
                        <div className="subheading text-center mt-2 pt-8 px-6">
                            One Engine Many Roles
                        </div>
                        <div className="my-8 px-6">
                            <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                Choose Role
                            </div>
                            <div className="w-full">
                                <Select
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    dropdownData={roleSelect}//options
                                />
                            </div>
                        </div>
                        <div className="my-8">
                            {/* Display previous questions lists */}
                            {data.slice().reverse().map((entry, index) => (
                                <>
                                    {(index < 1) ? (
                                        <div className="my-3 px-6 text-lg font-medium text-indigo-800 text-left my-2">
                                            Previous Question
                                        </div>
                                    ) : null}
                                </>
                            ))}
                            <div className="overflow-y-auto h-[60vh]">
                                {data.slice().reverse().map((entry, index) => (
                                    <div
                                        key={entry.id}
                                        className={`ml-6 mr-4 border border-[#23479F] rounded-lg text-[#23479F] px-4 p-3 mb-3 ${entry.id === selectedQuestionId ? "bg-[#385EBA] text-white" : ""
                                            }`}
                                        onClick={() => scrollToAnswer(entry.id)}
                                    >
                                        <div className="line-clamp-1">
                                            {entry.question}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </WhiteCard>
            </div>
            <div className="thirdcardWidth" ref={chatContainerRef} >
                <div className="chat-container">
                    <div className="overflow-auto h-[78vh]" id="chat-box">
                        {data.map((entry, figure, index) => (
                            <div
                                key={entry.id}
                                ref={latestAnswerRef}
                                className={`mb-4 ${entry.id === selectedQuestionId ? "bg-gray-200" : ""}`}
                                id={`answer-${entry.id}`}
                            >
                                <Card index={index}>
                                    <div className="chat-message">
                                        <div
                                            className="m-0">
                                            <div className="flex">
                                                <div className="w-1/12">
                                                    <img
                                                        src={questionIcon}
                                                        alt=""
                                                        width={"50px"}
                                                        height={"50px"}
                                                        className=""
                                                    />
                                                </div>
                                                <div className={` w-11/12 ml-4 bg-white rounded-lg text-xl mb-4 ${entry.id === selectedQuestionId ? "font-medium text-indigo-800" : ""}`}>
                                                    <div className="w-full border border-light-purple p-2">
                                                        {entry.question}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-message">
                                        <div
                                            className="m-0">
                                            <div className="flex">
                                                <div className="w-1/12">
                                                    <img
                                                        src={ansIcon}
                                                        alt=""
                                                        width={"50px"}
                                                        height={"50px"}
                                                        className=""
                                                    />
                                                </div>
                                                <div className="w-11/12 ml-4 bg-white rounded-lg text-xl">
                                                    <div className="w-full border border-light-purple p-2">
                                                        <div className="" dangerouslySetInnerHTML={{ __html: entry.answer }} />
                                                        {entry.figure && <img src={entry.figure} alt={"chart_image"} className="w-full h-auto" />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </div>
                    <div className="fixed bottom-0 thirdCard bg-[#e5eeff]">
                        <div className="mr-10 ml-4 my-4">
                            <div className="flex">
                                <div className="w-full text-lg">
                                    <textarea
                                        className="w-full border border-[#23479F] rounded-lg p-2"
                                        placeholder="Ask your question here....."
                                        value={currentQuestion}
                                        onChange={(e) =>
                                            setCurrentQuestion(e.target.value)
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault();
                                                setDisplayQuestionInput(false);
                                                callOneEngineManyRoleAPI();
                                            }
                                        }}
                                        rows={1}
                                    />
                                    <div className="">
                                        {errorMessage && (
                                            <div className="text-red-600">
                                                {errorMessage}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className=" ml-4">
                                    {loading ? (
                                        <div className="overlay">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <Button
                                            onClick={() => {
                                                setDisplayQuestionInput(false);
                                                callOneEngineManyRoleAPI();
                                            }}
                                        >
                                            Ask
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RolePlay;
