import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster, toast } from "react-hot-toast";
import { FcInfo } from 'react-icons/fc';
import { Button } from './CommonComponents';
import Spinner from './spinner';
import Logo from "../assets/logo.svg";


const OTPVerification = () => {
  const router = useNavigate();
  const location = useLocation();
  const { email, application_id, pathToNavigate } = location.state;


  const [otp, setOtp] = useState(null);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: email, // Use the email prop here
    application_id: application_id,
    otp: otp,
  });

  useEffect(() => {
    const notification = toast(
      'Please enter the OTP from your email to verify your account.',
      {
        duration: 6000,
        icon: <FcInfo fontSize={"35px"} />,
      }
    );

    return () => toast.dismiss(notification);
  }, []);




  const handleChange = (e) => {
    const enteredOtp = e.target.value;

    // Ensure that enteredOtp is a number
    if (/^\d+$/.test(enteredOtp)) {
      setOtp(enteredOtp);
      setFormData({ ...formData, otp: enteredOtp });
    } else {
      setOtp('');
    }
  };


  const handleOTPVerification = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("https://efhp0mxmli.execute-api.ap-south-1.amazonaws.com/dev/verify-otp", formData);

      if (response.data.message === "OTP verification successful") {
        await toast.success("OTP verification successful");
        if (pathToNavigate === "/reset-password") {
          router(pathToNavigate, { state: { email: email, application_id: 2 } });
        }
        else {
          router(pathToNavigate);
        }

      } else {
        await toast.error('OTP verification failed');
      }
    } catch (error) {
      console.error('API call error:', error);
      await toast.error('OTP verification failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className='py-5'><img
        src={Logo}
        alt="Logo of krtrimaIQ Cognitive Solutions"
        width={350}
      /></div>
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">OTP Verification</h2>
        <form onSubmit={handleOTPVerification}>
          <input
            type="number"
            name="otp"
            placeholder="Enter OTP"
            value={otp}
            onChange={handleChange} // Make sure onChange is correctly bound
            required
            max="999999"
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring focus:ring-indigo-200"
          />
          <div className="flex justify-center">
            <Button size="medium" color="light-purple" type="submit">
              {loading ? <Spinner size='sm' colour='white' /> : "Verify OTP"}
            </Button>
          </div>

        </form>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default OTPVerification;
