import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import {
    Card,
    Button,
    Slider,
    Spinner,
    FullCard,
    Select,
} from "../components/CommonComponents";
import MultiSelectDropdown from '../components/MultiSelectDropdown';
import SimpleDropdown from '../components/SimpleDropdown';
import { DragAndDrop, BrowseComputer, FileUpload } from "../components/Fileupload";
import { download, downloadWord } from "../utils";
import { scienceURL } from "../api/serverAPI";
import { sClassTypes, sciencePrimary, scienceMiddle, sciencedifficultyLevel } from "../components/Mapping";
import axios from 'axios';

const lookUp = {
    science_mcq_remember: "Remember MCQ",
    science_fillup_remember: "Remember Fill-Up",
    science_truefalse_remember: "Remember True or False",
    science_desc_remember: "Remember Descriptive",

    science_mcq_understand: "Understand MCQ",
    science_fillup_understand: "Understand Fill-Up",
    science_truefalse_understand: "Understand True or False",
    science_desc_understand: "Remember Descriptive",

    science_mcq_apply: "Apply MCQ",
    science_fillup_apply: "Apply Fill-Up",
    science_truefalse_apply: "Apply True or False",
    science_desc_apply: "Remember Descriptive",

    science_mcq_analyze: "Analyze MCQ",
    science_fillup_analyze: "Analyze Fill-Up",
    science_truefalse_analyze: "Analyze True or False",
    science_desc_analyze: "Remember Descriptive",

    science_mcq_evaluate: "Evaluate MCQ",
    science_fillup_evaluate: "Evaluate Fill-Up",
    science_truefalse_evaluate: "Evaluate True or False",
    science_desc_evaluate: "Remember Descriptive",

    science_mcq_create: "Create MCQ",
    science_fillup_create: "Create Fill-Up",
    science_truefalse_create: "Create True or False",
    science_desc_create: "Remember Descriptive",
}

const Science = () => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [base64Data, setBase64Data] = useState(null);
    const [progress, setProgress] = useState(0);
    const [noOfQuestion, setNoOfQuestion] = useState(2);
    const [generateqaData, setGenerateqaData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [classType, setClassType] = useState("primary");
    const [primaryOptions, setPrimaryOptions] = useState([]);
    const [middleOptions, setMiddleOptions] = useState([]);
    const [difficultyLevel, setDifficultyLevel] = useState([]);

    const handleSelect = (option) => {
        setClassType(option.value);

        // Determine which set of options to use based on the selected value
        if (option.value === "primary") {
            setPrimaryOptions([]);
        } else if (option.value === "middle") {
            setMiddleOptions([]);
        }

        // Reset the selected options in MultiSelectDropdown
        setPrimaryOptions([]);
        setMiddleOptions([]);
    };

    const handlePrimaryChange = (updatedOptions) => {
        setPrimaryOptions(updatedOptions);
    };

    const handleMiddleChange = (updatedOptions) => {
        setMiddleOptions(updatedOptions);

    };

    const handleDifficultyLevel = (updatedOptions) => {
        setDifficultyLevel(updatedOptions);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        handleFile(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
    };

    const handleFile = (file) => {
        setSelectedFiles(file);
        if (file && file.type === "application/pdf") {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                setBase64Data(base64String);
            };
            reader.readAsDataURL(file);
        }
        else {
            alert("Please upload a PDF file.");
        }
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                } else {
                    clearInterval(interval);

                    return prevProgress;
                }
            });
        }, 1000);
    };

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     setSelectedFiles(file);

    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
    //         setBase64Data(base64String);
    //     };
    //     reader.readAsDataURL(file);

    //     const interval = setInterval(() => {
    //         setProgress((prevProgress) => {
    //             if (prevProgress < 100) {
    //                 return prevProgress + 10;
    //             } else {
    //                 clearInterval(interval);

    //                 return prevProgress;
    //             }
    //         });
    //     }, 1000);
    // };

    // const handleDragOver = (e) => {
    //     e.preventDefault();
    // };

    // const handleDrop = (e) => {
    //     e.preventDefault();
    //     const file = e.dataTransfer.files[0];
    //     setSelectedFiles(file);

    //     if (file) {
    //         if (file.type === "application/pdf") {
    //             setSelectedFiles(file);
    //         } else {
    //             alert("Please upload a PDF file.");
    //         }
    //     }
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
    //         setBase64Data(base64String);
    //     };
    //     reader.readAsDataURL(file);

    //     const interval = setInterval(() => {
    //         setProgress((prevProgress) => {
    //             if (prevProgress < 100) {
    //                 return prevProgress + 10;
    //             } else {
    //                 clearInterval(interval);

    //                 return prevProgress;
    //             }
    //         });
    //     }, 1000);
    // };

    const handleEditClick = () => {
        const editableContent = document.getElementById("editableContent");

        if (editableContent) {
            editableContent.contentEditable = "true";
            editableContent.focus();
        }
    };

    const outputContent = () => {
        return (
            <>
                <div
                    id="editableContent"  // Add an id to identify the contentEditable div
                    className="p-4 flex flex-col align-center gap-[25px] outline-none focus-within:bg-[#ebeafd]"
                    contentEditable={false}  // Set to "true" when the Edit button is clicked
                >
                    {Object.entries(generateqaData).map(([key, item], index) => (
                        <div key={`${key}-${index}`}>
                            <div className="underline text-xl flex mb-5">
                                <strong>{lookUp[key]}</strong>
                            </div>
                            <div className="" dangerouslySetInnerHTML={{ __html: item }} />
                        </div>
                    ))}
                </div>
            </>

        );
    };
    const GetOutput = () => {
        setLoading(true);
        const questionType = classType === "primary" ? primaryOptions : middleOptions;
        const payload = {
            filename: selectedFiles.name,
            file: base64Data,
            level: classType,
            questionType: questionType,
            difficultyLevel: difficultyLevel,
            questionCount: noOfQuestion,
        };
        const url = scienceURL(); // get only the URL
        axios.post(url, payload) // send URL and payload
            .then((response) => {
                console.log("response", response);
                setGenerateqaData(response.data?.response || null);
                setLoading(false);
            })
            .catch((error) => {
                alert("Can you please try again in sometime");
                setLoading(false); // Ensure loading state is reset on error
            });
    };

    const DisplayOutput = () => {
        if (loading) {
            return (
                <>
                    <div className="overlay">
                        <Spinner />
                    </div>
                </>
            );
        }
        if (generateqaData) {
            return (
                <div className="">
                    <Card>
                        <div className="subheading text-center">
                            Here is your questions
                        </div>
                        <div className="my-6">
                            <div className="border-2 border-[#23479F] rounded-lg">
                                <div className="bg-[#23479F] p-4">
                                    <div class="grid grid-cols-2 gap-4">
                                        <p className="text-white mt-1">
                                            {selectedFiles && (
                                                <div className='mx-1 line-clamp-1'>
                                                    {selectedFiles.name}
                                                </div>
                                            )}
                                        </p>
                                        <div className="flex flex-nowrap sm:flex-wrap justify-end gap-3 ">
                                            <div>
                                                <Button size="white" onClick={handleEditClick} > Edit</Button>
                                            </div>
                                            <div className="bg-pure-white">
                                                <Button size="white">Save</Button>
                                            </div>
                                            <div>
                                                <Button size="download" onClick={downloadWord}>Download</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white mx-auto rounded-lg">
                                    <div id="page-container" className="">
                                        <div className="m-6">
                                            <div className="spacearound">
                                                {outputContent()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        }
    };

    return (
        <div className="flex gap-4">
            <div className="secondcardWidth">
                <FullCard>
                    <div className="subheading text-center mt-2">
                        Science
                    </div>
                    <div className="my-8 text-center">
                        <div className="items-center" onDragOver={handleDragOver}
                            onDrop={handleDrop}>
                            <FileUpload
                                accept={"application/pdf"}
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                    <div className="mt-4 text-center">
                        {selectedFiles && (
                            <div className="mx-10">
                                <b>File Name: </b>
                                {selectedFiles.name}
                            </div>
                        )}
                        {progress > 0 && (
                            <div className="my-4">
                                <div className="bg-[#D2CFF1] h-4 rounded-full">
                                    <div
                                        className="bg-[#23479F] h-full rounded-full"
                                        style={{
                                            width: `${progress}%`,
                                        }}
                                    ></div>
                                </div>
                                <div className="text-center mt-2">{`${progress}% Complete`}</div>
                            </div>
                        )}
                    </div>
                    <div className="text-lg font-medium text-indigo-800 text-left">
                        Choose from the following options
                    </div>
                    <div className="my-8">
                        <div className="text-lg font-medium text-indigo-800 text-left my-2">
                            Choose your class
                        </div>
                        <div className="w-full">
                            <SimpleDropdown
                                options={sClassTypes}
                                onSelect={handleSelect}
                                defaultValue={sClassTypes[0]}
                            />
                        </div>
                    </div>
                    <div className="my-8">
                        <div className="text-lg font-medium text-indigo-800 text-left my-2">
                            Question Type
                        </div>
                        <div className="w-full">
                            {classType === "primary" && (
                                <MultiSelectDropdown
                                    options={sciencePrimary}
                                    selectedOptions={primaryOptions}
                                    onChange={handlePrimaryChange}
                                    placeholder="Select one or more"
                                />
                            )}
                            {classType === "middle" && (
                                <MultiSelectDropdown
                                    options={scienceMiddle}
                                    selectedOptions={middleOptions}
                                    onChange={handleMiddleChange}
                                    placeholder="Select one or more"
                                />
                            )}
                        </div>
                        <div className="my-8">
                            <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                Difficulty Level
                            </div>
                            <div className="w-full">
                                <MultiSelectDropdown
                                    options={sciencedifficultyLevel}
                                    selectedOptions={difficultyLevel}
                                    onChange={handleDifficultyLevel}
                                    placeholder="Select one or more"
                                />
                            </div>
                        </div>
                        <div className="my-2">
                            <div className="my-2 items-center">
                                <div className="font-bold">
                                    Number of Questions
                                </div>
                                <div className="">
                                    <label className="block text-gray-600 flex justify-center w-full">
                                        {noOfQuestion}
                                    </label>
                                    <Slider
                                        min={1}
                                        max={20}
                                        step={1}
                                        value={noOfQuestion}
                                        onChange={(e) =>
                                            setNoOfQuestion(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {progress === 100 && difficultyLevel.length > 0 && (
                        <>
                            <div className="my-8">
                                {classType === "primary" && primaryOptions.length > 0 && (
                                    <Button onClick={GetOutput}>Proceed</Button>
                                )}
                                {classType === "middle" && middleOptions.length > 0 && (
                                    <Button onClick={GetOutput}>Proceed</Button>
                                )}
                            </div>
                        </>
                    )}
                </FullCard>
            </div>
            <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                <DisplayOutput />
            </div>
        </div>
    );
};

export default Science;