import React, { useState } from "react";
import { Button, Slider, Spinner, Card, Select, FullCard } from "../components/CommonComponents";
import { casestudyURL, casestudyInfoURL } from "../api/serverAPI";
import Sidebar from "../components/Sidebar";
import { download, downloadWord } from "../utils";
// import SimpleDropdown from '../components/SimpleDropdown';
import {
    scienceMiddle,
    questionTypes,
    sciencedifficultyLevel,
    tutorBoard,
    tutorGrade,
    tutorSubject,
    tutorTopic,
    context,
} from "../components/Mapping";
import { DragAndDrop, BrowseComputer, FileUpload } from "../components/Fileupload";
import "../App.css";
import Pagination from "../components/Pagination";
import SimpleDropdown from "../components/SimpleDropdown";
import Draggable from "react-draggable";
import axios from 'axios';
import MultiSelectDropdown from "../components/MultiSelectDropdown";
import { BadgeHelp, Check, MessageSquarePlus, Pencil, X } from "lucide-react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { toast, ToastContainer } from "react-toastify";


function b64_urlsafe(s) {
    return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace('=', '')
}
function randomString(n = 16) {
    return b64_urlsafe(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(n))))
}
// eslint-disable-next-line no-control-regex
let sender = randomString();

const lookUp = {
    evs_mcq: "MCQ",
    evs_fillup: "Fill-Up",
}
export const EditableCaseStudy = ({ item, index, generateqaData, setGenerateqaData }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({
        description: item.case_study.description,
        questions: item.questions.map(q => ({
            question_text: q.question_text,
            correct_answer: q.correct_answer,
            options: q.options
        }))
    });

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        const updatedData = generateqaData.map((dataItem, i) => {
            if (i === index) {
                return {
                    ...dataItem,
                    case_study: {
                        ...dataItem.case_study,
                        description: editedData.description
                    },
                    questions: dataItem.questions.map((q, qIndex) => ({
                        ...q,
                        question_text: editedData.questions[qIndex].question_text,
                        correct_answer: editedData.questions[qIndex].correct_answer,
                        options: editedData.questions[qIndex].options
                    }))
                };
            }
            return dataItem;
        });

        setGenerateqaData(updatedData);
        setIsEditing(false);
    };

    const handleDescriptionChange = (e) => {
        setEditedData(prev => ({
            ...prev,
            description: e.target.value
        }));
    };

    const handleQuestionChange = (qIndex, field, value) => {
        setEditedData(prev => ({
            ...prev,
            questions: prev.questions.map((q, i) =>
                i === qIndex
                    ? { ...q, [field]: value }
                    : q
            )
        }));
    };

    const handleOptionChange = (qIndex, optionKey, value) => {
        setEditedData(prev => ({
            ...prev,
            questions: prev.questions.map((q, i) =>
                i === qIndex
                    ? {
                        ...q,
                        options: {
                            ...q.options,
                            [optionKey]: value
                        }
                    }
                    : q
            )
        }));
    };

    return (
        <div className="rounded-lg p-4 relative">
            <div className="absolute top-2 right-2">
                {isEditing ? (
                    <div className="flex gap-2">
                        <button
                            onClick={handleSave}
                            className="p-1 hover:bg-green-100 rounded-full"
                            title="Save"
                        >
                            <Check className="w-5 h-5 text-green-600" />
                        </button>
                        <button
                            onClick={() => {
                                setIsEditing(false);
                                setEditedData({
                                    description: item.case_study.description,
                                    questions: item.questions.map(q => ({
                                        question_text: q.question_text,
                                        correct_answer: q.correct_answer,
                                        options: q.options
                                    }))
                                });
                            }}
                            className="p-1 hover:bg-red-100 rounded-full"
                            title="Cancel"
                        >
                            <X className="w-5 h-5 text-red-600" />
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={handleEdit}
                        className="p-1 hover:bg-blue-100 rounded-full"
                        title="Edit"
                    >
                        <Pencil className="w-5 h-5 text-blue-600" />
                    </button>
                )}
            </div>

            <h2 className="text-xl font-bold mb-4">Case Study {index + 1}: {item.case_study.title}</h2>

            {isEditing ? (
                <textarea
                    value={editedData.description}
                    onChange={handleDescriptionChange}
                    className="w-full p-2 border border-gray-300 rounded min-h-[100px] mb-4"
                />
            ) : (
                <p className="mb-4">{item.case_study.description}</p>
            )}

            {item.questions.map((question, qIndex) => (
                <div key={qIndex} className="mt-6">
                    <div className="flex items-center gap-2">
                        <span className="inline-block px-2 py-1 rounded text-sm text-white font-bold shrink-0" style={{
                            backgroundColor: question.type === 'true_false' ? '#007bff' :
                                question.type === 'multiple_choice' ? '#28a745' : '#6c757d'
                        }}>
                            {question.type === 'true_false' && "True/False"}
                            {question.type === 'multiple_choice' && "Multiple Choice"}
                            {question.type === 'subjective' && "Subjective"}
                        </span>

                        <div className="flex-1 flex items-center">
                            <strong className="mr-2"> #Q{qIndex + 1}:</strong>
                            {isEditing ? (
                                <textarea
                                    value={editedData.questions[qIndex].question_text}
                                    onChange={(e) => handleQuestionChange(qIndex, 'question_text', e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded mt-0 ml-2 inline-block align-middle"
                                />
                            ) : (
                                <span className="ml-2">{question.question_text}</span>
                            )}
                        </div>
                    </div>

                    {question.type === 'true_false' && (
                        <div className="mt-2 ">
                            <strong>Answer: </strong>
                            {isEditing ? (
                                <select
                                    value={editedData.questions[qIndex].correct_answer}
                                    onChange={(e) => handleQuestionChange(qIndex, 'correct_answer', e.target.value)}
                                    className="border border-gray-300 rounded p-1"
                                >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            ) : (
                                question.correct_answer.charAt(0).toUpperCase() + question.correct_answer.slice(1)
                            )}
                        </div>
                    )}

                    {question.type === 'multiple_choice' && (
                        <div className="mt-2 ">
                            <div className="grid grid-cols-2 gap-2 my-2">
                                {Object.entries(question.options).map(([key, value]) => (
                                    <div key={key} className="p-2 border border-gray-300 rounded">
                                        <strong>{key}: </strong>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                value={editedData.questions[qIndex].options[key]}
                                                onChange={(e) => handleOptionChange(qIndex, key, e.target.value)}
                                                className="border border-gray-300 rounded p-1 w-full mt-1"
                                            />
                                        ) : (
                                            value
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="mt-2">
                                <strong>Answer: </strong>
                                {isEditing ? (
                                    <select
                                        value={editedData.questions[qIndex].correct_answer}
                                        onChange={(e) => handleQuestionChange(qIndex, 'correct_answer', e.target.value)}
                                        className="border border-gray-300 rounded p-1"
                                    >
                                        {Object.keys(question.options).map(key => (
                                            <option key={key} value={key}>{key}</option>
                                        ))}
                                    </select>
                                ) : (
                                    question.correct_answer
                                )}
                            </div>
                        </div>
                    )}

                    {question.type === 'subjective' && (
                        <div className="mt-2 ">
                            <strong>Answer: </strong>
                            {isEditing ? (
                                <textarea
                                    value={editedData.questions[qIndex].correct_answer}
                                    onChange={(e) => handleQuestionChange(qIndex, 'correct_answer', e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded mt-1"
                                />
                            ) : (
                                question.correct_answer
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const CaseStudyContent = () => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [base64Data, setBase64Data] = useState(null);
    const [progress, setProgress] = useState(0);
    const [noOfQuestion, setNoOfQuestion] = useState(3);
    const [generateqaData, setGenerateqaData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [classType, setClassType] = useState("primary");
    const [casestudynoOfQuestion, setcasestudyNoOfQuestion] = useState(2);
    const [documentType, setDocumentType] = useState("Single");
    const [info, setInfo] = useState();
    const [difficultyLevel, setDifficultyLevel] = useState([]);
    const [selectedBoard, setSelectedBoard] = useState('CBSE');
    const [selectedSubject, setSelectedSubject] = useState('Mathematics');
    const [selectedGrade, setSelectedGrade] = useState('7');
    const [selectedTopic, setSelectedTopic] = useState('Integers');
    const [subTopic, setSubTopic] = useState("");
    const [middleOptions, setMiddleOptions] = useState([]);
    const [selectedContext, setSelectedContext] = useState("Select Topic")

    // Dummy state to force re-render when updating the global sender variable
    const [, setDummy] = useState(0);

    // Function to refresh sender value
    const refreshSender = () => {
        clearAllVariables();
        sender = randomString(); // Update the global sender variable
        setDummy(prev => prev + 1); // Force re-render by updating a dummy state
        // Trigger a toast notification
        toast.info(`Session Refreshed: ${sender}`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            progress: undefined,
        });
    };

    const clearAllVariables = () => {
        setSelectedFiles(null);
        setBase64Data(null);
        setProgress(0);
        setNoOfQuestion(3);
        setGenerateqaData(null);
        setLoading(false);
        setClassType("primary");
        setcasestudyNoOfQuestion(2);
        setDocumentType("Single");
        setInfo(undefined);
        setDifficultyLevel([]);
        setSelectedBoard('CBSE');
        setSelectedSubject('Mathematics');
        setSelectedGrade('7');
        setSelectedTopic('Integers');
        setSubTopic("");
        setMiddleOptions([]);
        setSelectedContext("Select Topic");

        setEditStates([]);
    };


    const handleSelect = (option) => {
        setClassType(option.value);
    };


    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     setSelectedFiles(file);

    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
    //         setBase64Data(base64String);
    //     };
    //     reader.readAsDataURL(file);

    //     const interval = setInterval(() => {
    //         setProgress((prevProgress) => {
    //             if (prevProgress < 100) {
    //                 return prevProgress + 10;
    //             } else {
    //                 clearInterval(interval);

    //                 return prevProgress;
    //             }
    //         });
    //     }, 1000);
    // };

    // const handleDragOver = (e) => {
    //     e.preventDefault();
    // };

    // const handleDrop = (e) => {
    //     e.preventDefault();
    //     const file = e.dataTransfer.files[0];
    //     setSelectedFiles(file);

    //     if (file) {
    //         if (file.type === "application/pdf") {
    //             setSelectedFiles(file);
    //         } else {
    //             alert("Please upload a PDF file.");
    //         }
    //     }
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         const base64String = reader.result.split(",")[1]; // Extract the Base64 data part
    //         setBase64Data(base64String);
    //     };
    //     reader.readAsDataURL(file);

    //     const interval = setInterval(() => {
    //         setProgress((prevProgress) => {
    //             if (prevProgress < 100) {
    //                 return prevProgress + 10;
    //             } else {
    //                 clearInterval(interval);

    //                 return prevProgress;
    //             }
    //         });
    //     }, 1000);
    // };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        handleFile(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
    };

    const handleFile = (file) => {
        setSelectedFiles(file);
        if (file && file.type === "application/pdf") {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                setBase64Data(base64String);
            };
            reader.readAsDataURL(file);
        }
        else {
            alert("Please upload a PDF file.");
        }
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                } else {
                    clearInterval(interval);

                    return prevProgress;
                }
            });
        }, 1000);
    };

    const handleSubjectChange = (subject) => {
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[selectedGrade][subject][0].text);
    };

    const handleBoard = (option) => {
        setSelectedBoard(option);
    }

    const handleTopicChange = (topic) => {
        setSelectedTopic(topic);
    }

    const handleClassChange = (classItem) => {
        if (classItem.toLowerCase().startsWith(`btech`)) classItem = `13`;
        setSelectedGrade(classItem);
        const subject = getSubjectOptions(classItem)[0].text;
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[classItem][subject][0].text);
    };

    function getSubjectOptions(grade) {
        return (tutorSubject.filter(subject => (tutorGrade.find(item => item.code === grade).subjects).includes(subject.code)));

    }

    const handleMiddleChange = (updatedOptions) => {
        setMiddleOptions(updatedOptions);
    };

    const handleDifficultyLevel = (updatedOptions) => {
        setDifficultyLevel(updatedOptions);
    };

    const downloadPdf = () => {
        const content = document.getElementById('pdf-content');

        // Hide elements with "no-pdf" class
        const noPdfElements = document.querySelectorAll('.no-pdf');
        noPdfElements.forEach(el => (el.style.display = 'none'));

        // Generate PDF
        html2canvas(content, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: [canvas.width + 40, canvas.height + 40], // Add padding
            });
            pdf.addImage(imgData, 'PNG', 20, 20, canvas.width, canvas.height); // Add 20px padding
            pdf.save('output.pdf');

            // Restore visibility of "no-pdf" elements
            noPdfElements.forEach(el => (el.style.display = ''));
        });
    };

    const GetOutput = () => {
        setLoading(true);

        const payload = {
            board: selectedBoard,
            grade: `class${selectedGrade}`,
            subject: selectedSubject.toLowerCase(),
            ...(selectedTopic && { topic: selectedTopic }), // Only include if selectedTopic has a value
            keyConcepts: casestudynoOfQuestion,
            questionCount: noOfQuestion,
            ...(selectedFiles?.name && { filename: selectedFiles.name }), // Only include if selectedFiles.name has a value
            ...(base64Data && { file: base64Data }) // Only include if base64Data has a value
        };

        const url = casestudyURL(); // get only the URL

        axios.post(url, payload)
            .then((response) => {
                console.log("response", response);

                if (response.data && response.data.statusCode === 200 && Array.isArray(response.data.response)) {
                    setGenerateqaData(response.data.response);
                } else {
                    setGenerateqaData(null);
                    alert("Unexpected response format. Please try again.");
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                alert("An error occurred. Please try again later.");
                setLoading(false);
            });
    };


    const DisplayOutput = () => {
        if (loading) {
            return (
                <>
                    <div className="overlay">
                        <Spinner />
                    </div>
                </>
            );
        }
        if (generateqaData) {
            return (
                <div className="my-2">
                    <Card>
                        <div>
                            <div className="border-2 border-[#23479F] rounded-lg">
                                <div className="bg-[#23479F] p-4">
                                    <div class="grid grid-cols-2 gap-4">
                                        <p className="text-white mt-1">
                                            {selectedFiles ? (
                                                <div className='mx-1 line-clamp-1'>
                                                    {selectedFiles.name}
                                                </div>
                                            ) : (
                                                selectedTopic && (
                                                    <div className='mx-1 line-clamp-1'>
                                                        {selectedTopic}
                                                    </div>
                                                )
                                            )}

                                        </p>
                                        <div className="flex flex-nowrap sm:flex-wrap justify-end gap-3">
                                            <div>
                                                <Button size="download" onClick={downloadPdf}>Download</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white overflow-auto mx-auto rounded-lg">

                                    <div className="m-4">
                                        <div id="pdf-content" className="spacearound ">
                                            {outputContent()}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        }
    };

    // Track edit state for each case study
    const [editStates, setEditStates] = useState(
        generateqaData?.map(() => false) || []
    );

    const handleEditToggle = (index) => {
        setEditStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };

    const outputContent = () => {
        if (!generateqaData || !Array.isArray(generateqaData)) return null;

        return generateqaData.map((item, index) => (
            <div>
                <div key={index} className="relative content-to-export">
                    <EditableCaseStudy
                        item={item}
                        index={index}
                        generateqaData={generateqaData}
                        setGenerateqaData={setGenerateqaData}
                    />

                    {index < generateqaData.length - 1 && <hr className="my-8 border-gray-200" />}
                </div>
            </div>
        ));
    };


    const handleInfo = async () => {
        setLoading(true);
        try {
            const url = casestudyInfoURL()
            const response = await axios.get(url);
            setLoading(false);
            if (response.status === 200) {
                setInfo(response.data);
            } else {
                console.error('Failed to generate info', response.data.output);
            }
        } catch (error) {
            setLoading(false);
            console.error('An error occurred while generating info', error);
        }
    };

    const isGenerateDisabled = selectedContext === "Upload File" && progress !== 100;

    return (
        <div className="flex gap-4">
            {info && (
                <Draggable>
                    <div
                        className={`z-50 absolute w-3/5 h-3/4 bg-white rounded-md shadow-lg overflow-auto p-4 items-center`}>
                        <button
                            className="absolute top-4 right-4 text-black"
                            onClick={(e) => { setInfo() }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 16 16">
                                <path fill="currentColor" fill-rule="evenodd" d="M13.5 8a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0M6.53 5.47a.75.75 0 0 0-1.06 1.06L6.94 8L5.47 9.47a.75.75 0 1 0 1.06 1.06L8 9.06l1.47 1.47a.75.75 0 1 0 1.06-1.06L9.06 8l1.47-1.47a.75.75 0 1 0-1.06-1.06L8 6.94z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <div dangerouslySetInnerHTML={{ __html: info }}>
                        </div>
                    </div>
                </Draggable>)}
            <div className="secondcardWidth">
                <FullCard>
                    <div className="flex justify-between items-center mt-2">
                        <div className="subheading text-center">
                            Case Study Generation
                        </div>
                        <button onClick={refreshSender} className="flex items-center mx-2">
                            <MessageSquarePlus className="mr-2" size={20} />
                        </button>
                        <button type="button" className="text-blue-500" onClick={handleInfo} aria-label="Info">
                            <BadgeHelp className="mr-2" size={20} />
                        </button>
                    </div>
                    {/* ToastContainer will show notifications */}
                    <ToastContainer />
                    <div className="my-8">
                        <div className="text-blue text-lg font-semibold my-2">Select Board</div>
                        <Select dropdownData={tutorBoard} value={selectedBoard}
                            onChange={(e) => handleBoard(e.target.value)}
                        />
                    </div>
                    <div className="my-8">
                        <div className="text-blue text-lg font-semibold my-2">Select Grade</div>
                        <Select dropdownData={tutorGrade}
                            value={selectedGrade === '13' ? 'Btech 1st year' : selectedGrade}
                            onChange={(e) => handleClassChange(e.target.value)} />
                    </div>
                    <div className="my-8">
                        <div className="text-blue text-lg font-semibold my-2">Select Subject</div>
                        <Select dropdownData={getSubjectOptions(selectedGrade)} value={selectedSubject}
                            onChange={(e) => handleSubjectChange(e.target.value)}
                        />
                    </div>
                    {selectedSubject === "Science" && (
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Get Context From</div>
                            <Select dropdownData={context} value={selectedContext}
                                onChange={(e) => setSelectedContext(e.target.value)}
                            />
                        </div>
                    )}
                    {selectedSubject === "Science" && selectedContext === "Upload File" && (
                        <div className="my-8">
                            <div className="items-center" onDragOver={handleDragOver}
                                onDrop={handleDrop}>
                                <FileUpload
                                    accept={"application/pdf"}
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className="mt-4 text-center">
                                {selectedFiles && (
                                    <div className="mx-10">
                                        <b>File Name: </b>
                                        {selectedFiles.name}
                                    </div>
                                )}
                                {progress > 0 && (
                                    <div className="my-4">
                                        <div className="bg-[#D2CFF1] h-4 rounded-full">
                                            <div
                                                className="bg-[#23479F] h-full rounded-full"
                                                style={{
                                                    width: `${progress}%`,
                                                }}
                                            ></div>
                                        </div>
                                        <div className="text-center mt-2">{`${progress}% Complete`}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedSubject === "Science" && selectedContext === "Select Topic" && (<div className="my-8">
                        <div className="text-blue text-lg font-semibold my-2">Select Topics</div>
                        <Select dropdownData={tutorTopic[selectedGrade][selectedSubject] || []}
                            value={selectedTopic}
                            onChange={(e) => handleTopicChange(e.target.value)} />
                    </div>)}
                    {selectedSubject !== "Science" && (<div className="my-8">
                        <div className="text-blue text-lg font-semibold my-2">Select Topics</div>
                        <Select dropdownData={tutorTopic[selectedGrade][selectedSubject] || []}
                            value={selectedTopic}
                            onChange={(e) => handleTopicChange(e.target.value)} />
                    </div>)}
                    <div className="my-8">
                        {/* <div className="text-lg font-medium text-indigo-800 text-left my-2">
                            Question Type
                        </div>
                        <div className="w-full">
                            <MultiSelectDropdown
                                options={scienceMiddle}
                                selectedOptions={middleOptions}
                                onChange={handleMiddleChange}
                                placeholder="Select one or more"
                            />
                        </div>
                        <div className="my-8">
                            <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                Difficulty Level
                            </div>
                            <div className="w-full">
                                <MultiSelectDropdown
                                    options={sciencedifficultyLevel}
                                    selectedOptions={difficultyLevel}
                                    onChange={handleDifficultyLevel}
                                    placeholder="Select one or more"
                                />
                            </div>
                        </div> */}
                        <div className="my-2">
                            <div className="my-2 items-center">
                                <div className="font-bold">
                                    Number of case studies
                                </div>
                                <div className="">
                                    <label className="block text-gray-600 flex justify-center w-full">
                                        {casestudynoOfQuestion}
                                    </label>
                                    <Slider
                                        min={1}
                                        max={5}
                                        step={1}
                                        value={casestudynoOfQuestion}
                                        onChange={(e) =>
                                            setcasestudyNoOfQuestion(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="my-2 items-center">
                                <div className="font-bold">
                                    Number of questions per per type
                                </div>
                                <div className="">
                                    <label className="block text-gray-600 flex justify-center w-full">
                                        {noOfQuestion}
                                    </label>
                                    <Slider
                                        min={1}
                                        max={5}
                                        step={1}
                                        value={noOfQuestion}
                                        onChange={(e) =>
                                            setNoOfQuestion(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-8">
                        <div className="text-blue text-lg font-semibold my-2">Sub Topic</div>
                        <div className="w-full text-sm">
                            <textarea
                                className="w-full border border-[#23479F] rounded-lg p-2"
                                placeholder="Mention Sub topic if any."
                                value={subTopic}
                                onChange={(e) =>
                                    setSubTopic(e.target.value)
                                }
                                rows={1}
                            />
                        </div>
                    </div>
                    <div className="my-8">
                        <div className="flex justify-center my-8">
                            <Button
                                onClick={GetOutput}
                                disabled={isGenerateDisabled}
                            >
                                Generate
                            </Button>
                        </div>
                    </div>
                </FullCard>
            </div>
            <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                <DisplayOutput />
            </div>
        </div>
    );
};

export default CaseStudyContent;