import { useEffect, useState } from "react";
import AddUserForm from "../components/AddUserForm";
import { AddOrgForm, AddInstForm } from '../components/AddOrgForm'
import { Button, Spinner } from "../components/CommonComponents";
import { tutorTopic } from "../components/Mapping";
import TutorTopicTree from "../components/TopicTree";
import {
    loginManagementURL,
    getOrganizationsManagementURL,
    getInstitutionsManagementURL,
    getRolesManagementURL,
    getFeaturesManagementURL,
    getSectionsManagementURL,
    getUsersManagementURL,
    createProgramManagementURL,
    createSectionManagementURL,
    createOrganizationManagementURL,
    createInstitutionManagementURL,
    createRoleManagementURL,
    createFeatureManagementURL,
    createSubjectManagementURL
} from "../api/serverAPI";



// Simulated data
const INITIAL_USERS = [
    { id: 1, email: 'admin@test.com', role: 'admin', permissions: ['class', 'subjects', 'topic', 'teacher_account', 'student_account'] },
    { id: 2, email: 'teacher@test.com', role: 'teacher', permissions: ['class', 'subjects', 'topic', 'student_account'] }
];

const INITIAL_ORGS = [
    { id: 1, name: 'Organization 1', institutes: ['Institute A', 'Institute B'] },
    { id: 2, name: 'Organization 2', institutes: ['Institute C'] }
];

const Management = () => {
    const [activeTab, setActiveTab] = useState('users');
    const [showAdminUser, setShowAdminUser] = useState(false);
    const [users, setUsers] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [showAddUser, setShowAddUser] = useState(false);
    const [showAddOrg, setShowAddOrg] = useState(false);
    const [showEditUser, setShowEditUser] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingOrg, setEditingOrg] = useState(null);
    const [editFormData, setEditFormData] = useState({
        name: '',
        institutes: ''
    });
    const [selectedOrgId, setSelectedOrgId] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    // Delete organization
    const handleDelete = (orgId) => {
        setOrganizations(organizations.filter(org => org.id !== orgId));
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleEditUser = () => {
        setShowEditUser(true);
    };

    const handleDeleteUser = (userId) => {
        setUsers(users.filter(user => user.id !== userId));
    };

    const handleAddUser = (newUser) => {
        setUsers([...users, { ...newUser, id: users.length + 1 }]);
        setShowAddUser(false);
    };

    const handleAddOrg = (newOrg) => {
        setOrganizations([...organizations, { ...newOrg, id: organizations.length + 1 }]);
        setShowAddOrg(false);
    };

    const handleSubmit = async (e) => {
        // Map the form data to API input
        const payload = {
            // username: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
            username: "super-admin-test@krtrimaiq.ai",
            password: "super-admin-test"
        };

        try {
            const response = await fetch(loginManagementURL(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Login successful:', data);
                sessionStorage.setItem('accessToken', data.access_token);
            } else {
                console.error('Login failed:', response.status);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    // Fetch Users
    const handleUserTable = async (e) => {
        try {
            const token = sessionStorage.getItem('accessToken');
            const url = getUsersManagementURL();
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            // Transform API data to match frontend structure
            const transformedUsers = data.map((apiUser, index) => ({
                id: index + 1, // Keep frontend ID format (numbers)
                email: apiUser.name, // Using name as email for now
                role: 'pending', // Placeholder until role implementation
                permissions: [], // Placeholder until permissions implementation
                apiId: apiUser.id // Store original API ID if needed
            }));

            setUsers(transformedUsers);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    // Fetch Orgs
    const handleOrgTable = async (e) => {
        try {
            const token = sessionStorage.getItem('accessToken');
            const url = getOrganizationsManagementURL();
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            // Transform API data to match frontend structure
            const transformedOrgs = data.map((apiUser) => ({
                id: apiUser.id,
                organization: apiUser.organization,
            }));

            setOrganizations(transformedOrgs);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    // Wrapper function approach
    const handleClick = async () => {
        setLoading(true);
        await handleSubmit();
        handleUserTable();
        handleOrgTable();
        setLoading(false);
    };

    const handleCreateOrganization = async (orgData) => {
        try {
            const token = sessionStorage.getItem('accessToken');
            const response = await fetch(createOrganizationManagementURL(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(orgData),
            });
            const newOrg = await response.json();
            setOrganizations([...organizations, { ...newOrg, institutes: [] }]); // Add the new organization with an empty institutes list
        } catch (error) {
            console.error('Error creating organization:', error);
        }
    };

    const handleCreateInstitution = async (institutionData, orgId) => {
        try {
            const token = sessionStorage.getItem('accessToken');
            const response = await fetch(createInstitutionManagementURL(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({ ...institutionData, organization: orgId }),
            });
            const newInstitution = await response.json();

            // Update the organization with the new institution
            setOrganizations(organizations.map(org =>
                org.id === orgId
                    ? { ...org, institutes: [...org.institutes, newInstitution.name] }
                    : org
            ));
        } catch (error) {
            console.error('Error creating institution:', error);
        }
    };
    const openPopup = (orgId) => {
        setSelectedOrgId(orgId);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setSelectedOrgId(null);
    };

    return (
        <div>
            {!showEditUser && (<div>
                <nav className="bg-white shadow-md">
                    <div className="max-w-7xl mx-auto px-4">
                        <div className="flex justify-between h-14">
                            <div className="flex">
                                <div className="flex-shrink-0 flex items-center">
                                    <h1 className="text-xl font-bold">Dashboard</h1>
                                </div>
                                <div className="ml-6 flex space-x-4">
                                    <button
                                        onClick={() => setActiveTab('users')}
                                        className={`px-3 py-2 rounded-md text-sm font-medium ${activeTab === 'users' ? 'bg-blue text-white' : 'text-gray-700'
                                            }`}
                                    >
                                        Users
                                    </button>
                                    {!showAdminUser && (
                                        <button
                                            onClick={() => setActiveTab('organizations')}
                                            className={`px-3 py-2 rounded-md text-sm font-medium ${activeTab === 'organizations' ? 'bg-blue text-white' : 'text-gray-700'
                                                }`}
                                        >
                                            Organizations
                                        </button>
                                    )}
                                    {showAdminUser && (
                                        <button
                                            onClick={() => setActiveTab('classes')}
                                            className={`px-3 py-2 rounded-md text-sm font-medium ${activeTab === 'classes' ? 'bg-blue text-white' : 'text-gray-700'
                                                }`}
                                        >
                                            Classes
                                        </button>
                                    )}
                                    <button onClick={() => setShowAdminUser(!showAdminUser)}>
                                        Toggle Admin View
                                    </button>
                                    <Button
                                        onClick={() => handleClick()}
                                    >
                                        Login
                                    </Button>
                                </div>
                                <div className="flex-shrink-0 flex items-center pl-2">
                                    <h1 className="text-xl font-bold">{showAdminUser ? "Admin View" : "Super Admin View"}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <main className="max-w-7xl mx-auto py-6 px-4 h-[78vh] overflow-auto">
                    {activeTab === 'users' && (
                        <div>
                            <div className="flex justify-between mb-4 ">
                                <h2 className="text-xl font-bold">User Management</h2>
                                <Button
                                    onClick={() => {
                                        setShowAddUser(true);
                                    }}
                                >
                                    Add User
                                </Button>
                            </div>

                            <div className="bg-white shadow rounded-lg">
                                <table className="min-w-full">
                                    <thead>
                                        <tr className="bg-gray-50">
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Id</th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Email</th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Role</th>
                                            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {users.map(user => (
                                            <tr key={user.id}>
                                                <td className="px-6 py-4">{user.id}</td>
                                                <td className="px-6 py-4">{user.email}</td>
                                                <td className="px-6 py-4">{user.role}</td>
                                                <td className="px-6 py-4 flex justify-center">
                                                    <button
                                                        onClick={() => {
                                                            handleEditUser();
                                                        }}
                                                        className="px-2 text-red-600 hover:text-red-900"
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        onClick={() => handleDeleteUser(user.id)}
                                                        className="px-2 text-red-600 hover:text-red-900"
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {showAddUser && (
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                                    <AddUserForm role={showAdminUser} onSubmit={handleAddUser} onCancel={() => setShowAddUser(false)} />
                                </div>
                            )}
                        </div>
                    )}
                    {!showAdminUser && (<>
                        {activeTab === 'organizations' && (
                            <div>
                                <div className="flex justify-between mb-4">
                                    <h2 className="text-xl font-bold">Organization Management</h2>
                                    <Button
                                        onClick={() => setShowAddOrg(true)}
                                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                                    >
                                        Add Organization
                                    </Button>
                                </div>

                                <div className="bg-white shadow rounded-lg">
                                    <table className="min-w-full">
                                        <thead>
                                            <tr className="bg-gray-50">
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">ID</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Institutes</th>
                                                <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {organizations.map(org => (
                                                <tr key={org.id}>
                                                    <td className="px-6 py-4">{org.id}</td>
                                                    <td className="px-6 py-4">{org.organization}</td>
                                                    <td className="px-6 py-4">pending</td>
                                                    {/* <td className="px-6 py-4">{org.institutes.join(', ')}</td> */}
                                                    <td className="px-6 py-4 flex justify-center">
                                                        <button
                                                            onClick={() => openPopup(org.id)}
                                                            className="px-2 text-blue-600 hover:text-blue-900"
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            onClick={() => handleDelete(org.id)}
                                                            className="px-2 text-red-600 hover:text-red-900"
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {isPopupOpen && (
                                        <AddInstForm
                                            orgId={selectedOrgId}
                                            onCreateInstitution={handleCreateInstitution}
                                            onClose={closePopup}
                                        />
                                    )}
                                </div>

                                {showAddOrg && (
                                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                                        <AddOrgForm onCreate={handleCreateOrganization} onCancel={() => setShowAddOrg(false)} />
                                    </div>
                                )}
                            </div>
                        )}
                    </>)}
                    {showAdminUser && (<>
                        {activeTab === 'classes' && (<div>
                            <div className="flex justify-between mb-4 ">
                                <h2 className="text-xl font-bold">Classes Management</h2>
                                <Button
                                    onClick={() => {
                                        setShowAddUser(true);
                                    }}
                                >
                                    Add Classes
                                </Button>
                            </div>
                        </div>)}
                    </>)}
                </main>
            </div>)}
            {showEditUser && (<div className="h-[84vh] overflow-auto">
                <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                    <TutorTopicTree tutorTopic={tutorTopic} />
                </div>
                <div className="flex justify-center space-x-2">
                    <Button onClick={() => { setShowEditUser(false) }}>
                        Save
                    </Button>
                    <Button onClick={() => { setShowEditUser(false) }}>
                        Back
                    </Button>
                </div>
            </div>)}

            {loading && (
                <div className="overlay">
                    <Spinner />
                </div>
            )}
        </div>
    );
};


export default Management;