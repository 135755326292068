export const requiredCookies = [
    "email_id",
    "first_name",
    "last_name",
    "theme",
    "voice_flag",
    "domain",
    "chart_flag",
];

export const sorry = [
    {
        recipient_id: "user",
        text: "Sorry, I could not understand the question. Can you please rephrase it or contact us through our website. (https://www.krtrimaiq.ai/)",
    },
];

export const authFields = {
    login: [
        {
            title: "Email",
            name: "email_id",
            type: "email",
        },
        {
            title: "Password",
            name: "password",
            type: "password",
        },
    ],
    signup: [
        {
            title: "Email",
            name: "email_id",
            type: "email",
        },
        {
            title: "First Name",
            name: "first_name",
            type: "text",
        },
        {
            title: "Last Name",
            name: "last_name",
            type: "text",
        },
        {
            title: "Password",
            name: "password",
            type: "password",
        },
        {
            title: "Reconfirm Password",
            name: "reconfirm_password",
            type: "password",
        },
    ],
    settings: [
        {
            title: "Email",
            name: "email_id",
            type: "email",
        },
        {
            title: "First Name",
            name: "first_name",
            type: "text",
        },
        {
            title: "Last Name",
            name: "last_name",
            type: "text",
        },
        {
            title: "Password",
            name: "password",
            type: "password",
        },
        {
            title: "Reconfirm Password",
            name: "reconfirm_password",
            type: "password",
        },
    ],
    reset: [
        {
            title: "Email",
            name: "email_id",
            type: "email",
        },
        {
            title: "Password",
            name: "password",
            type: "password",
        },
        {
            title: "Reconfirm Password",
            name: "reconfirm_password",
            type: "password",
        },
    ],
};

export const themes = [
    {text: "Select Theme", code: ""},
    {text: "Light Theme", code: "light-theme"},
    {text: "Dark Theme", code: "dark-theme"},
];

export const voiceFlags = [
    {text: "Select Voice Flag", code: ""},
    {text: "Enabled", code: "true"},
    {text: "Disabled", code: "false"},
];

export const domains = [
    {text: "Select Domain", code: ""},
    {text: "DVD Store Assistant", code: "dvd-bot"},
    {text: "COVID-19 Health Assistant", code: "covid-bot"},
];

export const content = {
    bgImage: {
        title: "Conversational AI",
        subtitle: "More than a chatbot",
        btn: "Explore Digital Assistants",
    },

    title: "Digital Assistants",
    subtitle: "Merging Mind and Machines",
    description: [
        "In today's fast-paced digital world, digital assistants have emerged as a hot topic and a game-changer across various industries. These intelligent virtual agents are revolutionizing the way businesses interact with customers, streamline operations, and deliver personalized experiences.",
        "Digital assistants can handle complex tasks, automate routine processes, and provide real-time assistance. They continuously learn and improve over time, becoming smarter in understanding user needs. Digital assistants can integrate with existing systems, access information, and offer valuable insights. They enable businesses to engage with customers on multiple channels, ensuring a consistent and convenient experience."
    ],
    list: [
        "Digital assistants can handle complex tasks, automate routine processes, and provide real-time assistance.",
        "They continuously learn and improve over time, becoming smarter in understanding user needs.",
        "Digital assistants can integrate with existing systems, access information, and offer valuable insights.",
        "They enable businesses to engage with customers on multiple channels, ensuring a consistent and convenient experience."
    ],
    imgSrc: "/solutions/digital-assistants/main.svg",
    assistants: {
        title: "Digital Assistants",
        text:"We are presenting 3 flavours of digital assistants for 3 distinct use cases.",
        info:[
            "InsightMax answers business intelligence questions based on the database it is connected to. It can be connected to any standard RDBMS.",
            "InsureBuddy answers questions on policy details, takes personal details including identification details, generates quotations and policies being offered by the organisation.",
            "FAQBot is an interactive assistant replacing traditional static web pages providing answers to frequently asked questions."
        ],
        type: [
            {
                value: "blank",
                imageSrc: "/solutions/digital-assistants/blank-bot.svg",
                title: "How can Digital Assistants help you? ",
                subtitle: "",
                path: "/solutions/digital-assistants/faq-assistant",
                description: [
                    "With their ability to learn and adapt, digital assistants continuously improve over time, becoming smarter and more intuitive in understanding user needs. They can integrate with existing systems and databases, access relevant information, and provide valuable insights and recommendations to users. ",
                    "The rise of digital assistants signifies a shift towards intelligent automation and personalized experiences. They empower businesses to engage with customers on multiple channels, including websites, mobile apps, and messaging platforms, delivering a consistent and convenient experience.",
                ],
                listinfo:[]
            },
            {
                value: "InsightMax",
                imageSrc: "/solutions/digital-assistants/bi-bot.svg",
                title: "InsightMax",
                subtitle: "Empowering Business Intelligence with Digital Assistant",
                path: "/solutions/digital-assistants/business-intelligence-assistant",
                description: [
                    "Welcome to the future of Business Intelligence with InsightMax, your intelligent digital assistant. Designed to enhance decision-making and drive business growth, InsightMax leverages advanced AI capabilities to provide real-time insights, predictive analytics, and actionable recommendations. Say goodbye to complex data analysis and hello to simplified business intelligence. Experience the power of digital assistance with InsightMax and unlock the full potential of your data-driven strategy.",
                ],
                listinfo:[
                    "InsightMax enable users to interact with data in a more human-like way, eliminating the need for complex query languages or technical expertise.",
                    "It facilitates faster decision-making by providing real-time insights and recommendations through natural language conversations such as smart narratives, question and answers, auto insights etc.",
                    "It empowers users to explore data effortlessly through conversational interfaces, reducing the learning curve and increasing adoption."
                ]
            },
            {
                value: "InsureBuddy",
                imageSrc: "/solutions/digital-assistants/insurance-bot.svg",
                title: "InsureBuddy",
                subtitle: "Your Digital Insurance Assistant Revolution",
                path: "/solutions/digital-assistants/insurance-assistant",
                description: [
                    "Experience the future of insurance with InsurBuddy, your trusted digital assistant. Powered by cutting-edge AI technology, InsurBuddy is revolutionizing the way insurance is handled. Discover a new era of insurance with InsurBuddy, your reliable companion for a seamless and engaging insurance journey.",
                ],
                listinfo:[
                    "Enhance customer satisfaction and engagement by enabling customers to communicate with the Digital Assistant using natural language, making interactions more seamless and effortless.",
                    "Streamline policy management processes by allowing customers to easily update their information, make policy inquiries, get quotations and policy purchase through natural language conversations.",
                    "Enable online KYC without any paperwork.",
                    "Simplify claims processing by guiding customers through the claim's submission process, capturing relevant details through intuitive conversations, and providing updates on claim status.",
                    "Utilize InsureBuddy to understand customer sentiment, preferences, and intent, enabling personalized recommendations and tailored insurance offerings."
                ]
            },
            {
                value: "FAQBot",
                imageSrc: "/solutions/digital-assistants/faq-bot.svg",
                title: "FAQBot",
                subtitle: "Empowering Websites with Intelligent Digital Assistants",
                path: "/solutions/digital-assistants/faq-assistant",
                description: [
                    "Introducing InfoWizard, the ultimate digital assistant for FAQ of websites. Say goodbye to static and mundane FAQ pages and embrace a new level of user engagement and satisfaction. Experience the power of intelligent automation with InfoWizard and watch your website engagement soar."
                ],
                listinfo:[
                    "InfoWizard understands user queries through natural language processing and provides real-time, accurate, and relevant answers.",
                    "InfoWizard continuously learns from user interactions to improve its responses over time.",
                    "It ensures a seamless user experience by delivering enhanced customer satisfaction.",
                    "InfoWizard transforms your FAQ website into an interactive knowledge hub.",
                    "It can handle various types of inquiries, including product/service information, troubleshooting, and general guidance."
                ]
            },
        ],
    },
    cardsInfo: {
        title: "What are the benefits of using Digital Assistants",
        cards: [
            {
                icon: "/solutions/digital-assistants/icons/machine-translation.svg",
                title: "Machine Translation",
                description:
                    "Machine Translation Lorem ipsum dolor sit amet consectetur. Ut morbi volutpat dignissim maecenas et augue. ",
            },
            {
                icon: "/solutions/digital-assistants/icons/voice-to-text.svg",
                title: "Voice To Text",
                description:
                    "Voice To Text Lorem ipsum dolor sit amet consectetur. Ut morbi volutpat dignissim maecenas et augue. ",
            },
            {
                icon: "/solutions/digital-assistants/icons/translation.svg",
                title: "Translation",
                description:
                    "Translation Lorem ipsum dolor sit amet consectetur. Ut morbi volutpat dignissim maecenas et augue. ",
            },
        ],
    },
    flexCardsInfo: {
        title: "Features of our platform",
        cards: [
            {
                icon: "/solutions/digital-assistants/icons/increased-productivity.svg",
                title: "Increased Productivity",
                description:
                    "Lorem ipsum dolor sit amet consectetur. Ut morbi volutpat dignissim maecenas et augue fusce commodo. Pellentesque sed purus nibh vitae ut sit. Est orci dignissim in id mauris ipsum.",
            },
            {
                icon: "/solutions/digital-assistants/icons/improved-accuracy.svg",
                title: "Improved Accuracy",
                description:
                    "Lorem ipsum dolor sit amet consectetur. Ut morbi volutpat dignissim maecenas et augue fusce commodo. Pellentesque sed purus nibh vitae ut sit. Est orci dignissim in id mauris ipsum.",
            },
            {
                icon: "/solutions/digital-assistants/icons/reduced-costs.svg",
                title: "Reduced Costs",
                description:
                    "Translation Lorem ipsum dolor sit amet consectetur. Ut morbi volutpat dignissim maecenas et augue. Lorem ipsum dolor sit amet consectetur. Ut morbi volutpat dignissim maecenas et augue fusce commodo. Pellentesque sed purus nibh vitae ut sit. Est orci dignissim in id mauris ipsum.",
            },
            {
                icon: "/solutions/digital-assistants/icons/improved-communication.svg",
                title: "Improved Communication",
                description:
                    "Translation Lorem ipsum dolor sit amet consectetur. Ut morbi volutpat dignissim maecenas et augue. Lorem ipsum dolor sit amet consectetur. Ut morbi volutpat dignissim maecenas et augue fusce commodo. Pellentesque sed purus nibh vitae ut sit. Est orci dignissim in id mauris ipsum.",
            },
        ],
    },
    blog: [
        {
            title: "What is Business Intelligence",
            imageSrc: "/Frame 94.svg",
            description:
                "Transform your business by unlocking the full potential of your data and gain a competitive advantage in today's data-driven world.",
        },
        {
            title: "How to deploy node js to Github packages",
            imageSrc: "/Frame 125.svg",
            description:
                "Transform business by unlocking the full potential of your data and gain a competitive advantage in today's data-driven world.",
        },
    ],
};
