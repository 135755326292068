/* eslint-disable no-unused-vars */
const style_min = `*{margin:0;padding:0;box-sizing:border-box;}.pdf-sheet-wrap{display:flex;/*transform:translateX(-12px);*/position:relative;height:297mm;}.pdf-sheet{width:210mm;height:297mm;background:#fff;//box-shadow:006px2px#c2c1c1d1;overflow:hidden;}.tbox{width:260px;display:flex;cursor:default;border-radius:2.62px;overflow:hidden;z-index:0;padding-right:6.26px;position:absolute;left:0px;top:0px;}.tbody-cont{position:relative;display:flex;align-items:center;width:100%;padding:2.6px;border-top:2.6pxdashedtransparent;border-right:2.6pxdashedtransparent;border-bottom:2.6pxdashedtransparent;min-width:26px;/*height:fit-content;*/}.tbody{width:100%;cursor:text;padding:08.62px00;z-index:1;overflow:hidden;overflow-wrap:break-word;}.tmover{width:12px;visibility:hidden;background:#0078d4;display:flex;align-items:center;justify-content:center;color:#fff;}.resizer{position:absolute;z-index:2;right:-14px;font-size:26px;border-radius:26px;color:#0078d4;cursor:e-resize;display:flex;align-items:center;justify-content:center;visibility:hidden;}`;

const style = `
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-kerning: normal !important;
}

.highlighter  {
  color:attr(data-color) !important;
}

.pdf-sheet-wrap {
    display: flex;
    /* transform: translateX(-12px); */
    position: relative;
}


.pdf-sheet {
    width: 210mm;
    height: 297mm;
    background: #fff;
    overflow: hidden;
    /* letter-spacing: 0px !important; */
}

.tbox {
    width: 260px;
    display: flex;
    cursor: default;
    border-radius: 2.62px;
    overflow: hidden;
    z-index: 0;
    padding-right: 6.26px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.tbody-cont {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2.6px;
    border-top: 2.6px dashed transparent;
    border-right: 2.6px dashed transparent;
    border-bottom: 2.6px dashed transparent;
    min-width: 26px;

    /* height: fit-content; */
}

.tbody {
    width: 100%;
    cursor: text;
    padding: 0 8.62px 0 0;
    z-index: 1;
    overflow: hidden;
    overflow-wrap: break-word;
}

.tmover {
    width: 12px;
    visibility: hidden;
    background: #0078d4;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.resizer {
    position: absolute;
    z-index: 2;
    right: -14px;
    font-size: 26px;
    border-radius: 26px;
    color: #0078d4;
    cursor: e-resize;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
}
`;

export const getHTML = () => {
  const props = {
    contenteditable: "false",
    class: "pdf-sheet",
  };

  const document = window.pdfDoc;
  const pages = [];
  const pagesNodes = document.querySelectorAll(".pdf-sheet");

  for (const node of pagesNodes.values()) {
    const node_ = node.cloneNode(true);
    const container = document.createElement("div");

    const [width, height] = [node_.style.width, node_.style.height];
    props.style = `position: relative; width: ${width}; height: ${height};`;

    Object.entries(props).forEach(([key, value]) => {
      container.setAttribute(key, value);
    });

    for (const node of node_.childNodes.values()) {
      const clonedNode = node.cloneNode(true);

      if (clonedNode.nodeName !== "IMG") {
        // console.log(clonedNode);
        
        try {
          const dataContainers =
           clonedNode.querySelectorAll('.data-container');
          for (let container of dataContainers) {
            const color = container.getAttribute("data-color");
            const children = container.children;
            if (!children) console.log("CHILD #");
            for (let child of children) {
              child.style.color = color;
              // console.log("CHILD ", child);
            }
          }
        } catch (err) {
          throw err;
        }
      }

      container.appendChild(clonedNode);
      if (!clonedNode?.id?.startsWith("clkaway-id--")) {
        continue;
      }

      clonedNode.firstChild.firstChild.remove();

      const [x, y] =
        clonedNode.firstChild.firstChild.style.transform.match(/\d+(\.\d+)?/g);
      clonedNode.firstChild.firstChild.style.transform = `translate(${
        x - 12
      }px, ${y}px)`;
    }
    pages.push(container.outerHTML);
  }

  const html = `<html><head><meta charset='UTF-8'/></head><body><style>${style}</style>${pages.join(
    ""
  )}</body></html>`;
  return html;
};
