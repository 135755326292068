export const questionTypes = [
    {
        code: "Subjective",
        text: "Subjective",
    },
    {
        code: "Fill in the Blanks",
        text: "Fill in the Blanks",
    },
    {
        code: "Multiple Choice Questions",
        text: "Multiple Choice Questions",
    },
    {
        code: "True or False",
        text: "True or False",
    },
];

export const difficultyLevels = [
    {
        code: "Simple",
        text: "Simple",
    },
    {
        code: "Medium",
        text: "Medium",
    },
    {
        code: "Complex",
        text: "Complex",
    },
];

export const taxonomySimple = [
    {
        code: "Remember",
        text: "Remember",
    },
];

export const taxonomySimpleNew = {
    deafultValue: "Remember",
    options: [
        {
            code: "Remember",
            text: "Remember",
        },
    ],
};
export const taxonomyMedium = [
    {
        code: "Understand",
        text: "Understand",
    },
    {
        code: "Apply",
        text: "Apply",
    },
];

export const taxonomyComplex = [
    {
        code: "Analyze",
        text: "Analyze",
    },
    {
        code: "Evaluate",
        text: "Evaluate",
    },
    {
        code: "Create",
        text: "Create",
    },
];

export const dummyDatabase = [
    {
        code: "General",
        text: "General",
    },
];

export const enterpriseSearchVectorStores = [
    { value: "krtrimaIQ", label: "KrtrimaIQ" },
];

export const knowledgeManagementStores = [
    // {value: "Teri", label: "Teri"}
    { value: "asset_management_user_guide", label: "Customer Support" }
];

export const noAgents = [
    {
        code: "1",
        text: "1"
    },
    {
        code: "2",
        text: "2"
    },
    {
        code: "3",
        text: "3"
    },
    {
        code: "4",
        text: "4"
    },
    {
        code: "5",
        text: "5"
    },
]

export const searchTypes = [
    {
        code: "Ask a Question",
        text: "Ask a Question"
    },
    {
        code: "Search documents",
        text: "Search documents"
    }
]

export const roleSelect = [
    {
        code: "Cardiologist",
        text: "Cardiologist",
    },
    {
        code: "Computer Vision Expert",
        text: "Computer Vision Expert",
    },
    {
        code: "Data Science Expert",
        text: "Data Science Expert",
    },
    {
        code: "Dentist",
        text: "Dentist",
    },
    {
        code: "Emergency Medicine Expert",
        text: "Emergency Medicine Expert",
    },
    {
        code: "General Assistant",
        text: "General Assistant",
    },
    {
        code: "General Medicine Expert",
        text: "General Medicine Expert",
    },
    {
        code: "Health Expert",
        text: "Health Expert",
    },
    {
        code: "Legal Specialist",
        text: "Legal Specialist",
    },
    {
        code: "Movie Expert",
        text: "Movie Expert",
    },
    {
        code: "Neurologist",
        text: "Neurologist",
    },
    {
        code: "NLP Expert",
        text: "NLP Expert",
    },
    {
        code: "Pediatrician",
        text: "Pediatrician",
    },
    {
        code: "Physiotherapist",
        text: "Physiotherapist",
    },
    {
        code: "Psychiatrist",
        text: "Psychiatrist",
    },
    {
        code: "Radiology Expert",
        text: "Radiology Expert",
    },
    {
        code: "Trading and Investment",
        text: "Trading and Investment",
    },

];

export const dbSelect = [
    // {
    //     code: "DVD Store",
    //     text: "DVD Store", 
    // }
    { label: 'DVD Store', value: 'DVD Store' }
];
export const artData = [
    { value: "painting", label: "Painting" },
    { value: "drawing", label: "Drawing" },
    { value: "illustration", label: "Illustration" },
    { value: "sketch", label: "Sketch" }
];
export const paletteData = [
    {
        value: "vibrant",
        label: "Vibrant",
    },
    {
        value: "muted",
        label: "Muted",
    },
    {
        value: "monochromatic",
        label: "Monochromatic",
    },
    {
        value: "natural",
        label: "Natural",
    },
    {
        value: "pastel",
        label: "Pastel",
    },
    {
        value: "vivid",
        label: "Vivid",
    },
    {
        value: "blackandwhite",
        label: "Black And White",
    },
];
export const themeData = [
    { value: "natural", label: "Natural" },
    { value: "cartoon", label: "Cartoon" }
];
export const modelData = [
    { label: "DALLE", value: "DALLE" }
];
export const numberOfImages = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" }
];
export const grade = [
    {
        code: "10",
        text: "10"
    }
];
export const evsClassTypes = [
    {
        code: 'Primary (I-V)',
        text: 'Primary (I-V)'
    }
];
export const scienceClassTypes = [
    { label: 'Primary (I-V)', value: 'Primary (I-V)' },
    { label: 'Middle School (VI-VIII)', value: 'Middle School (VI-VIII)' }
];

export const englishClassTypes = [
    { label: 'Primary (I-V)', value: 'primary' },
    { label: 'Middle School (VI-VIII)', value: 'middle' },
];

// dropdownOptions.js
export const englishPrimary = [
    { label: 'Multiple Choice Question', value: 'mcq' },
    { label: 'Fill-Up', value: 'fillup' },
    { label: 'True or False', value: 'truefalse' },
    { label: 'Descriptive (Simple)', value: 'desc_simple' },
    { label: 'Descriptive (Medium)', value: 'desc_medium' },
    { label: 'Descriptive (Hard)', value: 'desc_hard' },
];

export const englishMiddle = [
    { label: 'Multiple Choice', value: 'mcq' },
    { label: 'Fill-Up', value: 'fillup' },
    { label: 'True or False', value: 'truefalse' },
    { label: 'Descriptive (Simple)', value: 'desc_simple' },
    { label: 'Descriptive (Medium)', value: 'desc_midium' },
    { label: 'Descriptive (Hard)', value: 'desc_hard' },
];

export const gkClassTypes = [
    { label: 'Primary (I-V)', value: 'primary' },
    { label: 'Middle School (VI-VIII)', value: 'middle' },
];

// dropdownOptions.js
export const gkPrimary = [
    { label: 'Multiple Choice Question', value: 'mcq' },
    { label: 'Fill-Up', value: 'fillup' },
    { label: 'True or False', value: 'truefalse' },
    { label: 'Who am I', value: 'whoami' },
];

export const gkMiddle = [
    { label: 'Multiple Choice', value: 'mcq' },
    { label: 'Fill-Up', value: 'fillup' },
    { label: 'True or False', value: 'truefalse' },
    { label: 'Who am I', value: 'whoami' },
];
export const tutorRoles = [
    {
        text: 'Teacher',
        code: 'Teacher',
    },
    {
        text: 'Student',
        code: 'Student',
    }
];
export const tRoles = [
    { label: 'Teacher', value: 'Teacher' },
    { label: 'Student', value: 'Student' }
];
export const tutorTeacherMethods = [
    {
        text: 'FAQ generation',
        code: 'faq'
    },
    {
        text: 'Brainstorming',
        code: 'brainstorming'
    }
]

export const tutorSubject = [
    {
        code: "Mathematics",
        text: "Mathematics",
    },
    {
        code: "Science",
        text: "Science",
    },
    {
        code: "Physics",
        text: "Physics",
    },
    {
        code: "English",
        text: "English",
    },
    {
        code: "Chemistry",
        text: "Chemistry",
    },
    {
        code: "Biology",
        text: "Biology",
    },
    {
        code: "History",
        text: "History"
    },
    {
        code: "Hindi",
        text: "Hindi"
    },
    {
        code: "Social Science",
        text: "Social Science"
    },
    {
        code: "WorldAroundUs",
        text: "WorldAroundUs"
    },
    {
        code: "EnvironmentalStudies",
        text: "EnvironmentalStudies"
    }
];

export const tutorBoard = [
    {
        code: "CBSE",
        text: "CBSE"
    }
    // {
    //     code: "ICSE",
    //     text: "ICSE"
    // },
    // {
    //     code: "State Board",
    //     text: "State Board"
    // }
]

export const context = [
    {
        code: "File",
        text: "Upload File"
    },
    {
        code: "Topic",
        text: "Select Topic"
    }
]

export const storyTheme = [
    // {
    //     code: "maharbharata",
    //     text: "Maharbharata"
    // },
    // {
    //     code: "ramayana",
    //     text: "Ramayana"
    // },
    {
        code: "",
        text: "Select Theme"
    },
    {
        code: "vikram_bethala",
        text: "Vikram Bethala"
    },
    {
        code: "panchatantra",
        text: "Panchatantra"
    },
    {
        code: "",
        text: "Custom Theme"
    }
]

export const fileTypes = [
    {
        code: "",
        text: "Select File Type"
    },
    {
        code: "excel",
        text: "Excel"
    },
    {
        code: "pdf",
        text: "PDF"
    },
]

export const readingCompGrade = [
    {
        code: "3",
        text: "3",
        subjects: ["English", "Hindi", "WorldAroundUs"]
    },
    {
        code: "4",
        text: "4",
        subjects: ["English", "Mathematics", "EnvironmentalStudies"] // ["English", "Hindi", "Mathematics", "EnvironmentalStudies"]
    },
    {
        code: "5",
        text: "5",
        subjects: ["English", "Hindi", "Mathematics", "EnvironmentalStudies"]
    },
    {
        code: "6",
        text: "6",
        subjects: ["English", "Hindi", "Mathematics", "Science"]
    },
];

export const tutorGrade = [
    {
        code: "1",
        text: "1",
        subjects: ["English"] //["Mathematics", "English"]
    },
    {
        code: "2",
        text: "2",
        subjects: ["English"] // ["Mathematics", "English"]
    },
    {
        code: "3",
        text: "3",
        subjects: ["English", "Hindi"] // ["Mathematics", "English", "Hindi"]
    },
    {
        code: "4",
        text: "4",
        subjects: ["Mathematics", "English"] // ["Mathematics", "English", "Hindi"] 
    },
    {
        code: "5",
        text: "5",
        subjects: ["Mathematics", "English", "Hindi"]
    },
    {
        code: "6",
        text: "6",
        subjects: ["Mathematics", "Science", "English", "Hindi"]
    },
    {
        code: "7",
        text: "7",
        subjects: ["Mathematics", "Science", "English", "Social Science"]
    },
    {
        code: "8",
        text: "8",
        subjects: ["English", "Mathematics", "Science"]
    },
    {
        code: "9",
        text: "9",
        subjects: ["Mathematics", "Science", "English"] // ["Mathematics", "Science", "English", "History"]
    },
    {
        code: "10",
        text: "10",
        subjects: ["Mathematics", "Science"]
    },
    {
        code: "11",
        text: "11",
        subjects: ["Mathematics", "Biology", "Physics", "Chemistry"]
    },
    {
        code: "12",
        text: "12",
        subjects: ["Mathematics", "Biology", "Physics", "Chemistry"]
    },
];

export const tutorTopic = {

    "1": {
        "English": [
            { "text": "My Family And Me", "code": 1 },
            { "text": "Life Around Us", "code": 2 },
            { "text": "Food", "code": 3 },
            { "text": "Seasons", "code": 4 }
        ],
        "Mathematics": [
            {
                "text": "Finding the Furry Cat!(Pre-number Concepts)",
                "code": 1
            },
            {
                "text": "What is Long? What is Round?(Shapes)",
                "code": 2
            },
            {
                "text": "Mango Treat(Numbers 1 to 9)",
                "code": 3
            },
            {
                "text": "Making 10(Numbers 10 to 20)",
                "code": 4
            },
            {
                "text": "How Many?(Addition and Subtraction of Single Digit Numbers)",
                "code": 5
            },
            {
                "text": "Vegetable Farm(Addition and Subtraction up to 20)",
                "code": 6
            },
            {
                "text": "Lina's Family(Measurement)",
                "code": 7
            },
            {
                "text": "Fun with Numbers(Numbers 21 to 99)",
                "code": 8
            },
            {
                "text": "Utsav(Patterns)",
                "code": 9
            },
            {
                "text": "How do I Spend my Day?(Time)",
                "code": 10
            },
            {
                "text": "How Many Times?(Multiplication)",
                "code": 11
            },
            {
                "text": "How Much Can We Spend?(Money)",
                "code": 12
            },
            {
                "text": "So Many Toys(Data Handling)",
                "code": 13
            }
        ]
    },
    "2": {
        "English": [
            { "text": "Fun with Friends", "code": 1 },
            { "text": "Welcome to My World", "code": 2 },
            { "text": "Going Places", "code": 3 },
            { "text": "Life Around Us", "code": 4 },
            { "text": "Harmony", "code": 5 }
        ],
        "Mathematics": [
            {
                "text": "A Day at the Beach (Counting in Groups)",
                "code": 1
            },
            {
                "text": "Shapes Around Us (3D Shapes)",
                "code": 2
            },
            {
                "text": "Fun with Numbers (Numbers 1 to 100)",
                "code": 3
            },
            {
                "text": "Shadow Story (Togalu) (2D Shapes)",
                "code": 4
            },
            {
                "text": "Playing with Lines (Orientations of a line)",
                "code": 5
            },
            {
                "text": "Decoration for Festival (Addition and Subtraction)",
                "code": 6
            },
            {
                "text": "Rani's Gift (Measurement)",
                "code": 7
            },
            {
                "text": "Grouping and Sharing (Multiplication and Division)",
                "code": 8
            },
            {
                "text": "Which Season is it? (Measurement of Time)",
                "code": 9
            },
            {
                "text": "Fun at the Fair (Money)",
                "code": 10
            },
            {
                "text": "Data Handling",
                "code": 11
            }
        ]
    },
    "3": {
        "English": [
            { "text": "Fun with Friends", "code": 1 },
            { "text": "Toys And Games", "code": 2 },
            { "text": "Good Food", "code": 3 },
            { "text": "The Sky", "code": 4 }
        ],
        "Mathematics": [
            {
                "text": "What's in a Name?",
                "code": 1
            },
            {
                "text": "Toy Joy",
                "code": 2
            },
            {
                "text": "Double Century",
                "code": 3
            },
            {
                "text": "Vacation with My Nani Maa",
                "code": 4
            },
            {
                "text": "Fun with Shapes",
                "code": 5
            },
            {
                "text": "House of Hundreds - I",
                "code": 6
            },
            {
                "text": "Raksha Bandhan",
                "code": 7
            },
            {
                "text": "Fair Share",
                "code": 8
            },
            {
                "text": "House of Hundreds - II",
                "code": 9
            },
            {
                "text": "Fun at Class Party!",
                "code": 10
            },
            {
                "text": "Filling and Lifting",
                "code": 11
            },
            {
                "text": "Give and Take",
                "code": 12
            },
            {
                "text": "Time Goes On",
                "code": 13
            },
            {
                "text": "The Suraikund Fair",
                "code": 14
            }
        ],
        "Hindi": [
            { "text": "Our Environment", "code": 1 }
        ],
        "WorldAroundUs": [
            { "text": "Taking Charge of Waste", "code": 1 }
        ]
    },
    "4": {
        "English": [
            { "text": "Wake Up", "code": 1 },
            { "text": "Nose", "code": 2 },
            { "text": "Run", "code": 3 },
            { "text": "Why Alice in Wonderland", "code": 4 },
            { "text": "Don't be Afraid of the Dark", "code": 5 },
            { "text": "Hiawatha", "code": 6 },
            { "text": "A Watering Rhyme", "code": 7 },
            { "text": "Books", "code": 8 },
            { "text": "The Naughty Boy", "code": 9 }
        ],
        "Mathematics": [
            {
                "text": "Building with Bricks",
                "code": 1
            },
            {
                "text": "Long and Short",
                "code": 2
            },
            {
                "text": "A Trip to Bhopal",
                "code": 3
            },
            {
                "text": "Tick-Tick-Tick",
                "code": 4
            },
            {
                "text": "The Way The World Looks",
                "code": 5
            },
            {
                "text": "The Junk Seller",
                "code": 6
            },
            {
                "text": "Jugs and Mugs",
                "code": 7
            },
            {
                "text": "Carts and Wheels",
                "code": 8
            },
            {
                "text": "Halves and Quarters",
                "code": 9
            },
            {
                "text": "Play with Patterns",
                "code": 10
            },
            {
                "text": "Tables and Shares",
                "code": 11
            },
            {
                "text": "How Heavy? How Light?",
                "code": 12
            },
            {
                "text": "Fields and Fences",
                "code": 13
            },
            {
                "text": "Smart Charts",
                "code": 14
            }
        ],
        "Hindi": [
            { "text": "Innocent clouds", "code": 1 }
        ],
        "EnvironmentalStudies": [
            { "text": "EatingTogether", "code": 1 }
        ]
    },
    "5": {
        "English": [
            { "text": "Ice Cream Man", "code": 1 },
            { "text": "TeamWork", "code": 2 },
            { "text": "My Shadow", "code": 3 },
            { "text": "Crying", "code": 4 },
            { "text": "Rip Van Winkle", "code": 5 },
            { "text": "Class Discussion", "code": 6 },
            { "text": "Topsy-turvy Land", "code": 7 },
            { "text": "Nobody's Friend", "code": 8 },
            { "text": "Sing A Song Of People", "code": 9 },
            { "text": "Malu Bhalu", "code": 10 }
        ],
        "Hindi": [
            { "text": "Raaka's path", "code": 1 }
        ],
        "Mathematics": [
            { "text": "The Fish Tale", "code": 1 },
            { "text": "Shapes and Angles", "code": 2 },
            { "text": "How Many Squares?", "code": 3 },
            { "text": "Parts and Wholes", "code": 4 },
            { "text": "Does it Look the Same?", "code": 5 },
            { "text": "Be My Multiple, I'll be Your Factor", "code": 6 },
            { "text": "Can You See the Pattern?", "code": 7 },
            { "text": "Mapping Your Way", "code": 8 },
            { "text": "Boxes and Sketches", "code": 9 },
            { "text": "Tenths and Hundredths", "code": 10 },
            { "text": "Area and its Boundary", "code": 11 },
            { "text": "Smart Charts", "code": 12 },
            { "text": "Ways to Multiply and Divide", "code": 13 },
            { "text": "How Big? How Heavy?", "code": 14 }
        ],
        "EnvironmentalStudies": [
            { "text": "Walls Tell Stories", "code": 1 }
        ]
    },
    '6': {
        "English": [
            { "text": "Who Did Patrick's Homework", "code": 1 },
            { "text": "How The Dog Found Himself", "code": 2 },
            { "text": "Taro's Reward", "code": 3 },
            { "text": "An Indian-American Women in Space", "code": 4 },
            { "text": "A Different Kind Of School", "code": 5 },
            { "text": "Who I Am", "code": 6 },
            { "text": "Fair Play", "code": 7 },
            { "text": "The Banyan Tree", "code": 8 }
        ],
        "Hindi": [
            { "text": "maatrbhoomi", "code": 1 },
        ],
        "Science": [
            { "text": "The Wonderful World of Science", "code": 1 },
            { "text": "Diversity in the Living World", "code": 2 },
            { "text": "Mindful Eating: A Path to a Healthy Body", "code": 3 },
            { "text": "Exploring Magnets", "code": 4 },
            { "text": "Measurement of Length and Motion", "code": 5 },
            { "text": "Materials Around Us", "code": 6 },
            { "text": "Temperature and its Measurement", "code": 7 },
            { "text": "A Journey through States of Water", "code": 8 },
            { "text": "Methods of Separation in Everyday Life", "code": 9 },
            { "text": "Living Creatures: Exploring their Characteristics", "code": 10 },
            { "text": "Nature's Treasures", "code": 11 },
            { "text": "Beyond Earth", "code": 12 }
        ],
        "Mathematics": [
            { "text": "Patterns in Mathematics", "code": 1 },
            { "text": "Lines and Angles", "code": 2 },
            { "text": "Number Play", "code": 3 },
            { "text": "Data Handling and Presentation", "code": 4 },
            { "text": "Prime Time", "code": 5 },
            { "text": "Perimeter and Area", "code": 6 },
            { "text": "Fractions", "code": 7 },
            { "text": "Playing with Constructions", "code": 8 },
            { "text": "Symmetry", "code": 9 },
            { "text": "The Other Side of Zero", "code": 10 }
        ]
    },
    '7': {
        'Science': [
            {
                text: 'Nutrition in Plants',
                code: 1,
            },
            {
                text: 'Nutrition in Animals',
                code: 2,
            },
            {
                text: 'Heat',
                code: 3,
            },
            {
                text: 'Acids, Bases and Salts',
                code: 4,
            },
            {
                text: 'Physical and Chemical Changes',
                code: 5,
            },
            {
                text: 'Respiration in Organisms',
                code: 6,
            },
            {
                text: 'Transportation in Animals and Plants',
                code: 7,
            },
            {
                text: 'Reproduction in Plants',
                code: 8,
            },
            {
                text: 'Motion and Time',
                code: 9,
            },
            {
                text: 'Electric Current and its Effects',
                code: 10,
            },
            {
                text: 'Light',
                code: 11,
            },
            {
                text: 'Forests: Our Lifeline',
                code: 12,
            },
            {
                text: 'Wastewater Story',
                code: 13,
            },
        ],
        'English': [
            {
                text: 'Three Questions and The Squirrel',
                code: 1,
            },
            {
                text: 'A Gift of Chappals and The Rebel',
                code: 2,
            },
            // {
            //     text: 'Gopal and the Hilsa Fish and The Shed',
            //     code: 3,
            // },
            {
                text: 'The Ashes That Made Trees Bloom and Chivy',
                code: 3,
            },
            {
                text: 'Quality and Trees',
                code: 4,
            },
            {
                text: 'Expert Detectives and Mystery of the Talking Fan',
                code: 5,
            },
            {
                text: 'The Invention of Vita-Wonk, Dad and the Cat and the Tree and Garden Snake',
                code: 6,
            },
            // {
            //     text: 'A Homage to our Brave Soldiers and Meadow Surprises',
            //     code: 8,
            // },
            // {
            //     text: 'A Hero',
            //     code: 9,
            // },
            // {
            //     text: 'The Taste of Watermelon',
            //     code: 10,
            // },
            // {
            //     text: 'The Flower-School',
            //     code: 11,
            // },
        ],
        "Mathematics": [
            {
                text: 'Integers',
                code: 1,
            },
            {
                text: 'Fractions and Decimals',
                code: 2,
            },
            {
                text: 'Data Handling',
                code: 3,
            },
            {
                text: 'Simple Equations',
                code: 4,
            },
            {
                text: 'Lines and Angles',
                code: 5,
            },
            {
                text: 'The Triangle and its Properties',
                code: 6,
            },
            {
                text: 'Comparing Quantities',
                code: 7,
            },
            {
                text: 'Rational Numbers',
                code: 8,
            },
            {
                text: 'Perimeter and Area',
                code: 9,
            },
            {
                text: 'Algebraic Expressions',
                code: 10,
            },
            {
                text: 'Exponents and Powers',
                code: 11,
            },
            {
                text: 'Symmetry',
                code: 12,
            },
            {
                text: 'Visualising Solid Shapes',
                code: 13,
            },
        ],
        "Social Science": [
            {
                text: 'Medieval India',
                code: 1,
            }
        ]
    },
    '8': {
        'English': [
            {
                text: 'The Best Christmas Present in the World & The Ant and the Cricket',
                code: 1,
            },
            {
                text: 'The Tsunami & Geography Lesson',
                code: 2,
            },
            // {
            //     text: 'Glimpses of the Past',
            //     code: 3,
            // },
            // {
            //     text: "Bepin Choudhury’s Lapse of Memory & The Last Bargain",
            //     code: 4,
            // },
            // {
            //     text: 'The Summit Within & The School Boy',
            //     code: 5,
            // },
            // {
            //     text: "This is Jody's Fawn",
            //     code: 6,
            // },
            // {
            //     text: 'A Visit to Cambridge',
            //     code: 7,
            // },
            // {
            //     text: 'A Short Monsoon Diary & On the Grasshopper and Cricket',
            //     code: 8,
            // },
        ],
        'Mathematics': [
            {
                text: 'Rational Numbers',
                code: 1,
            },
            {
                text: 'Linear Equations in One Variable',
                code: 2,
            },
            {
                text: 'Understanding Quadrilaterals',
                code: 3,
            },
            {
                text: "Data Handling",
                code: 4,
            },
            {
                text: 'Squares and Square Roots',
                code: 5,
            },
            {
                text: "Cubes and Cube Roots",
                code: 6,
            },
            {
                text: 'Comparing Quantities',
                code: 7,
            },
            {
                text: 'Algebraic Expressions and Identities',
                code: 8,
            },
            {
                text: 'Mensuration',
                code: 9,
            },
            {
                text: 'Exponents and Powers',
                code: 10,
            },
            {
                text: 'Direct and Inverse Proportions',
                code: 11,
            },
            // {
            //     text: 'Factorisation',
            //     code: 12,
            // },
            {
                text: 'Introduction to Graphs',
                code: 13,
            },
        ],
        'Science': [
            {
                text: 'CROP PRODUCTION AND MANAGEMENT',
                code: 1,
            },
            {
                text: 'MICROORGANISMS : FRIEND AND FOE',
                code: 2,
            },
            {
                text: 'COAL AND PETROLEUM',
                code: 3,
            },
            {
                text: 'COMBUSTION AND FLAME',
                code: 4,
            },
            {
                text: 'CONSERVATION OF PLANTS AND ANIMALS',
                code: 5,
            },
            {
                text: 'REPRODUCTION IN ANIMALS',
                code: 6,
            },
            {
                text: 'REACHING THE AGE OF ADOLESCENCE',
                code: 7,
            },
            // {
            //     text: 'FORCE AND PRESSURE',
            //     code: 8,
            // },
            {
                text: 'FRICTION',
                code: 9,
            },
            // {
            //     text: 'SOUND',
            //     code: 10,
            // },
            {
                text: 'CHEMICAL EFFECTS OF ELECTRIC CURRENT',
                code: 11,
            },
            {
                text: 'SOME NATURAL PHENOMENA',
                code: 12,
            },
            {
                text: 'LIGHT',
                code: 13
            }
        ]
    },
    '9': {
        'English': [
            {
                text: 'The Fun They Had & The Road Not Taken',
                code: 1,
            },
            {
                text: 'The Sound of Music & Wind',
                code: 2,
            },
            // {
            //     text: 'The Little Girl & Rain on the Roof',
            //     code: 3,
            // },
            // {
            //     text: "A Truly Beautiful Mind & The Lake Isle of Innisfree",
            //     code: 4,
            // },
            {
                text: 'The Snake and the Mirror & A Legend of the Northland',
                code: 5,
            },
            {
                text: "My Childhood & No Men Are Foreign",
                code: 6,
            },
            // {
            //     text: 'Reach for the Top & On Killing a Tree',
            //     code: 7,
            // },
            // {
            //     text: 'Kathmandu & A Slumber Did My Spirit Seal',
            //     code: 8,
            // },
            {
                text: 'If I Were You',
                code: 9,
            },
        ],
        'Mathematics': [
            {
                text: 'NUMBER SYSTEMS',
                code: 1,
            },
            {
                text: 'POLYNOMIALS',
                code: 2,
            },
            {
                text: 'COORDINATE GEOMETRY',
                code: 3,
            },
            {
                text: "LINEAR EQUATIONS IN TWO VARIABLES",
                code: 4,
            },
            {
                text: 'INTRODUCTION TO EUCLID’S GEOMETRY',
                code: 5,
            },
            // {
            //     text: "LINES AND ANGLES",
            //     code: 6,
            // },
            // {
            //     text: 'TRIANGLES',
            //     code: 7,
            // },
            // {
            //     text: 'QUADRILATERALS',
            //     code: 8,
            // },
            {
                text: 'CIRCLES',
                code: 6,
            },
            {
                text: 'HERON’S FORMULA',
                code: 7,
            },
            {
                text: 'SURFACE AREAS AND VOLUMES',
                code: 8,
            },
            {
                text: 'STATISTICS',
                code: 9,
            }
        ],
        'Science': [
            {
                text: 'MATTER IN OUR SURROUNDINGS',
                code: 1,
            },
            {
                text: 'IS MATTER AROUND US PURE?',
                code: 2,
            },
            {
                text: 'ATOMS AND MOLECULES',
                code: 3,
            },
            {
                text: 'STRUCTURE OF THE ATOM',
                code: 4,
            },
            {
                text: 'THE FUNDAMENTAL UNIT OF LIFE',
                code: 5,
            },
            {
                text: 'TISSUES',
                code: 6,
            },
            {
                text: 'MOTION',
                code: 7,
            },
            {
                text: 'FORCE AND LAWS OF MOTION',
                code: 8,
            },
            {
                text: 'GRAVITATION',
                code: 9,
            },
            {
                text: 'WORK AND ENERGY',
                code: 10,
            },
            {
                text: 'SOUND',
                code: 11,
            },
            {
                text: 'IMPROVEMENT IN FOOD RESOURCES',
                code: 12,
            },
            // {
            //     text: 'MATTER AND MATERIALS',
            //     code: 13
            // }
        ],
        'History': [
            {
                text: 'Turkish Invasion',
                code: 4,
            },
            {
                text: 'Vijayanagar and Bahmani Kingdoms',
                code: 7,
            },
            {
                text: 'Akbar',
                code: 9,
            },
        ]
    },
    '10': {
        'Mathematics': [
            {
                text: 'Real Numbers',
                code: 1,
            },
            {
                text: 'Polynomials',
                code: 2,
            },
            {
                text: 'Pair of Linear Equations in Two Variables',
                code: 3,
            },
            {
                text: "Quadratic Equations",
                code: 4,
            },
            {
                text: 'Arithmetic Progressions',
                code: 5,
            },
            {
                text: "Triangles",
                code: 6,
            },
            {
                text: 'Coordinate Geometry',
                code: 7,
            },
            {
                text: 'Introduction to Trigonometry',
                code: 8,
            },
            {
                text: 'Some Applications of Trigonometry',
                code: 9,
            },
            {
                text: 'Circles',
                code: 10,
            },
            {
                text: 'Areas Related to Circles',
                code: 11,
            },
            {
                text: 'Surface Areas and Volumes',
                code: 12,
            },
            {
                text: 'Statistics',
                code: 13,
            },
            {
                text: 'Probability',
                code: 14,
            }
        ],
        'Science': [
            {
                text: 'Chemical Reactions and Equations',
                code: 1,
            },
            {
                text: 'Acids, Bases and Salts',
                code: 2,
            },
            {
                text: 'Metals and Non-metals',
                code: 3,
            },
            {
                text: 'Carbon and its Compounds',
                code: 4,
            },
            {
                text: 'Life Processes',
                code: 5,
            },
            {
                text: 'Control and Coordination',
                code: 6,
            },
            {
                text: 'How do Organisms Reproduce?',
                code: 7,
            },
            {
                text: 'Heredity',
                code: 8,
            },
            {
                text: 'Light – Reflection and Refraction',
                code: 9,
            },
            {
                text: 'The Human Eye and the Colourful World',
                code: 10,
            },
            {
                text: 'Electricity',
                code: 11,
            },
            {
                text: 'Magnetic Effects of Electric Current',
                code: 12,
            },
            {
                text: 'Our Environment',
                code: 13
            }
        ]
    },
    "11": {
        "Mathematics": [
            {
                "text": "SETS",
                "code": 1
            },
            {
                "text": "RELATIONS AND FUNCTIONS",
                "code": 2
            },
            {
                "text": "TRIGONOMETRIC FUNCTIONS",
                "code": 3
            },
            {
                "text": "TRIGONOMETRIC FUNCTIONS - JEE QUESTIONS",
                "code": 4
            },
            {
                "text": "COMPLEX NUMBERS AND QUADRATIC EQUATIONS",
                "code": 5
            },
            {
                "text": "LINEAR INEQUALITIES",
                "code": 6
            },
            {
                "text": "PERMUTATIONS AND COMBINATIONS",
                "code": 7
            },
            {
                "text": "BINOMIAL THEOREM",
                "code": 8
            },
            {
                "text": "SEQUENCES AND SERIES",
                "code": 9
            },
            {
                "text": "STRAIGHT LINES",
                "code": 10
            },
            {
                "text": "CONIC SECTIONS",
                "code": 11
            },
            {
                "text": "INTRODUCTION TO THREE DIMENSIONAL GEOMETRY",
                "code": 12
            },
            {
                "text": "LIMITS AND DERIVATIVES",
                "code": 13
            },
            {
                "text": "STATISTICS",
                "code": 14
            },
            {
                "text": "PROBABILITY",
                "code": 15
            }
        ],
        "Physics": [
            {
                "text": "UNITS AND MEASUREMENT",
                "code": 1
            },
            {
                "text": "MOTION IN A STRAIGHT LINE",
                "code": 2
            },
            {
                "text": "MOTION IN A PLANE",
                "code": 3
            },
            {
                "text": "LAWS OF MOTION",
                "code": 4
            },
            {
                "text": "WORK, ENERGY AND POWER",
                "code": 5
            },
            {
                "text": "SYSTEMS OF PARTICLES AND ROTATIONAL MOTION",
                "code": 6
            },
            {
                "text": "GRAVITATION",
                "code": 7
            },
            {
                "text": "MECHANICAL PROPERTIES OF SOLIDS",
                "code": 8
            },
            {
                "text": "MECHANICAL PROPERTIES OF FLUIDS",
                "code": 9
            },
            {
                "text": "THERMODYNAMICS",
                "code": 10
            },
            {
                "text": "KINETIC THEORY",
                "code": 11
            },
            {
                "text": "OSCILLATIONS",
                "code": 12
            },
            {
                "text": "WAVES",
                "code": 13
            }
        ],
        "Chemistry": [
            {
                "text": "SOME BASIC CONCEPTS OF CHEMISTRY",
                "code": 1
            },
            {
                "text": "Structure of atom",
                "code": 2
            },
            {
                "text": "Classification of Elements and Periodicity in Properties",
                "code": 3
            },
            {
                "text": "Chemical Bonding And Molecular Structure",
                "code": 4
            },
            {
                "text": "Thermodynamics",
                "code": 5
            },
            {
                "text": "Equilibrium",
                "code": 6
            },
            {
                "text": "redox Reactions",
                "code": 7
            },
            {
                "text": "Organic chemistry - Some Basic Principles And Techniques",
                "code": 8
            },
            {
                "text": "Hydrocarbons",
                "code": 9
            }
        ],
        "Biology": [
            {
                "text": "THE LIVING WORLD",
                "code": 1
            },
            {
                "text": "BIOLOGICAL CLASSIFICATION",
                "code": 2
            },
            {
                "text": "PLANT KINGDOM",
                "code": 3
            },
            {
                "text": "ANIMAL KINGDOM",
                "code": 4
            },
            {
                "text": "MORPHOLOGY OF FLOWERING PLANTS",
                "code": 5
            },
            {
                "text": "ANATOMY OF FLOWERING PLANTS",
                "code": 6
            },
            {
                "text": "STRUCTURAL ORGANISATION IN ANIMALS",
                "code": 7
            },
            {
                "text": "CELL: THE UNIT OF LIFE",
                "code": 8
            },
            {
                "text": "BIOMOLECULES",
                "code": 9
            },
            {
                "text": "CELL CYCLE AND CELL DIVISION",
                "code": 10
            },
            {
                "text": "PHOTOSYNTHESIS IN HIGHER PLANTS",
                "code": 11
            }
        ]
    },
    "12": {
        "Mathematics": [
            {
                "text": "RELATIONS AND FUNCTIONS",
                "code": 1
            },
            {
                "text": "DETERMINANTS",
                "code": 2
            },
            {
                "text": "CONTINUITY AND DIFFERENTIABILITY",
                "code": 3
            },
            {
                "text": "APPLICATION OF DERIVATIVES",
                "code": 4
            },
            {
                "text": "Integrals",
                "code": 5
            },
            {
                "text": "APPLICATION OF INTEGRALS",
                "code": 6
            },
            {
                "text": "DIFFERENTIAL EQUATIONS",
                "code": 7
            },
            {
                "text": "VECTOR ALGEBRA",
                "code": 8
            },
            {
                "text": "THREE DIMENSIONAL GEOMETRY",
                "code": 9
            },
            {
                "text": "LINEAR PROGRAMMING",
                "code": 10
            }
        ],
        "Physics": [
            {
                "text": "ELECTRIC CHARGES AND FIELDS",
                "code": 1
            },
            {
                "text": "ELECTROSTATIC POTENTIAL AND CAPACITANCE",
                "code": 2
            },
            {
                "text": "CURRENT ELECTRICITY",
                "code": 3
            },
            {
                "text": "MOVING CHARGES AND MAGNETISM",
                "code": 4
            },
            {
                "text": "MAGNETISM AND MATTER",
                "code": 5
            },
            {
                "text": "ELECTROMAGNETIC WAVES",
                "code": 6
            },
            {
                "text": "WAVE OPTICS",
                "code": 7
            },
            {
                "text": "DUAL NATURE OF RADIATION AND MATTER",
                "code": 8
            },
            {
                "text": "ATOMS",
                "code": 9
            },
            {
                "text": "NUCLEI",
                "code": 10
            }
        ],
        "Chemistry": [
            {
                "text": "Solutions",
                "code": 1
            },
            {
                "text": "Electrochemistry",
                "code": 2
            },
            {
                "text": "Chemical Kinetics",
                "code": 3
            },
            {
                "text": "he d- and f- Block Elements",
                "code": 4
            },
            {
                "text": "Coordination Compounds",
                "code": 5
            },
            {
                "text": "Haloakanes And Haloarenes",
                "code": 6
            },
            {
                "text": "Alcohols, Phenols and Ethers",
                "code": 7
            },
            {
                "text": "Aldehydes, Ketones and Carboxylic Acids",
                "code": 8
            },
            {
                "text": "Amines",
                "code": 9
            }
        ],
        "Biology": [
            {
                "text": " SEXUAL REPRODUCTION IN FLOWERING PLANTS",
                "code": 1
            },
            {
                "text": "Human Reproduction",
                "code": 2
            },
            {
                "text": "Reproductive Health",
                "code": 3
            },
            {
                "text": "Principles Of Inheritance And Variation",
                "code": 4
            },
            {
                "text": "Molecular basis Of Inheritance",
                "code": 5
            },
            {
                "text": "Evolution",
                "code": 6
            },
            {
                "text": "Human Health Abd Disease",
                "code": 7
            },
            {
                "text": "Microbes In Human Welfare",
                "code": 8
            },
            {
                "text": "Biotechnology : Principles And Processes",
                "code": 9
            }
        ]
    }
};

export const mathClassTypes = [
    { label: "Class 1", value: "Class 1" },
    { label: "Class 2", value: "Class 2" }
];

export const sciencePrimary = [
    { label: 'Multiple Choice', value: 'mcq' },
    { label: 'Fill-Up', value: 'fillup' },
    { label: 'True or False', value: 'truefalse' },
    { label: 'Descriptive', value: 'desc' },
];

export const scienceMiddle = [
    { label: 'Multiple Choice', value: 'MCQ' },
    { label: 'Fill in the Blanks', value: 'Fill in the Blanks' },
    { label: 'True or False', value: 'True or False' },
    { label: 'Descriptive', value: 'Descriptive' },
    // { label: 'Multiple Choice', value: 'mcq' },
    // { label: 'Fill-Up', value: 'fillup' },
    // { label: 'True or False', value: 'truefalse' },
    // { label: 'Descriptive', value: 'desc' },
];

export const sClassTypes = [
    { label: 'Primary (I-V)', value: 'primary' },
    { label: 'Middle School (VI-VIII)', value: 'middle' },
];
export const sciencedifficultyLevel = [
    { label: 'Remember', value: 'Remember', additionalLabel: "Simple" }, // Added 'additionalLabel'
    { label: 'Understand', value: 'Understand', additionalLabel: "Medium" }, // Added 'additionalLabel' },
    { label: 'Apply', value: 'Apply' },
    { label: 'Analyze', value: 'Analyze', additionalLabel: "Complex" }, // Added 'additionalLabel'
    { label: 'Evaluate', value: 'Evaluate' },
    { label: 'Create', value: 'Create' },
    // { label: 'Remember', value: 'remember', additionalLabel: "Simple" }, // Added 'additionalLabel'
    // { label: 'Understand', value: 'understand', additionalLabel: "Medium" }, // Added 'additionalLabel' },
    // { label: 'Apply', value: 'apply' },
    // { label: 'Analyze', value: 'analyze', additionalLabel: "Complex" }, // Added 'additionalLabel'
    // { label: 'Evaluate', value: 'evaluate' },
    // { label: 'Create', value: 'create' },
];

export const comprehensionClassTypes = [
    { label: 'Primary (I-V)', value: 'primary' },
    { label: 'Middle School (VI-VIII)', value: 'middle' },
];

export const comprehensionStyles = [
    { label: 'Narrative', value: 'narrative' },
    { label: 'Descriptive', value: 'descriptive' },
    { label: 'Informative', value: 'informative' },
];

export const imgThemes = [
    { label: 'Cartoon', value: 'cartoon' },
    { label: 'Realistic', value: 'realistic' },
    { label: 'Fantasy', value: 'fantasy' },
    { label: 'Watercolor', value: 'watercolor' },
    { label: 'Minimalist', value: 'minimalist' }
]

export const translationLanguages = [
    { label: 'Hindi', value: 'hi' },
    { label: 'Bengali', value: 'bn' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Assamese', value: 'as' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Kannada', value: 'kn' },
    { label: 'Telugu', value: 'te' },
    { label: 'Malayalam', value: 'ml' },
    { label: 'English', value: 'en' },
    { label: 'French', value: 'fr' },
    { label: 'German', value: 'de' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
]
/***
 * {'afrikaans': 'af', 'albanian': 'sq', 'amharic': 'am', 'armenian': 'hy', 'aymara': 'ay', 'azerbaijani': 'az', 'bambara': 'bm', 'basque': 'eu', 'belarusian': 'be', ''bhojpuri': 'bho', 'bosnian': 'bs', 'bulgarian': 'bg', 'catalan': 'ca', 'cebuano': 'ceb', 'chichewa': 'ny', 'chinese (simplified)': 'zh-CN', 'chinese (traditional)': 'zh-TW', 'corsican': 'co', 'croatian': 'hr', 'czech': 'cs', 'danish': 'da', 'dhivehi': 'dv', 'dogri': 'doi', 'dutch': 'nl', 'esperanto': 'eo', 'estonian': 'et', 'ewe': 'ee', 'filipino': 'tl', 'finnish': 'fi', 'frisian': 'fy', 'galician': 'gl',
 * 'georgian': 'ka', 'greek': 'el', 'guarani': 'gn', 'haitian creole': 'ht', 'hausa': 'ha', 'hawaiian': 'haw', 'hebrew': 'iw',
 * 'hmong': 'hmn', 'hungarian': 'hu', 'icelandic': 'is', 'igbo': 'ig', 'ilocano': 'ilo', 'indonesian': 'id',
 * 'irish': 'ga', 'javanese': 'jw','kazakh': 'kk', 'khmer': 'km', 'kinyarwanda': 'rw', 'konkani': 'gom', 'korean': 'ko',
 * 'krio': 'kri', 'kurdish (kurmanji)': 'ku', 'kurdish (sorani)': 'ckb', 'kyrgyz': 'ky', 'lao': 'lo', 'latin': 'la',
 * 'latvian': 'lv', 'lingala': 'ln', 'lithuanian': 'lt', 'luganda': 'lg', 'luxembourgish': 'lb', 'macedonian': 'mk',
 * 'maithili': 'mai', 'malagasy': 'mg', 'malay': 'ms', 'maltese': 'mt', 'maori': 'mi', 'marathi': 'mr',
 * 'meiteilon (manipuri)': 'mni-Mtei', 'mizo': 'lus', 'mongolian': 'mn', 'myanmar': 'my', 'nepali': 'ne',
 * 'norwegian': 'no', 'odia (oriya)': 'or', 'oromo': 'om', 'pashto': 'ps', 'persian': 'fa', 'polish': 'pl',
 * 'portuguese': 'pt', 'punjabi': 'pa', 'quechua': 'qu', 'romanian': 'ro', 'russian': 'ru', 'samoan': 'sm',
 * 'sanskrit': 'sa', 'scots gaelic': 'gd', 'sepedi': 'nso', 'serbian': 'sr', 'sesotho': 'st', 'shona': 'sn', 'sindhi': 'sd',
 * 'sinhala': 'si', 'slovak': 'sk', 'slovenian': 'sl', 'somali': 'so', 'spanish': 'es', 'sundanese': 'su',
 * 'swahili': 'sw', 'swedish': 'sv', 'tajik': 'tg', 'tatar': 'tt', 'thai': 'th', 'tigrinya': 'ti', 'tsonga': 'ts',
 * 'turkish': 'tr', 'turkmen': 'tk', 'twi': 'ak', 'ukrainian': 'uk', 'urdu': 'ur', 'uyghur': 'ug', 'uzbek': 'uz',
 * 'vietnamese': 'vi', 'welsh': 'cy', 'xhosa': 'xh', 'yiddish': 'yi', 'yoruba': 'yo', 'zulu': 'zu'}
 */


export const llm = {
    'OpenAI': [
        // { code: "gpt3_turbo", text: "GPT-3.5-turbo" },
        // { code: "gpt4", text: "GPT-4" },
        // { code: "gpt4_turbo", text: "GPT-4-turbo" },
        {code: "gpt-4o-mini", text: "GPT-4o Mini"},
        {code: "gpt4o", text: "GPT-4o"},
        
    ],
    // 'Anthropic': [
    //     { code: "claude_opus", text: "Claude Opus" },
    //     { code: "claude_sonnet", text: "Claude Sonnet" },
    //     { code: "claude_haiku", text: "Claude Haiku" },
    // ]
};

export const organisation = [
    { code: "OpenAI_GPT", text: "OpenAI" },
    // { code: "Anthropic_Claude", text: "Anthropic" },
];

export const langTranslation = [
    {
        code: "en",
        text: "English",
    },
    {
        code: "hi",
        text: "Hindi",
    },
    {
        code: "as",
        text: "Assamese",
    },
    {
        code: "mr",
        text: "Marathi",
    },
    {
        code: "ta",
        text: "Tamil",
    },
    {
        code: "bn",
        text: "Bengali",
    },
    {
        code: "kn",
        text: "Kannada",
    },
    {
        code: "or",
        text: "Oriya",
    },
    {
        code: "te",
        text: "Telugu",
    },
    {
        code: "gu",
        text: "Gujarati",
    },
    {
        code: "ml",
        text: "Malayalam",
    },
    {
        code: "pa",
        text: "Punjabi",
    },
    {
        code: "mn",
        text: "Manipuri",
    },
    {
        code: "ur",
        text: "Urdu",
    },
    {
        code: "sd",
        text: "Sindhi",
    },
]

export const adaptiveLearningQuePatterns = [
    {
        code: "fill_in_the_blank",
        text: "Fill-Up"
    },
    {
        code: "multiple_choice",
        text: "Multiple Choice Question"
    },
    {
        code: "descriptive",
        text: "Descriptive"
    },
    {
        code: "true_false",
        text: "True or False"
    }
];

export const adaptiveLearningComplexity = [
    {
        code: "beginner",
        text: "Beginner"
    },
    {
        code: "intermediate",
        text: "Intermediate"
    },
    {
        code: "advanced",
        text: "Advanced"
    }
];


export const essayCategory = [
    {
        code: "",
        text: "Select a Category"
    },
    {
        code: "question_based",
        text: "Question Based"
    },
    {
        code: "missing",
        text: "Missing Section"
    },
    {
        code: "attempt",
        text: "Attempt"
    }
]