/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState, useContext } from "react";
import Sheet from "./components/Sheet";
import Toolbar from "./components/Toolbar";
import { nanoid } from "nanoid";
// import { BsInputCursorText } from "react-icons/bs";
// import "./PdfEditor.css";
// import { handleInsertTextEvent, startInsertTextEvent } from "./utils/handler";
// import DOM from "./utils/dom";
// import ClickAway from "./common/ClickAway";
import Iframe from "./common/Iframe";
import * as store from "./common/store";
// import ContextMenu from "./common/ContextMenu";
import { template } from "./sample/data.js";
import {
  createIntersectionObserver,
  createMutationObserver,
  deleteById,
  toPx,
} from "./utils/utils";
// import Spinner from "./common/Spinner";


const eventMap = {
  insertText: false,
  editingText: false,
  cancel: false,
};

function generateNewBlock(parentId, position, options = {}) {
  const { type = "text", focused = true } = options;
  return {
    id: parentId,
    content: {
      id: nanoid(5),
      insert: true,
      type,
      focused,
      data: "",
      position,
      style: {
        size: 16,
      },
    },
  };
}

function PdfEditor(props) {
  const readOnly = props.readOnly ?? true;
  const [data, setData] = useState(props.data);

  useEffect(()=>{
      setData(props.data);
  },[props.data])
  // const [insertText, setInsertText] = useState(null);
  

  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState({
    key: null,
    id: null,
    value: null,
  });

  useEffect(() => {
    // Update dataCopy when the data prop changes
    // setDataCopy(data);
    store.init(data);
  }, [data]);

  function stopInsertText() {
    const sheets = document.getElementsByClassName("pdf-sheet");
    for (let i = 0; i < sheets.length; i++) {
      const sheet = sheets[i];
      sheet.style.cursor = "default";
      
    }
    eventMap.insertText = false;
    eventMap.cancel = true;
    window.removeEventListener("keydown", handleKeydown);
  }

  

  function handleKeydown(e) {
    if (e.key === "Escape") {
      stopInsertText();
    }
  }
  

  function handleClick(e, id) {
    if (eventMap.insertText) {
      return;
    }
    eventMap.cancel = false;
    eventMap.insertText = true;
    const sheets = document.getElementsByClassName("pdf-sheet");
    for (let i = 0; i < sheets.length; i++) {
      const sheet = sheets[i];
      sheet.style.cursor = "text";
      
    }
    window.addEventListener("keydown", handleKeydown);
  }

 

  function handleContextMenu(e, key) {
    const pageId = `sheet-id--${key}`;
    const pageNode = window.pdfDoc.getElementById(pageId);
    
    if (!pageNode.contains(e.target)) {
      return;
    }
    if(!menuRef.current) return
    menuRef.current.style.display = "none";
    menuRef.current.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;

    setCurrentPage({
      key,
      id: pageId,
      value: +pageNode.attributes.dataindex.value,
    });
    setTimeout(() => {
      menuRef.current.style.display = "flex";
    }, [126]);

    setShowMenu(true);
  }

  

  function handlePagination(entries, observer) {
    const page = entries.find(
      (elem) => elem.intersectionRect.height / elem.rootBounds.height > 0.4
    );
    const pageIndex = +page?.target?.attributes?.dataindex?.value ?? 0;

    if (!(page && entries[pageIndex])) {
      return;
    }
    
    const key = entries[pageIndex].target.attributes.datakey.value;
    setCurrentPage({ id: key, value: pageIndex });
  }

  function handleScroll() {
    const target = window?.pdfDoc?.querySelectorAll?.(".pdf-sheet");
    const config = {
      root: window?.pdfDoc?.getElementById?.("pdf-sheet--cont"),
      threshold: 1.0,
    };
    createIntersectionObserver(target, config, handlePagination);
  }

  

  if(!data){
    return ;
  }

  

  return (
    <div className="pdf-main--cont">
      <Toolbar
        onClick={handleClick}
        totalPage={data.pages.length}
        currentPage={currentPage.value}
        metaData={{
          id: data.id,
          name: data.name,
          size: data.size[0] + "px," + data.size[1] + "px",
        }}
        
      />

      {/* <ClickAway
        onClickAway={setShowMenu}
        sync={showMenu}
        boundClass={["pdf-sheet", "sheet-bg"]}
      >
        <ContextMenu
          _ref={(e) => {
            menuRef.current = e;
          }}
          pageId={currentPage.id}
          open={showMenu}
          totalPages={data.pages.length}
          insertText={({ id, position }) =>
            insertTextBox(generateNewBlock(id, position))
          }
          onInsertPage={handleInsertPage}
          onDeletePage={(id) => {
            const data_ = { ...data };
            data_.pages = deleteById(
              currentPage.key,
              currentPage.value,
              data.pages
            );
            setData(data_);
          }}
          setShowMenu={setShowMenu}
        />
      </ClickAway> */}
      <div
        id="pdf-sheet-main--cont"
        style={{ position: "relative", flexGrow: 1, overflow: "auto" }}
      >
        <div id="pdf-sheet--cont" onScroll={handleScroll}>
          {data.pages.map((d, idx) => (
            <Sheet
              key={d.id}
              index={idx}
              id={d.id}
              data={d}
              // insertText={insertText}
              // onContextMenu={handleContextMenu}
              bg={d.layout}
              readOnly={readOnly}
              size={data.size}
              style={{
                width: `${data?.size?.[0]}px`,
                height: `${data?.size?.[1]}px`,
              }}
              isClicked={props.btnClicked}
              currentPage={currentPage.value}
              dataCopy={data}
              setDataCopy={setData}

            />
          ))}
        </div>

        <div id="overlay"></div>
      </div>
      {/* <Spinner area={"download-pdf"} /> */}
    </div>
  );
}

const PdfEditorApp = (props) => {
  return (
    <Iframe>
      <PdfEditor {...props} />
    </Iframe>
  );
};

export default PdfEditorApp;
