import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import { Button, Card, Slider, Spinner, FullCard } from "../components/CommonComponents";
import { FileUpload } from "../components/Fileupload";
import { generalKnowledgeURL } from "../api/serverAPI";
import { download, downloadWord } from "../utils";
import { gkClassTypes, gkPrimary, gkMiddle } from "../components/Mapping";
import SimpleDropdown from "../components/SimpleDropdown";
import MultiSelectDropdown from "../components/MultiSelectDropdown";
import axios from 'axios';

const lookUp = {
    gk_mcq: "MCQ",
    gk_fillup: "Fill-Up",
    gk_whoami: "Who am I",
    gk_truefalse: "True or False",
}

const GeneralKnowledge = () => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    const [noOfQuestion, setNoOfQuestion] = useState(3);
    const [generateqaData, setGenerateqaData] = useState(null);
    const [base64Data, setBase64Data] = useState(null);
    const [loading, setLoading] = useState(false);
    const [classType, setClassType] = useState("primary");
    const [primaryOptions, setPrimaryOptions] = useState([]);
    const [middleOptions, setMiddleOptions] = useState([]);

    const handleSelect = (option) => {
        setClassType(option.value);

        // Determine which set of options to use based on the selected value
        if (option.value === "primary") {
            setPrimaryOptions([]);
        } else if (option.value === "middle") {
            setMiddleOptions([]);
        }

        // Reset the selected options in MultiSelectDropdown
        setPrimaryOptions([]);
        setMiddleOptions([]);
    };

    const handlePrimaryChange = (updatedOptions) => {
        setPrimaryOptions(updatedOptions);
    };

    const handleMiddleChange = (updatedOptions) => {
        setMiddleOptions(updatedOptions);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFiles(file);

        const reader = new FileReader();
        reader.onload = () => {
            const literature_base64String = reader.result.split(",")[1]; // Extract the Base64 data part
            setBase64Data(literature_base64String);
        };
        reader.readAsDataURL(file);

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                } else {
                    clearInterval(interval);

                    return prevProgress;
                }
            });
        }, 1000);
    };

    const GetOutput = () => {
        setLoading(true);

        const questionType = classType === "primary" ? primaryOptions : middleOptions;

        const payload = {
            filename: selectedFiles.name,
            file: base64Data,
            questionCount: noOfQuestion,
            questionType: questionType,
            level: classType,
        };

        const url = generalKnowledgeURL(); // get only the URL

        axios.post(url, payload) // send URL and payload
            .then((response) => {
                console.log("response", response);
                setGenerateqaData(response.data?.response || null);
                setLoading(false);
            })
            .catch((error) => {
                alert("Can you please try again in sometime");
                setLoading(false); // Ensure loading state is reset on error
            });
    };

    const DisplayOutput = () => {
        if (loading) {
            return (
                <>
                    <div className="overlay">
                        <Spinner />
                    </div>
                </>
            );
        }
        if (generateqaData) {
            return (
                <Card>
                    <div className="subheading text-center">
                        Here is your Answers
                    </div>
                    <div className="mt-6">
                        <div className="border-2 border-[#23479F] rounded-lg">
                            <div className="bg-[#23479F] p-4">
                                <div class="grid grid-cols-2 gap-4">
                                    <p className="text-white mt-1">
                                        {selectedFiles && (
                                            <div className='mx-1 line-clamp-1'>
                                                {selectedFiles.name}
                                            </div>
                                        )}
                                    </p>
                                    <div className="flex flex-nowrap sm:flex-wrap justify-end gap-3">
                                        <div>
                                            <Button size="outline" onClick={handleEditClick}>Edit</Button>
                                        </div>
                                        <div>
                                            <Button size="outline" >Save</Button>
                                        </div>
                                        <div>
                                            <Button size="white" onClick={downloadWord}>Download</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white overflow-auto mx-auto rounded-lg">
                                <div id="page-container" className="">
                                    <div className="m-2">
                                        {outputContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            );
        }
    };

    const outputContent = () => {
        return (
            <>
                <div
                    id="editableContent"  // Add an id to identify the contentEditable div
                    className="p-10 flex flex-col align-center gap-[25px] outline-none focus-within:bg-[#ebeafd]"
                    contentEditable={false}  // Set to "true" when the Edit button is clicked
                >
                    {Object.entries(generateqaData).map(([key, item], index) => (
                        <div key={`${key}-${index}`}>
                            <div className="underline text-xl flex mb-5">
                                <strong>{lookUp[key]}</strong>
                            </div>
                            <div className="" dangerouslySetInnerHTML={{ __html: item }} />
                        </div>
                    ))}
                </div>
            </>
        );
    };

    // Inside your EvsContent component
    const handleEditClick = () => {
        const editableContent = document.getElementById("editableContent");
        if (editableContent) {
            editableContent.contentEditable = "true";
            editableContent.focus();
        }
    };

    return (
        <>
            <div className="flex gap-4 mr-4">
                <div className="secondcardWidth">
                    <FullCard>
                        <div className="subheading text-center mt-2">
                            General Knowledge
                        </div>
                        <div className="text-center">
                            <div className="my-8">
                                <div className="items-center">
                                    <FileUpload
                                        accept={"application/pdf"}
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="my-4 text-center">
                            {selectedFiles && (
                                <div className="">
                                    <b>File Name: </b>
                                    {selectedFiles.name}
                                </div>
                            )}
                            {progress > 0 && (
                                <div className="my-4">
                                    <div className="bg-[#D2CFF1] h-4 rounded-full">
                                        <div
                                            className="bg-[#23479F] h-full rounded-full"
                                            style={{
                                                width: `${progress}%`,
                                            }}
                                        ></div>
                                    </div>
                                    <div className="text-center mt-2">{`${progress}% Complete`}</div>
                                </div>
                            )}
                        </div>
                        <div className="text-lg font-medium text-indigo-800 text-left">
                            Choose from the following options
                        </div>
                        <div className="font-medium text-base my-6">
                            Choose your class
                            <div className="my-2 text-base">
                                <SimpleDropdown
                                    options={gkClassTypes}
                                    onSelect={handleSelect}
                                    defaultValue={gkClassTypes[0]}
                                />
                            </div>
                        </div>
                        <div className="font-medium text-base my-6">
                            Question Type
                            <div className="my-2 text-base">
                                {classType === "primary" && (
                                    <MultiSelectDropdown
                                        options={gkPrimary}
                                        selectedOptions={primaryOptions}
                                        onChange={handlePrimaryChange}
                                        placeholder="Select one or more"
                                    />
                                )}
                                {classType === "middle" && (
                                    <MultiSelectDropdown
                                        options={gkMiddle}
                                        selectedOptions={middleOptions}
                                        onChange={handleMiddleChange}
                                        placeholder="Select one or more"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="my-2 items-center">
                            <div className="font-bold">
                                Number of Questions
                            </div>
                            <div className="">
                                <label className="block text-gray-600 flex justify-center w-full">
                                    {noOfQuestion}
                                </label>
                                <Slider
                                    min={1}
                                    max={5}
                                    step={1}
                                    value={noOfQuestion}
                                    onChange={(e) =>
                                        setNoOfQuestion(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        {progress === 100 && (
                            <>
                                <div className="my-8">
                                    {classType === "primary" && primaryOptions.length > 0 && (
                                        <Button onClick={GetOutput}>Proceed</Button>
                                    )}
                                    {classType === "middle" && middleOptions.length > 0 && (
                                        <Button onClick={GetOutput}>Proceed</Button>
                                    )}
                                </div>
                            </>
                        )}
                    </FullCard>
                </div>
                <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                    <DisplayOutput />
                </div>
            </div>
        </>
    )
}

export default GeneralKnowledge