import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toaster, toast } from "react-hot-toast";
import { FcInfo } from 'react-icons/fc';
import { Button } from './CommonComponents';
import Spinner from './spinner';
import Logo from "../assets/logo.svg";

const ResetPassword = () => {
    const location = useLocation();
    const { email, application_id } = location.state;
    const router = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const notification = toast(
            'Please reset your password.',
            {
                duration: 6000,
                icon: <FcInfo fontSize={"35px"} />,
            }
        );

        return () => toast.dismiss(notification);
    }, []);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        // Check if new password matches reconfirmed password
        if (newPassword === confirmPassword) {
            try {
                const response = await axios.post("https://efhp0mxmli.execute-api.ap-south-1.amazonaws.com/dev/forgot-password", {
                    email: email,
                    application_id: application_id,
                    updated_password: newPassword,
                });

                if (response.data.message === "Password updated successfully") {
                    await toast.success('Password updated successfully');
                    router("/login");
                } else {
                    await toast.error("Failed to update password");
                }
            } catch (error) {
                console.error('API call error:', error);
                await toast.error("Failed to update password");
            } finally {
                setLoading(false);
            }
        } else {
            await toast.error("Passwords do not match");
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className='py-5'><img
                src={Logo}
                alt="Logo of krtrimaIQ Cognitive Solutions"
                width={350}
            /></div>
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-semibold mb-4 text-center">Reset Password</h2>
                <form onSubmit={handleResetPassword}>
                    <input
                        type="password"
                        name="newPassword"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring focus:ring-indigo-200"
                    />
                    <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Reconfirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring focus:ring-indigo-200"
                    />

                    <div className="flex justify-center">
                        <Button size="medium" color="light-purple" type="submit">
                            {loading ? <Spinner size='sm' colour='white' /> : "Reset Password"}
                        </Button>
                    </div>
                </form>
            </div>
            <Toaster position="top-center" reverseOrder={false} />
        </div>
    );
};

export default ResetPassword;
