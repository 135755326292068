import React, { useState, useRef } from "react";
import Sidebar from "../components/Sidebar";
import { enterpriseSearchVectorStores } from "../components/Mapping";
import { artData } from "../components/Mapping";
import { themeData } from "../components/Mapping";
import { paletteData } from "../components/Mapping";
import { modelData } from "../components/Mapping";
import { numberOfImages } from "../components/Mapping";
import { Card, FullCard, Select, RadioButton, Accordion, Spinner, Button, Slider } from "../components/CommonComponents";
import { bookcoverURL } from "../api/serverAPI";
import { TexttoImageConversion } from "../components/Fileupload";
import SimpleDropdown from "../components/SimpleDropdown";
import axios from 'axios';

export const TexttoImage = () => {
    const [textareaContent, setTextareaContent] = useState("");
    const [modelType, setModelType] = useState("DALLE");
    const [artType, setArtType] = useState("painting");
    const [paletteType, setPaletteType] = useState("vibrant");
    const [themeType, setThemeType] = useState("natural");
    const [imagesNumber, setImagesNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [generateqaData, setGenerateqaData] = useState(null);
    const [buttonClicked, setButtonClicked] = useState(false)
    const isTextareaEmpty = textareaContent.trim() === "";

    const bookGetOutput = () => {
        setLoading(true);
        setButtonClicked(true)

        // Assuming you want to generate three images
        const numberOfImages = imagesNumber;
        const requests = [];

        for (let i = 0; i < numberOfImages; i++) {
            const payload = {
                text: textareaContent,
                artStyle: artType,
                palette: paletteType,
                theme: themeType
                // numImage: imagesNumber, // if needed
            };
        
            const url = bookcoverURL(); 
        
            requests.push(
                axios.post(url, payload)
            );
        }
        

        Promise.all(requests)
            .then((responses) => {
                console.log(responses);
                const generatedImages = responses.map((response) => response.data?.response || null);
                setGenerateqaData(generatedImages);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error generating images:", error);
                setLoading(false);
                // Handle error (display a message to the user, etc.)
            });
    };
    const DisplayOutput = () => {
        if (loading) {
            return (
                <div className="overlay">
                    <Spinner />
                </div>
            );
        }

        if (generateqaData && generateqaData.length > 0) {
            return (
                <>
                    <div className="bg-[#23479F] text-white rounded-lg p-6 mb-6">
                        <p className="text-base pr-10">
                            {textareaContent}
                        </p>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                        {generateqaData.map((imageUrl, index) => (
                            <div key={index}>
                                <div id="page-container">
                                    <img src={imageUrl} alt={`generate_image_${index}`} className="w-full h-auto" />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        }

    };

    return (
        <>
            <div className="flex gap-4">
                <div className="secondcardWidth">
                    <FullCard>
                        <div className="h-full">
                            <div className="subheading text-center mt-2">
                                Image
                            </div>
                            <div className="my-8">
                                <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                    Choose Model
                                </div>
                                <div className="w-full">
                                    <SimpleDropdown
                                        defaultValue={modelData[0]}
                                        onSelect={(e) => setModelType(e.value)}
                                        options={modelData}
                                    />
                                </div>
                            </div>
                            <div className="my-8">
                                <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                    Art Style
                                </div>
                                <div className="w-full">
                                    <SimpleDropdown
                                        defaultValue={artData[0]}
                                        onSelect={(e) => setArtType(e.value)}
                                        options={artData}
                                    />
                                </div>
                            </div>
                            <div className="my-8">
                                <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                    Palette
                                </div>
                                <div className="w-full">
                                    <SimpleDropdown
                                        defaultValue={paletteData[0]}
                                        onSelect={(e) => setPaletteType(e.value)}
                                        options={paletteData}
                                    />
                                </div>
                            </div>
                            <div className="my-8">
                                <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                    Theme
                                </div>
                                <div className="w-full">
                                    <SimpleDropdown
                                        defaultValue={themeData[0]}
                                        onSelect={(e) => setThemeType(e.value)}
                                        options={themeData}
                                    />
                                </div>
                            </div>
                            <div className="my-8">
                                <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                    Number of images
                                </div>
                                <div className="w-full">
                                    <SimpleDropdown
                                        defaultValue={numberOfImages[0]}
                                        onSelect={(e) => setImagesNumber(e.value)}
                                        options={numberOfImages}
                                    />
                                </div>
                                <div className="w-full rounded-lg my-6 text-base text-purple my-8">
                                    <textarea
                                        className="w-full border border-light-purple bg-light-purple p-2"
                                        placeholder="Describe the image you want to generate"
                                        value={textareaContent}
                                        onChange={(e) => setTextareaContent(e.target.value)}
                                        rows={6}
                                    />
                                </div>
                                <div className={"flex justify-center my-8"}>
                                    <Button
                                        onClick={bookGetOutput}
                                        disabled={isTextareaEmpty}
                                        className={`${isTextareaEmpty
                                            ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                                            : "bg-[#766AFF] hover:bg-[#544AC0] text-white"
                                            } px-6 py-3 tracking-wider leading-5 rounded-lg text-base font-bold font-heading`}
                                    >
                                        Generate
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </FullCard>
                </div>
                <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                    <div className="">
                        <Card>
                            <div className="justify-center">
                                {buttonClicked ? <DisplayOutput /> : <TexttoImageConversion />}
                            </div>
                        </Card>
                    </div>
                </div>

            </div>
        </>
    );
};

export default TexttoImage;
