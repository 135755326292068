import React, { useState, useMemo, useEffect } from "react";

// function removehtml(message) {
//   return (
//     message.replaceAll("(", "that is ").replaceAll(")", ""),
//     message.replace(/(<([^>]+)>)/gi, "")
//   );
// }

function removehtml(message) {
    const regex = /\(https?:\/\/[^)]+\)/g;
    const textWithoutLinks = message.replace(regex, '');

    return textWithoutLinks.replace(/(<([^>]+)>)/gi, '');
}


function useBlob(message) {
    const [blob, setBlob] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        (async () => {
            try {
                const url =
                    "https://asia-south1-swetha-test-project-245110.cloudfunctions.net/translateToSpeechMultiLang";
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Disposition": "inline",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        Text: message,
                        click: "download",
                        Language: "en",
                    }),
                };
                const response = await fetch(url, requestOptions);
                if (!response.ok)
                    throw new Error(`Response not OK (${response.status})`);
                setBlob(await response.blob());
            } catch (ex) {
                setError(ex instanceof Error ? ex : new Error(String(ex)));
            }
        })();
    }, [message]);
    return { blob, error };
}

/**
 * Get an object URL for the current blob. Will revoke old URL if blob changes.
 * https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
 */
function useObjectUrl(blob) {
    const url = useMemo(() => URL.createObjectURL(blob), [blob]);
    useEffect(() => () => URL.revokeObjectURL(url), [blob, url]);
    return url;
}

// Use the hook and render the audio element
function AudioPlayer({ blob, setIdx }) {
    const src = useObjectUrl(blob);
    return <audio controls autoPlay {...{ src }} style={{ display: "none" }} onEnded={() => setIdx(null)} />;
}

const TextToSpeech = (props) => {
    console.log("[DEBUG]: TTS component rendered.");
    const { blob } = useBlob(removehtml(props.message));
    return (
        <div className="d-flex justify-content-around">
            <div>{blob ? <AudioPlayer setIdx={props.setIdx} {...{ blob }} /> : null}</div>
        </div>
    );
};

export default TextToSpeech;
