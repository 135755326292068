import React, { useEffect, useCallback, useRef, useState } from "react";
import { Button, Card, FullCard, Select, Spinner, AnswerWithReadMore } from "../components/CommonComponents";
import {
    tutorGrade,
    tutorRoles,
    tutorSubject,
    tutorTopic,
    tutorBoard,
    tutorTeacherMethods,
    adaptiveLearningComplexity,
    adaptiveLearningQuePatterns,
} from "../components/Mapping";
import SimpleDropdown from "../components/SimpleDropdown";
import DOMPurify from 'dompurify'
import questionIcon from "../assets/questionIcon.svg";
import ansIcon from "../assets/ansIcon.svg";
import jsPDF from 'jspdf';
import {
    generateQPAdaptiveURL,
    submitAnswersAdaptiveURL,
    adaptiveLearningGetInfoURL,
    generateContentAdaptiveURL,
    clarificationAdaptiveURL,
    likeAdaptiveURL,
    dislikeAdaptiveURL,
    chatHistoryAdaptiveURL,
    downloadPDFAdaptiveURL
} from "../api/serverAPI";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    BadgeHelp,
    ThumbsDown,
    ThumbsUp,
    Copy,
    MessageSquarePlus,
    Download,
    MessageCircleQuestion
} from 'lucide-react';
import Draggable from "react-draggable";
import axios from 'axios';

// console.log("ngoninittttttttttttt");
// window.speechSynthesis.cancel();
/**
 * BAse-64 encoding with a URL-safe charset
 * @param {string} s the string to encode
 * @returns {string} the encoded string
 */
function b64_urlsafe(s) {
    return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace('=', '')
}

/**
 * A random string of n **bytes**.
 *
 * ..note::
 *    not all characters are a single byte. intermediate steps may contain fewer characters
 *    if String.fromCharCode returns a multi-byte sequence. This is normalized by base64.
 * @param n The number of bytes, defaults to 16
 * @returns {string} The randomly generated string.
 */
function randomString(n = 16) {
    return b64_urlsafe(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(n))))
}

// Function to format timestamp
const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // This formats the date and time in a readable format
};

// eslint-disable-next-line no-control-regex
const pattern = new RegExp('data:image/[^/]+;base64,[^\0]+\0\0');
let sender = randomString();

const AdaptiveLearning = () => {
    const [selectedRole, setSelectedRole] = useState('teacher');
    const [selectedBoard, setSelectedBoard] = useState('CBSE');
    const [endpoint, setEndpoint] = useState('faq');
    const [selectedGrade, setSelectedGrade] = useState('8');
    const [selectedSubject, setSelectedSubject] = useState('English');
    const [selectedTopic, setSelectedTopic] = useState('The Best Christmas Present in the World & The Ant and the Cricket');
    const [selectedTeacherMethod, setSelectedTeacherMethod] = useState('faq');
    const [loading, setLoading] = useState(false);
    const [disableTextArea, setDisableTextArea] = useState(true);
    const [translationLanguage, setTranslationLanguage] = useState('hi')
    const [translatedText, setTranslatedText] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [stream, setStream] = useState(true);
    const [components, setComponents] = useState([
        {
            questionType: adaptiveLearningQuePatterns[0]?.code || '',
            complexity: adaptiveLearningComplexity[0]?.code || '',
            numberOfQuestions: 1,
        },
    ]);
    const [maxQuestions, setMaxQuestions] = useState();
    const [questionPaper, setQuestionPaper] = useState();
    const [userAnswers, setUserAnswers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [mainQuestion, setMainQuestion] = useState("");
    const [chatBot, setChatBot] = useState(false);
    const [minMax, setMinMax] = useState(false);
    const chatContainerRef = useRef(null);
    const [autoScroll, setAutoScroll] = useState(true);
    const [info, setInfo] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);
    const [showDownload, setShowDownload] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const ws = useRef(null);
    const botMessageRef = useRef(null);
    let bIndex = 0;
    // Dummy state to force re-render when updating the global sender variable
    const [, setDummy] = useState(0);

    // Function to refresh sender value
    const refreshSender = () => {
        clearAllVariables();
        sender = randomString(); // Update the global sender variable
        setDummy(prev => prev + 1); // Force re-render by updating a dummy state
        // Trigger a toast notification
        toast.info(`Session Refreshed: ${sender}`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            progress: undefined,
        });
    };

    const clearAllVariables = () => {
        setLoading(false);
        setDisableTextArea(true);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
        setStream(true);
        setComponents([{
            questionType: adaptiveLearningQuePatterns[0]?.code || '',
            complexity: adaptiveLearningComplexity[0]?.code || '',
            numberOfQuestions: 1,
        }]);
        setMaxQuestions(undefined);
        setQuestionPaper(undefined);
        setUserAnswers([]);
        setMessages([]);
        setInput("");
        setMainQuestion("");
        setChatBot(false);
        setMinMax(false);
        setAutoScroll(true);
        setInfo(undefined);
        setShowPopup(false);
        setChatHistory([]);
        setSelectedSession(null);
        setSelectedSessionIndex(0);
        setShowDownload(false);

        // Resetting useRef values (not state variables)
        if (ws.current) ws.current = null;
        if (botMessageRef.current) botMessageRef.current = null;
        if (chatContainerRef.current) chatContainerRef.current = null;
    };

    useEffect(() => {
        window?.speechSynthesis?.cancel();
    }, [])

    const handleInfo = async () => {
        setLoading(true);
        try {
            const url = adaptiveLearningGetInfoURL();
            const response = await axios.get(url);
            setLoading(false);
            if (response.status === 200) {
                setInfo(response.data.output);
            } else {
                console.error('Failed to generate info', response.data.output);
            }
        } catch (error) {
            setLoading(false);
            console.error('An error occurred while generating info', error);
        }
    };

    //Handle colour change and checks if the likebutton is enabled
    const handleLike = (id) => (event) => {
        const likeButton = event.currentTarget.firstChild;
        const dislikeButton = document.getElementById(`dislike-bot-response-${id}`).firstChild;
        const isLikeClicked = likeButton.style.color === 'blue';
        const isDislikeClicked = dislikeButton.style.color === 'red';

        // Toggle like button color
        if (isLikeClicked) {
            likeButton.style.color = 'black'; // Unclick like
        } else {
            likeButton.style.color = 'blue';  // Click like

            // If dislike is clicked, unclick it and toggle dislike in the backend
            if (isDislikeClicked) {
                dislikeButton.style.color = 'black'; // Unclick dislike
                toggleDislike(id, isDislikeClicked);  // Call function to hit API to remove dislike
            }
        }

        // Call function to toggle like in the backend
        toggleLike(id, isLikeClicked);
    };
    //Handle colour change and checks if the dislikebutton is enabled
    const handleDislike = (id) => (event) => {
        const dislikeButton = event.currentTarget.firstChild;
        const likeButton = document.getElementById(`like-bot-response-${id}`).firstChild;
        const isLikeClicked = likeButton.style.color === 'blue';
        const isDislikeClicked = dislikeButton.style.color === 'red';

        // Toggle dislike button color
        if (isDislikeClicked) {
            dislikeButton.style.color = 'black'; // Unclick dislike
        } else {
            dislikeButton.style.color = 'red';  // Click dislike

            // If like is clicked, unclick it and toggle like in the backend
            if (isLikeClicked) {
                likeButton.style.color = 'black'; // Unclick like
                toggleLike(id, isLikeClicked);  // Call function to hit API to remove like
            }
        }

        // Call function to toggle dislike in the backend
        toggleDislike(id, isDislikeClicked);
    };
    // Function to toggle like in the backend based on the current state
    const toggleLike = (id, isLikeClicked) => {
        const url = likeAdaptiveURL();
        const payload = {
            email: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
            session_id: sender,
            index: id
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (!isLikeClicked) {
                    toast.info("We're happy you found this helpful!", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "colored",
                    });
                }
            })
            .catch(error => {
                console.error('Error in API request:', error);
            });
    };
    // Function to toggle dislike in the backend based on the current state
    const toggleDislike = (id, isDislikeClicked) => {
        const url = dislikeAdaptiveURL();
        const payload = {
            email: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
            session_id: sender,
            index: id
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (!isDislikeClicked) {
                    toast.info("Noted! We're always learning from your feedback", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "colored",
                    });
                }
            })
            .catch(error => {
                console.error('Error in API request:', error);
            });
    };
    // Function to handle copy from chatbot
    const handleCopyText = (elementId) => {
        const textToCopy = document.getElementById(elementId).innerText;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success(`Response Copied to Clipboard`, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    //Generate LLM summary pdf for entire chat
    const generatePDFForEntireChat = async () => {
        setLoading(true);
        const apiURL = downloadPDFAdaptiveURL();
        // Determine session_id based on selectedSession
        const downloadSessionId = selectedSession ? selectedSession.session_id : sender;
        const payload = {
            email: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
            session_id: downloadSessionId
        };

        try {
            const response = await axios.post(apiURL, payload, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'blob' // This handles the binary data response (PDF in this case)
            });

            // Create a download link and trigger it to download the file
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'summary.pdf'); // Set the file name
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // Free up the memory used by the blob URL

            console.log("PDF download triggered successfully!");
            setLoading(false);
        } catch (error) {
            console.error("Error downloading PDF: ", error);
            setLoading(false);
        }
    };
    //Generate simple pdf for one response
    const generatePDF = (question, answer) => {
        const doc = new jsPDF();

        // Add question to the PDF
        doc.setFontSize(16);
        doc.text('You:', 10, 10);
        doc.setFontSize(12);
        const wrappedQuestion = doc.splitTextToSize(question, 180); // Wrap text to fit within 180 units
        doc.text(wrappedQuestion, 10, 20);

        // Clean the HTML from the answer
        const cleanedAnswer = stripHTML(answer);

        // Add answer to the PDF
        doc.setFontSize(16);
        doc.text('Bot:', 10, 40);
        doc.setFontSize(12);
        const wrappedAnswer = doc.splitTextToSize(cleanedAnswer, 180); // Wrap text to fit within 180 units
        doc.text(wrappedAnswer, 10, 50);

        // Save the PDF
        doc.save('Chat.pdf');
    };

    const stripHTML = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    const handleScroll = () => {
        const container = document.getElementById("chat-box")
        if (container.scrollTop < container.scrollHeight - container.clientHeight - 50) {
            setAutoScroll(false);
        }
        else {
            setAutoScroll(true);
        }
    }

    useEffect(() => {
        console.log(mainQuestion);
    }, [mainQuestion]);

    useEffect(() => {
        function handleClickOutside(event) {
            const chatbotElement = document.getElementById('chatbot-container');
            if (chatbotElement && !chatbotElement.contains(event.target)) {
                setChatBot(false);
                setMessages("");
            }
        }
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const handleChat = () => {
        const initialBotMessage = { text: "Initializing chat...", sender: 'bot', timestamp: new Date() };
        setMessages((prevMessages) => [...prevMessages, initialBotMessage]);

        // Prepare the WebSocket URL and message data
        const sp = new URLSearchParams();
        sp.set('sender', sender);
        sp.set('grade', `class${selectedGrade}`);
        sp.set('subject', selectedSubject.toLowerCase());
        sp.set('topic', selectedTopic);

        const apiUrl = new URL(generateContentAdaptiveURL());
        apiUrl.search = sp.toString();

        // Initialize the WebSocket and assign it to ws.current
        ws.current = new WebSocket(apiUrl.href);

        ws.current.addEventListener('open', (event) => {
            console.log('WebSocket connection opened:', event);

            // After the WebSocket is open, send the user's message
            if (input.trim()) {
                ws.current.send(JSON.stringify({ message: input }));
            }
        });

        ws.current.onmessage = (event) => {
            try {
                const response = JSON.parse(event.data);
                if (response && response.length > 0) {
                    const currentText = response[0].text;  // Current chunk

                    // Update the chat with the streaming bot's response
                    setMessages((prevMessages) => {
                        const lastMessage = prevMessages[prevMessages.length - 1];

                        if (lastMessage.sender === 'bot') {
                            // If the last message was from the bot, update it with the new chunk
                            lastMessage.text = currentText;
                            return [...prevMessages.slice(0, -1), lastMessage];
                        } else {
                            // If no previous bot message, add a new one
                            const botMessage = {
                                text: currentText,
                                sender: 'bot',
                                timestamp: new Date(),
                            };
                            return [...prevMessages, botMessage];
                        }
                    });
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        ws.current.onerror = (error) => console.error('WebSocket error:', error);

        ws.current.onclose = () => {
            console.log('WebSocket connection closed');
        };

        // Clear the input
        setInput("");

        // Cleanup function to close the WebSocket if the component unmounts
        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    };

    const handleSend = () => {
        if (input.trim()) {
            // Create and add the user message to the chat
            const userMessage = { text: input, sender: 'user', timestamp: new Date() };
            setMessages((prevMessages) => [...prevMessages, userMessage]);

            // Prepare the WebSocket URL and message data
            const sp = new URLSearchParams();
            // sp.set('total_questions', maxQuestions);
            // sp.set('main_question', mainQuestion);
            sp.set('question', input);
            sp.set('sender', sender);
            sp.set('grade', `class${selectedGrade}`);
            sp.set('subject', selectedSubject.toLowerCase());
            sp.set('topic', selectedTopic);

            const url = clarificationAdaptiveURL();
            const fullUrl = `${url}?${sp.toString()}`;
            ws.current = new WebSocket(fullUrl);

            ws.current.addEventListener('open', (event) => {
                console.log('WebSocket connection opened:', event);
            });

            ws.current.onmessage = (event) => {
                try {
                    const response = JSON.parse(event.data);
                    if (response && response.length > 0) {
                        const currentText = response[0].text;  // Current chunk

                        // Update the chat with the streaming bot's response
                        setMessages((prevMessages) => {
                            const lastMessage = prevMessages[prevMessages.length - 1];

                            if (lastMessage.sender === 'bot') {
                                // If the last message was from the bot, update it with the new chunk
                                lastMessage.text = currentText;
                                return [...prevMessages.slice(0, -1), lastMessage];
                            } else {
                                // If no previous bot message, add a new one
                                const botMessage = {
                                    text: currentText,
                                    sender: 'bot',
                                    timestamp: new Date(),
                                };
                                return [...prevMessages, botMessage];
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };

            ws.current.onerror = (error) => console.error('WebSocket error:', error);

            ws.current.onclose = () => {
                console.log('WebSocket connection closed');
            };

            // Clear the input and main question
            setInput("");
        }

        // Cleanup function to close the WebSocket if the component unmounts
        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    };

    const fetchChatHistory = async () => {
        console.log("Fetching Chat History");
        try {
            setLoading(true);
            const url = chatHistoryAdaptiveURL();
            const params = new URLSearchParams({
                "email": JSON.parse(sessionStorage.getItem("userDetails"))['user_email']
            });
            const response = await axios.get(`${url}?${params.toString()}`);
            setChatHistory(response.data.chat_history);
            setDisableTextArea(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching chat history", error);
        }
    };

    // Function to go to the next session
    const handleNextClick = () => {
        if (selectedSessionIndex < chatHistory.length - 1) {
            const newIndex = selectedSessionIndex + 1;  // Calculate the new index
            setSelectedSessionIndex(newIndex);  // Update the index state
            setSelectedSession(chatHistory[newIndex]);  // Set the session using the new index
        }
    };

    // Function to go to the previous session
    const handlePreviousClick = () => {
        if (selectedSessionIndex > 0) {
            const newIndex = selectedSessionIndex - 1;  // Calculate the new index
            setSelectedSessionIndex(newIndex);  // Update the index state
            setSelectedSession(chatHistory[newIndex]);  // Set the session using the new index
        }
    };

    // Function to handle session selection from the popup
    const handleSessionClick = (session) => {
        // Find the index of the clicked session
        const sessionIndex = chatHistory.findIndex((s) => s.session_id === session.session_id);

        if (sessionIndex !== -1) {
            // First, update the selected session index
            setSelectedSessionIndex(sessionIndex);

            // Then, update the selected session based on the new index
            setSelectedSession(chatHistory[sessionIndex]);
        }
        setShowPopup(false);
        console.log("Session index:", sessionIndex);
    };


    const handleBoard = (option) => {
        setSelectedBoard(option);
    }

    const handleTopicChange = (topic) => {
        if (!disableTextArea) {
            setStream(false);
        }
        setSelectedTopic(topic);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    }

    const handleSubjectChange = (subject) => {
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[selectedGrade][subject][0].text);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    };

    const handleClassChange = (classItem) => {
        // XXX: find somethig better. i`m too tired.
        if (classItem.toLowerCase().startsWith(`btech`)) classItem = `13`;
        setSelectedGrade(classItem);
        const subject = getSubjectOptions(classItem)[0].text;
        setSelectedSubject(subject);
        setSelectedTopic(tutorTopic[classItem][subject][0].text);
        setTranslationLanguage('hi');
        setTranslatedText(null);
        setSelectedFiles(null);
    };

    /**
     *
     * @param {InputEvent} e
     */
    const handleRoleChange = (e) => {
        setSelectedFiles(null);
        const role = e.target.value.toLowerCase();
        setSelectedRole(() => role);

        setTranslationLanguage('hi');
        setTranslatedText(null);
    }

    /**
     *
     * @param {InputEvent} e
     */
    const handleTeacherMethod = (e) => {
        setSelectedFiles(null);
        setSelectedTeacherMethod(() => e.target.value);
        setEndpoint(() => e.target.value);
        setTranslationLanguage('hi');
        setTranslatedText(null);
    }

    function getSubjectOptions(grade) {
        return (tutorSubject.filter(subject => (tutorGrade.find(item => item.code === grade).subjects).includes(subject.code)));

    }

    const handleChange = (id, answer) => {
        setUserAnswers((prevAnswers) => ({
            ...prevAnswers,
            [id]: answer
        }));
    };

    const submitAnswers = async () => {
        setLoading(true);
        const formattedAnswers = Object.keys(userAnswers).map((id) => ({
            id: Number(id),
            answer: userAnswers[id],
            question_type: questionPaper.find(q => q.id === Number(id)).question_type
        }));
        try {
            const url = submitAnswersAdaptiveURL();
            const payload = {
                user_type: selectedRole,
                user_answers_list: formattedAnswers,
                grade: `class${selectedGrade}`,
                subject: selectedSubject.toLowerCase(),
                topic: selectedTopic,
                sender: sender,
                email: JSON.parse(sessionStorage.getItem("userDetails"))['user_email'],
            };

            const response = await axios.post(url, payload);
            if (response.status === 200) {
                var res = questionPaper.map(x => Object.assign(x, { answers: response.data.output.find(y => y.id == x.id) }));
                setQuestionPaper(res);
            } else {
                console.error('Failed to generate Question Paper', response.data.output);
            }
        } catch (error) {
            console.error('An error occurred while generating Question paper', error);
        } finally {
            setShowDownload(true);
            setLoading(false);
        }
    };

    const groupedQuestions = questionPaper?.reduce((acc, question) => {
        const { question_type } = question;
        if (!acc[question_type]) {
            acc[question_type] = [];
        }
        acc[question_type].push(question);
        return acc;
    }, {});

    const addComponent = () => {
        setComponents([...components, {
            questionType: adaptiveLearningQuePatterns[0]?.code || '',
            complexity: adaptiveLearningComplexity[0]?.code || '',
            numberOfQuestions: 1,
        }]);
    };

    const handleComplexityChange = useCallback((index, value) => {
        const updatedComponents = [...components];
        updatedComponents[index] = { ...updatedComponents[index], complexity: value };
        setComponents(updatedComponents);
    }, [components]);

    const handlePatternChange = useCallback((index, value) => {
        const updatedComponents = [...components];
        updatedComponents[index] = { ...updatedComponents[index], questionType: value };
        setComponents(updatedComponents);
    }, [components]);

    const handleNumberChange = (index, value) => {
        const number = parseInt(value, 10);

        // Disable if value is 0 or less
        if (value === '' || number < 1){
            setIsSubmitDisabled(true); // Disable the button
            toast.error('The value cannot be zero or negative.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            setIsSubmitDisabled(false); // Enable the button when valid
        }
        // Update component state with the new value
        setComponents((prevComponents) => {
            const newComponents = [...prevComponents];
            newComponents[index] = { ...newComponents[index], numberOfQuestions: number };
            return newComponents;
        });
    };

    const handleMaxQuestionsChange = (e) => {
        const maxQ = parseInt(e.target.value, 10);
        setMaxQuestions(maxQ);
    };


    const handleGenerateQP = async () => {
        setLoading(true);
        setUserAnswers([]);
        const totalQuestions = components.reduce((total, component) => total + component.numberOfQuestions, 0);
        const questionTypes = components.map(component => component.questionType);
        const hasDuplicates = new Set(questionTypes).size !== questionTypes.length;

        if (!maxQuestions) {
            toast.error("Please select Maximum no. of Questions", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            setLoading(false);
            return;
        }

        if (totalQuestions > maxQuestions) {
            toast.error(`Total number of questions (${totalQuestions}) exceeds the limit of (${maxQuestions}).`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            setLoading(false);
            return;
        }

        if (totalQuestions < maxQuestions) {
            toast.error(`Total number of questions (${totalQuestions}) is lower than the (${maxQuestions}).`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            setLoading(false);
            return;
        }

        if (hasDuplicates) {
            toast.error("You cannot select the same question type more than once.", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            setLoading(false);
            return;
        }

        try {
            const payload = {
                question_parameters: components.map(component => ({
                    question_type: component.questionType,
                    complexity_level: component.complexity,
                    number_of_questions: component.numberOfQuestions,
                })),
                total_questions: maxQuestions,
                user_type: selectedRole,
                grade: `class${selectedGrade}`,
                subject: selectedSubject.toLowerCase(),
                topic: selectedTopic,
                sender: sender
            };
            const url = generateQPAdaptiveURL();
            const response = await axios.post(url, payload, { timeout: 600000 });
            if (response.status === 200) {
                setQuestionPaper(response.data.output);
            } else {
                console.error('Failed to generate Question Paper', response.data.output);
            }
        } catch (error) {
            console.error('An error occurred while generating Question paper', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="relative">
            {loading && (
                <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
                    <Spinner />
                </div>
            )}
            {info && (
                <Draggable>
                    <div
                        className={`z-50 absolute w-3/4 h-3/4 bg-white rounded-md shadow-lg overflow-auto p-4 items-center`}>
                        <button
                            className="absolute top-4 right-4 text-black"
                            onClick={(e) => { setInfo() }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 16 16">
                                <path fill="currentColor" fill-rule="evenodd" d="M13.5 8a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0M6.53 5.47a.75.75 0 0 0-1.06 1.06L6.94 8L5.47 9.47a.75.75 0 1 0 1.06 1.06L8 9.06l1.47 1.47a.75.75 0 1 0 1.06-1.06L9.06 8l1.47-1.47a.75.75 0 1 0-1.06-1.06L8 6.94z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <div dangerouslySetInnerHTML={{ __html: info }}>
                            {/* class="select-none" should be from backend */}
                        </div>
                    </div>

                </Draggable>)}
            {chatBot && (
                <div id="chatbot-container" className={`z-50 absolute top-0 right-0 h-full bg-white rounded-md shadow-lg border-2 overflow-auto ${minMax ? 'w-4/5 max-w-4/5' : 'w-1/3 max-w-1/3'
                    }`}>
                    <div className="bg-blue p-2 h-10 flex items-center justify-between relative">
                        <p className="font-bold text-white flex-grow text-center">Chat Bot</p>
                        <button className="text-white ml-auto p-2 " onClick={(e) => { setMinMax(!minMax) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3m0 2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z" /></svg>
                        </button>
                        <button className="text-white ml-auto" onClick={(e) => { setChatBot(false); setMessages(""); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M13.5 8a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0M6.53 5.47a.75.75 0 0 0-1.06 1.06L6.94 8L5.47 9.47a.75.75 0 1 0 1.06 1.06L8 9.06l1.47 1.47a.75.75 0 1 0 1.06-1.06L9.06 8l1.47-1.47a.75.75 0 1 0-1.06-1.06L8 6.94z" clip-rule="evenodd" /></svg>
                        </button>
                    </div>
                    <div className="bg-white shadow-lg rounded-lg p-4">
                        <div ref={chatContainerRef} className="h-[67vh] overflow-y-auto mb-4">
                            {messages.map((msg, index) => (
                                <div key={index} className={`mb-2 ${msg.sender === "bot" ? "text-left" : "text-right"}`}>
                                    <div className={`inline-block p-2 rounded-lg ${msg.sender === "bot" ? "bg-[#F7F9FD] text-[#224B99]" : "bg-[#F5F5F5]"}`}>
                                        <div className="text-xs text-[#646464] mt-1">
                                            {msg.sender === "bot" ? "Bot" : "YOU"}
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: msg.text }}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex">
                            <input
                                className="flex-grow p-2 border rounded-l-lg focus:outline-none"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Ask your doubt..."
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSend();
                                    }
                                }}
                            />
                            <button
                                className="w-20 bg-[#4366A8] text-white p-2 rounded-r-lg flex items-center justify-center"
                                onClick={handleSend}
                            >
                                Ask
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex gap-4">
                <div className="secondcardWidth">
                    <FullCard>
                        <div className="flex justify-between items-center mt-2">
                            <div className="subheading text-center">
                                Lesson Practice
                            </div>
                            <button onClick={refreshSender} className="flex items-center mx-2">
                                <MessageSquarePlus className="mr-2" size={20} />
                            </button>
                            <button type="button" className="text-blue-500" onClick={handleInfo} aria-label="Info">
                                <BadgeHelp className="mr-2" size={20} />
                            </button>
                        </div>
                        {/* ToastContainer will show notifications */}
                        <ToastContainer />
                        <div className="my-8">
                            <div className={"flex justify-center my-8"}>
                                <Button onClick={() => {
                                    fetchChatHistory();
                                    setShowPopup(true);
                                }}>Chat History</Button>
                            </div>
                        </div>
                        <div className="my-8 flex justify-end">
                            <input
                                type="number"
                                placeholder="Total Que"
                                className="w-1/2 p-2 border rounded focus:outline-none"
                                min="1"
                                value={maxQuestions || ''} // Ensure value is controlled properly
                                onChange={handleMaxQuestionsChange}
                            />
                        </div>
                        <div className="my-8">
                            <div className="text-lg font-medium text-indigo-800 text-left my-2">Select Roles</div>
                            <div className="w-full">
                                <Select dropdownData={tutorRoles}
                                    value={selectedRole[0].toUpperCase() + selectedRole.substring(1)}
                                    onChange={handleRoleChange} />
                            </div>
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Board</div>
                            <Select dropdownData={tutorBoard} value={selectedBoard}
                                onChange={(e) => handleBoard(e.target.value)}
                            />
                        </div>
                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Grade</div>
                            <Select dropdownData={tutorGrade}
                                value={selectedGrade === '13' ? 'Btech 1st year' : selectedGrade}
                                onChange={(e) => handleClassChange(e.target.value)} />
                        </div>

                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Subject</div>
                            <Select dropdownData={getSubjectOptions(selectedGrade)} value={selectedSubject}
                                onChange={(e) => handleSubjectChange(e.target.value)}
                            />
                        </div>

                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Select Topics</div>
                            <Select dropdownData={tutorTopic[selectedGrade][selectedSubject] || []}
                                value={selectedTopic}
                                onChange={(e) => handleTopicChange(e.target.value)} />
                        </div>

                        <div className="my-8">
                            <div className="text-blue text-lg font-semibold my-2">Generate</div>
                            <div className="border-gray-500 border-2 rounded-lg p-1">
                                {components.map((component, index) => (
                                    <div key={index} className="p-1">
                                        <div className="text-sm border-2 border-gray rounded-lg p-1">
                                            <div className="max-w-[11rem] p-2">
                                                {(selectedRole === "teacher") && (<input
                                                    type="number"
                                                    placeholder="No."
                                                    className="w-full p-2 border rounded focus:outline-none"
                                                    min="1"
                                                    value={component.numberOfQuestions}
                                                    onChange={(e) => handleNumberChange(index, e.target.value)}
                                                />)}
                                            </div>
                                            <div className="p-2">
                                                {(selectedRole === "teacher") && (<SimpleDropdown
                                                    key={`complexity-${index}`}
                                                    options={adaptiveLearningComplexity.map(item => ({
                                                        value: item.code,
                                                        label: item.text,
                                                    }))}
                                                    defaultValue={adaptiveLearningComplexity
                                                        .map(item => ({
                                                            value: item.code,
                                                            label: item.text,
                                                        }))
                                                        .find(option => option.value === component.complexity) || { label: 'Select an option', value: '' }}
                                                    onSelect={(option) => handleComplexityChange(index, option.value)}
                                                />)}
                                            </div>
                                            <div className="p-2">
                                                <SimpleDropdown
                                                    key={`questionType-${index}`}
                                                    options={adaptiveLearningQuePatterns.map(item => ({
                                                        value: item.code,
                                                        label: item.text,
                                                    }))}
                                                    defaultValue={adaptiveLearningQuePatterns
                                                        .map(item => ({
                                                            value: item.code,
                                                            label: item.text,
                                                        }))
                                                        .find(option => option.value === component.questionType) || { label: 'Select an option', value: '' }}
                                                    onSelect={(option) => handlePatternChange(index, option.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="flex justify-center mt-2">
                                    <button
                                        className="flex items-center text-2xl justify-center w-8 h-8 bg-blue-500 text-white rounded-full"
                                        onClick={addComponent}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
                                            <path fill="gray" d="M8 15c-3.86 0-7-3.14-7-7s3.14-7 7-7s7 3.14 7 7s-3.14 7-7 7M8 2C4.69 2 2 4.69 2 8s2.69 6 6 6s6-2.69 6-6s-2.69-6-6-6" />
                                            <path fill="gray" d="M8 11.5c-.28 0-.5-.22-.5-.5V5c0-.28.22-.5.5-.5s.5.22.5.5v6c0 .28-.22.5-.5.5" />
                                            <path fill="gray" d="M11 8.5H5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h6c.28 0 .5.22.5.5s-.22.5-.5.5" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="my-8 flex justify-center">
                            {!isSubmitDisabled && (<Button
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                                onClick={() => {
                                    handleGenerateQP();
                                }}
                                disabled={isSubmitDisabled} // Disable the button if numberOfQuestions is 0
                            >
                                Generate QP
                            </Button>)}
                        </div>
                    </FullCard>
                </div>
                <div className="thirdcardWidth ">
                    {/* chat history popup modal */}
                    {showPopup && (
                        <Draggable>
                            <div className="z-50 absolute flex flex-col justify-between items-center inset-0 m-auto w-3/4 h-3/4 bg-white rounded-md shadow-lg border-2 p-4 overflow-hidden">
                                <div className="subheading flex justify-center text-center mb-4">
                                    Chat History
                                </div>
                                <div className="flex-1 overflow-y-auto grid grid-cols-1 gap-4 w-full">
                                    {chatHistory.map((session, index) => (
                                        <button
                                            key={index}
                                            className="flex flex-col p-4 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 transition-all"
                                            onClick={() => {
                                                handleSessionClick(session);
                                                setShowPopup(false);
                                                setShowDownload(true);
                                            }}>
                                            <div className="mb-2"><strong>User:</strong>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(session.data[0]?.topic)
                                                }} />
                                            </div>
                                            <div><strong>Timestamp:</strong> {formatDate(session.data[0]?.timestamp)}</div>
                                        </button>
                                    ))}
                                </div>
                                {/* Button Row */}
                                <div className="flex gap-4 mt-4">
                                    {/* Close button with icon */}
                                    <Button
                                        onClick={() => { setShowPopup(false); }}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </div>
                        </Draggable>
                    )}

                    <div className="chat-container relative">
                        {/* Chat history */}
                        {selectedSession && (
                            <div className={`overflow-auto ${selectedRole === 'student' ? "h-[80vh]" : "h-[80vh]"}`} // ${selectedRole === 'student' ? "h-[70vh]" : "h-[78vh]"}
                                onScroll={handleScroll}
                                id="chat-box">
                                <div
                                    onScroll={handleScroll}
                                >
                                    <div className="flex-1 grid grid-cols-1 gap-4 w-full">
                                        {selectedSession.data.map((item, index) => (
                                            <Card>
                                                <div className="chat-message relative">
                                                    <div key={index} className="m-5">
                                                        <div className="flex">
                                                            <div className="w-1/12">
                                                                <MessageCircleQuestion size={50} className="w-[50px] h-[50px]" />
                                                            </div>
                                                            <div className="w-11/12 ml-4 bg-white rounded-lg text-xl mb-4">
                                                                {item.question && <div className="w-full p-2"> {item.question}</div>}
                                                            </div>
                                                        </div>
                                                        {/* User's Answer */}
                                                        <div className="flex">
                                                            <div className="w-1/12">
                                                                <img
                                                                    src={questionIcon}
                                                                    alt=""
                                                                    width={"50px"}
                                                                    height={"50px"}
                                                                    className=""
                                                                />
                                                            </div>
                                                            <div className="w-11/12 ml-4 bg-gray-100 rounded-lg text-xl ">
                                                                {item.user_answer &&
                                                                    <div
                                                                        className="w-full p-2"
                                                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.user_answer) }}
                                                                    ></div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="w-1/12">
                                                                <img
                                                                    src={ansIcon}
                                                                    alt=""
                                                                    width={"50px"}
                                                                    height={"50px"}
                                                                    className=""
                                                                />
                                                            </div>
                                                            <div className="w-11/12 ml-4 bg-green-100 rounded-lg text-xl ">
                                                                {item.correct_answer &&
                                                                    <div
                                                                        className="w-full p-2"
                                                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.correct_answer) }}
                                                                    ></div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Main QP Chat */}
                        {!(selectedSession) && (<div>
                            {questionPaper && (
                                <div className={`overflow-auto h-[90vh]`} // ${selectedRole === 'student' ? "h-[70vh]" : "h-[78vh]"}
                                    id="chat-box">
                                    <div className="bg-white rounded-lg">
                                        <Card>
                                            <div className="flex items-center justify-between bg-blue rounded-t-lg p-2">
                                                {(showDownload) && (<div>
                                                    <div>
                                                        <button
                                                            // onClick={handleLike(bIndex)}
                                                            className="p-2 text-white rounded-lg transition-colors"
                                                        >
                                                            <ThumbsUp className="w-5 h-5" />
                                                        </button>
                                                        <button
                                                            // onClick={handleDislike(bIndex)}
                                                            className="p-2 text-white rounded-lg transition-colors"
                                                        >
                                                            <ThumbsDown className="w-5 h-5" />
                                                        </button>
                                                        <button
                                                            className="p-2 text-white rounded-lg transition-colors"
                                                        >
                                                            <Copy className="w-5 h-5" />
                                                        </button>
                                                    </div>
                                                </div>)}
                                                <p className="text-xl text-white font-bold flex-grow text-center">
                                                    Question Paper
                                                </p>
                                                {(showDownload) && (<div>
                                                    <button
                                                        onClick={() => generatePDFForEntireChat()}
                                                        className="p-2 text-white  hover:rounded-lg transition-colors"
                                                    >
                                                        <Download />
                                                    </button>
                                                </div>)}
                                                {(!questionPaper[0]?.answers) && (
                                                    <Button className="bg-white  text-blue font-bold px-4 py-2 rounded-lg" onClick={submitAnswers}>
                                                        Submit
                                                    </Button>
                                                )}
                                                {(questionPaper[0]?.answers) && (
                                                    <button
                                                        className="p-2 "
                                                        onClick={() => {
                                                            setChatBot(true);
                                                            handleChat();
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><g fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-5l-5 3v-3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3zM9.5 9h.01m4.99 0h.01" /><path d="M9.5 13a3.5 3.5 0 0 0 5 0" /></g></svg>
                                                    </button>
                                                )}
                                            </div>
                                            <div className="border-2 border-[#23479F] rounded-b-lg p-2">
                                                {Object.entries(groupedQuestions).map(([type, questionPaper]) => (
                                                    <div key={type} className="mb-6">
                                                        {questionPaper.some(q => q.question_type === 'multiple_choice') && (
                                                            <h2 className="text-xl text-center text-blue font-bold mb-2">Multiple Choice Questions</h2>
                                                        )}
                                                        {questionPaper.filter(q => q.question_type === 'multiple_choice').map((questionData, index) => (
                                                            <div key={index} className="p-4 mb-4 border-b">
                                                                <p className="text-lg font-semibold">{index + 1}. {questionData.question}</p>

                                                                <div className="pl-4 mt-2">
                                                                    {Object.entries(questionData.options).map(([key, option]) => (
                                                                        <div key={key} className="flex items-center mb-1">
                                                                            <input
                                                                                type="radio"
                                                                                name={`question-${questionData.id}`}
                                                                                value={key}
                                                                                className="mr-2"
                                                                                checked={userAnswers[questionData.id] === key}
                                                                                onChange={() => handleChange(questionData.id, key)}
                                                                            />
                                                                            <label htmlFor={`option-${key}-${questionData.id}`}>{key}. {option}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {questionData.answers && (
                                                                    <div
                                                                        className={`ml-4 rounded-lg border-2 p-2 ${questionData.answers.similarity_score === "correct" ? "border-green-500" : "border-red-500"
                                                                            }`}
                                                                    >
                                                                        <p>Your answer is {questionData.answers.similarity_score.toString()}</p>
                                                                        <AnswerWithReadMore text={`Correct Answer: ${questionData.answers.correct_answer.toString()}`} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}

                                                        {questionPaper.some(q => q.question_type === 'true_false') && (
                                                            <h2 className="text-xl text-center text-blue font-bold mb-2">True or False Questions</h2>
                                                        )}
                                                        {questionPaper.filter(q => q.question_type === 'true_false').map((questionData, index) => (
                                                            <div key={index} className="p-4 mb-4 border-b">
                                                                <p className="text-lg font-semibold">{index + 1}. {questionData.question}</p>

                                                                <div className="pl-4 mt-2">
                                                                    <div className="flex items-center mb-1">
                                                                        <input
                                                                            type="radio"
                                                                            name={`question-${questionData.id}`}
                                                                            value="True"
                                                                            className="mr-2"
                                                                            checked={userAnswers[questionData.id] === 'True'}
                                                                            onChange={() => handleChange(questionData.id, 'True')}
                                                                        />
                                                                        <label htmlFor={`true-${questionData.id}`}>True</label>
                                                                    </div>
                                                                    <div className="flex items-center mb-1">
                                                                        <input
                                                                            type="radio"
                                                                            name={`question-${questionData.id}`}
                                                                            value="False"
                                                                            className="mr-2"
                                                                            checked={userAnswers[questionData.id] === 'False'}
                                                                            onChange={() => handleChange(questionData.id, 'False')}
                                                                        />
                                                                        <label htmlFor={`false-${questionData.id}`}>False</label>
                                                                    </div>
                                                                </div>
                                                                {questionData.answers && (
                                                                    <div
                                                                        className={`ml-4 rounded-lg border-2 p-2 ${questionData.answers.similarity_score === "correct" ? "border-green-500" : "border-red-500"
                                                                            }`}
                                                                    >
                                                                        <p>Your answer is {questionData.answers.similarity_score.toString()}</p>
                                                                        <AnswerWithReadMore text={`Correct Answer: ${questionData.answers.correct_answer.toString()}`} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}

                                                        {questionPaper.some(q => q.question_type === 'descriptive') && (
                                                            <h2 className="text-xl text-center text-blue font-bold mb-2">Descriptive Questions</h2>
                                                        )}
                                                        {questionPaper.filter(q => q.question_type === 'descriptive').map((questionData, index) => (
                                                            <div key={index} className="p-4 mb-4 border-b">
                                                                <p className="text-lg font-semibold">{index + 1}. {questionData.question}</p>

                                                                <div className="pl-4 mt-2">
                                                                    <textarea
                                                                        rows="4"
                                                                        className="w-full p-2 border rounded"
                                                                        placeholder="Write your answer here..."
                                                                        value={userAnswers[parseInt(questionData.id)] || ''}
                                                                        onChange={(e) => handleChange(questionData.id, e.target.value)}
                                                                    />
                                                                </div>
                                                                {questionData.answers && (
                                                                    <div
                                                                        className={`ml-4 rounded-lg border-2 p-2 ${questionData.answers.similarity_score === "correct" ? "border-green-500" : "border-red-500"
                                                                            }`}
                                                                    >
                                                                        <p>Your answer is {questionData.answers.similarity_score.toString()}</p>
                                                                        <AnswerWithReadMore text={`Correct Answer: ${questionData.answers.correct_answer.toString()}`} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}

                                                        {questionPaper.some(q => q.question_type === 'fill_in_the_blank') && (
                                                            <h2 className="text-xl text-center text-blue font-bold mb-2">Fill in the Blank Questions</h2>
                                                        )}
                                                        {questionPaper.filter(q => q.question_type === 'fill_in_the_blank').map((questionData, index) => (
                                                            <div key={index} className="p-4 mb-4 border-b">
                                                                <p className="text-lg font-semibold">{index + 1}. {questionData.question}</p>

                                                                <div className="pl-4 mt-2">
                                                                    <input
                                                                        type="text"
                                                                        value={userAnswers[questionData.id] || ''}
                                                                        className="w-full p-2 border rounded"
                                                                        placeholder="Fill in the blank..."
                                                                        onChange={(e) => handleChange(questionData.id, e.target.value)}
                                                                    />
                                                                </div>
                                                                {questionData.answers && (
                                                                    <div
                                                                        className={`ml-4 rounded-lg border-2 p-2 ${questionData.answers.similarity_score === "correct" ? "border-green-500" : "border-red-500"
                                                                            }`}
                                                                    >
                                                                        <p>Your answer is {questionData.answers.similarity_score.toString()}</p>
                                                                        <AnswerWithReadMore text={`Correct Answer: ${questionData.answers.correct_answer.toString()}`} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            )}
                        </div>)}
                        <div className="fixed bottom-0 thirdCard bg-[#e5eeff]">
                            <div className="mr-10 ml-4 my-4">
                                {/* clicked on chat history */}
                                {(selectedSession) && (<div>
                                    {/* Buttons to navigate between chat sessions */}
                                    <div className="flex justify-between mb-4">
                                        <Button
                                            onClick={handlePreviousClick}
                                            disabled={selectedSessionIndex === 0}
                                        >
                                            Previous
                                        </Button>

                                        <Button
                                            onClick={() => {
                                                clearAllVariables();
                                                setShowDownload(false);
                                            }}
                                        >
                                            Back
                                        </Button>

                                        <Button
                                            onClick={handleNextClick}
                                            disabled={selectedSessionIndex === chatHistory.length - 1}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdaptiveLearning;
