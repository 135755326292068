'use client';
import React, {useState} from 'react';
// import Checkbox from './checkbox';

const MultiSelectDropdown = ({isOpen, children}) => {
    if (isOpen)
        return (
            <div className="w-full absolute z-10 flex flex-col gap-2 p-4 rounded-lg bg-white border border-light-purple border-opacity-20 max-h-[200px] overflow-y-scroll">
                {children}
            </div>
        );
}

const Select = ({onChange, dropdownData, value, type, updateTable, x, y, name, required}) => {
    const [multiselect, setMultiselect] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    if (type === 'file')
        return (
            <>
                <select
                    value={value}
                    onChange={onChange}
                    className="bg-white border border-light-purple text-black text-sm rounded-lg focus:ring-krtrima-blue focus:border-krtrima-blue block w-full p-2.5"
                >
                    {dropdownData.map((x, i) => (
                        <option className={"hover:bg-almost-purple"} value={x.code} key={i}>
                            {x.text}
                        </option>
                    ))}
                </select>
            </>
        );
    else if (type === 'database')
        return (
            <>
                <select
                    id="large"
                    value={value}
                    onChange={onChange}
                    className="my-2 px-3 py-2 bg-light-blue border border-black placeholder-black focus:outline-none focus:border-sky-blue focus:ring-sky-blue block w-full rounded-md focus:ring-1"
                >
                    {/* <option selected disabled>
            {title}
          </option> */}
                    {dropdownData.map((x, i) => (
                        <option value={x.code} key={i}>
                            {x.text}
                        </option>
                    ))}
                </select>
            </>
        );
    else if (type === 'datagen')
        return (
            <>
                <select
                    value={value}
                    onChange={onChange}
                    className="bg-white border border-light-purple text-black text-sm rounded-lg focus:ring-krtrima-blue focus:border-krtrima-blue block w-full p-2.5"
                >
                    <option disabled selected value>
                        Data Category
                    </option>
                    {dropdownData.map((x, i) => (
                        <option value={x.generator_id} key={i}>
                            {x.name}
                        </option>
                    ))}
                </select>
            </>
        );
    else if (type === 'multiple') {

        const handleChange = (checked, key) => {
            let temp = multiselect;
            if (checked) {
                temp.push(key)
            }
            else {
                const index = multiselect.indexOf(5);
                if (index > -1) { // only splice array when item is found
                    multiselect.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            setMultiselect(temp);
            updateTable('data_type', x, y, multiselect);
        };
        return (
            <div className={"relative w-full"}>
                <div
                    className="bg-white border border-light-purple text-black text-sm rounded-lg focus:ring-krtrima-blue focus:border-krtrima-blue block w-full p-2.5"
                >
                    <button
                        className="rounded w-full flex justify-between items-center"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <div className={multiselect.length == 0?"hidden":""}>
                            {multiselect.length} items selected
                        </div>
                        <div className={multiselect.length > 0?"hidden":""}>
                            Data Type
                        </div>
                        <svg
                            className={`w-6 h-6 transition-transform transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <polyline points="6 9 12 15 18 9" />
                        </svg>
                    </button>
                </div>
                <MultiSelectDropdown isOpen={isOpen}>
                    {dropdownData.map((x, i) => (
                        <div key={i}>
                            {/*<Checkbox label={x.name} onChange={(e) => handleChange(e.target.checked, x.method)} />*/}
                            {/*{x.name}*/}
                        </div>
                    ))}
                </MultiSelectDropdown>
            </div>
        );
    } else
        return (
            <select
                id="large"
                value={value}
                onChange={onChange}
                name={name}
                required={required}
                className="bg-white border border-light-purple text-black text-sm rounded-lg focus:ring-krtrima-blue focus:border-krtrima-blue block w-full p-2.5"
            >
                {dropdownData.map((x, i) => (
                    <option value={x.code} key={i} disabled={i === 0 && x.code === ""} selected={i === 0}>
                        {x.text}
                    </option>
                ))}
            </select>
        );
};

export default Select;
