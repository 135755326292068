import React, {useEffect, useState, useRef} from "react";
import {
    Card,
    FullCard,
    Button,
    Slider,
    RadioButton,
    Spinner,
    NewText,
} from "../components/CommonComponents";
import {
    langTransDemo
} from "../api/serverAPI";
import TextToSpeech from "../components/TextToSpeech";
import SpeechRecognition, {
    useSpeechRecognition,
} from "react-speech-recognition";
import {
    BotIcon,
    Close,
    Dark,
    Light,
    PaperPlane,
    Refresh,
    Settings,
    VolumeOff,
    VolumeOn,
} from "../components/svg";
import Input from "../components/input";
import Select from "../components/select";
import Logo from '../assets/logo.svg'
import {langTranslation, roleSelect, tutorTeacherMethods} from "../components/Mapping";

export const voiceFlags = [
    {text: "Select Voice Flag", code: ""},
    {text: "Enabled", code: "true"},
    {text: "Disabled", code: "false"},
];


const HeaderChat = ({onClose, setVoice, voice, openSettings, settings, setChat, chat}) => {
    const handleRefresh = () => {
        // setChat(chat.slice(0, 1));
        setChat((_) => [[{
            recipient_id: 'new_user',
            text: 'Welcome to krtrimaIQ Cognitive Solutions! Please enter a text to translate.',
            buttons: [],
            index: 0,
        }]]);

    }

    return (
        <div className="bg-[#385EBA] dark:bg-dark-gray flex flex-row justify-between items-center">
            <img
                src={Logo}
                width={69}
                height={21}
                alt="krtrimaIQ Logo"
            />
            <div className="flex flex-row gap-10">
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        setVoice(!voice);
                    }}
                >
                    <div className={voice ? "flex" : "hidden"}>
                        <VolumeOn/>
                    </div>
                    <div className={voice ? "hidden" : "flex"}>
                        <VolumeOff/>
                    </div>
                </div>
                <div
                    className="cursor-pointer"
                >
                    <Light/>
                </div>
            </div>
            <div className="flex flex-row gap-3">
                <div
                    // onClick={() => window.location.reload()}
                    onClick={handleRefresh}
                    className="cursor-pointer"
                >
                    <Refresh/>
                </div>
                <div
                    onClick={openSettings}
                    className={`cursor-pointer ${settings ? "flex" : "hidden"}`}
                >
                    <Settings/>
                </div>
                <div onClick={onClose} className="cursor-pointer">
                    <Close/>
                </div>
            </div>
        </div>
    );
};

const ChatMessage = ({key, val, setStoreUserMessage, voice, policyNo, setPolicyNo, phoneNo, setPhoneNo}) => {
    if (Array.isArray(val) && val.length > 0 && typeof val[0] !== "undefined" && typeof val[0]['recipient_id'] !== "undefined" &&
        (val[0]['recipient_id'] === "user" || val[0]['recipient_id'] === "new_user")) {
        console.log()
        console.log(val[0]['text'])
        if (val[0].text !== null)
            return (
                <div className="flex justify-start">
                    <div className="bg-black rounded-lg shadow-lg px-3 py-2 max-w-xs dark:shadow-slate-500">
                        <div className="flex gap-3 justify-between items-center">
                            <div className="text-white text-sm text-wrap">{val[0].text}</div>
                        </div>
                    </div>
                </div>
            );
    } else {
        console.log("BOT")
        if (val.text !== "" && val.text.length > 1)
            return (
                <div className="flex justify-end">
                    <div className="bg-black rounded-lg shadow-lg px-3 py-2 max-w-xs dark:shadow-slate-500">
                        <div className="flex gap-3 justify-between items-center">
                            <div className="text-white text-sm text-wrap">{val.text}</div>
                        </div>
                    </div>
                </div>
            );
        else
            return null
    }
};

const setBotResponse = (api, sourceLanguage, setSourceLanguage, targetLanguage, setTargetLanguage,
                        storeUserMessage, chat, setChat, setIdx, ws = false) => {
    const langTranslationRequest = {
        "text": storeUserMessage[0].text,
        "source_language": sourceLanguage,
        "target_language": targetLanguage
    }

    // reset state.
    setIdx(null);
    api(langTranslationRequest)
        .then((response) => {
            console.log(response)
            console.log(storeUserMessage)
            if (Object.keys(response.data).length !== 0) {
                if (storeUserMessage[0].text.length !== 0) {
                    storeUserMessage.recipient_id = 'bot';
                    setChat((chat) => [...chat, storeUserMessage, response.data]);
                } else setChat((chat) => [...chat, response.data]);
            } else {
                if (storeUserMessage[0].text.length !== 0)
                    setChat((chat) => [...chat, storeUserMessage]);
            }
        })
        .catch((error) => console.error(error));
};


const AssistantBody = ({
                           storeUserMessage,
                           setStoreUserMessage,
                           chat,
                           setChat,
                           voice,
                           sourceLanguage, setSourceLanguage, targetLanguage, setTargetLanguage
                       }) => {
    console.log(storeUserMessage);
    const digitalBotType = 'language-translation-bot';
    const [policyNo, setPolicyNo] = useState();
    const [phoneNo, setPhoneNo] = useState();
    // each packet sent over the websocket has an index. we store the index of the
    // last packet, so that the appropriate message is forwarded to the
    // TextToSpeech component.
    const [idx, setIdx] = useState();
    // the index thing above only makes sense if we're hitting a websocket.
    const [usingWs, setUsingWs] = useState(false);

    const messageEl = useRef(null);

    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener("DOMNodeInserted", (event) => {
                const {currentTarget: target} = event;
                target.scroll({top: target.scrollHeight, behavior: "smooth"});
            });
        }
    }, []);
    useEffect(() => {
        if (digitalBotType === "language-translation-bot") {
            setBotResponse(langTransDemo, sourceLanguage, setSourceLanguage, targetLanguage, setTargetLanguage,
                storeUserMessage, chat, setChat, setIdx, true);
        } else {
            setUsingWs(true);
            setBotResponse(undefined, storeUserMessage, chat, setChat, setIdx, true);
        }
    }, [digitalBotType, storeUserMessage]);

    let text = "";
    let lastObj = chat[chat.length - 1];
    if (typeof lastObj !== "undefined") {
        if (usingWs) {
            text = lastObj.filter((obj) => obj.index === idx).map((obj) => obj.text).join('.');
        } else {
            // text = lastObj.map((obj) => obj.text).join('.');
        }
    }

    return (
        <div
            className="overflow-y-auto scrollbar-hide overflow-x-hidden h-[25rem] p-3"
            ref={messageEl}
            style={{
                scrollbarWidth: "thin",
                scrollbarColor: "transparent transparent",
            }}
        >
            {chat.map((message, i) => {
                console.log(message)
                return (
                    <div key={i} className="py-1 mb-2 rounded">
                        <ChatMessage
                            key={i}
                            val={message}
                            setStoreUserMessage={setStoreUserMessage}
                            voice={voice}
                            policyNo={policyNo}
                            setPolicyNo={setPolicyNo}
                            phoneNo={phoneNo}
                            setPhoneNo={setPhoneNo}
                        />

                    </div>
                );
            })}
            {voice && text && <TextToSpeech setIdx={setIdx} message={text}/>}
        </div>
    );
};

const ResponseButtons = ({
                             val,
                             setStoreUserMessage,
                         }) => {
    return (
        <Button
            size="small"
            onClick={() =>
                setStoreUserMessage([{recipient_id: "bot", text: val, btn: true}])
            }
        >
            {val.title}
        </Button>
    );
};

const BotResponse = ({
                         content,
                         setStoreUserMessage,
                         policyNo,
                         setPolicyNo,
                         phoneNo,
                     }) => {
    const linkMatch = /\((https?:\/\/[^)]+)\)/;
    const link = content.text && content.text.match(linkMatch);
    const [buttons, setButtons] = useState([]);
    const regex = /^https?:\/\//;
    if (regex.test(content.image)) {
        delete content.image;
    }


    useEffect(() => {
        if (typeof content.buttons !== "undefined") setButtons(content.buttons);
        else setButtons([]);
    }, [content.buttons, setButtons]);

    return (
        <div className="flex items-start justify-start ml-2">
            <div
                className={`${typeof content.text !== "undefined" ? "flex" : "hidden"
                } bg-black rounded-lg shadow-lg p-2 pr-5 flex-col items-start w-[90%] my-1 dark:bg-dark-gray dark:shadow-slate-500`}
            >
                <div className="grid grid-cols-8 gap-1">
                    <div>
                        {/*<img*/}
                        {/*    src={"/digital-assitant/bot.svg"}*/}
                        {/*    width={32}*/}
                        {/*    height={32}*/}
                        {/*    alt="bot_logo"*/}
                        {/*/>*/}
                    </div>
                    <div className="col-span-7">
                        <div
                            className={`${typeof content.text !== "undefined" ? "flex flex-col" : "hidden"
                            } w-full`}
                        >
                            <div className="text-black text-sm dark:text-white">
                                <p className="text-black text-sm dark:text-white">
                                    {React.createElement("div", {
                                        dangerouslySetInnerHTML: {
                                            __html:
                                                content.text && content.text.replace(linkMatch, ""),
                                        },
                                    })}
                                </p>
                            </div>
                            <a
                                href={link ? link[1] : ""}
                                target="_blank"
                                className={`${link ? "flex" : "hidden"
                                } text-base underline text-light-purple dark:text-lavender-blue`}
                            >
                                More details
                            </a>
                        </div>
                        <div className={`flex flex-wrap flex-grow items-center`}>
                            {buttons.map((val, i) => (
                                <div key={i} className="m-1">
                                    <ResponseButtons
                                        val={val}
                                        phoneNo={phoneNo}
                                        policyNo={policyNo}
                                        setPolicyNo={setPolicyNo}
                                        setStoreUserMessage={setStoreUserMessage}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${typeof content.image !== "undefined" ? "flex" : "hidden"
                } w-full`}
            >
                <img
                    src={typeof content.image !== "undefined" ? content.image : ""}
                    alt=""
                    width={370}
                    height={370}
                    className="rounded-lg shadow-lg"
                />
            </div>
        </div>
    );
};

const FooterChat = ({setStoreUserMessage}) => {
    const [userMessage, setUserMessage] = useState("");
    const [mic, setMic] = useState(false);
    const [speechRecognitionSupported, setSpeechRecognitionSupported] =
        useState(null); // null or boolean
    let {transcript, resetTranscript, browserSupportsSpeechRecognition} =
        useSpeechRecognition();

    useEffect(() => {
        // sets to true or false after component has been mounted
        setSpeechRecognitionSupported(browserSupportsSpeechRecognition);
    }, [browserSupportsSpeechRecognition]);

    if (speechRecognitionSupported === null) return null; // return null on first render, can be a loading indicator

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (userMessage.length !== 0) {
            console.log("RETURN", userMessage)
            setStoreUserMessage([
                {recipient_id: "user", text: userMessage, btn: false},
            ]);
            setUserMessage("");
        }
    }


    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (userMessage.length !== 0) {
                setStoreUserMessage([
                    {recipient_id: "user", text: userMessage, btn: false},
                ]);

                setUserMessage("");
                resetTranscript();
            }
        } else if (event.key === "Backspace") {
            setUserMessage(event.target.value);
            resetTranscript();
        }
    };

    const StopListenVoice = () => {
        SpeechRecognition.stopListening();
        setUserMessage(transcript);
    };
    console.log("MAIN", userMessage)
    return (
        <div className="p-3 flex rounded-b-lg">
            <div className="relative flex-grow">
                <div className="z-0">
                    <form onKeyDown={handleKeyDown}>
                    {/*<form>*/}
                        {/*<form >*/}
                        <input
                            type="text"
                            value={userMessage || transcript}
                            className="border border-light-purple rounded-full p-4 w-[90%] placeholder-light-purple dark:placeholder-purplish-gray focus:outline-none focus:border-sky-blue focus:ring-sky-blue"
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Enter text"
                            autoFocus
                        />
                    </form>
                </div>
                <div
                    className="absolute inset-y-0 right-0 bottom-1 flex items-center pr-3 cursor-pointer"
                    onClick={handleSendMessage}
                >
                    <PaperPlane/>
                </div>
            </div>
        </div>
    );
};

const ChatBody = ({
                      chatWindow,
                      setChatWindow,
                      chat,
                      setChat,
                      voice,
                      digitalBotType,
                      storeUserMessage,
                      setStoreUserMessage,
                      showModal,
                      setShowModal,
                      sourceLanguage, setSourceLanguage, targetLanguage, setTargetLanguage
                  }) => {
    console.log(storeUserMessage)
    if (chatWindow === "chat")
        return (
            <div>
                <AssistantBody
                    storeUserMessage={storeUserMessage}
                    setStoreUserMessage={setStoreUserMessage}
                    chat={chat}
                    setChat={setChat}
                    voice={voice}
                    sourceLanguage={sourceLanguage}
                    setSourceLanguage={setSourceLanguage}
                    targetLanguage={targetLanguage}
                    setTargetLanguage={setTargetLanguage}
                />
                <FooterChat setStoreUserMessage={setStoreUserMessage}/>
            </div>
        );
    else return null;
};

const ChatbotModal = ({
                          showModal,
                          setShowModal,
                          chatbotType,
                          data,
                          status,
                      }) => {
    const [chatWindow, setChatWindow] = useState("chat");
    const [voice, setVoice] = useState(false);
    const [chat, setChat] = useState([]);
    const [sourceLanguage, setSourceLanguage] = useState('en');
    const [targetLanguage, setTargetLanguage] = useState('hi');

    const [storeUserMessage, setStoreUserMessage] = useState([
        {recipient_id: "bot", text: "", btn: false},
    ]);

    const openSettings = () => {
        setChat([]);
        setChatWindow("settings");
    };

    if (showModal)
        return (
            <div className="w-96 h-100">
                <div className={"flex fixed items-end justify-end z-50 rounded-lg"}>
                    <div className="fixed inset-0 bg-black opacity-50"/>
                    {/*<ThemeProvider attribute="class">*/}
                    <div
                        className="bg-light-blue dark:bg-almost-black rounded-lg shadow-lg fixed ml-5 right-5 bottom-16 lg:w-[450px] lg:h-[33rem] md:w-[450px] md:h-[33rem]  sm:w-[450px] sm:h-[33rem] xs:left-5 xs:w-[400px] xs:h-[33rem]">
                        <div
                            className={
                                "bg-[#385EBA] rounded-t-lg dark:bg-dark-gray p-2.5"
                            }
                        >
                            <HeaderChat
                                onClose={() => {
                                    setShowModal(false);
                                    setStoreUserMessage([
                                        {recipient_id: "bot", text: null, btn: false},
                                    ]);
                                    setChat([]);
                                }}
                                setVoice={setVoice}
                                voice={voice}
                                settings={
                                    chatbotType === "language-translation-bot" &&
                                    chatWindow === "chat"
                                }
                                openSettings={openSettings}
                                chat={chat}
                                setChat={setChat}
                            />
                        </div>

                        <div
                            className={"bg-white"}
                        >
                            <div className="px-6 flex flex-row-2 justify-around">
                                <div>
                                    <div className="text-md font-medium text-indigo-800 text-left">
                                        Source Language
                                    </div>
                                    <div className="w-full">
                                        <Select
                                            value={sourceLanguage}
                                            onChange={(e) => setSourceLanguage(e.target.value)}
                                            dropdownData={langTranslation}//options
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="text-md font-medium text-indigo-800 text-left">
                                        Target Language
                                    </div>
                                    <div className="w-full">
                                        <Select
                                            value={targetLanguage}
                                            onChange={(e) => setTargetLanguage(e.target.value)}
                                            dropdownData={langTranslation}//options
                                        />
                                    </div>
                                </div>
                            </div>
                            <ChatBody
                                chatWindow={chatWindow}
                                setChatWindow={setChatWindow}
                                chat={chat}
                                setChat={setChat}
                                digitalBotType={chatbotType}
                                voice={voice}
                                storeUserMessage={storeUserMessage}
                                setStoreUserMessage={setStoreUserMessage}
                                showModal={showModal}
                                setShowModal={setShowModal}
                                sourceLanguage={sourceLanguage}
                                setSourceLanguage={setSourceLanguage}
                                targetLanguage={targetLanguage}
                                setTargetLanguage={setTargetLanguage}
                            />
                        </div>
                    </div>
                    {/*</ThemeProvider>*/}
                </div>
            </div>
        );
    else return null;
};

const LanguageTranslation = ({chatbotType, solution = false}) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className={"w-full max-sm:hidden sm:hidden md:hidden lg:block"}>
            <button
                className={"p-4 rounded-lg fixed bottom-3 right-3 z-50"}
                onClick={() => setShowModal(true)}
            >
                <BotIcon solution={solution} chatbotType={chatbotType}/>
            </button>
            <ChatbotModal
                showModal={showModal}
                setShowModal={setShowModal}
                chatbotType={chatbotType}
            />
        </div>
    );
};

export default LanguageTranslation;
