import axios from 'axios';
//.env has the baseURL
const HTTP_URL = process.env.REACT_APP_HTTP_BASE_URL;
const DEV_URL = process.env.REACT_APP_HTTP_DEV_URL;
const GEN_URL = process.env.REACT_APP_HTTP_GEN_URL;
const WS_URL = process.env.REACT_APP_WS_BASE_URL;


export const generateQandA = (data, jwtToken) => {
    const headers = {
        'Authorization': 'Bearer ${jwtToken}',
        'Content-Type': 'application/json',
    };

    return axios.post(
        `${GEN_URL}:8602/generate_qna`,
        data,
        {headers: headers}
    );
};

export const enterpriseSearchEngine = (data, jwtToken) => {
    console.log('sending data', data);
    const headers = {
        'Authorization': 'Bearer ${jwtToken}',
        'Content-Type': 'application/json',
    };
    return axios.post(
        `${DEV_URL}:6003/search_docs`,
        data,
        {headers: headers}
    );
};

export const knowledgeManagementURL = (data, jwtToken) => {
    console.log('sending data', data);
    const headers = {
        'Authorization': 'Bearer ${jwtToken}',
        'Content-Type': 'application/json',
    };
    return axios.post(
        `${DEV_URL}:6008/chat`,
        data,
        {headers: headers}
    );
};

// -n.t
export const dvdBiBotURL = (data) => {
    return axios.post(`${DEV_URL}:8998/chat`, data);
};
// -n.t
export const summarization = (data) => {
    console.log(data);
    return axios.post(
        `${HTTP_URL}/summary`,
        data
    );
};
// -n.t
export const searchDocumentFile = (data) => {
    return axios.put(`${GEN_URL}:42069/documents`, data);
};
// -n.t
export const searchDocumentFileQuestion = (data) => {
    return axios.post(`${GEN_URL}:42069/query`, data);
};
// -n.t
export const deleteDocument = () => {
    return axios.delete(`${GEN_URL}:42069/documents`);
};
// -n.t
export const informationretreivalAPI = (data) => {
    return axios.post(
        `https://lnyy0axgm8.execute-api.ap-south-1.amazonaws.com/dev/information-retrieval`,
        data
    );
};
//Done -n.t
export const proofReadingURL = () => {
    return `${HTTP_URL}/proofread`;
};
//Done -n.t
export const RoleplayURL = () => {
    return `${HTTP_URL}/role-play`;
};

//Done -n.t
export const searchDocumentFileQURL = () => { 
    return `${GEN_URL}:42070/documents`;
};

//Done -n.t
export const searchDocumentFileQuestionQURL = () => {
    return `${GEN_URL}:42070/query`;
};

//Done -n.t
export const deleteDocumentQURL = () => {
    return `${GEN_URL}:42070/documents`;
};

//Done -n.t
export const informationExtractionURL = () => {
    return `${DEV_URL}:6005/resume_parse`;
};

// Tara (Reading Comp)
//Done 
export const generateImgURL = () => {
    return `${HTTP_URL}/tara/images`;
};
//Done
export const pronounceTaraURL = () => {
    return `${HTTP_URL}/tara/pronounce-word`;
};
//Done
export const downloadPDFTaraURL = () => {
    return `${HTTP_URL}/tara/summary_qa_pdf`;
}
//Done
export const askQuestionTaraURL = () => {
    return `${WS_URL}/tara/chat_tokens_qa`;
}

export const generateStoryTaraURL = () => {
    return `${WS_URL}/tara/generate_story`;
}

//Management
//Done
export const loginManagementURL = () => {
    return `${HTTP_URL}/login`;
};
//Done
export const signupManagementURL = () => {
    return `${HTTP_URL}/signup`;
};

export const getOrganizationsManagementURL = () => {
    return `${HTTP_URL}/get_organizations`;
};

export const getInstitutionsManagementURL = () => {
    return `${HTTP_URL}/get_institutions`;
};

export const getRolesManagementURL = () => {
    return `${HTTP_URL}/get_roles`;
};

export const getFeaturesManagementURL = () => {
    return `${HTTP_URL}/get_features`;
};

export const getSectionsManagementURL = () => {
    return `${HTTP_URL}/get_sections`;
};

export const getUsersManagementURL = () => {
    return `${HTTP_URL}/get_users`;
};

//classes
export const createProgramManagementURL = () => {
    return `${HTTP_URL}/create_program`;
};

export const createSectionManagementURL = () => {
    return `${HTTP_URL}/create_section`;
};
//Done
export const createOrganizationManagementURL = () => {
    return `${HTTP_URL}/create_organization`;
};
//Done
export const createInstitutionManagementURL = () => {
    return `${HTTP_URL}/create_institution`;
};

export const createRoleManagementURL = (roleName = 'admin') => {
    return `${HTTP_URL}/create_role?role_name=${encodeURIComponent(roleName)}`;
};

export const createFeatureManagementURL = () => {
    return `${HTTP_URL}/create_feature`;
};

export const createSubjectManagementURL = (subjectName = 'chemistry') => {
    return `${HTTP_URL}/create_subject?subject_name=${encodeURIComponent(subjectName)}`;
};



//TexttoImage.js
//Done
export const bookcoverURL = () => {
    return `${HTTP_URL}/qa/book_cover_generation`;
};
//QnAGeneration.js
export const qnaAPIURL = () => {
    return `${HTTP_URL}/qa/qna_generation`;
};
//CaseStudyContent.js
//Done - n.t
export const casestudyURL = () => {
    return `${HTTP_URL}/qa/generate_casestudy_qna`;
};
//english.js
//Done - n.t
export const englishLiteratureURL = () => {
    return `${HTTP_URL}/qa/generate_english_qna`;
};
//GeneralKnowledge.js
//done - n.t
export const generalKnowledgeURL = () => {
    return `${HTTP_URL}/qa/generate_gk_qna`;
};
//Math.js
//Done - n.t
export const mathAPIURL = () => {
    return `${HTTP_URL}/qa/math_question_paper`;
};
//Science.js
//Done - n.t
export const scienceURL = () => {
    return `${HTTP_URL}/qa/generate_science_qna`;
};
//Comprehension
//Done - n.t
export const comprehensionURL = () => {
    return `${HTTP_URL}/qa/generate_english_comp_qna`;
};

//TRANSLATIONS
export const languageTranslationURL = (data) => {
    return axios.post(`${HTTP_URL}/translate`, data); 
}

export const langTransDemo = (data) => {
    console.log(data)
    return axios.post(`https://languageapi.krtrimaiq.ai:5102/translate`, data);
}

//TUTOR BOT

export const downloadPDFTutorURL = () => {
    return `${HTTP_URL}/teacher_bot/summary_qa_pdf`;
}


//EVALUATION
//Done
export const schemaDownloadURL = () => {
    return `${HTTP_URL}/evaluation/download_schema`;
}
//Done
export const evaluateResultURL = () => {
    return `${HTTP_URL}/evaluation/evaluate`;
}
//Done
export const downloadResultURL = () => {
    return `${HTTP_URL}/evaluation/excel`;
}

//STORY
//Done
export const charactersStoryURL = () => {
    return `${HTTP_URL}/characters`;
}
//Done
export const getListStoryURL = () => {
    return `${HTTP_URL}/get-list`;
}
//Done
export const processStoryURL = () => {
    return `${WS_URL}/process_story`;
}

export const downloadPDFStoryURL = () => {
    return `${HTTP_URL}/story_summary_qa_pdf`;
}


//GUIDED LEARNING
//Done
export const hintGuidedURL = () => {
    return `${WS_URL}/guidedactivity/hint`;
}

export const downloadPDFGuidedURL = () => {
    return `${HTTP_URL}/guidedactivity/summary_qa_pdf`;
}


//ADAPTIVE (Lesson Practice) - done
//Done
export const generateQPAdaptiveURL = () => {
    return `${HTTP_URL}/adaptive/questions`;
}
//Done
export const submitAnswersAdaptiveURL = () => {
    return `${HTTP_URL}/adaptive/submit-answers`;
}
//Done
export const generateContentAdaptiveURL = () => {
    return `${WS_URL}/adaptive/generate-content`;
}
//Done
export const clarificationAdaptiveURL = () => {
    return `${WS_URL}/adaptive/clarification`;
}

export const downloadPDFAdaptiveURL = () => {
    return `${HTTP_URL}/adaptive/summary_qa_pdf`;
}


//ESSAY- Done
//Done
export const teacherGenerateEssayURL = () => {
    return `${WS_URL}/essay/generate_full_essay`;
}
//Done
export const generateQuestionsEssayURL = () => {
    return `${HTTP_URL}/essay/generate_questions`;
}
//Done
export const questionAnswerEssayURL = () => {
    return `${WS_URL}/essay/qa_generate_final_essay`;
}
//Done
export const missingSectionPartsEssayURL = () => {
    return `${WS_URL}/essay/get_essay_for_student`;
}
//Done
export const missingSectionFullEssayURL = () => {
    return `${WS_URL}/essay/validate_student_part`;
}
//Done
export const attemptEssayURL = () => {
    return `${WS_URL}/essay/validate_full_essay`;
}
//Done
export const essayListURL = () => {
    return `${HTTP_URL}/essay/list_teacher_essays`;
}

//DEBATE - DOne
//Done
export const judgeDebateURL = () => {
    return `${HTTP_URL}/debate/judge_intro`;
}
//Done
export const updatePersonaDebateURL = () => {
    return `${HTTP_URL}/debate/update_persona`;
}
//Done
export const generateDebateURL = () => {
    return `${WS_URL}/debate/`;
}

//LLAMA
export const llamaTestURL = () => {
    return `${HTTP_URL}/lama/chat`;
}


//CHAT HISTORY
//Done
export const chatHistoryGuidedURL = () => {
    return `${HTTP_URL}/guidedactivity/chathistory`;
}
//Done
export const chatHistoryEssayURL = () => {
    return `${HTTP_URL}/essay/chathistory`;
}
//Done
export const chatHistoryStoryURL = () => {
    return `${HTTP_URL}/story_chathistory`;
}
//Done
export const chatHistoryTutorURL = () => {
    return `${HTTP_URL}/teacher_bot/teacher_chathistory`;
}

export const chatHistoryReadingCompURL = () => {
    return `${HTTP_URL}/tara/chathistory`;
}
//Done
export const chatHistoryAdaptiveURL = () => {
    return `${HTTP_URL}/adaptive/chathistory`;
}



//LIKE DISLIKE - DOne
//Done
export const likeGuidedURL = () => {
    return `${HTTP_URL}/guidedactivity/like`;
}
//Done
export const dislikeGuidedURL = () => {
    return `${HTTP_URL}/guidedactivity/dislike`;
}   

export const likeEssayURL = () => {
    return `${HTTP_URL}/essay/like`;
}

export const dislikeEssayURL = () => {
    return `${HTTP_URL}/essay/dislike`;
}

export const likeLLamaURL = () => {
    return `${HTTP_URL}/lama/like`;
}

export const dislikeLLamaURL = () => {
    return `${HTTP_URL}/lama/dislike`;
}

export const likeStoryURL = () => {
    return `${HTTP_URL}/story_like`;
}

export const dislikeStoryURL = () => {
    return `${HTTP_URL}/story_dislike`;
}

export const likeReadingCompURL = () => {
    return `${HTTP_URL}/tara/like`;
}

export const dislikeReadingCompURL = () => {
    return `${HTTP_URL}/tara/dislike`;
}

export const likeTutorURL = () => {
    return `${HTTP_URL}/teacher_bot/like`;
}

export const dislikeTutorURL = () => {
    return `${HTTP_URL}/teacher_bot/dislike`;
}

export const likeAdaptiveURL = () => {
    return `${HTTP_URL}/adaptive/like`;
}

export const dislikeAdaptiveURL = () => {
    return `${HTTP_URL}/adaptive/dislike`;
}

//HELP
//Done
export const casestudyInfoURL = () => {
    return `${HTTP_URL}/qa/casestudy_help`;
};

export const tutorInfoURL = () => {
    return  `${HTTP_URL}/teacher_bot/help`;
}
//Done
export const adaptiveLearningGetInfoURL = () => {
    return `${HTTP_URL}/adaptive/help`;
}
//Done
export const readingCompInfoURL = () => {
    return `${HTTP_URL}/tara/help`;
}
//Done
export const guidedInfoURL = () => {
    return `${HTTP_URL}/guidedactivity/help`;
}
//Done
export const storyInfoURL = () => {
    return `${HTTP_URL}/story_help`;
}
// Done
export const essayInfoURL = () => {
    return `${HTTP_URL}/essay/help`;
}

export const llamaInfoURL = () => {
    return `${HTTP_URL}/lama/help`;
}
// Not Done
export const debateInfo = () => {
    return axios.get(`${HTTP_URL}/debate/help`);
}


//temp
export const languageTranslation = (data) => {
    return axios.post("https://edu.genai.krtrimaiq.ai/translate", data); //https://dev.genai.krtrimaiq.ai:6006/translate changed on 23-07
}

export const mmsSearch = (data) => {
    return axios.post('https://languageapi.krtrimaiq.ai:5604/query',data);
};
export const mmsFileUpload= (data) => {
    return axios.post('https://languageapi.krtrimaiq.ai:5603/upload',data);
};

export const mmsSearchSpeech= (data) => {
    return axios.post('https://languageapi.krtrimaiq.ai:5502/speech',data);
};
export const mmsSearchFeedback= (data) => {
    return axios.post('https://languageapi.krtrimaiq.ai:5604/usr_feedback',data);
};
export const mmsExternal= (data) => {
    return axios.post('https://languageapi.krtrimaiq.ai:5604/broad',data);
};
