import katex from "katex";

export const handleClickAway = (target, ids = [], exceptions = null) => {
  if (target?.parentElement?.id === undefined) {
    return false;
  }
  if (ids.includes(target?.parentElement?.id)) {
    return true;
  }
  return handleClickAway(target?.parentElement, ids);
};

export const removeEvent = (event, handler) => {
  window.removeEventListener(event, handler);
};

export const toKeys = (obj = {}) => {
  return Object.keys(obj);
};

export const RGBAtoHEX = (rgba = "") => {
  const tupple = rgba.match(/\d+/g) ?? [];
  const [red, green, blue, alpha] = tupple.map((c) =>
    (0x0 + +c).toString(16).padStart(2, "0")
  );

  const hexColor = "#" + red + green + blue + (alpha || "ff");
  return hexColor;
};

export const colorParser = (color) => {
  switch (color?.length) {
    case 3:
      return color
        .split("")
        .map((c) => c.padStart(2, c))
        .join("")
        .toLowerCase();
    case 6:
      return (color + "ff").toLowerCase();
    default:
      return color;
  }
};

export const compareHexColor = (a, b) => {
  const color1 = colorParser(a.match(/[^#]+/g)?.[0]);
  const color2 = colorParser(b.match(/[^#]+/g)?.[0]);

  return color1 === color2;
};

export function toPx(magnitude, unit) {
  const constant = {
    px: 1,
    pt: 96 / 72,
    in: 96,
  };
  return +(magnitude * constant[unit]).toFixed(2) || 1;
}
export function isOverflown(parent) {
  const constraintHeight = +parent.attributes.dataheight.value;
  return (
    isNaN(constraintHeight) ||
    Math.round(constraintHeight) < Math.round(parent.scrollHeight)
  );
}
export const resizeText = (element, parent, unit = "px", gate = null) => {
  if (gate) {
    return;
  }
  let size = 10.67;
  const maxSize = 96;
  let overflow = false;
  while (!overflow && size < maxSize) {
    element.style.fontSize = `${size}${unit}`;
    overflow = isOverflown(parent);
    if (!overflow) size++;
  }
  element.style.fontSize = `${size - 1}${unit}`;
};

export const deleteById = (id, index, data) => {
  const data_ = [...data];
  for (const content of data) {
    if (content.id === id) {
      data_.splice(index, 1);
    }
  }
  return data_;
};

export const pushX = (data, array) => {
  const array_ = [...array];
  const isDuplicate = array_.some((obj) => obj.id === data.id);

  if (!isDuplicate) {
    array_.push(data);
  }
  return array_;
};

export const valueParser = (value) => {
  const valueStr = ("" + value).match(/^[0-9.]*/).toString();
  const unitStr = ("" + value).match(/[^0-9]*$/).toString() || "px";

  return valueStr + unitStr;
};

export const inputValidation = (value, type = "int") => {
  switch (type) {
    case "int": {
      if (typeof value !== "number" || isNaN(value)) {
        return false;
      }
      return true;
    }
  }
};

export const handleClickAwayNew = (target, selectors = []) => {
  const boundNodes = [];
  selectors.forEach((c) => {
    const nodeList = window.pdfDoc.querySelectorAll(c);
    for (const node of nodeList.values()) {
      boundNodes.push(node);
    }
  });
  // console.log(boundNodes, target);
  return boundNodes.some((n) => n.contains(target));
};

export function createIntersectionObserver(target, config, cb) {
  const observer = new IntersectionObserver(cb, config);

  if (typeof target === "object") {
    target.forEach((t) => {
      observer.observe(t, config);
    });
  } else {
    observer.observe(target, config);
  }

  return observer;
}

export function createMutationObserver(target, config, cb) {
  const observer = new MutationObserver(cb);

  if (target?.values?.()?.[Symbol?.toStringTag] === "Array Iterator") {
    target.forEach((t) => {
      observer.observe(t, config);
    });
  } else {
    observer.observe(target, config);
  }

  return observer;
}

export function parseTemplateDataPlain(data, key="text") {
  let content = ``;

  for (let c of data) {
    switch (c.type) {
      case "text": {
        if (c[key]) {
          content += c[key];
        }
        break;
      }
      case "math": {
        if (c.text) {
          content += `<span latexstring="${c.text}">${katex.renderToString(
            c.text,
            {
              throwOnError: false,
              output: "mathml",
            }
          )}</span>`;
        }
        break;
      }
    }
  }
  return content;
}

// export const handleClickAway = (target, ids = []) => {
//   let flag = false;
//   const nodes = ids.map((d) => document.getElementById(d));
//   for (let node of nodes) {
//     // console.log(node, node.contains(target));
//     const contains = node ? node.contains(target) : true;
//     flag ||= contains;
//   }

//   return flag;
// };

// const getTextBoxPosition = (content, key, x = 0, y = 0) => {
//   let pos = {
//     x: props.content.position.x - 12,
//     y: props.content.position.y - 39,
//   };
//   try {
//     pos = document.getElementById(`tbox-id--${key}`).getBoundingClientRect();
//     // console.log(pos, window.scrollX, window.scrollY, props.sheetId);
//     const sheet = document
//       .getElementById(props.sheetId)
//       .getBoundingClientRect();
//     return `translate(${pos.left - sheet.x + x}px, ${
//       pos.top - sheet.y + y - 39
//     }px)`;
//   } catch (err) {
//     return `translate(${pos.x + x}px, ${pos.y + y}px)`;
//   }
// };
