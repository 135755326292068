import React, {useEffect, useState, useRef} from "react";

import TextToSpeech from "../components/TextToSpeech";
import {useCookies} from "react-cookie";
import "regenerator-runtime/runtime";
import SpeechRecognition, {
    useSpeechRecognition,
} from "react-speech-recognition";
// import {
//     BotIcon,
//     Close,
//     Dark,
//     Light,
//     PaperPlane,
//     Refresh,
//     Settings,
//     VolumeOff,
//     VolumeOn,
// } from "@components/svg";
// import Input from "@components/elements/input";
import {
    requiredCookies,
    authFields,
    themes,
    domains,
    voiceFlags,
    sorry,
} from "../utils/personal-assistant-utils";
// import Select from "@components/elements/select";
import {Button} from "../components/CommonComponents";
import {ThemeProvider, useTheme} from "styled-components";


const HeaderChat = ({onClose, setVoice, voice, openSettings, settings, setChat, chat}) => {
    const {theme, setTheme} = useTheme();
    const [cookies, setCookie] = useCookies(["theme", "voice_flag"]);

    const handleRefresh = () => {
        // setChat(chat.slice(0, 1));
        setChat((_) => [[{
            recipient_id: 'new_user',
            text: 'Welcome to krtrimaIQ Cognitive Solutions! I am a digital assistant powered by Generative AI. How may I help you?',
            buttons: [],
            index: 0,
        }]]);

    }

    useEffect(() => {
        let temp = false;
        if (typeof cookies.voice_flag !== "undefined")
            if (JSON.parse(cookies.voice_flag) !== null)
                temp = JSON.parse(cookies.voice_flag);
        setCookie('voice_flag', false)
        setVoice(temp);
    }, [cookies.voice_flag, setVoice, setCookie]);

    return (
        <div className="bg-azure-white dark:bg-dark-gray flex flex-row justify-between items-center">
            <img
                src={cookies.theme === "dark" ? "/logo-dark.svg" : "/logo.svg"}
                width={69}
                height={21}
                alt="krtrimaIQ Logo"
            />
            <div className="flex flex-row gap-10">
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        setCookie("voice_flag", voice);
                        setVoice(!voice);
                    }}
                >
                    {/*<div className={voice ? "flex" : "hidden"}>*/}
                    {/*    <VolumeOn />*/}
                    {/*</div>*/}
                    {/*<div className={voice ? "hidden" : "flex"}>*/}
                    {/*    <VolumeOff />*/}
                    {/*</div>*/}
                </div>
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        setCookie("theme", theme === "dark" ? "light" : "dark");
                        setTheme(theme === "dark" ? "light" : "dark");
                    }}
                >
                    {/*<div className={theme === "light" ? "flex" : "hidden"}>*/}
                    {/*    <Dark />*/}
                    {/*</div>*/}
                    {/*<div className={theme === "light" ? "hidden" : "flex"}>*/}
                    {/*    <Light />*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="flex flex-row gap-3">
                {/*<div*/}
                {/*    // onClick={() => window.location.reload()}*/}
                {/*    onClick={handleRefresh}*/}
                {/*    className="cursor-pointer"*/}
                {/*>*/}
                {/*    <Refresh />*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    onClick={openSettings}*/}
                {/*    className={`cursor-pointer ${settings ? "flex" : "hidden"}`}*/}
                {/*>*/}
                {/*    <Settings />*/}
                {/*</div>*/}
                {/*<div onClick={onClose} className="cursor-pointer">*/}
                {/*    <Close />*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

const ChatMessage = ({val, setStoreUserMessage, voice, policyNo, setPolicyNo, phoneNo, setPhoneNo}) => {

    useEffect(() => {
        if (val.recipient_id === "bot") {
            if (/^\+?\d{12}$/.test(val.text)) setPhoneNo(val.text);

            if (/^[A-Z0-9]{4} [A-Z0-9]{4} [A-Z0-9]{4} [A-Z0-9]{4}$/.test(val.text))
                setPolicyNo(val.text);
        }
    }, [setPhoneNo, setPolicyNo, val]);


    if (val.recipient_id === "user" || val.recipient_id === "new_user")
        return (
            <BotResponse
                content={val}
                setStoreUserMessage={setStoreUserMessage}
                voice={voice}
                policyNo={policyNo}
                setPolicyNo={setPolicyNo}
                phoneNo={phoneNo}
                setPhoneNo={setPhoneNo}
            />
        );
    else
        return (
            <div className="flex justify-end">
                <div className="bg-light-purple rounded-lg shadow-lg px-3 py-2 max-w-xs dark:shadow-slate-500">
                    <div className="flex gap-3 justify-between items-center">
                        <div className="text-white text-sm text-wrap">{val.text}</div>
                        <div className="flex justify-center items-start">
                            <img
                                src={"/digital-assitant/user.svg"}
                                width={32}
                                height={32}
                                alt="bot_logo"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
};

const setBotResponse = (api, storeUserMessage, chat, setChat, setIdx, ws = false) => {
    // reset state.
    setIdx(null);
    if (chat.length === 0) {
        // the bot's message should be read aloud, and websocket packets are
        // 1-indexed, so this is safe. this is the only time an index of 0 is valid.
        setIdx(0);
        // this is quite literally one of only FIVE !@*#ING COMMENTS in this 900+ line file.
        // none of them are useful. at all. in the least. not even this one. the point of
        // this comment is simply to draw attention to this fact. and to emphasise the
        // ridiculousness of the state that lives in this chat thing. It's an array.. of
        // arrays. each of those arrays has exactly one element. NOTHING IN THIS ENTIRE
        // CODEBASE explains why whoever the hell thought of this decided that this...
        // is the best way to represent this object. truly heinous. even by JS standards.
        setChat((_) => [[{
            recipient_id: 'user',
            text: 'Welcome to krtrimaIQ Cognitive Solutions! I am a digital assistant powered by Generative AI. How may I help you?',
            buttons: [],
            index: 0,
        }]]);
        return;
    }
    if (ws) {
        let first = true;
        const url = new URL('wss://genai.krtrimaiq.ai:8002/temporary-test-chat');
        const sp = new URLSearchParams();
        sp.set('sender', 'user');
        const user_message = storeUserMessage[0].text?.payload || storeUserMessage[0].text;
        if (user_message) {
            setChat((chat) => [...chat, storeUserMessage]);
        }
        sp.set('message', user_message || 'hi');
        url.search = sp.toString();
        const on_message = (ev) => {
            const m = JSON.parse(ev.data);
            // special message that informs us which packet contains the entire
            // response.
            if (m.last_msg) {
                setIdx(m.last_msg);
                return;
            }
            // we delay by 250ms so the illusion of typing is clearer.
            // note to maintainers: this is what a good comment looks like
            // it shouldn't be about the characteristics of your code, but
            // should provide necessary context about *why* you're doing things
            // this way instead of some other way (or why you're doing this at all).
            setTimeout(() => setChat((chat) => {
                let c;
                if (!first) {
                    c = chat.slice(0, -1);
                } else {
                    first = false;
                    c = chat;
                }
                return [...c, m];
            }), 250);
        };

        const ws = new WebSocket(url);
        ws.onmessage = on_message;
        return;
    }
    if (chat.length === 0) {
        // FIXME: someone please for the love of all things sacred
        // make this *not* hit the LLM with "hi".
        // whose idea was this anyway? these calls incur a cost.
        // not just money, but time and resources.
        //
        // i really hope that someone rewrites this whole thing,
        // and when they do, make these calls server-side. if you
        // don't see the massive hole here, well... give it a think.
        // an audit, even from me, comes at a cost.
        //
        // that's it for my unsolicited, and mostly rude, code review.
        // the intention is not to ridicule, but to give you something
        // to think about. things like this are critical when you're
        // creating software that will be used by other people. doubly
        // so when those people are paying you, directly or indirectly.
        api("user", "hi").then((response) => {
            if (Object.keys(response.data).length !== 0)
                setChat((chat) => [...chat, response.data]);
        });
    } else {
        if (storeUserMessage[0].btn === false) {
            api("user", storeUserMessage[0].text)
                .then((response) => {
                    if (Object.keys(response.data).length !== 0) {
                        if (storeUserMessage[0].text.length !== 0)
                            setChat((chat) => [...chat, storeUserMessage, response.data]);
                        else setChat((chat) => [...chat, response.data]);
                    } else {
                        if (storeUserMessage[0].text.length !== 0)
                            // setChat((chat) => [...chat, storeUserMessage, sorry]);
                            setChat((chat) => [...chat, storeUserMessage]);
                    }
                })
                .catch((error) => console.error(error));
        } else {
            api("user", storeUserMessage[0].text.payload)
                .then((response) => {
                    if (storeUserMessage[0].text.length !== 0) {
                        setChat((chat) => [
                            ...chat,
                            [
                                {
                                    recipient_id: "bot",
                                    text: storeUserMessage[0].text.title,
                                    btn: true,
                                },
                            ],
                            response.data,
                        ]);
                    } else {
                        setChat((chat) => [...chat, response.data]);
                    }
                })
                .catch((error) => console.error(error));
        }
    }
};

const AssistantBody = ({
                           storeUserMessage,
                           setStoreUserMessage,
                           chat,
                           setChat,
                           voice,
                           digitalBotType,
                       }) => {
    // const cookies = useCookies(requiredCookies)[0];
    const [policyNo, setPolicyNo] = useState();
    const [phoneNo, setPhoneNo] = useState();
    // each packet sent over the websocket has an index. we store the index of the
    // last packet, so that the appropriate message is forwarded to the
    // TextToSpeech component.
    const [idx, setIdx] = useState();
    // the index thing above only makes sense if we're hitting a websocket.
    const [usingWs, setUsingWs] = useState(false);

    const messageEl = useRef(null);

    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener("DOMNodeInserted", (event) => {
                const {currentTarget: target} = event;
                target.scroll({top: target.scrollHeight, behavior: "smooth"});
            });
        }
    }, []);
    // useEffect(() => {
    //     if (digitalBotType === "business-intelligence") {
    //         setUsingWs(false);
    //         if (
    //             cookies &&
    //             cookies.domain &&
    //             cookies.domain.toLowerCase() === "covid-bot"
    //         ) {
    //             setBotResponse(covidAPI, storeUserMessage, chat, setChat);
    //         } else {
    //             setBotResponse(rasaAPI, storeUserMessage, chat, setChat);
    //         }
    //     } else if (digitalBotType === "insurance-bot") {
    //         setUsingWs(false);
    //         setBotResponse(insuranceBot, storeUserMessage, chat, setChat);
    //     } else {
    //         setUsingWs(true);
    //         setBotResponse(undefined, storeUserMessage, chat, setChat, setIdx, true);
    //     }
    // }, [cookies.domain, digitalBotType, storeUserMessage]);

    let text = "";
    let lastObj = chat[chat.length - 1];
    if (typeof lastObj !== "undefined") {
        if (usingWs) {
            text = lastObj.filter((obj) => obj.index === idx).map((obj) => obj.text).join('.');
        } else {
            text = lastObj.map((obj) => obj.text).join('.');
        }
    }

    return (
        <div
            className="overflow-y-auto scrollbar-hide overflow-x-hidden h-[25rem] p-3"
            ref={messageEl}
            style={{
                scrollbarWidth: "thin",
                scrollbarColor: "transparent transparent",
            }}
        >
            {chat.map((message, i) => {
                return (
                    <div key={i} className="py-1 mb-2 rounded">
                        {message.map((val, j) => (
                            <ChatMessage
                                key={j}
                                val={val}
                                setStoreUserMessage={setStoreUserMessage}
                                voice={voice}
                                policyNo={policyNo}
                                setPolicyNo={setPolicyNo}
                                phoneNo={phoneNo}
                                setPhoneNo={setPhoneNo}
                            />
                        ))}
                    </div>
                );
            })}
            {voice && text && <TextToSpeech setIdx={setIdx} message={text}/>}
        </div>
    );
};

const ResponseButtons = ({
                             val,
                             setStoreUserMessage,
                             policyNo,
                             setPolicyNo,
                             phoneNo,
                         }) => {
    // if (val.payload.includes("/ec_localfile_upload"))
    //     return (
    //         <FileUploader
    //             setStoreUserMessage={setStoreUserMessage}
    //             btnName={val.title}
    //             policyNo={policyNo}
    //         />
    //     );
    // else if (val.payload.includes("/ec_webcam"))
    //     return (
    //         <>
    //             <div className="font-bold text-md my-4">OR</div>
    //             <ImageCrop
    //                 setStoreUserMessage={setStoreUserMessage}
    //                 btnName={val.title}
    //                 policyNo={policyNo}
    //             />
    //         </>
    //     );
    // else if (val.payload.includes("/webcam"))
    //     return (
    //         <>
    //             <div className="font-bold text-md my-4">OR</div>
    //             <ImageCrop
    //                 setStoreUserMessage={setStoreUserMessage}
    //                 btnName={val.title}
    //                 phoneNo={phoneNo}
    //             />
    //         </>
    //     );
    // else if (val.title.includes("Scan QR Code"))
    //     return (
    //         <QRCodeScanner
    //             setStoreUserMessage={setStoreUserMessage}
    //             btnName={val.title}
    //             policyNo={policyNo}
    //             setPolicyNo={setPolicyNo}
    //         />
    //     );
    // else if (val.payload.includes("/localfile_upload"))
    //     return (
    //         <FileUploader
    //             setStoreUserMessage={setStoreUserMessage}
    //             btnName={val.title}
    //             phoneNo={phoneNo}
    //         />
    //     );
    // else
    return (
        <Button
            size="small"
            onClick={() =>
                setStoreUserMessage([{recipient_id: "bot", text: val, btn: true}])
            }
        >
            {val.title}
        </Button>
    );
};

const BotResponse = ({
                         content,
                         setStoreUserMessage,
                         policyNo,
                         setPolicyNo,
                         phoneNo,
                     }) => {
    const linkMatch = /\((https?:\/\/[^)]+)\)/;
    const link = content.text && content.text.match(linkMatch);
    const [buttons, setButtons] = useState([]);
    const regex = /^https?:\/\//;
    if (regex.test(content.image)) {
        delete content.image;
    }


    useEffect(() => {
        if (typeof content.buttons !== "undefined") setButtons(content.buttons);
        else setButtons([]);
    }, [content.buttons, setButtons]);

    return (
        <div className="flex items-start justify-start ml-2">
            <div
                className={`${typeof content.text !== "undefined" ? "flex" : "hidden"
                } bg-white rounded-lg shadow-lg p-2 pr-5 flex-col items-start w-[90%] my-1 dark:bg-dark-gray dark:shadow-slate-500`}
            >
                <div className="grid grid-cols-8 gap-1">
                    <div>
                        <img
                            src={"/digital-assitant/bot.svg"}
                            width={32}
                            height={32}
                            alt="bot_logo"
                        />
                    </div>
                    <div className="col-span-7">
                        <div
                            className={`${typeof content.text !== "undefined" ? "flex flex-col" : "hidden"
                            } w-full`}
                        >
                            <div className="text-black text-sm dark:text-white">
                                <p className="text-black text-sm dark:text-white">
                                    {React.createElement("div", {
                                        dangerouslySetInnerHTML: {
                                            __html:
                                                content.text && content.text.replace(linkMatch, ""),
                                        },
                                    })}
                                </p>
                            </div>
                            {/*<Link*/}
                            {/*    href={link ? link[1] : ""}*/}
                            {/*    target="_blank"*/}
                            {/*    className={`${link ? "flex" : "hidden"*/}
                            {/*    } text-base underline text-light-purple dark:text-lavender-blue`}*/}
                            {/*>*/}
                            {/*    More details*/}
                            {/*</Link>*/}
                        </div>
                        <div className={`flex flex-wrap flex-grow items-center`}>
                            {buttons.map((val, i) => (
                                <div key={i} className="m-1">
                                    <ResponseButtons
                                        val={val}
                                        phoneNo={phoneNo}
                                        policyNo={policyNo}
                                        setPolicyNo={setPolicyNo}
                                        setStoreUserMessage={setStoreUserMessage}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${typeof content.image !== "undefined" ? "flex" : "hidden"
                } w-full`}
            >
                <img
                    src={typeof content.image !== "undefined" ? content.image : ""}
                    alt=""
                    width={370}
                    height={370}
                    className="rounded-lg shadow-lg"
                />
            </div>
        </div>
    );
};

const FooterChat = ({setStoreUserMessage}) => {
    const [userMessage, setUserMessage] = useState("");
    const [mic, setMic] = useState(false);
    const [speechRecognitionSupported, setSpeechRecognitionSupported] =
        useState(null); // null or boolean
    let {transcript, resetTranscript, browserSupportsSpeechRecognition} =
        useSpeechRecognition();

    useEffect(() => {
        // sets to true or false after component has been mounted
        setSpeechRecognitionSupported(browserSupportsSpeechRecognition);
    }, [browserSupportsSpeechRecognition]);

    if (speechRecognitionSupported === null) return null; // return null on first render, can be a loading indicator

    // if (!speechRecognitionSupported) {
    //   return <span>Browser does not support speech recognition.</span>;
    // }


    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (userMessage.length !== 0) {
                setStoreUserMessage([
                    {recipient_id: "bot", text: userMessage, btn: false},
                ]);

                setUserMessage("");
                resetTranscript();
            }
        } else if (event.key === "Backspace") {
            setUserMessage(event.target.value);
            resetTranscript();
        }
    };

    const StopListenVoice = () => {
        SpeechRecognition.stopListening();
        setUserMessage(transcript);
    };

    return (
        <div className="p-3 flex rounded-b-lg">
            {speechRecognitionSupported === true ?
                <div
                    className="mr-2 cursor-pointer"
                    onClick={() => setMic((mic) => !mic)}
                >
                    <div className={mic ? "flex" : "hidden"} onClick={StopListenVoice}>
                        <img
                            src={"/digital-assitant/mic-on.svg"}
                            width={50}
                            height={50}
                            alt="bot_logo"
                        />
                    </div>
                    <div
                        className={mic ? "hidden" : "flex"}
                        onClick={() => SpeechRecognition.startListening({continuous: true})}
                    >
                        <img
                            src={"/digital-assitant/mic-off.svg"}
                            width={50}
                            height={50}
                            alt="bot_logo"
                        />
                    </div>
                </div>
                : null}
            <div className="relative flex-grow">
                <div className="z-0">
                    <form onKeyDown={handleKeyDown}>
                        <input
                            type="text"
                            value={userMessage || transcript}
                            className="border border-light-purple rounded-full p-4 w-[90%] placeholder-light-purple dark:placeholder-purplish-gray focus:outline-none focus:border-sky-blue focus:ring-sky-blue"
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="How can I help you?"
                            autoFocus
                        />
                    </form>
                </div>
                <div
                    className="absolute inset-y-0 right-0 bottom-1 flex items-center pr-3 cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        if (userMessage.length !== 0) {
                            setStoreUserMessage([
                                {recipient_id: "bot", text: userMessage, btn: false},
                            ]);
                            setUserMessage("");
                        }
                    }}
                >
                    {/*<PaperPlane />*/}
                    <Button>Send</Button>
                </div>
            </div>
        </div>
    );
};

const ChatBody = ({
                      chatWindow,
                      setChatWindow,
                      chat,
                      setChat,
                      voice,
                      digitalBotType,
                      storeUserMessage,
                      setStoreUserMessage,
                      showModal,
                      setShowModal,
                  }) => {
    // if (chatWindow === "auth")
    //     return <Authentication setChatWindow={setChatWindow} />;
    // else if (chatWindow === "chat")
    return (
        <div>
            <AssistantBody
                storeUserMessage={storeUserMessage}
                setStoreUserMessage={setStoreUserMessage}
                chat={chat}
                setChat={setChat}
                voice={voice}
                digitalBotType={digitalBotType}
            />
            <FooterChat setStoreUserMessage={setStoreUserMessage}/>
        </div>
    );
// else
//     if (chatWindow === "settings")
//         return (
//             <Authentication
//                 setChatWindow={setChatWindow}
//                 authType={"settings"}
//                 showModal={showModal}
//                 setShowModal={setShowModal}
//             />
//         );
//     else return null;
};


const ChatbotModal = ({
                          showModal,
                          setShowModal,
                          chatbotType,
                          cookies,
                          data,
                          status,
                      }) => {
    const [chatWindow, setChatWindow] = useState("auth");
    const [voice, setVoice] = useState(false);
    const [chat, setChat] = useState([]);

    const [storeUserMessage, setStoreUserMessage] = useState([
        {recipient_id: "bot", text: "", btn: false},
    ]);

    useEffect(() => {
        if (["business-intelligence", "insurance-bot"].includes(chatbotType)) {
            if (Object.keys(cookies).includes("email_id")) setChatWindow("chat");
            else setChatWindow("auth");
        } else setChatWindow("chat");
    }, [cookies, chatbotType]);

    const openSettings = () => {
        setChat([]);
        setChatWindow("settings");
    };

    if (showModal)
        return (
            <div className="w-96 h-fit">
                <div className={"flex fixed items-end justify-end z-50 rounded-lg"}>
                    <div className="fixed inset-0 bg-black opacity-50"/>
                    <ThemeProvider attribute="class">
                        <div
                            className="bg-light-blue dark:bg-almost-black rounded-lg shadow-lg fixed ml-5 right-5 bottom-5 lg:w-[450px] lg:h-[33rem] md:w-[450px] md:h-[33rem]  sm:w-[450px] sm:h-[33rem] xs:left-5 xs:w-[400px] xs:h-[33rem]">
                            <div
                                className={
                                    "bg-azure-white rounded-t-lg dark:bg-dark-gray p-2.5"
                                }
                            >
                                <HeaderChat
                                    onClose={() => {
                                        setShowModal(false);
                                        setStoreUserMessage([
                                            {recipient_id: "bot", text: null, btn: false},
                                        ]);
                                        setChat([]);
                                    }}
                                    setVoice={setVoice}
                                    voice={voice}
                                    settings={
                                        chatbotType === "business-intelligence" &&
                                        chatWindow === "chat"
                                    }
                                    openSettings={openSettings}
                                    chat={chat}
                                    setChat={setChat}
                                />
                            </div>
                            <div
                                className={"bg-light-blue dark:bg-almost-black dark:text-white"}
                            >
                                <ChatBody
                                    chatWindow={chatWindow}
                                    setChatWindow={setChatWindow}
                                    chat={chat}
                                    setChat={setChat}
                                    digitalBotType={chatbotType}
                                    voice={voice}
                                    storeUserMessage={storeUserMessage}
                                    setStoreUserMessage={setStoreUserMessage}
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                />
                            </div>
                        </div>
                    </ThemeProvider>
                </div>
            </div>
        );
    else return null;
};

const PersonalAssistant = ({chatbotType, solution = false}) => {
    const [showModal, setShowModal] = useState(false);
    const cookies = useCookies(requiredCookies)[0];

    return (
        <div className={"w-full max-sm:hidden sm:hidden md:hidden lg:block"}>
            <button
                className={"p-4 rounded-lg fixed bottom-3 right-3 z-50"}
                onClick={() => setShowModal(true)}
            >
                {/*<BotIcon solution={solution} chatbotType={chatbotType}/>*/}
            </button>
            <ChatbotModal
                showModal={showModal}
                setShowModal={setShowModal}
                chatbotType={chatbotType}
                cookies={cookies}
            />
        </div>
    );
};

export default PersonalAssistant;
