import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import {
    Card,
    Button,
    Slider,
    Spinner,
    FullCard,
    Select
} from "../components/CommonComponents";
import { download, downloadWord } from "../utils";
import { mathClassTypes } from "../components/Mapping";
import { mathAPIURL } from "../api/serverAPI";
import SimpleDropdown from "../components/SimpleDropdown";
import axios from 'axios';

const Math = () => {
    const [gradeType, setGradeType] = useState("Class 1");
    const [generateqaData, setGenerateqaData] = useState(["cjbscb"]);
    const [noOfQuestion, setNoOfQuestion] = useState(3);
    const [loading, setLoading] = useState(false);

    const [responseData, setResponseData] = useState([]);




    const handleEditClick = () => {
        const editableContent = document.getElementById("editableContent");

        if (editableContent) {
            editableContent.contentEditable = "true";
            editableContent.focus();
        }
    };

    const handleSave = ({
        editedQuestion,
        editedOptions,
        editedExplanation,
        // editedAnswer,
        index,
        number,
    }) => {
        // Update the database or JSON object here with the new data
        // For demonstration purposes, we'll update the state only
        let updatedQuestions = [...generateqaData];
        updatedQuestions[index] = {
            number: number,
            question: editedQuestion,
            options: editedOptions,
            explanation: editedExplanation,
            // answer: editedAnswer,
        };
        updatedQuestions = updatedQuestions.map((q) => {
            let obj = { ...q };
            for (let o in obj) {
                if (obj[o] === undefined) delete obj[o];
            }
            return obj;
        });
    };


    const handleGradeType = (e) => {
        setGradeType(e.value);
        setGenerateqaData([]);
    };

    const GetOutput = () => {
        setLoading(true);

        const payload = {
            class: gradeType,
            questionCount: noOfQuestion,
        };
        const url = mathAPIURL(); // get only the URL
        axios.post(url, payload) // send URL and payload
            .then((response) => {
                console.log("response", response);
                setResponseData(response.data.response);
                setResponseData(response.data.response.question_answer_pairs);
                console.log(response.data.response.question_answer_pairs);
                setLoading(false);
            })
            .catch((error) => {
                alert("Can you please try again in sometime");
                setLoading(false); // Ensure loading state is reset on error
            });
    };


    // console.log(responseData);
    // const generateData = data.flatMap(category => {
    //     return responseData[category]?.question_answer_pairs || [];

    // }
    // );



    const DisplayOutput = () => {
        if (loading) {
            return (
                <>
                    <div className="overlay">
                        <Spinner />
                    </div>
                </>
            );
        }
        if (responseData.length > 0) {

            return (
                <div className="my-4">
                    <Card>
                        <div className="subheading text-center">
                            Here is your questions
                        </div>
                        <div className="my-6">
                            <div className="border-2 border-[#23479F] rounded-lg">
                                <div className="bg-[#23479F] p-4">

                                    <div className="flex flex-nowrap sm:flex-wrap justify-end gap-3 ">
                                        <div>
                                            <Button size="download" onClick={downloadWord}>Download</Button>
                                        </div>
                                    </div>

                                </div>
                                <div className="bg-white mx-auto rounded-lg">
                                    <div id="page-container" className="h-[80vh] overflow-y-auto">
                                        <div className="m-6">
                                            <div className="spacearound">
                                                {responseData.map((item) => (
                                                    <div key={item.number} className="m-8" style={{ marginRight: "20px" }}>
                                                        <div className="w-full" id="editableContent">
                                                            <span className="mr-2 text-black text-md font-base tems-center justify-center">
                                                                {item.number}.
                                                            </span>
                                                            <span className="text-black text-lg font-md tems-center justify-center">
                                                                {item.question}
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        }
    };





    return (
        <div className="flex gap-4">
            <div className="secondcardWidth">
                <FullCard>
                    <div className="subheading text-center mt-2">
                        Math
                    </div>
                    <div className="mt-6 text-center text-lg font-medium text-indigo-800 text-left">
                        Choose from the following options
                    </div>
                    <div className="my-8">
                        <div className="text-lg font-medium text-indigo-800 text-left my-2">
                            Choose your class
                        </div>
                        <div className="w-full">
                            <SimpleDropdown
                                defaultValue={mathClassTypes[0]}
                                onSelect={handleGradeType}
                                options={mathClassTypes}
                            />
                        </div>
                    </div>
                    <div className="my-2">
                        <div className="my-2 items-center">
                            <div className="font-bold">
                                Number of Questions
                            </div>
                            <div className="">
                                <label className="block text-gray-600 flex justify-center w-full">
                                    {noOfQuestion}
                                </label>
                                <Slider
                                    min={1}
                                    max={20}
                                    step={1}
                                    value={noOfQuestion}
                                    onChange={(e) =>
                                        setNoOfQuestion(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <>
                        <div className={"my-8"}>
                            <Button onClick={GetOutput}>Generate</Button>
                        </div>
                    </>

                </FullCard>
            </div>
            <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                <DisplayOutput />
            </div>
        </div>

    );
};

export default Math;
