import React, { useState } from 'react';
import Modal from 'react-modal';

import final_tarakit from '../Videos/Hindi_narakeet_video_subs_highlighting.mp4';
import openai from '../Videos/Hindi_openai_video_subs_highlighting.mp4';
import sarvam from '../Videos/Hindi_sarvam_video_subs_highlighting_slowed.mp4';
import narakeetEng from '../Videos/Narakeet_adjusted_subs_with_punctuation_video.mp4';
import openaiEng from '../Videos/openai_adjusted_subs_with_punctuation_video.mp4';
import sarvamEng from '../Videos/sarvam_adjusted_subs_with_punctuation.mp4';

// Thumbnail Images (assuming you have these images)
import narakeetThumb from '../Thumbnails/English.png';
import openaiThumb from '../Thumbnails/Hindi.png';

const videos = [
    { title: 'Narakeet Hindi', src: final_tarakit, thumb: openaiThumb },
    { title: 'Open AI Hindi', src: openai, thumb: openaiThumb },
    { title: 'Sarvam Hindi', src: sarvam, thumb: openaiThumb },
    { title: 'Narakeet English', src: narakeetEng, thumb: narakeetThumb },
    { title: 'Open AI English', src: openaiEng, thumb: narakeetThumb },
    { title: 'Sarvam English', src: sarvamEng, thumb: narakeetThumb },
];

const VideoGeneration = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);

    const openModal = (video) => {
        setCurrentVideo(video);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setCurrentVideo(null);
    };

    return (
        <div className="flex bg-white h-[90vh] overflow-y-auto justify-center items-start p-4">
            <div className="flex flex-wrap gap-4 justify-center">
                {videos.map((video, index) => (
                    <div key={index} className="flex flex-col items-center p-4 cursor-pointer" onClick={() => openModal(video)}>
                        <img src={video.thumb} alt={video.title} className="w-64 h-36 object-cover" />
                        <p className="text-lg font-bold mt-2">{video.title}</p>
                    </div>
                ))}
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Video Modal" className="flex items-center justify-center p-4">
                <div className="flex flex-col items-center bg-white p-4 rounded-lg">
                    {currentVideo && (
                        <>
                            <div className="relative w-full flex justify-center">
                                <p className="text-lg font-bold">{currentVideo.title}</p>
                                <button onClick={closeModal} className="absolute right-0 text-xl font-bold text-gray-500 hover:text-red-700">
                                    &times;
                                </button>
                            </div>
                            <video width="750" height="500" controls>
                                <source src={currentVideo.src} type="video/mp4" />
                            </video>
                        </>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default VideoGeneration;
