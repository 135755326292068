/** @typedef {Object} Message
 * @property {boolean} fromUser
 * @property {string} text
 */

/**
 * @template T
 * @param {Message[]}arr
 * @param {(a: Message, b: Message, i?: number) => T} callback
 * @returns T
 */
export function pairwise(arr, callback) {
    const clone = [...arr];
    if (arr.length % 2 === 1) {
        clone.push(null);
    }
    const ret = [];
    for (let i = 0; i < clone.length - 1; i+= 2) {
        ret.push(callback(clone[i], clone[i + 1], i));
    }
    return ret;
}
