import { createContext } from "react";

const IFrameContext = createContext({
  window: null,
  document: null,

  setDocument: () => {},
});

export default IFrameContext;
