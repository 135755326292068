export const authFields = {
    login: [
        {
            title: "Email",
            name: "email",
            type: "email",
        },
        {
            title: "Password",
            name: "password",
            type: "password",
        },
    ],
    signup: [
        {
            title: "Email",
            name: "email",
            type: "email",
        },
        {
            title: "First Name",
            name: "first_name",
            type: "text",
        },
        {
            title: "Last Name",
            name: "last_name",
            type: "text",
        },
        {
            title: "Organization",
            name: "organization",
            type: "text",
        },
        {
            title: "Password",
            name: "password",
            type: "password",
        },
        // {
        //     title: "Role",
        //     name: "role",
        //     type: "text",
        // }
    ],

    settings: [
        {
            title: "Email",
            name: "email",
            type: "email",
        },
        {
            title: "First Name",
            name: "first_name",
            type: "text",
        },
        {
            title: "Last Name",
            name: "last_name",
            type: "text",
        },
        {
            title: "Password",
            name: "password",
            type: "password",
        },
        {
            title: "Reconfirm Password",
            name: "reconfirm_password",
            type: "password",
        },
    ],
    reset: [
        {
            title: "Email",
            name: "email",
            type: "email",
        },
        {
            title: "Password",
            name: "password",
            type: "password",
        },
        {
            title: "Reconfirm Password",
            name: "reconfirm_password",
            type: "password",
        },
    ],
};