import React, { useState, useRef } from "react";
import { knowledgeManagementStores } from "../components/Mapping";
import { Card, FullCard, RadioButton, Spinner, Button, Slider } from "../components/CommonComponents";
import { knowledgeManagementURL } from "../api/serverAPI";
import { useCookies } from "react-cookie";
import questionIcon from "../assets/questionIcon.svg";
import ansIcon from "../assets/ansIcon.svg";
import SimpleDropdown from "../components/SimpleDropdown";
import {KnowledgeManagementAnswer} from "../components/KnowledgeManagementAnswer";

const KnowledgeManagement = () => {
    const [selectedRadioButton, setSelectedRadioButton] = useState("question");
    const [selectedDatabase, setSelectedDatabase] = useState("asset_management_user_guide");
    const [docsCount, setDocsCount] = useState(1);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [currentAnswer, setCurrentAnswer] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const chatContainerRef = useRef(null);
    const [cookies, setCookie,] = useCookies(['jwtToken']);
    const jwtToken = cookies['jwtToken'];
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(null);

    const callknowledgeManagementAPI = async () => {
        if (currentQuestion.trim() === "") {
            setErrorMessage("Question cannot be empty");
            setLoading(false);
        } else {
            setLoading(true);
            setErrorMessage("");
            // Prepare the request data with the current question
            let requestData;
            if (selectedRadioButton === "question") {
                requestData = {
                    // query: currentQuestion,
                    // type: selectedRadioButton,
                    // vectorStore: selectedDatabase
                    question: currentQuestion,
                    collection: selectedDatabase
                };
            } else {
                requestData = {
                    // query: currentQuestion,
                    // type: selectedRadioButton,
                    // vectorStore: selectedDatabase,
                    docsCount: docsCount,
                    question: currentQuestion,
                    collection: selectedDatabase,
                };
            }
            try {
                knowledgeManagementURL(requestData, jwtToken).then((response) => {
                    console.log(response);
                    if (response.data.token && response.data.token !== "undefined") {
                        setCookie('jwtToken', response.data.token);
                    } else {
                        setError("An error occurred. Please try again.")
                    }

                    if (selectedRadioButton === 'question') {
                        // const answer = response.data.answer
                        const answer = response.data.result
                        setData((prevData) => [
                            ...prevData,
                            { question: currentQuestion, answer },
                        ]);
                        setTimeout(() => {
                            console.log(data);
                        }, 10)
                        // Update the current answer
                        setCurrentAnswer(answer);

                        // Clear the current question
                        setCurrentQuestion("");

                        setLoading(false);

                        // Scroll to the newly added content
                        chatContainerRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "end", // Scroll to the end (bottom)
                        });

                        setLoading(false);
                    }
                    else {
                        const answer = response.data['relevant_docs']
                        setData((prevData) => [
                            ...prevData,
                            { question: currentQuestion, answer },
                        ]);

                        // Clear the current question
                        setCurrentQuestion("");

                        setLoading(false);

                        // Scroll to the newly added content
                        chatContainerRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "end", // Scroll to the end (bottom)
                        });

                        setLoading(false);
                    }

                }).catch((error) => {
                    console.error("Failed to fetch user details:", error);
                    setError(error); // Update the error state
                });

            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleRadioButtonChange = (e) => {
        setSelectedRadioButton(e.target.value);
        setData([]);
        setDocsCount(1);
    };

    const handleDatabaseChange = (e) => {
        setSelectedDatabase(e.value);
        setData([]);
    }

    return (
        <>
            <div className="flex gap-4">
                <div className="secondcardWidth">
                    <FullCard>
                        <div className="">
                            <div className="subheading text-center mt-2">
                                Knowledge Management
                            </div>
                            <div className="my-8">
                                <div className="text-lg font-medium text-indigo-800 text-left my-2">
                                    Choose Your Repository
                                </div>
                                <div className="w-full">
                                    <SimpleDropdown
                                        defaultValue={knowledgeManagementStores[0]}
                                        onSelect={handleDatabaseChange}
                                        options={knowledgeManagementStores}
                                    />
                                </div>
                            </div>
                            {/*<div className="my-8">*/}
                            {/*    <div className="my-3 text-lg font-medium text-indigo-800 text-left">*/}
                            {/*        Choose search type*/}
                            {/*    </div>*/}
                            {/*    {selectedDatabase && (*/}
                            {/*        <>*/}
                            {/*            <div className="flex items-center gap-3 my-2">*/}
                            {/*                <RadioButton*/}
                            {/*                    type="radio"*/}
                            {/*                    value="question"*/}
                            {/*                    name="action"*/}
                            {/*                    id="askQuestion"*/}
                            {/*                    label="Ask a Question"*/}
                            {/*                    htmlFor="askQuestion"*/}
                            {/*                    checked={selectedRadioButton === "question"}*/}
                            {/*                    onChange={handleRadioButtonChange}*/}
                            {/*                    customClassName={"orangeColor"}*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*            <div className="flex items-center gap-3 my-2">*/}
                            {/*                <RadioButton*/}
                            {/*                    type="radio"*/}
                            {/*                    value="search"*/}
                            {/*                    name="action"*/}
                            {/*                    id="searchDocument"*/}
                            {/*                    label="Search documents"*/}
                            {/*                    htmlFor="searchDocument"*/}
                            {/*                    checked={selectedRadioButton === "search"}*/}
                            {/*                    onChange={handleRadioButtonChange}*/}
                            {/*                    customClassName={"orangeColor"}*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*        </>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                            {selectedRadioButton === "search" && (
                                <>
                                    <div className="font-bold">Number of documents</div>
                                    <label className="block text-gray-600 flex justify-center w-full">
                                        {docsCount}
                                    </label>
                                    <Slider
                                        min={1}
                                        max={10}
                                        step={1}
                                        value={docsCount}
                                        onChange={(e) =>
                                            setDocsCount(e.target.value)
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </FullCard>
                </div>
                <div className="thirdcardWidth overflow-y-auto h-[90vh]">
                    <div className="chat-container">
                        <div className="mb-20">
                            {/* Display answers for previous questions */}
                            {data.map((entry, index) => (
                                <div className="mb-4" key={index}>
                                    <Card index={index}>
                                        <div key={index} className="chat-message">
                                            <div
                                                className="m-5">
                                                <div className="flex">
                                                    <div className="w-1/12">
                                                        <img
                                                            src={questionIcon}
                                                            alt=""
                                                            width={"50px"}
                                                            height={"50px"}
                                                            className=""
                                                        />
                                                    </div>
                                                    <div className="w-11/12 ml-4 bg-white rounded-lg text-xl">
                                                        <div className="w-full border border-light-purple p-2">
                                                            {entry.question}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div key={index} className="chat-message">
                                            <div
                                                className="m-5">
                                                <div className="flex">
                                                    <div className="w-1/12">
                                                        <img
                                                            src={ansIcon}
                                                            alt=""
                                                            width={"50px"}
                                                            height={"50px"}
                                                            className=""
                                                        />
                                                    </div>
                                                    <div className="w-11/12 ml-4 bg-white rounded-lg text-xl">
                                                        <div className="w-full border border-light-purple p-2">
                                                            <KnowledgeManagementAnswer type={selectedRadioButton} answer={entry.answer} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </div>
                        <div className="fixed bottom-0 thirdCard bg-[#e5eeff]">
                            <div className="mr-10 ml-4 my-4">
                                <div className="flex">
                                    <div className="w-full text-lg">
                                        <textarea
                                            className="w-full border border-[#23479F] rounded-lg p-2"
                                            placeholder="Ask your question here....."
                                            value={currentQuestion}
                                            onChange={(e) =>
                                                setCurrentQuestion(e.target.value)
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault();
                                                    callknowledgeManagementAPI();
                                                }
                                            }}
                                            rows={1}
                                        />
                                        <div className="">
                                            {errorMessage && (
                                                <div className="text-red-600">
                                                    {errorMessage}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        {loading ? (
                                            <div className="overlay">
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <Button
                                                onClick={() => {
                                                    callknowledgeManagementAPI();
                                                }}
                                            >
                                                Ask
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default KnowledgeManagement;
