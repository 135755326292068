import { useState } from "react";
import { Button } from "./CommonComponents";

function AddOrgForm({ onCreate, onCancel }) {
    const [orgData, setOrgData] = useState({
        name: '',
        location: '',
        contact: '',
        email: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrgData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onCreate(orgData);
        setOrgData({ name: '', location: '', contact: '', email: '' }); // Reset form
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Modal Overlay */}
            <div className="absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm" onClick={onCancel} />

            {/* Modal Content */}
            <div className="relative w-full max-w-2xl mx-4 bg-white rounded-lg shadow-xl">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between border-b border-gray-200 pb-4">
                        <h2 className="text-2xl font-semibold text-gray-800">Add Organization</h2>
                        <button
                            onClick={onCancel}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">
                                    Organization Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={orgData.name}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter organization name"
                                />
                            </div>

                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">
                                    Location
                                </label>
                                <input
                                    type="text"
                                    name="location"
                                    value={orgData.location}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter location"
                                />
                            </div>

                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">
                                    Contact
                                </label>
                                <input
                                    type="text"
                                    name="contact"
                                    value={orgData.contact}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter contact"
                                />
                            </div>

                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={orgData.email}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter email address"
                                />
                            </div>
                        </div>

                        <div className="flex justify-center space-x-4 pt-4 border-t border-gray-200">
                            <Button
                                type="submit"
                            >
                                Create
                            </Button>
                            <Button
                                type="Button"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

function AddInstForm({ orgId, onCreateInstitution, onClose }) {
    const [institutionData, setInstitutionData] = useState({
        name: '',
        location: '',
        contact: '',
        email: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInstitutionData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onCreateInstitution(institutionData, orgId);
        onClose(); // Close popup after submission
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Modal Overlay */}
            <div className="absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm" onClick={onClose} />

            {/* Modal Content */}
            <div className="relative w-full max-w-2xl mx-4 bg-white rounded-lg shadow-xl">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between border-b border-gray-200 pb-4">
                        <h2 className="text-2xl font-semibold text-gray-800">Add Institution</h2>
                        <button
                            onClick={onClose}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Organization ID Field */}
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">
                                    Organization ID
                                </label>
                                <input
                                    type="text"
                                    value={orgId}
                                    readOnly
                                    className="w-full p-3 bg-gray-50 border border-gray-300 rounded-lg text-gray-500 cursor-not-allowed focus:outline-none"
                                />
                            </div>

                            {/* Name Field */}
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">
                                    Institution Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={institutionData.name}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter institution name"
                                />
                            </div>

                            {/* Location Field */}
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">
                                    Location
                                </label>
                                <input
                                    type="text"
                                    name="location"
                                    value={institutionData.location}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter location"
                                />
                            </div>

                            {/* Contact Field */}
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700">
                                    Contact Person
                                </label>
                                <input
                                    type="text"
                                    name="contact"
                                    value={institutionData.contact}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter contact person"
                                />
                            </div>

                            {/* Email Field */}
                            <div className="space-y-2 md:col-span-2">
                                <label className="text-sm font-medium text-gray-700">
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={institutionData.email}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter email address"
                                />
                            </div>
                        </div>

                        {/* Form Actions */}
                        <div className="flex justify-center space-x-4 pt-4 border-t border-gray-200">
                            <Button
                                type="submit"
                            >
                                Add Institution
                            </Button>
                            <Button
                                type="button"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export { AddOrgForm, AddInstForm }
