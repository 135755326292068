import "./App.css";
import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import SummarizeContent from "./pages/SummarizeContent";
import OneEngineManyRoles from "./pages/oneEngineManyRoles";
import InsightsRetrieval from "./pages/insightsRetrieval";
import Login from "./components/login";
import SignUp from "./components/signUp";
import OTPVerification from "./components/otpVerification";
import { jwtDecode } from "jwt-decode";
import ForgotPassword from "./components/forgotPassword";
import ResetPassword from "./components/resetPassword";
import { useCookies } from 'react-cookie';
import InformationExtraction from "./pages/InformationExtraction";
import PersonalAssistant from "./pages/PersonalAssistant";
// import AuthDemo from "./pages/authDemo";
import DvdBiBot from "./pages/DvdBiBot";
import EnterpriseSearch from "./pages/EnterpriseSearch";
import TutorBot from "./pages/TutorBot";
import QnAGeneration from "./pages/QnAGeneration";
import KnowledgeManagement from "./pages/KnowledgeManagement";
import RolePlay from "./pages/oneEngineManyRoles";
import TexttoImage from "./pages/TexttoImage";
import CaseStudyContent from "./pages/CaseStudyContent";
import Math from "./pages/Math";
import Science from "./pages/Science";
import ProofReading from "./pages/ProofReading";
import English from "./pages/English";
import GeneralKnowledge from "./pages/GeneralKnowledge";
import Comprehension from "./pages/Comprehension";
import FormNumberExtraction from "./pages/FormNumberExtraction";
import UlmLimitsExtraction from "./pages/UlmLimitsExtraction";
import LanguageTranslation from "./pages/LanguageTranslation";
import Evaluation from "./pages/Evaluation";
import AdaptiveLearning from "./pages/AdaptiveLearning";
import ReadingComprehension from "./pages/ReadingComprehension";
import axios from "axios";
import StoryBuilder from "./pages/StoryBuilder";
import GuidedLearning from "./pages/GuidedLearning";
import Llama from "./pages/Llama3O";
import VideoGeneration from "./pages/VideoGeneration";
import EssayWriter from "./pages/EssayWriter";
import DebateBot from "./pages/DebateBot";
import PdfUpload from "./pages/PdfUpload";
import Management from "./pages/management";
import MultimediaSearch from "./pages/MultimediaSearch";
import EnterpriseFileUpload from "./pages/EnterpriseFileUpload";

const App = () => {
  const [cookies] = useCookies(['jwtToken']);
  const jwtToken = cookies['jwtToken'];


  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to set authentication status
  const setAuthenticationStatus = (status) => {
    setIsAuthenticated(status);
  };

  // Check if the user is authenticated
  useEffect(() => {
    if (jwtToken) {
      setAuthenticationStatus(true);
    } else {
      setAuthenticationStatus(false);
    }
  }, [jwtToken, cookies]);
  const [userDetails, setUserDetails] = useState(null);


  // console.log(isAuthenticated, jwtToken)
  useEffect(() => {
    if (isAuthenticated) {


      const axiosInstance = axios.create({
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      // Fetch user details using Axios
      axiosInstance.get("https://efhp0mxmli.execute-api.ap-south-1.amazonaws.com/dev/validate-jwt")
        .then((response) => {
          // console.log(response.data)
          const data = response.data.message;
          if (data.response_code === "USER_DETAILS_RECEIVED") {
            setUserDetails(data.user);
            sessionStorage.setItem("userDetails", JSON.stringify(data.user));
          }
        })
        .catch((error) => {
          console.error("Failed to fetch user details:", error);
        });
    }
  }, [isAuthenticated, cookies]);

  return (
    <Layout isAuthenticated={isAuthenticated} userDetails={userDetails}>
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route path="/login" element={<Login setAuthenticationStatus={setAuthenticationStatus} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/otp-verification" element={<OTPVerification />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </>
        ) : null}

        {isAuthenticated? (
          <>
            <Route path="/content-summarization" element={<SummarizeContent />} />
            <Route path="/tutor-bot" element={<TutorBot />} />
            <Route path="/qna-generation" element={<QnAGeneration />} />
            <Route path="/comprehension" element={<Comprehension />} />
            <Route path="/content-summarization" element={<SummarizeContent />} />
            <Route path="/roles" element={<OneEngineManyRoles setAuthenticationStatus={setAuthenticationStatus} />} />
            <Route path="/insights-retrieval" element={<InsightsRetrieval />} />
            <Route path="/information-extraction" element={<InformationExtraction />} />
            <Route path="/bi-assistant" element={<DvdBiBot />} />
            <Route path="/enterprise-search" element={<EnterpriseSearch />} />
            <Route path="/knowledge-management" element={<KnowledgeManagement />} />
            <Route path="/role-play" element={<RolePlay />} />
            <Route path="/text-to-image" element={<TexttoImage />} />
            <Route path="/case-study" element={<CaseStudyContent />} />
            <Route path="/proof-reading" element={<ProofReading />} />
            <Route path="/english" element={<English />} />
            <Route path="/general-knowledge" element={<GeneralKnowledge />} />
            <Route path="/math" element={<Math />} />
            <Route path="/science" element={<Science />} />
            <Route path="/" element={<TutorBot />} />
            <Route path="/comprehension" element={<Comprehension />} />
            <Route path="/personal-assistant" element={<PersonalAssistant />} />
            <Route path="/form-number-extraction" element={<FormNumberExtraction />} />
            <Route path="/ulm-limits-extraction" element={<UlmLimitsExtraction />} />
            <Route path="/language-translation" element={<LanguageTranslation />} />
            <Route path="/evaluation" element={<Evaluation />} />
            <Route path="/adaptive-learning" element={<AdaptiveLearning />} />
            <Route path="/reading-comprehension" element={<ReadingComprehension />} />
            <Route path="/story-builder" element={<StoryBuilder />} />
            <Route path="/guided-learning" element={<GuidedLearning />} />
            <Route path="/llama" element={<Llama />} />
            <Route path="/video-generation" element={<VideoGeneration />} />
            <Route path="/essay-writer" element={<EssayWriter />} />
            <Route path="/debate-bot" element={<DebateBot />} />
            <Route path="/pdf-upload" element={<PdfUpload />} />
            <Route path="/management" element={<Management />} />
            <Route path="/multimedia-search" element={<MultimediaSearch />} />
                      
            <Route path="/Enterprise-fileupload" element={<EnterpriseFileUpload />} />
          </>
        ) : null}

        {isAuthenticated ? <Route path="/*" element={<Navigate to="/" />} /> : null}
        {!isAuthenticated ? <Route path="/*" element={<Navigate to="/login" />} /> : null}
      </Routes>
    </Layout>


  );
};

export default App;
