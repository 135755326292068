import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const MATH_SYMBOLS = {
    "Set Theory and Logic": [
        "∅", "∈", "∉", "⊤", "≡", "∋", "∤", "∩", "∪",
        "⊂", "⊃", "⊆", "⊇", "⊥", "∃", "∀", "⊢", "⊨",
        "≾", "≺", "≻", "⧸", "⊗", "⊕"
    ],
    "Calculus and Mathematical Analysis": [
        "∇", "∇·", "∇×", "∂", "∇", "∛", "∫"
    ],
    "Algebra and Linear Algebra": [
        "ℍ", "ℳ", "A⊗B", "n!", "λ", "σ", "τ", "ρ",
        "χ", "θ", "φ", "ψ"
    ],
    "Complex Numbers and Other Number Systems": [
        "ℂ", "ℕ", "ℤ", "ℝ", "ℚ", "ℵ₀", "ℵ"
    ],
    "Functions and Operators": [
        "ℱ", "↔", "≡", "∘", "↦", "⋅"
    ],
    "Geometry and Topology": [
        "∠", "∟", "∥", "⊥", "↻"
    ],
    "Miscellaneous and Advanced Topics": [
        "∇·", "℘", "ℯ", "⊥", "⊘", "⊤", "⇑", "⇓"
    ]
};

export const MathKeyboard = ({ onSymbolClick }) => {
    const [recentSymbols, setRecentSymbols] = useState([]);

    // Load recent symbols from localStorage on component mount
    useEffect(() => {
        const savedRecentSymbols = localStorage.getItem('recentMathSymbols');
        if (savedRecentSymbols) {
            setRecentSymbols(JSON.parse(savedRecentSymbols));
        }
    }, []);

    const handleSymbolClick = (symbol) => {
        // Call the provided onSymbolClick handler
        onSymbolClick(symbol);

        // Update recent symbols
        const updatedRecentSymbols = [
            symbol,
            ...recentSymbols.filter(s => s !== symbol)
        ].slice(0, 10);

        setRecentSymbols(updatedRecentSymbols);
        localStorage.setItem('recentMathSymbols', JSON.stringify(updatedRecentSymbols));
    };

    return (
        <div className="relative w-full max-w-[75vw] bg-white border border-[#23479F] rounded-lg shadow-lg p-4">
            {/* Scrollable Content Container */}
            <div className="max-h-[48vh] overflow-y-auto pr-2 border-b-2">
                {Object.entries(MATH_SYMBOLS).map(([category, symbols]) => (
                    <div key={category} className="mb-4">
                        {/* Category Title with Divider */}
                        <div className="flex items-center mb-2">
                            <div className="flex-grow border-t border-gray-300 mr-4"></div>
                            <span className="font-semibold text-lg text-gray-700">{category}</span>
                            <div className="flex-grow border-t border-gray-300 ml-4"></div>
                        </div>

                        {/* Symbols Grid */}
                        <div className="grid grid-cols-7 gap-2">
                            {symbols.map((symbol, index) => (
                                <button
                                    key={index}
                                    className="p-2 border border-[#23479F] rounded-lg text-lg hover:bg-blue-50"
                                    onClick={() => handleSymbolClick(symbol)}
                                >
                                    {symbol}
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>


            {/* Recently Used Symbols - Sticky Bottom */}
            {recentSymbols.length > 0 && (
                <div className="sticky bottom-0 left-0 right-0 bg-blue-50 px-2 pt-2 rounded-b-lg z-20">
                    <div className="text-sm font-semibold text-blue-800 mb-2">
                        Recently Used Symbols
                    </div>
                    <div className="flex space-x-2 overflow-x-auto pb-2 gap-2">
                        {recentSymbols.map((symbol, index) => (
                            <button
                                key={index}
                                className="flex-shrink-0 p-2 border border-blue-300 rounded-lg text-lg hover:bg-blue-100"
                                onClick={() => handleSymbolClick(symbol)}
                            >
                                {symbol}
                            </button>
                        ))}
                    </div>
                </div>
            )
            }
        </div >
    );
};